import * as cx from 'classnames';
import * as React from 'react';

import { useProfile } from 'common/hooks/useProfile';
import { pickFollow } from 'common/hooks/useProfile/utils';

import { Button } from 'consumer/components/Button';
import { useEmailNagModal } from 'consumer/containers/EmailNagModal';

import * as s from './FollowButton.m.less';
import { Props } from './types';

const FollowButton: React.FunctionComponent<Props> = function FollowButton({
  type, pk, className, id,
  onFollow, onUnfollow,
}) {
  const { show: showEmailNagModal } = useEmailNagModal();
  const { subscription, profile, follow: [follow] } = useProfile();

  const following = pickFollow(profile || subscription, type).includes(pk);

  const handleClick = (): void => {
    if (onFollow && !following) {
      return onFollow();
    }

    if (onUnfollow && following) {
      return onUnfollow();
    }

    if (!profile && !subscription.email) {
      showEmailNagModal(null, () => {
        follow({ type, pk, follow: !following });
      });
    } else {
      follow({ type, pk, follow: !following });
    }

    if (following && onUnfollow) {
      onUnfollow();
    } else if (!following && onFollow) {
      onFollow();
    }
  };

  return (
    <Button
      variant={following ? 'primary' : 'secondary'}
      className={cx(s.button, className, following && s.following)}
      id={id}
      onClick={handleClick}
    >
      <span className={s.following}>Following</span>
      <span className={s.unfollow}>Unfollow</span>
      <span className={s.follow}>Follow</span>
    </Button>
  );
};

const FollowButtonMemo: React.FunctionComponent<Props> = React.memo(FollowButton);
export { FollowButtonMemo as FollowButton };
