import { ReactComponent as Close } from '@mdi/svg/svg/close.svg';
import LogoLargeBlueImg from 'images/logo_large_blue.png';
import { curry } from 'lodash/fp';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Subscription } from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { useProfile } from 'common/hooks/useProfile';
import { ERROR_EMAIL, ERROR_NAME } from 'common/utils/errors';
import { trimObject } from 'common/utils/helpers';
import { logoPath } from 'common/utils/logos';
import { isEmailValid } from 'common/utils/validations';

import { Button } from 'consumer/components/Button';

import {
  EMAIL_NAG_CHECK_INTERVAL, EMAIL_NAG_WAIT, EMAIL_NAG_WAIT_FIRST_TIME,
  EmailNagModalContext,
} from './constants';
import * as s from './EmailNagModal.m.less';
import { OpenProps, Props, SubscriptionErrors } from './types';

const validate = (subscription: Subscription): SubscriptionErrors => {
  const subscriber = trimObject(
    ['firstName', 'lastName', 'email'],
    subscription,
  );

  const { email, firstName, lastName } = subscriber;
  const errors = {
    email: !isEmailValid(email) && ERROR_EMAIL,
    name: (!firstName || !lastName) && ERROR_NAME,
  };

  return Object.keys(errors).reduce(
    (prev, key) => (errors[key] ? ({ ...prev, [key]: errors[key] }) : prev),
    {},
  );
};

const defaultIgnore = [];

export const EmailNagModal: React.FunctionComponent<Props> = function EmailNagModal({
  ignore = defaultIgnore, children, schedule,
}) {
  const timer = React.useRef(0);
  const timeout = React.useRef(0);
  const cycle = React.useRef(0);
  const { pathname } = useLocation();
  const {
    profile,
    subscription,
    saveSubscription: [save, { isLoading, isSuccess }] } = useProfile();
  const [subscriber, setSubscriber] = React.useState(subscription);
  const [name, setName] = React.useState(
    () => [subscription.firstName, subscription.lastName].filter(Boolean).join(' '),
  );
  const [errors, setErrors] = React.useState<SubscriptionErrors>({});
  const [props, setProps] = React.useState<OpenProps>(null);
  const { appCues, popups, playing } = useApplication();

  React.useEffect(() => {
    if (popups && schedule && !props
        && !playing && !appCues
        && (!profile || !subscription.email)
        && !ignore.some((url) => pathname.indexOf(url) === 0)
    ) {
      timer.current = window.setInterval((): void => {
        // protection to not show nag screen over other modals and self
        const hasModals = document.body.className.split(/\s+/).indexOf('modal-open') !== -1;
        if (!hasModals) {
          const wait = timeout.current + EMAIL_NAG_CHECK_INTERVAL;
          const maxwait = cycle.current === 0 ? EMAIL_NAG_WAIT_FIRST_TIME : EMAIL_NAG_WAIT;

          if (wait >= maxwait) {
            setProps({});
            timeout.current = 0;
            cycle.current += 1;
          } else {
            timeout.current = wait;
          }
        } else if (timeout.current !== 0) {
          timeout.current = 0;
        }
      }, EMAIL_NAG_CHECK_INTERVAL * 1000);

      return (): void => window.clearInterval(timer.current);
    }
  }, [
    schedule,
    popups,
    profile,
    subscription.email,
    props,
    playing,
    appCues,
    pathname,
    ignore,
  ]);

  const handleChange = React.useCallback(curry((
    property: 'name' | 'email',
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;

    if (property === 'name') {
      const [firstName = '', lastName = ''] = value
        .trim()
        .split(/\s(.+)/, 2);

      setSubscriber({ ...subscriber, firstName, lastName });
      setName(value);
    } else {
      setSubscriber({ ...subscriber, [property]: value });
    }
  }), [subscriber]);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Thank you!');
      if (props?.action) {
        props.action();
      }
      setProps(null);
    }
  }, [isSuccess]);

  const handleSubmit = React.useCallback((): void => {
    const err = validate(subscriber);
    setErrors(err);

    if (Object.keys(errors).length === 0) {
      save(subscriber);
    }
  }, [subscriber]);

  const team = subscriber.recent_team;

  const handleClose = React.useCallback(() => setProps(null), []);

  const EmailNagModalContextValue = React.useMemo(() => ({ toggle: setProps }), [setProps]);

  return (
    <EmailNagModalContext.Provider value={EmailNagModalContextValue}>
      {children}
      <Modal
        show={Boolean(props)}
        keyboard
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        backdropClassName="modal-backdrop-blackout"
      >
        <Modal.Body>
          <div className={s.container}>
            <button
              className={s.close}
              type="button"
              onClick={handleClose}
            >
              <Close />
            </button>
            <img
              src={LogoLargeBlueImg}
              className={s.logo}
              alt="WePlayed"
            />
            {props?.message || (
              <>
                <div className={s.header}>Brand new, exciting content every week</div>
                <div className={s.info}>
                  Watch
                  {team?.name && team?.sport ? ` ${team.name} ${team.sport.name} ` : ' all the college sports '}
                  you want for free.
                </div>
              </>
            )}
            {team?.logo ? (
              <div className={s.teamLogo}>
                <img src={logoPath(team.logo, 'x-large')} alt="" />
              </div>
            ) : (
              <div className={s.conferenceLogos}>
                <img
                  src="https://media.weplayed.com/conference_logos/conference_acc_logo.png"
                  alt=""
                />
                <img
                  src="https://media.weplayed.com/conference_logos/conference_sec_logo.png"
                  alt=""
                />
                <img
                  src="https://media.weplayed.com/conference_logos/conference_big_12_logo.png"
                  alt=""
                />
              </div>
            )}
            <div className={s.form}>
              <form onSubmit={handleSubmit}>
                <label htmlFor="email-nag-name">Full Name</label>
                <div className={errors?.name && 'has-error'}>
                  <input
                    className="form-control"
                    id="email-nag-name"
                    type="text"
                    name="name"
                    maxLength={255}
                    onChange={handleChange('name')}
                    value={name}
                    required
                  />
                  {errors?.name && (
                    <span className="label label-warning">{errors.name}</span>
                  )}
                </div>

                <label htmlFor="email-nag-email">Email Address</label>
                <div className={errors?.email && 'has-error'}>
                  <input
                    autoComplete="off"
                    autoCorrect="off"
                    className="form-control"
                    id="email-nag-email"
                    type="email"
                    name="email"
                    maxLength={255}
                    onChange={handleChange('email')}
                    value={subscriber.email || ''}
                    required
                  />
                  {errors && errors.email && (
                    <span className="label label-warning">{errors.email}</span>
                  )}
                </div>

                <input type="submit" style={{ position: 'absolute', left: -100000 }} />
              </form>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            size="lg"
            className={s.button}
            onClick={handleSubmit}
            disabled={isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </EmailNagModalContext.Provider>
  );
};
