import * as React from 'react';

interface Props {
  name: string;
  label: string;
  required: boolean;
}

const FormLabel: React.FC<Props> = function FormLabel(props) {
  if (props.label === '') {
    return null;
  }
  return (
    <label htmlFor={props.name}>
      {props.label}
      {' '}
      {props.required ? <span className="required">*</span> : ''}
    </label>
  );
};

export default FormLabel;
