import 'consumer/styles/less/style.less';

import * as React from 'react';
// import { DFPSlotsProvider } from 'react-dfp';
import * as ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';

import { ApplicationProvider } from 'common/components/ApplicationProvider';
import { ErrorBoundary } from 'common/components/ErrorBoundary';

import { App } from 'consumer/containers/App';

import { CLIENT_URLS } from './routes';

const MOUNT_NODE = document.getElementById('app');
const WithHot = hot(App);

const ConsumerWrap: React.FC = function CMSWrap() {
  return (
    <BrowserRouter basename={process.env.URL_PREFIX}>
      <ErrorBoundary>
        <ApplicationProvider loginUrl={CLIENT_URLS.AUTH.LOGIN}>
          {/* <DFPSlotsProvider dfpNetworkId={process.env.DFP_NETWORK_ID}> */}
            <WithHot />
          {/* </DFPSlotsProvider> */}
        </ApplicationProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

ReactDOM.render(<ConsumerWrap />, MOUNT_NODE);
