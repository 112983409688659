import * as cx from 'classnames';
import { debounce } from 'lodash/fp';
import * as React from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { Player, Tag, Team } from 'weplayed-typescript-api';

import { RenderData, toPlayerDisplay } from 'common/utils/moments';

import * as s from './MomentDescription.m.less';
import { MomentDescriptionReadonly } from './MomentDescriptionReadonly';
import { getPlainTextValue, renderTeam } from './ReadOnlyBuilder';
import { Props, State } from './types';

const MAX_CHARS = 500;
const DEBOUNCE_TIMEOUT = 500;

const stopPropagation = (e: React.KeyboardEvent): void => e.stopPropagation();

export class MomentDescription extends React.Component<Props, State> {
  // Use the moment's plain text name if description hasn't been set yet.
  // this helps with migration from legacy moment with only name and no rich description.
  protected static getDescriptionWithFallback = (
    description: string,
    plainTextValue: string,
  ): string => (description || plainTextValue);

  protected static getDerivedStateFromProps(props, state): State | null {
    const result: State = {
      textBoxValue: props.moment?.description || props.moment?.name || '',
      plainTextValue: (props.moment?.description
        ? getPlainTextValue(props.moment?.description)
        : props.moment?.name) || '',
      inEditMode: false,
    };

    if (!props.inEditMode) {
      return result;
    }

    if (!state.inEditMode || result.textBoxValue !== state.textBoxValue) {
      // revert state back if beyond limits in edit mode
      if (result.plainTextValue.length > MAX_CHARS) {
        result.plainTextValue = state.plainTextValue;
        result.textBoxValue = state.textBoxValue;

        // inner values are longer than expected, call onChanged with prev values
        result.needsReset = true;
      }

      result.inEditMode = true;

      return result;
    }

    return null;
  }

  protected static transformPlayerData = (players: Player[]): RenderData<Player>[] => players
    .map((player) => ({
      id: `${player.pk}_${player.team_color}`,
      display: toPlayerDisplay(player),
      color: player.team_color,
    }));

  protected static transformTagData = (tags: Tag[]): RenderData<Tag>[] => tags
    .map((tag) => ({
      id: tag.pk,
      display: `#${tag.name}`,
    }));

  protected static transformTeamData = (teams: Team[]): RenderData<Team>[] => teams
    .map((team) => {
      const color = team.primary_color ? `#${team.primary_color.replace(/^#/, '')}` : '';

      return {
        id: `${team.pk}_${color}`,
        display: team.acronym,
        name: team.name,
        color: color || 'transparent',
      };
    });

  private textArea: React.RefObject<HTMLTextAreaElement> = React.createRef();

  public state: State = { };

  protected tagTrigger = '#';

  protected tagMarkup = '{{#|__id__|__display__}}';

  protected teamTrigger = /(?:^| )([@#]([^@#]*))$/m;

  protected teamMarkup = '{{%|__id__|__display__}}';

  protected playerTrigger = '@';

  protected playerMarkup = '{{@|__id__|__display__}}';

  protected fetchPlayers = debounce(DEBOUNCE_TIMEOUT, (query, callback): void => {
    if (!query || !this.props.onSearchPlayer) {
      return callback([]);
    }

    this.props.onSearchPlayer(query, MomentDescription.transformPlayerData, callback);
  });

  protected fetchTags = debounce(DEBOUNCE_TIMEOUT, (query, callback): void => {
    if (!query || !this.props.onSearchTag) {
      return callback([]);
    }

    this.props.onSearchTag(query, MomentDescription.transformTagData, callback);
  });

  protected fetchTeams = debounce(DEBOUNCE_TIMEOUT, (query, callback): void => {
    if (!query || !this.props.onSearchTeam) {
      return callback([]);
    }

    this.props.onSearchTeam(query, MomentDescription.transformTeamData, callback);
  });

  public componentDidMount(): void {
    this.registerListeners();
    if (this.props.inEditMode) {
      this.putFocusOnTextArea();
    }
  }

  public shouldComponentUpdate(nextProps, nextState): boolean {
    const shouldUpdate = nextState.plainTextValue !== this.state.plainTextValue
      || nextState.textBoxValue !== this.state.textBoxValue
      || nextProps.moment?.description !== this.props.moment?.description
      || nextProps.moment?.name !== this.props.moment?.name
      || nextProps.inEditMode !== this.props.inEditMode
      || nextProps.disabled !== this.props.disabled;
    return shouldUpdate;
  }

  public componentDidUpdate(prevProps): void {
    if (this.textArea.current && this.props.inEditMode) {
      if (!prevProps.inEditMode) {
        this.registerListeners();
      }

      if (!prevProps.inEditMode
        || this.props.moment?.description !== prevProps.moment?.description) {
        this.putFocusOnTextArea();
      }
    }

    if (this.state.needsReset && this.props.onChanged) {
      this.setState(
        { needsReset: false },
        () => this.props.onChanged(this.state.textBoxValue, this.state.plainTextValue),
      );
    }
  }

  protected registerListeners = (): void => {
    if (this.textArea.current) {
      this.textArea.current.addEventListener('focus', this.onFocus);
      this.textArea.current.addEventListener('blur', this.onBlur);
    }
  };

  protected putFocusOnTextArea = (): void => {
    if (this.textArea?.current
        && !this.props.noFocus
        && document.activeElement !== this.textArea.current) {
      this.textArea.current.focus();
      const textLen = this.state.textBoxValue.length;
      this.textArea.current.setSelectionRange(textLen, textLen);
    }
  };

  protected onFocus = (): void => {
    if (this.props.onEditingBegun) {
      this.props.onEditingBegun();
    }
  };

  protected onBlur = (): void => {
    if (this.props.onEditingStopped) {
      this.props.onEditingStopped();
    }
  };

  protected onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const plainTextValue = getPlainTextValue(
      event.target.value,
    );

    if (plainTextValue.length > MAX_CHARS
        && plainTextValue.length >= this.state.plainTextValue.length) {
      return;
    }

    this.setState({
      plainTextValue,
      textBoxValue: event.target.value,
    });

    if (this.props.onChanged) {
      this.props.onChanged(event.target.value, plainTextValue);
    }
  };

  protected onClickReadOnlyTextDiv = (): void => {
    if (this.props.onEditingBegun) {
      this.props.onEditingBegun();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  protected teamRender = (team: RenderData<Team>): React.ReactNode => renderTeam(team);

  // eslint-disable-next-line class-methods-use-this
  protected tagRender = (
    _tag: RenderData<Tag>,
    _search: string,
    highlightedDisplay: string,
  ): React.ReactNode => (
    <div className={cx(s.suggestionItem, s.suggestionItemTag)}>
      {highlightedDisplay}
    </div>
  );

  // eslint-disable-next-line class-methods-use-this
  protected playerRender = (
    player: RenderData<Player>,
    _search: string,
    highlightedDisplay: string,
  ): React.ReactNode => (
    <div
      style={player.color ? { backgroundColor: player.color } : undefined}
      className={s.suggestionItem}
    >
      {highlightedDisplay}
    </div>
  );

  public render(): JSX.Element {
    const {
      className, moment, textareaPlaceholder, disabled,
    } = this.props;
    const { inEditMode } = this.state;

    if (!inEditMode) {
      return (
        <MomentDescriptionReadonly
          className={cx(s.editable, className)}
          moment={moment}
          textareaPlaceholder={textareaPlaceholder}
          onClick={this.onClickReadOnlyTextDiv}
        />
      );
    }

    return (
      <div
        className={cx(s.container, disabled && s.disabled)}
        onKeyPress={stopPropagation}
      >
        <div className={s.placeholder}>
          {this.state.textBoxValue ? null : this.props.textareaPlaceholder}
        </div>
        <MentionsInput
          allowSpaceInQuery
          inputRef={this.textArea}
          value={this.state.textBoxValue}
          onChange={this.onChange}
          className={cx(s.textbox, this.props.className)}
        >
          <Mention
            trigger={this.teamTrigger}
            markup={this.teamMarkup}
            renderSuggestion={this.teamRender}
            data={this.fetchTeams}
          />

          <Mention
            allowSpaceInQuery
            trigger={this.playerTrigger}
            markup={this.playerMarkup}
            renderSuggestion={this.playerRender}
            data={this.fetchPlayers}
          />

          <Mention
            allowSpaceInQuery
            trigger={this.tagTrigger}
            markup={this.tagMarkup}
            renderSuggestion={this.tagRender}
            data={this.fetchTags}
          />
        </MentionsInput>
        <div className={s.counter}>
          {(this.state.plainTextValue || '').length}
          {' '}
          /
          {MAX_CHARS}
        </div>
      </div>
    );
  }
}
