import cx from 'classnames';
import { defaults } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Player, Tag, Team } from 'weplayed-typescript-api';

import { isColorDark } from 'common/utils/colors';
import {
  parseAsPlayer, parseAsTag, parseAsTeam, RenderData,
} from 'common/utils/moments';

import * as s from './MomentDescription.m.less';
import { ReadOnlyBuilderOptions } from './types';

const PROPS_CACHE = {};

const getProps = (
  className: string,
  color: string | null,
): Pick<React.HTMLProps<HTMLElement>, 'className' | 'style'> => {
  const key = `${className}-${color && color !== 'transparent' ? color : 'none'}`;

  if (!(key in PROPS_CACHE)) {
    const isDark = isColorDark(color);
    PROPS_CACHE[key] = {
      style: { backgroundColor: color },
      className: cx(className, !isDark && s.light),
    };
  }
  return PROPS_CACHE[key];
};

export const renderTeam = (
  team: RenderData<Team>,
  // eslint-disable-next-line default-param-last
  link?: ReadOnlyBuilderOptions['link'],
  key?: React.Key,
): React.ReactNode => {
  const text = team.name ? `${team.name} (${team.display})` : team.display;
  const props = { key, ...getProps(s.team, team.color) };

  return link
    ? <Link to={link('team', team.id)} {...props}>{text}</Link>
    : <span {...props}>{text}</span>;
};

export const renderPlayer = (
  player: RenderData<Player>,
  // eslint-disable-next-line default-param-last
  link?: ReadOnlyBuilderOptions['link'],
  key?: React.Key,
): React.ReactNode => {
  const text = player.display;
  const props = { key, ...getProps(s.player, player.color) };

  return link
    ? <Link to={link('player', player.id)} {...props}>{text}</Link>
    : <span {...props}>{text}</span>;
};

export const renderTag = (
  tag: RenderData<Tag>,
  // eslint-disable-next-line default-param-last
  link?: ReadOnlyBuilderOptions['link'],
  key?: React.Key,
): React.ReactNode => {
  const text = tag.display;
  const props: Pick<React.HTMLProps<HTMLElement>, 'className' | 'key'> = {
    className: s.tag,
    key,
  };

  return link
    ? <Link to={link('tag', text)} {...props}>{text}</Link>
    : <span {...props}>{text}</span>;
};

const renderSegment = (
  segment: string,
  key: number,
  options: ReadOnlyBuilderOptions = {},
): React.ReactNode => {
  const team = parseAsTeam(segment);
  if (team) {
    return renderTeam(team, options.link, key);
  }

  const player = parseAsPlayer(segment);
  if (player) {
    return renderPlayer(player, options.link, key);
  }

  const tag = parseAsTag(segment);
  if (tag) {
    return renderTag(tag, options.link, key);
  }

  return (
    <React.Fragment key={key}>
      {segment}
    </React.Fragment>
  );
};

const getDisplayValueFromSegment = (segment: string): string => {
  if (!segment || segment.length === 0) {
    return '';
  }

  const player = parseAsPlayer(segment);
  if (player) {
    return player.display;
  }

  const tag = parseAsTag(segment);
  if (tag) {
    return tag.display;
  }

  return segment;
};

const checkInlineTruncateAround = (
  inlineTruncateAround,
  textLength,
  display,
): boolean => inlineTruncateAround > 0
           && textLength + 4 + display.length > inlineTruncateAround;

export class ReadOnlyBuilder {
  private segment: string;

  private display: string;

  private description: string;

  private readonly len: number;

  private elements: React.ReactNode[];

  private plainText: string;

  private lengthSoFar: number;

  private stopAppending: boolean;

  private inlineTruncateAround: number;

  private options: ReadOnlyBuilderOptions;

  private segmentId = 0;

  constructor(description: string, options: ReadOnlyBuilderOptions) {
    // we're mocking text only tag entry into a format supported by parser
    this.description = description.replace(
      /(^|[,. ]+)(#[a-z0-9-+]+)/ig,
      '$1{{#|00000000-0000-0000-0000-000000000000|$2}}',
    );
    this.len = this.description.length;
    this.options = defaults({}, options || {}, { withLinks: true });
    this.reset();
  }

  reset = (): void => {
    this.elements = [];
    this.plainText = '';
    this.segment = '';
    this.display = '';
    this.lengthSoFar = 0;
    this.stopAppending = false;
    this.inlineTruncateAround = 0;
  };

  processCurrentSegment = (): void => {
    this.display = getDisplayValueFromSegment(this.segment);
    if (checkInlineTruncateAround(this.inlineTruncateAround, this.lengthSoFar, this.display)) {
      // Time to truncate without displaying the current segment. Just add " ..."
      if (this.lengthSoFar === 0) {
        // Likely just plain text at the very start of the description and it is too long to fit on
        // its own.
        this.display = `${this.display.substr(
          0,
          this.inlineTruncateAround - 4,
        )} ...`;
      } else {
        this.display = '...';
      }
      this.segment = this.display;
      this.stopAppending = true;
    }
    this.plainText = `${this.plainText}${this.display}`;
    this.elements.push(renderSegment(this.segment, this.segmentId, this.options));
    this.lengthSoFar = this.lengthSoFar + 1 + this.display.length; // '1' accounts for the space
    this.segment = '';
    this.segmentId += 1;
  };

  getReadOnly = (
    asPlainText: boolean,
    inlineTruncateAround: number,
  ): React.ReactNode[] | string => {
    this.reset();

    this.inlineTruncateAround = inlineTruncateAround;

    const maxIndex = this.len - 1;
    let insideMarker = false;

    for (let i = 0; i < this.len; i += 1) {
      const c = this.description.charAt(i);
      // Check for consecutive "{{"
      if (
        c === '{'
        && i + 1 <= maxIndex
        && this.description.charAt(i + 1) === '{'
        && !insideMarker
      ) {
        if (this.segment.length > 0) {
          this.processCurrentSegment();
          if (this.stopAppending) {
            break;
          }
        }
        insideMarker = true;
      }
      // Check for consecutive "}}"
      if (
        c === '}'
        && i - 1 >= 0
        && this.description.charAt(i - 1) === '}'
        && insideMarker
      ) {
        if (this.segment.length > 0) {
          /* eslint no-useless-escape:1 */
          this.segment = `${this.segment}}`; // add the final }
          this.processCurrentSegment();
          if (this.stopAppending) {
            break;
          }
        }
        insideMarker = false;
        // to skip adding the final } to the next segment
        // eslint-disable-next-line no-continue
        continue;
      }

      if (c === '{' && !insideMarker) {
        if (this.segment.length > 0) {
          this.processCurrentSegment();
          if (this.stopAppending) {
            break;
          }
        } else {
          // reset segment
          this.segment = '';
        }
      }

      this.segment += c;
    }

    // TOOD: refactor this segment processing to a builder class.
    if (this.segment.length > 0) {
      this.processCurrentSegment();
    }

    return asPlainText ? this.plainText : this.elements;
  };
}

export const renderReadOnly = (
  description: string,
  asPlainText: boolean,
  inlineTruncateAround: number,
  options: ReadOnlyBuilderOptions = {},
): React.ReactNode => {
  const builder = new ReadOnlyBuilder(description, options);
  const result = builder.getReadOnly(asPlainText, inlineTruncateAround);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return asPlainText ? result : <>{result}</>;
};

export const getPlainTextValue = (description: string): string => (
  renderReadOnly(description, true, null) as string
);
