import * as React from 'react';

import * as s from './ErrorMessage.m.less';
import { Props } from './types';

export const ErrorMessage: React.FC<Props> = function ErrorMessage({
  title = 'Sorry, error is occured',
  error,
}) {
  return error
    ? (
      <div className={s.root}>
        <h3>{title}</h3>
        <pre>{error instanceof Error ? error.message : error}</pre>
      </div>
    )
    : null;
};
