import * as React from 'react';

import { VideoPlayer } from '../VideoPlayer';
import { VideoPlayerRefType } from '../VideoPlayer/types';
import * as s from './GamePlayer.m.less';
import { GamePlayerControls } from './GamePlayerControls';
import { GamePlayerProps } from './types';

const help = (
  <>
    Moments
    <dl>
      <dt>⏎</dt>
      <dd>Create / Edit Moment</dd>
    </dl>
  </>
);

export const GamePlayer = React.memo(React.forwardRef<
  VideoPlayerRefType, GamePlayerProps
>(({ onMomentCreate, controls, ...props }, ref) => {
  const keyListener = React.useCallback((key: string): boolean => {
    if (key === 'Enter' && onMomentCreate) {
      onMomentCreate();
      return true;
    }

    return false;
  }, [onMomentCreate]);

  return (
    <div className={s.root}>
      <VideoPlayer
        {...props}
        controls={controls ? <GamePlayerControls /> : null}
        help={onMomentCreate && help}
        onKeyDown={keyListener}
        ref={ref}
      />
    </div>
  );
}));

GamePlayer.displayName = 'GamePlayer';
