import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';

import { Avatar } from 'consumer/components/Avatar';
import { CLIENT_URLS } from 'consumer/routes';

export const Profile: React.FunctionComponent = function Profile() {
  const { profile } = useAuth();

  return (
    <div className="container">
      <Helmet>
        <title>WePlayed: My Profile</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>USER PROFILE</h1>
          <br />
          <div className="profile-form-avatar-container">
            <Avatar user={profile} size="x-large" hideVerified />
          </div>
          <br />
          <h4>{`Username: ${profile.username}`}</h4>
          <br />
          <p>{`Name: ${profile.full_name || '-'}`}</p>
          <p>{`Email: ${profile.email}`}</p>

          <div className="profile-form-save-button-container">
            <Button
              size="lg"
              as={Link}
              to={CLIENT_URLS.USER.MY_PROFILE_UPDATE.toPath()}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
