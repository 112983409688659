import { InfiniteQueryResult, QueryResult } from 'react-query';
import {
  Channel, Collection, Facet, Game, HttpError, Moment, PaginatedResponse,
  Player, search,
} from 'weplayed-typescript-api';

export type SearchParams = search.Params<search.WhatSearch>;

export enum Section {
  MOMENTS = 'moments',
  PLAYLISTS = 'playlists',
  GAMES = 'games',
  PLAYERS = 'players',
  CHANNELS = 'channels',
}

type SearchStats = {
  [K in Section]: number;
};

interface QueryResultPair<T> {
  data: InfiniteQueryResult<PaginatedResponse<T>, HttpError>;
  facets: QueryResult<Facet, HttpError>;
}

export type UseSearchSectionReturnType<T> = QueryResultPair<T>;

export interface UseSearchSectionArgs<T> {
  data?: (
    params: SearchParams,
    count: number,
    page?: number,
  ) => Promise<PaginatedResponse<T>>;
  enabled: boolean;
  facets?: (params: SearchParams) => Promise<Facet>;
  query: SearchParams;
  type: string;
}

export interface UseSearchReturnType {
  summary: QueryResult<SearchStats, HttpError>;
  [Section.MOMENTS]: UseSearchSectionReturnType<Moment>;
  [Section.PLAYLISTS]: UseSearchSectionReturnType<Collection>;
  [Section.GAMES]: UseSearchSectionReturnType<Game>;
  [Section.PLAYERS]: UseSearchSectionReturnType<Player>;
  [Section.CHANNELS]: UseSearchSectionReturnType<Channel>;
}

interface UseSearchArgs {
  query: SearchParams;
  enabled?: Section | Section[];
}

export type UseSearchType = (args: UseSearchArgs) => UseSearchReturnType;
