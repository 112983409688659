import * as cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { formatDate } from 'common/utils/dates';

import { LiveButton } from 'consumer/components/LiveButton';
import { TeamImage } from 'consumer/components/TeamImage';
import { Renderer } from 'consumer/components/Tile/types';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './GameTile.m.less';
import { Props } from './types';

export const GameTile: React.FC<Props> = function GameTile({
  item,
  showBottom = true,
}) {
  return (
    <div className={cx(s.root, item.live_now && s.live)}>
      <Link
        className={cx(item.sport && item.sport.slug)}
        to={CLIENT_URLS.GAME.VIEW.toPath({ uid: item.pk })}
      >
        <div>
          <div className={s.team}>
            <TeamImage team={item.team1} size="x-large" />
            <span>{item.team1_score}</span>
          </div>
          <span>at</span>
          <div className={s.team}>
            <TeamImage team={item.team2} size="x-large" />
            <span>{item.team2_score}</span>
          </div>
        </div>
        <div className={s.date}>
          {item.live_now ? <LiveButton live collapse={false} /> : formatDate(item.date_played)}
        </div>
        {item.name_extra && (
          <div className={s.extra} title={item.name_extra}>
            {item.name_extra}
          </div>
        )}
      </Link>
      {showBottom && (
        <div className="tile-legend">
          <div />
          <div className="tile-legend-moment-count">
            <Link to={CLIENT_URLS.GAME.VIEW.toPath({ uid: item.pk })}>
              <button
                className="btn btn-primary nowrap-button"
                type="button"
              >
                {item.moment_count > 0
                  ? `${item.moment_count} Moments`
                  : 'Create Your First Moment!'}
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export const gameRenderer: Renderer<What.GAMES> = (
  item,
  options,
) => (
  <GameTile
    {...options}
    item={item}
  />
);
