import { format } from 'date-fns';
import { isEmpty, isEqual } from 'lodash/fp';
import {
  autocomplete, Game, ID, Moment, MomentPublication, PinConfig, PinConfigEntry,
  Player, PublishDestination, SportSlug, Tag, Team, What,
} from 'weplayed-typescript-api';

import { CollectionMomentsSortOrder, EmbedParams } from 'common/types';
import {
  EventContext, EVENTS, MomentEvents, weplayedEvent,
} from 'common/utils/events';

export interface RenderData<T,
  C = T extends Player | Team ? string : never,
  L = T extends Team ? string : never> {
  id: string;
  display: string;
  color?: C;
  logo?: L;
  name?: L;
  prefix?: string;
  postfix?: string;
}

/**
 * Parses team mentions in format
 * {{%|<id>_<color>|<display>}}
 * color is optional
 */
 export const parseAsTeam = (segment: string): RenderData<Team> | null => {
  if (segment) {
    const match = segment.match(/(.*)\{\{%\|([a-f0-9-]+)_(#[a-f0-9]{0,6})?\|(.*?)\}\}(.*)/is);

    if (match) {
      return {
        id: match[2],
        color: match[3] && match[3].length > 1 ? match[3] : 'transparent',
        display: match[4],
        prefix: match[1],
        postfix: match[5],
      };
    }
  }

  return null;
};

/**
 * Parses player embedded tag in format
 * {{@|<id>_<color>|<display>}}
 * color is optional
 */
 export const parseAsPlayer = (segment: string): RenderData<Player> | null => {
  if (segment) {
    const match = segment.match(/(.*)\{\{@\|([a-f0-9-]+)_(#[a-f0-9]{0,6})?\|(.+?)\}\}(.*)/is);

    if (match && match.length === 6) {
      return {
        id: match[2],
        color: match[3] && match[3].length > 1 ? match[3] : 'transparent',
        display: match[4],
        prefix: match[1],
        postfix: match[5],
      };
    }
  }

  return null;
};

/**
 * Parses tag in formats
 * {{#|<id>|<display>}}
 * or
 * #display
 */
 export const parseAsTag = (segment: string): RenderData<Tag> | null => {
  if (segment) {
    let match = segment.match(/(.*)\{\{#\|([a-z0-9-+]+)\|(.+?)\}\}(.*)/is);

    if (match) {
      return {
        id: match[2],
        display: match[3],
        prefix: match[1],
        postfix: match[4],
      };
    }

    match = segment.match(/(.*)(#[a-z0-9-+]+)(.*)/is);
    if (match) {
      return {
        id: 'new',
        display: match[2],
        prefix: match[1],
        postfix: match[3],
      };
    }
  }

  return null;
};

/**
 * Sorts moments with passed userPk precedence.
 * May reverse moment sorting within groups
 */
export const sortMomentsByTimeWithUser = (
  moments: Moment[],
  userPk?: string,
  reverse?: boolean,
): Moment[] => (
  Array.from(moments).sort((a: Moment, b: Moment): number => {
    if (userPk && a.curator?.pk === userPk && b.curator?.pk !== userPk) {
      return -1;
    }

    if (userPk && a.curator?.pk !== userPk && b.curator?.pk === userPk) {
      return 1;
    }

    return reverse ? b.start - a.start : a.start - b.start;
  })
);

// assumes moments are already sorted by time
export const createGroups = (moments: Moment[], size: number, _userPk: string): Moment[][] => {
  // group moments into groups
  const grouped = moments.reduce((mm: Moment[][], m: Moment): Moment[][] => {
    let group: Moment[] = mm[mm.length - 1] || [];

    if (!group[0] || m.start - group[0].start < size) {
      group.push(m);
    } else {
      group = [m];
    }

    if (group.length > 1) {
      // eslint-disable-next-line no-param-reassign
      mm[mm.length - 1] = group;
    } else {
      mm.push(group);
    }

    return mm;
  }, []);

  return grouped;

  /* no point in sorting with user preference anymore
  // sort moments in groups with user precedence
  const group_sorted = grouped.map(($moments: Moment[]): Moment[] => sortMoments($moments, userPk));

  return group_sorted;
  */
};

const isPositionEvent = <T extends Omit<MomentEvents, 'moment_id'>>(
  event: T,
): event is T & { position: number } => event.position !== undefined;

export const momentViewEvent = (
  moment_id: string,
  context: EventContext,
  params?: EmbedParams,
): void => weplayedEvent({
  collection_id: params?.collectionId || null,
  context,
  features: isEmpty(params?.features) ? null : params.features,
  moment_id,
  parent: params?.parent,
  type: EVENTS.MOMENT_VIEW,
});

export type MomentEventDispatcher = (event: Omit<MomentEvents, 'moment_id'>) => void;

/**
 * Creates moment events dispatcher for use in VideoPlayer
 *
 * @param moment Moment events dispatcher create for
 * @param params Parameters passed in embedded mode
 * @returns Moment event dispatcher
 */
export const createMomentEventDispatcher = (
  moment: Moment,
  context: EventContext,
  params?: EmbedParams,
): MomentEventDispatcher => (event: MomentEvents): void => {
  if (event.type === EVENTS.MOMENT_VIEW) {
    return momentViewEvent(moment.pk, context, params);
  }

  const position = isPositionEvent(event)
    ? Math.max(moment.start, Math.min(moment.end, event.position))
    : null;

  const wEvent: MomentEvents = {
    ...event,
    collection_id: params?.collectionId || null,
    context,
    features: isEmpty(params?.features) ? null : params?.features,
    moment_id: moment.pk,
    parent: params?.parent,
    position,
  };

  weplayedEvent(wEvent);
};

export const momentDuration = (moment: Moment): number => moment.end - moment.start;

export const momentsCount = (moments: number): string | null => (
  `${moments} moment${moments !== 1 ? 's' : ''}`
);

export async function searchPlayer(
  name: string,
  game,
  transformFunc,
  callbackFunc,
): Promise<void> {
  if (!name || name.trim().length === 0) {
    callbackFunc([]);
    return;
  }

  try {
    const data = await autocomplete.players({
      search: name,
      date_active: game.date_played ? format(game.date_played, 'yyyy-MM-dd') : null,
      team: [game.team1.pk, game.team2.pk],
    });
    callbackFunc(transformFunc(data));
  } catch (error) {
    callbackFunc([]);
  }
}

export async function searchTag(
  name: string,
  sportId: string,
  transformFunc,
  callbackFunc,
): Promise<void> {
  if (!name || name.trim().length === 0) {
    callbackFunc([]);
    return;
  }

  try {
    const data = await autocomplete.tags({ sportId, search: name });
    callbackFunc(transformFunc(data));
  } catch (error) {
    callbackFunc([]);
  }
}

export async function searchTeam(
  name: string,
  game: Game,
  transformFunc,
  callbackFunc,
): Promise<void> {
  if (!name || name.trim().length === 0) {
    callbackFunc([]);
    return;
  }

  const needle = name.toLowerCase().trim();
  const suggestions = [game.team1, game.team2]
    .filter((team: Team) => needle.length !== 0 && (
      team.name.toLowerCase().indexOf(needle) !== -1
    || team.acronym.toLowerCase().indexOf(needle) !== -1));
  callbackFunc(transformFunc(suggestions));
}

export const sortMoments = (moments: Moment[], order: CollectionMomentsSortOrder): Moment[] => {
  const inverse = [
    CollectionMomentsSortOrder.PLAY_DESC,
    CollectionMomentsSortOrder.DATE_DESC,
  ].includes(order);

  const getTime = (m: Moment): number => (
    [CollectionMomentsSortOrder.PLAY_ASC, CollectionMomentsSortOrder.PLAY_DESC].includes(order)
      ? new Date(m.game.date_played).valueOf() + m.start
      : new Date(m.ctime).valueOf()
  );

  return [...moments].sort((a, b) => {
    const aa = getTime(a);
    const bb = getTime(b);
    const result = (aa > bb && 1) || (aa < bb && -1) || 0;

    return inverse ? -result : result;
  });
};

export const guessMomentsSort = (moments: Moment[]): CollectionMomentsSortOrder => {
  const pkify = ({ pk }): string => pk;
  const pks = moments.map(pkify);

  return Object.values(CollectionMomentsSortOrder).find((order) => isEqual(
    pks,
    sortMomentsByTimeWithUser(moments, order).map(pkify),
  ));
};

export const toPlayerDisplay = (player: Player): string => `${
  player.name
} (${
  player.number
    ? player.number
    : player.position
})${
  player.number
    ? ` ${player.position}`
    : ''
}`.trim();

/**
 * Converts provided publishing status into moment blocked status
 * according to provided context
 * @param type Type where moment is published, team, org, school etc
 * @param publication Publication content
 * @param uid ID of where publishing is changed, e.g. team ID, athlete ID etc
 * @returns Blocked in the given context or not
 */
export const isBlocked = (
  type: PublishDestination,
  publication: MomentPublication,
  uid: ID,
): boolean => {
  const {
    blocked_from_all,
    blocked_from_org_schools,
    blocked_from_org_sport,
    blocked_from_org,
    blocked_from_team_athletes,
    blocked_from_team,
  } = publication;

  switch (type) {
    case What.ORGS: {
      return blocked_from_org || false;
    }

    case What.SPORTS: {
      return blocked_from_org_sport || false;
    }

    case What.SCHOOLS: {
      return blocked_from_org_schools?.includes(uid) || false;
    }

    case What.TEAMS: {
      return blocked_from_team || false;
    }

    case What.ATHLETES: {
      return blocked_from_team_athletes?.includes(uid) || false;
      break;
    }

    default: {
      return blocked_from_all || false;
    }
  }
};

/**
 * Detects is moment pinned in given context
 * @param config Context pin config
 * @param sport Sport slug if present
 * @param uid Moment ID
 * @returns Pin status
 */
export const isPinned = (
  config: PinConfig,
  sport: SportSlug | undefined,
  /**
   * Moment ID to search for in config
   */
  uid: ID,
): PinConfigEntry | false => {
  const path: keyof PinConfig = sport ? 'top-moment-sport-pins' : 'top-moment-pins';
  const pins: PinConfigEntry[] = sport ? config[path][sport] : config[path];
  const map = Object.fromEntries((pins || []).map((c) => [c.id, c]));

  return map?.[uid] || false;
};
