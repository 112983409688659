import * as cx from 'classnames';
import { curry, memoize } from 'lodash/fp';
import { parse } from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Moment } from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { useAuth } from 'common/hooks/useAuth';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { EventContext, EVENTS, weplayedEvent } from 'common/utils/events';
import { cleanPageTitle } from 'common/utils/seo';

import { MomentActionBarProvider } from 'consumer/components/MomentActionBar';
import {
  Action, MomentActionBarProviderRefType,
} from 'consumer/components/MomentActionBar/types';
import { MomentPresenter } from 'consumer/components/MomentPresenter';
import {
  MomentPresenterInstance,
} from 'consumer/components/MomentPresenter/types';
import { useCollection } from 'consumer/hooks/useCollection';
import { useMoments } from 'consumer/hooks/useMoments';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './Collection.m.less';
import { CollectionView } from './CollectionView';
import { CollectionContext } from './constants';
import {
  CollectionContextType, CollectionPostLoginAction, QueryParams, RouteParams,
  RouteProps,
} from './types';

const CollectionEdit: React.FC = React.lazy(
  async () => {
    const { CollectionEdit: edit } = await import(
      /* webpackChunkName: "collection-edit" */ './CollectionEdit'
    );

    return { default: edit };
  },
);

export const Collection: React.FC = function Collection() {
  const match = useRouteMatch<RouteParams>(CLIENT_URLS.COLLECTIONS.VIEW_COLLECTION.route);
  const location = useLocation();
  const history = useHistory();

  const [scrolled, setScrolled] = React.useState(false);
  const [adjustPk, setAdjustPk] = React.useState<string>();

  const dispatcher = React.useRef<MomentActionBarProviderRefType>();
  const presenters = React.useRef<Record<string, MomentPresenterInstance>>({});

  const { appCues, broadcast, registerAuthAware, strict, wlOrgId } = useApplication();
  const { profile: user } = useAuth();

  const { uid, action, cwu } = React.useMemo<RouteProps>((): RouteProps => {
    const qs = parse(location.search) as QueryParams;

    return {
      ...match.params,
      cwu: qs.cwu === '1',
    };
  }, [location.search, match.params]);

  const {
    clone: [
      clone,
      { data: newPk, isLoading: isCloning, error: cloneError },
    ],
    collection: { data: collection, isLoading, error },
    loadMore,
    download: [
      download,
      { isLoading: isDownloading, isSuccess: isDownloadSuccess, error: downloadError },
    ],
    remove: [
      remove,
      { isLoading: isRemoving, isSuccess: isRemoved, error: removeError },
    ],
    like: [like],
    moments: [
      sort,
      { isLoading: isSorting, isSuccess: isSortingSuccess, error: sortingError },
    ],
    update: [
      update,
      { isLoading: isUpdating, isSuccess: isUpdated, error: updateError },
    ],
  } = useCollection({
    uid,
    infinite: action !== 'edit',
    where: wlOrgId ? 'inSharePage' : undefined,
    wlOrgId,
  });

  const {
    update: [
      updateMoment,
      { isLoading: isMoment, isSuccess: isMomentSuccess, error: momentError },
    ],
  } = useMoments();

  useHandleQuery({ isLoading, error });

  React.useEffect(() => {
    if (isUpdated) {
      broadcast('success', 'Playlist updated');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  React.useEffect(() => {
    if (updateError) {
      broadcast('error', 'Cannot update collection');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  React.useEffect(() => {
    if (isSortingSuccess) {
      broadcast('success', 'Moments order updated');
      history.replace(CLIENT_URLS.COLLECTIONS.VIEW_COLLECTION.buildPath({ uid }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSortingSuccess]);

  React.useEffect(() => {
    if (sortingError) {
      broadcast('error', 'Cannot save moments order');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingError]);

  React.useEffect(() => {
    if (isMomentSuccess) {
      broadcast('success', 'Moment saved successfully');
      setAdjustPk(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMomentSuccess]);

  React.useEffect(() => {
    if (momentError) {
      broadcast('error', 'Cannot save moment');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [momentError]);

  React.useEffect(() => {
    if (isDownloadSuccess) {
      broadcast(
        'success',
        'Email with download with be sent shortly',
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadSuccess]);

  React.useEffect(() => {
    if (downloadError) {
      if (downloadError.status === 403) {
        broadcast(
          'error',
          'Sorry, you do not have the necessary content rights to get this download',
        );
      } else {
        broadcast('error', 'Error occurred while requesting the download');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadError]);

  React.useEffect(() => {
    if (newPk) {
      broadcast('success', 'Playlist cloned successfully');
      const url = CLIENT_URLS.COLLECTIONS.VIEW_COLLECTION.toPath({ uid: newPk });
      history.push(url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPk]);

  React.useEffect(() => {
    if (cloneError) {
      broadcast('error', 'Cannot clone collection');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneError]);

  React.useEffect(() => {
    if (isRemoved) {
      broadcast('success', 'Playlist removed');
      history.replace(CLIENT_URLS.USER.MY_CHANNEL.toPath());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoved]);

  React.useEffect(() => {
    if (removeError) {
      broadcast('error', 'Cannot remove collection');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeError]);

  const invokeAuthAware = React.useMemo(() => registerAuthAware(
    (a: CollectionPostLoginAction | Action) => {
      if (a.action === 'collection_like') {
        const { like: liked } = a;
        if (!collection
          || (collection.liked_by_user && like)
          || (!collection.liked_by_user && !like)) {
          return;
        }

        like(liked);
      } else {
        window.setTimeout(() => {
          const presenterRef = presenters.current[a.momentPk];
          if (presenterRef) {
            presenterRef.ref.current.scrollIntoView();
          }
        }, 300);

        dispatcher.current(a);
      }
    },
  ), [collection, like, registerAuthAware]);

  React.useEffect(() => {
    if (collection?.pk && action !== 'edit') {
      weplayedEvent({
        context: EventContext.COLLECTION,
        type: EVENTS.COLLECTION_VIEW,
        collection_id: collection.pk,
      });
    }
  }, [action, collection?.pk]);

  React.useEffect(() => {
    const listener = (): void => {
      const headerHeight = parseInt(
        window.getComputedStyle(document.body).getPropertyValue('--header-height'),
        10,
      );

      if (headerHeight > window.scrollY && scrolled) {
        setScrolled(false);
      } else if (headerHeight < window.scrollY && !scrolled) {
        setScrolled(true);
      }
    };

    window.addEventListener('scroll', listener);

    return (): void => {
      window.removeEventListener('scroll', listener);
    };
  }, [scrolled]);

  const handleActionBar = React.useCallback((a: Action) => {
    if (a.action === 'adjust') {
      setAdjustPk(a.momentPk);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const register: CollectionContextType['register'] = React.useCallback(
    memoize(curry((momentPk: string, presenter: MomentPresenter) => {
      if (presenter) {
        presenters.current[momentPk] = presenter;
      } else {
        delete presenters.current[momentPk];
      }
    })),
    [],
  );

  const handleLike = React.useCallback((liked: boolean) => {
    invokeAuthAware({ action: 'collection_like', like: liked });
  }, [invokeAuthAware]);

  const handleRename = React.useCallback((name: string) => {
    update({ name });
  }, [update]);

  const handleMoment = React.useCallback((moment: Moment) => {
    updateMoment({ uid: moment.pk, moment });
  }, [updateMoment]);

  const handleMomentRemove = React.useCallback((moment: Moment) => {
    invokeAuthAware({
      action: 'remove',
      momentPk: moment.pk,
      gamePk: moment.game?.pk || moment.game_id,
      collectionPk: uid,
    });
  }, [invokeAuthAware, uid]);

  const context: CollectionContextType = React.useMemo(() => ({
    action,
    adjustPk,
    collection,
    cwu,
    isCloning,
    isRemoving,
    isDownloading,
    isMoment,
    isSorting,
    isUpdating,
    loadMore,
    onClone: clone,
    onCancelMomentEdit: (): void => setAdjustPk(null),
    onDelete: remove,
    onDownload: download,
    onLike: handleLike,
    onMoment: handleMoment,
    onMomentRemove: handleMomentRemove,
    onRename: handleRename,
    onSort: sort,
    paused: appCues,
    register,
    scrolled,
    strict,
    user,
  }), [
    action, adjustPk, appCues, collection, cwu,
    isCloning, isRemoving, isDownloading, isMoment, isSorting, isUpdating,
    clone, remove, download, handleLike,
    handleMoment, handleMomentRemove, handleRename, loadMore, register,
    scrolled, sort, strict, user,
  ]);

  return (
    <CollectionContext.Provider value={context}>
      <Helmet>
        <title>
          {`WePlayed Playlist: ${cleanPageTitle(context.collection?.name)}`}
        </title>
      </Helmet>
      <MomentActionBarProvider
        ref={dispatcher}
        user={user}
        onUnauthorized={invokeAuthAware}
        onAccept={handleActionBar}
      >
        <div className={cx(s.root, wlOrgId && s.wl)}>
          {context.collection && (
            action === 'edit' ? <CollectionEdit /> : <CollectionView />
          )}
        </div>
      </MomentActionBarProvider>
    </CollectionContext.Provider>
  );
};
