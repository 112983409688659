import * as React from 'react';

import {
  HINT_BEGIN, HINT_CC, HINT_CONTROLS, HINT_END, HINT_FULLSCREEN, HINT_HELP,
  HINT_NEXT, HINT_PAUSE_RESUME, HINT_PREV, HINT_SKIP_BACK, HINT_SKIP_FWD,
  HINT_SLOW_DOWN, HINT_SPEED_UP, HINT_VOLUME_DOWN, HINT_VOLUME_UP,
} from './constants';
import { VideoPlayerHelpProps } from './types';
import * as s from './VideoPlayer.m.less';

export const VideoPlayerHelp: React.FC<VideoPlayerHelpProps> = function VideoPlayerHelp({
  allowFullscreen,
  help,
  onNext,
  onPrevious,
  vtt,
}) {
  return (
    <div className={s.help}>
      Player Controls
      <dl>
        <dt title="Spacebar">␣</dt>
        <dd>{HINT_PAUSE_RESUME}</dd>
        <dt title="Tab">↹</dt>
        <dd>{HINT_CONTROLS}</dd>
        <dt title="Arrow Up">⇑</dt>
        <dd>{HINT_SPEED_UP}</dd>
        <dt title="Arrow Down">⇓</dt>
        <dd>{HINT_SLOW_DOWN}</dd>
        <dt title="Left Arrow">⇐</dt>
        <dd>{HINT_SKIP_BACK}</dd>
        <dt title="Right Arrow">⇒</dt>
        <dd>{HINT_SKIP_FWD}</dd>
        <dt title="Home">⇱</dt>
        <dd>{HINT_BEGIN}</dd>
        <dt title="End">⇲</dt>
        <dd>{HINT_END}</dd>
        <dt title="Plus or Equal">+ / =</dt>
        <dd>{HINT_VOLUME_UP}</dd>
        <dt title="Minus">-</dt>
        <dd>{HINT_VOLUME_DOWN}</dd>
        {onPrevious && (
          <>
            <dt title="Less-than or Comma">&lt; / ,</dt>
            <dd>{HINT_PREV}</dd>
          </>
        )}
        {onNext && (
          <>
            <dt title="Greater-than or Period">&gt; / .</dt>
            <dd>{HINT_NEXT}</dd>
          </>
        )}
        {allowFullscreen && (
          <>
            <dt title="F">f</dt>
            <dd>{HINT_FULLSCREEN}</dd>
          </>
        )}
        {vtt && (
          <>
            <dt title="C">c</dt>
            <dd>{HINT_CC}</dd>
          </>
        )}
        <dt title="?">?</dt>
        <dd>{HINT_HELP}</dd>
      </dl>
      {help}
    </div>
  );
};
