import { useQuery } from 'react-query';
import { Conference, HttpError, org } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';
import { queryRetry } from 'common/hooks/utils';

import { UseLoadConferences } from './types';

export const useLoadConferences: UseLoadConferences = function useLoadConferences() {
  const { profile } = useAuth();

  return useQuery<Conference[], HttpError>(
    'conferences.load',
    () => (profile?.conference ? [profile.conference] : org.conferences()),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      retry: queryRetry,
    },
  );
};
