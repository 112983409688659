import { useQuery } from 'react-query';
import { org } from 'weplayed-typescript-api';

import { UseLoadOrgReturnType, UseLoadOrgType } from './types';

export const useLoadOrg: UseLoadOrgType = function useLoadOrg(
  slug?: string,
  uid?: string,
): UseLoadOrgReturnType {
  return useQuery(`org.load.${slug || uid}`, async () => {
    if (!slug && !uid) {
      return;
    }

    return slug ? org.bySlug({ slug }) : org.byUid({ uid });
  }, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
