import { ReactComponent as Facebook } from '@mdi/svg/svg/facebook.svg';
import { ReactComponent as Instagram } from '@mdi/svg/svg/instagram.svg';
import { ReactComponent as Linkedin } from '@mdi/svg/svg/linkedin.svg';
import { ReactComponent as Twitter } from '@mdi/svg/svg/twitter.svg';
import * as cx from 'classnames';
import { ReactComponent as Logo } from 'images/logo-symbol-gray.svg';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useApplication } from 'common/hooks/useApplication';

import { CLIENT_URLS } from 'consumer/routes';

import * as s from './Footer.m.less';

export const Footer: React.FC = function Footer() {
  const { strict, navType } = useApplication();

  if (strict) {
    return null;
  }

  const tiny = navType('tiny');

  /* eslint-disable react/jsx-no-target-blank */
  return (
    <footer className={cx(s.root, tiny && s.tiny)}>
      <div className={s.container}>
        <Link to={CLIENT_URLS.HOME.DISCOVER_DEFAULT.toPath()} className={s.logo} aria-hidden>
          <Logo role="banner" aria-label="WePlayed" />
          <span>{`v${process.env.UI_VERSION}`}</span>
        </Link>

        <ul role="group" aria-label="Company">
          <li><a target="_blank" href="https://weplayed.com/about.html">About</a></li>
          <li><a target="_blank" href="https://weplayed.com/partnerships.html">Partners</a></li>
          <li><a target="_blank" href="https://weplayed.com/careers.html">Careers</a></li>
          <li><a target="_blank" href="https://weplayed.com/blog.html">Blog</a></li>
        </ul>

        <ul role="group" aria-label="Product">
          <li><a target="_blank" href="https://weplayed.com/product.html">Overview</a></li>
        </ul>

        <ul role="group" aria-label="Support">
          <li><a target="_blank" href="mailto:support@weplayed.com">Contact Us</a></li>
          <li><a target="_blank" href="https://weplayed.com/privacy.html">Privacy Support</a></li>
        </ul>

        <ul role="group" aria-label="Social Links" className={s.social}>
          <li><a target="_blank" rel="noopener" href="https://www.linkedin.com/company/weplayed/"><Linkedin aria-label="LinkedIn" /></a></li>
          <li><a target="_blank" rel="noopener" href="https://www.instagram.com/weplayed/"><Instagram aria-label="Instagram" /></a></li>
          <li><a target="_blank" rel="noopener" href="https://twitter.com/weplayed"><Twitter aria-label="Twitter" /></a></li>
          <li><a target="_blank" rel="noopener" href="https://www.facebook.com/weplayedinc/"><Facebook aria-label="Facebook" /></a></li>
        </ul>

        <div>{`© ${new Date().getFullYear()} WePlayed. All Right Reserved`}</div>
      </div>
    </footer>
  );
};
