// extracted by mini-css-extract-plugin
var _1 = "rDA1dRF0peRRlluyWhm6";
var _2 = "bci0szGGGbM55oEuIbxB";
var _3 = "AqzPuT0ZSkfXTwDzETlE";
var _4 = "TXYleQ2TDRSdkxE7a63a";
var _5 = "MeC38xeQrpFCiJfkm0D0";
var _6 = "VVKal0kZTqYCRNc4lDwg";
var _7 = "h_9M3iq47LA7Frn_IvhE";
var _8 = "VxitQFYdtnd8Pihzjn4R";
export { _1 as "content", _2 as "follow", _3 as "header", _4 as "logo", _5 as "pinOff", _6 as "search", _7 as "sport", _8 as "teams" }
