import { Location } from 'history';
import * as queryString from 'query-string';

import { persistent } from 'common/utils/persistent';
import { lifecycle } from 'common/utils/recompose';

interface Props {
  location: Location;
}

export const withSourceTracking = lifecycle<Props>({
  componentDidMount() {
    if (this.props.location.search) {
      const qs = queryString.parse(this.props.location.search) || {};

      persistent.set(Object.fromEntries(
        Object.keys(qs).filter((k) => k.startsWith('utm_')).map((k) => [k, qs[k] as string]),
      ));
    }
  },
});
