import { Query } from 'react-query';
import {
  HttpError, Moment, MomentPublication, User,
} from 'weplayed-typescript-api';

import { BaseVariables, LikeVariables, UpdateVariables } from './types';

export const getLikeKey = (uid?: string): string[] => ['moment-like', uid].filter(Boolean);

export function isMomentCuratedByUser(moment: Moment, user: User): boolean {
  if (!moment || !user || !moment.curator) {
    return false;
  }
  return moment.curator.pk === user.pk;
}

export const isLikeQuery = (
  query: Query<unknown, unknown>,
): query is Query<LikeVariables, HttpError> => {
  const { queryKey } = query;
  return Array.isArray(queryKey) && queryKey[0] === getLikeKey().shift();
};

export const getLikeQueryData = (
  query: Query<Omit<LikeVariables, keyof BaseVariables>, HttpError>,
): LikeVariables => ({
  uid: query.queryKey[1] as string,
  like: query.state.data.like,
});

export const getRemoveKey = (uid?: string): string[] => ['moment-remove', uid].filter(Boolean);

export const isRemoveQuery = (
  query: Query<unknown, unknown>,
): query is Query<BaseVariables, HttpError> => {
  const { queryKey } = query;
  return Array.isArray(queryKey) && queryKey[0] === getRemoveKey().shift();
};

export const getRemoveQueryData = (
  query: Query<BaseVariables, HttpError>,
): BaseVariables => ({
  uid: query.queryKey[1] as string,
});

export const getUpdateKey = (uid?: string): string[] => ['moment-update', uid].filter(Boolean);

export const isUpdateQuery = (
  query: Query<unknown, unknown>,
): query is Query<Moment, HttpError> => {
  const { queryKey } = query;
  return Array.isArray(queryKey) && queryKey[0] === getUpdateKey().shift();
};

export const getUpdateQueryData = (
  query: Query<Moment, HttpError>,
): UpdateVariables => ({
  uid: query.queryKey[1] as string,
  moment: query.state.data,
});

export const getPublicationKey = (uid?: string): unknown[] => ['moment-publication', uid].filter(Boolean);

export const isPublicationQuery = (
  query: Query<unknown, unknown>,
): query is Query<MomentPublication, HttpError> => {
  const { queryKey } = query;
  return Array.isArray(queryKey) && queryKey[0] === getPublicationKey().shift();
};

type GetPublicationQueryDataType = {
  uid?: string;
  publication?: MomentPublication;
}

export const getPublicationQueryData = (
  query: Query<MomentPublication, HttpError>,
): GetPublicationQueryDataType => ({
  uid: query.queryKey[1] as string,
  publication: query.state.data,
});
