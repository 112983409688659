import { FollowTypes, Subscription, Type } from 'weplayed-typescript-api';

export const pickFollowKey = (type: FollowTypes): string => {
  switch (type) {
    case 'team': return 'following_teams';
    case 'channel': return 'following_channels';
    case 'player': return 'following_players';
    default:
  }
};

export const pickFollow = (subscription: Subscription, type: FollowTypes): string[] => (
  subscription[pickFollowKey(type)]
);

export const visitedKey = (type: Type, pk: string): string => `visited:${type}:${pk}`;
