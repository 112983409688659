import { defaultsDeepAll } from 'lodash/fp';
import { AnnotationPathImageProps } from 'weplayed-typescript-api';

import { PathImageComponent } from '../../AnnotationsRepository/types';
import { Block as Base } from '../Block';
import { DEFAULT_THICKNESS } from '../contants';

export class PathImage extends Base<AnnotationPathImageProps> {
  /**
   * Reference to repository component
   */
  protected $component: PathImageComponent;

  constructor(paper: RaphaelPaper, props: AnnotationPathImageProps) {
    super(paper, defaultsDeepAll([props, {
      color: 'yellow',
      thickness: DEFAULT_THICKNESS,
      arrow: false,
    }]));
  }

  public draw(): this {
    const path = this.$paper
      .path(this.makePath())
      .attr({
        stroke: this.$props.color,
        'stroke-width': this.$paper.width * this.$props.thickness,
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      });

    this.$element = this.$paper.set([path]);

    return super.draw();
  }

  protected makePath(): string {
    const width = this.$props.size * this.$paper.width;
    const w2 = width / 2;
    const height = (width * this.$component.height) / this.$component.width;
    const h2 = height / 2;

    const lines = this.$component.path.map(
      (line) => line.map(
        (point) => `${point.x * width - w2} ${point.y * height - h2}`,
      ).join('L'),
    ).join('M');

    return `M${lines}`;
  }
}
