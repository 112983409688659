import { ReactComponent as MenuDown } from '@mdi/svg/svg/menu-down.svg';
import * as React from 'react';
import Slider from 'react-slick';
import { Team } from 'weplayed-typescript-api';

import { expandSeason } from 'common/utils/dates';

import { GameTile } from 'consumer/components/GameTile';
import { TeamImage } from 'consumer/components/TeamImage';
import { TooltipButton } from 'consumer/components/TooltipButton';
import { SingleSelectionModal } from 'consumer/containers/SingleSelectionModal';
import { SeasonSummary } from 'consumer/hooks/useHome/types';

interface Props {
  seasonSummary: SeasonSummary;
  onLoadSeasonSummary: (season: number) => void;
  team: Team;
}

interface State {
  showingSeasonSelection: boolean;
}

export class SeasonGamesSection extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { showingSeasonSelection: false };
  }

  onClickDropDown = (): void => {
    if (!this.state.showingSeasonSelection) {
      this.setState({ showingSeasonSelection: true });
    }
  };

  onSeasonModalSelect = (newSeason): void => {
    this.props.onLoadSeasonSummary(newSeason);
    this.setState({ showingSeasonSelection: false });
  };

  onSeasonModalCancel = (): void => {
    this.setState({ showingSeasonSelection: false });
  };

  render(): JSX.Element {
    const { team, seasonSummary } = this.props;

    if (!seasonSummary.games?.length) {
      return null;
    }

    return (
      <>
        <div className="home-section-spacer" />
        <div className="home-season-header-container">
          <div className="home-season-header-item">
            <h2 className="home-season-header-text">
              {expandSeason(seasonSummary.season)}
              {' '}
              SEASON
              <span className="home-page-section-header-spacer">—</span>
              {team.acronym}
              <TeamImage team={team} size="x-small" className="home-page-section-header-logo" />
            </h2>
          </div>
          {seasonSummary.available_seasons.length > 1 ? (
            <div className="home-season-header-item">
              {/* TODO: move to shared component */}
              <TooltipButton
                className="btn btn-primary"
                id="seasons-selector"
                onClick={this.onClickDropDown}
                tooltip="Select another season"
              >
                &nbsp;&nbsp;other seasons
                <MenuDown />
              </TooltipButton>
            </div>
          ) : (
            ''
          )}
        </div>
        {this.state.showingSeasonSelection ? (
          <SingleSelectionModal
            title="Select season"
            current={seasonSummary.season.toString()}
            options={Object.fromEntries(
              seasonSummary.available_seasons.map((s) => [s, expandSeason(s)]),
            )}
            onCancel={this.onSeasonModalCancel}
            onSelect={this.onSeasonModalSelect}
          />
        ) : (
          ''
        )}
        <hr />
        <Slider
          variableWidth
          infinite={false}
          draggable
          swipeToSlide
          speed={500}
          className="slider variable-width"
        >
          {seasonSummary?.games.map((game) => (
            <GameTile key={game.pk} item={game} />
          ))}
        </Slider>
      </>
    );
  }
}
