import { StringTuple } from 'common/types';

type Arg<T = unknown> = T | [T, (v: T) => string];

export function format(
  $format: string,
  ...$args: Arg[]
): string;
export function format(
  $format: StringTuple,
  $value: Arg<number>,
  ...$args: Arg[]
)

export function format<T extends string | StringTuple>(
  $format: string | StringTuple,
  $value?: T extends string ? Arg<number> : Arg,
  ...$args: unknown[]
): string {
  const value = Array.isArray($value) ? $value[0] : $value;
  const fmt = Array.isArray($format) ? ((value === 1 && $format[0]) || $format[1]) : $format;
  const args = [$value, ...$args];

  return fmt.replace(
    /{(\d+)}/g,
    (_, idx) => {
      if (Array.isArray(args[idx])) {
        return String(args[idx][1](args[idx][0]));
      }

      if (typeof args[idx] !== undefined) {
        return String(args[idx]);
      }

      return '';
    },
  );
}

export const commas = (num: number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const stopwords = [
  'from', 'with', 'be', 'to', 'is', 'his', 'he', 'she',
  'the', 'of', 'an', 'it', 'by', 'who',
];
const ignoreRe = /^[0-9-]/;

export const makeTokens = (str: string): string[] => (
  str.toLowerCase().replace(/[^a-z0-9-]+/gi, ' ').split(' ')
    .filter((w) => w.length >= 2 && !ignoreRe.test(w) && !stopwords.includes(w))
);
