import * as cx from 'classnames';
import { curry } from 'lodash/fp';
import * as React from 'react';
import { What } from 'weplayed-typescript-api';

import * as s from './Tile.m.less';
import { Props } from './types';

export const Tile = function Tile<T extends What>({
  item, modes, state, className, children, onStateChange, ...props
}: Props<T>): JSX.Element {
  const onSelectChange = React.useCallback(
    curry((type, { target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      if (onStateChange) {
        if (type === 'select') {
          onStateChange(item, 'select', checked);
        }
      }
    }),
    [onStateChange, item],
  );

  return (
    <div className={cx(s.root, className)} {...props}>
      {children}
      {modes?.select && (
        <>
          <input
            className={s.select}
            type="checkbox"
            checked={state?.select || false}
            onChange={onSelectChange('select')}
          />
          <span />
        </>
      )}
    </div>
  );
};
