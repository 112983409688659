import * as cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';

import { TeamImage } from 'consumer/components/TeamImage';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './TeamLogo.m.less';
import { Props } from './types';

const TeamLogo: React.FC<Props> = function TeamLogo({
  disableLink, team, size = 'small', className, linkNewWindow,
}) {
  const { profile: user } = useAuth();
  const to = !disableLink
    && (!user?.org || user?.org.available_teams.includes(team.pk))
    && CLIENT_URLS.HOME.DISCOVER.toPath({ uid: team.pk });
  const classes = cx(s.container, className, s[size.replace('-', '')]);
  const children = (
    <TeamImage team={team} size={size} className={s.image} />
  );

  return to
    ? <Link className={classes} target={linkNewWindow ? '_blank' : undefined} to={to}>{children}</Link>
    : <div className={classes}>{children}</div>;
};

export { TeamLogo };
