import * as React from 'react';

import { formatDate } from 'common/utils/dates';

import { TeamImage } from 'consumer/components/TeamImage';

import * as s from './GameInfoVs.m.less';
import { Props } from './types';

export const GameInfoVs: React.FC<Props> = function GameInfoVs({
  game, team, ...rest
}) {
  const away = game.team1.pk === team.pk;
  const diff = game.team1_score - game.team2_score;

  const summary = away
    ? ` ${diff > 0 ? 'W' : (diff && 'L') || 'T'}, ${game.team1_score}-${game.team2_score}`
    : ` ${diff < 0 ? 'W' : (diff && 'L') || 'T'}, ${game.team2_score}-${game.team1_score}`;

  return (
    <div {...rest}>
      <TeamImage team={away ? game.team1 : game.team2} size="small" className={s.logo} />
      {`${away ? 'at' : 'vs'}`}
      <TeamImage team={!away ? game.team1 : game.team2} size="small" className={s.logo} />
      <span className={s.dot} />
      {formatDate(game.date_played)}
      <span className={s.dot} />
      {summary}
    </div>
  );
};
