import { ReactComponent as Spinner } from 'images/spinner.svg';
import { difference, intersection } from 'lodash/fp';
import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { usePrevious } from 'common/hooks/usePrevious';

import { Button } from 'consumer/components/Button';
import { CheckboxList } from 'consumer/components/CheckboxList';
import { Item, Values } from 'consumer/components/CheckboxList/types';
import {
  isValidCollectionName, useCollections,
} from 'consumer/hooks/useCollections';

import * as s from './CollectionModal.m.less';
import { Props } from './types';

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  e.stopPropagation();
  e.nativeEvent.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};

export const CollectionModal: React.FunctionComponent<Props> = function CollectionModal({
  moments, onClose, selectedId,
}) {
  const [text, setText] = React.useState('');
  const [collections, setCollections] = React.useState<Item[]>([]);
  const [selected, setSelected] = React.useState<Values>({});

  const {
    collections: { data: loaded, isFetching: isLoading },
    create: [create, { data: created, isLoading: isCreating }],
    moments: [update, { isLoading: isUpdating }],
  } = useCollections();

  React.useEffect(() => {
    if (isUpdating) {
      return;
    }

    if (loaded) {
      const sortTopId = created || selectedId;

      setCollections(loaded
        .map((collection) => ({
          id: collection.pk,
          name: collection.name,
        }))
        .sort(({ id: a }, { id: b }) => (
          ((a === sortTopId) && -1) || ((b === sortTopId) && 1) || 0
        )));

      setSelected(Object.fromEntries(loaded.map((collection) => {
        const common = intersection(collection.moment_ids, moments).length;

        return [
          collection.pk,
          selected[collection.pk] !== undefined
            ? selected[collection.pk]
            : (collection.pk === sortTopId
              || common === moments.length
              || (common === 0 ? false : null)),
        ];
      })));
    }
  }, [loaded, created, selectedId, moments]);

  const handleSave = React.useCallback(
    () => {
      // prepare list of collections to update
      const updates = loaded
        .filter((collection) => {
          const common = intersection(moments, collection.moment_ids);

          return !(
            // collection marked "leave as is" - some moments present, some not
            (selected[collection.pk] === null)
            // user selected collection to include moments but it already has these moments
            || (selected[collection.pk] === true && common.length === moments.length)
            // collection deselected and has no any of the moments
            || (selected[collection.pk] === false && common.length === 0)
          );
        })
        .map((collection) => [
          collection.pk,
          selected[collection.pk] === true
            ? [
              ...(collection.moment_ids || []),
              ...difference(moments, collection.moment_ids || []),
            ]
            : difference(collection.moment_ids || [], moments),
        ]);

      if (updates.length !== 0) {
        update(Object.fromEntries(updates));
      } else {
        onClose(true);
      }
    },
    [loaded, moments, selected],
  );

  const prevUpdating = usePrevious(isUpdating);
  React.useEffect(() => {
    if (prevUpdating && !isUpdating) {
      onClose(true);
    }
  }, [isUpdating]);

  const handleType = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
      setText(value);
    },
    [setText],
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault();
      create({ name: text });
    },
    [text],
  );

  React.useEffect(() => {
    if (isCreating === false) {
      setText('');
    }
  }, [isCreating]);

  const isValidNewCollectionName = isValidCollectionName(
    text,
    (loaded || []).map(({ name }) => name),
  );

  const handleClose = (): void => onClose(false);

  return (
    <Modal
      show
      keyboard
      onHide={handleClose}
      onEscapeKeyDown={handleClose}
      backdropClassName="modal-backdrop-blackout"
      className={s.root}
    >
      <Modal.Header>
        <Modal.Title>
          Add
          {moments.length > 1 ? ` ${moments.length} ` : ' '}
          moment
          {moments.length > 1 ? 's ' : ' '}
          to playlists
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={s.filterContainer} onSubmit={handleSubmit}>
          <input
            id="input-playlist-name"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            autoComplete="off"
            autoCorrect="off"
            type="search"
            name="name"
            placeholder={
              loaded?.length > 0
                ? 'Search or create a playlist'
                : 'Name your first playlist'
            }
            className="form-control"
            maxLength={255}
            onKeyDown={handleKeyDown}
            onChange={handleType}
            required
            value={text}
            disabled={isLoading || isCreating || isUpdating}
          />
          <Button
            type="submit"
            loading={isCreating}
            disabled={isLoading || isCreating || isUpdating || !isValidNewCollectionName}
            id="button-playlist-create"
          >
            Create
          </Button>
        </form>
        <div className={s.collections}>
          <CheckboxList
            items={collections}
            filter={text}
            values={selected}
            onChange={setSelected}
          />
          {(isLoading || isCreating || isUpdating) && (
            <div className={s.loading}>
              <Spinner />
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={handleSave}
          type="button"
          disabled={isCreating || isUpdating}
          loading={isCreating || isUpdating}
          id="button-add-playlist-save"
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          type="button"
          disabled={isCreating || isUpdating}
          id="button-add-playlist-cancel"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
