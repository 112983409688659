import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { School } from 'weplayed-typescript-api';

import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { persistent } from 'common/utils/persistent';

import { TabbedContent } from 'consumer/components/TabbedContent';
import { TeamLogo } from 'consumer/components/TeamLogo';
import { useLoadConferences } from 'consumer/hooks/useLoadConferences';
import { CLIENT_URLS } from 'consumer/routes';

import { conferenceNameMap, conferenceParam } from '../constants';
import * as s from './Conferences.m.less';

export const SchoolsViewConferences: React.FunctionComponent = function SchoolsViewConferences() {
  const data = useLoadConferences();
  const [tab, setTab] = React.useState(0);
  const history = useHistory();

  useHandleQuery(data);

  const conferences = data.data;

  React.useEffect(() => {
    if (conferences) {
      const conference = persistent.get(conferenceParam, true, true);
      setTab(Math.max(0, conferences.findIndex((c) => c.name === conference)));
    }
  }, [conferences]);

  const handleTabChange = React.useCallback((newTab: number) => (): void => {
    setTab(newTab);
    persistent.set(conferenceParam, conferences[newTab].name);
  }, [conferences]);

  const handleChangeSchool = (school: School) => (e: React.MouseEvent): void => {
    e.preventDefault();
    history.push(
      CLIENT_URLS.SCHOOLS.DETAIL_VIEW.buildPath({ uid: school.pk }),
    );
  };

  if (data.isError || data.isLoading) {
    return null;
  }

  // see https://github.com/nfl/react-helmet/issues/408
  const title = `WePlayed Schools: ${
    conferenceNameMap[conferences[tab].name] || 'Choose your school'
  }`;

  const renderButton = (school: School): React.ReactNode => (
    <a
      className={s.school}
      href={CLIENT_URLS.SCHOOLS.DETAIL_VIEW.buildPath({ uid: school.pk })}
      id={`school-${school.pk}`}
      key={school.name}
      onClick={handleChangeSchool(school)}
    >
      <TeamLogo disableLink size="x-small" team={school} />
      <span>{school.name}</span>
    </a>
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Schools and Teams on WePlayed" />
      </Helmet>
      <TabbedContent
        className={s.root}
        tabClassName={s.tab}
        contentClassName={s.content}
        onChange={handleTabChange}
        tab={tab}
        tabs={conferences.map((conf) => ({
          label: (
            <>
              <img alt={conf.name} src={conf.logo} />
              <span className={s.tabLabel}>{conferenceNameMap[conf.name] || conf.name}</span>
            </>
          ),
          id: `school-tab-${conf.name}`,
        }))}
      >
        {conferences.map((conference) => (
          <div key={conference.name} className={s.content}>
            {conference.subconference !== undefined
              ? conference.subconference.map((subconference) => (
                <div key={subconference.name} className={s.subconference}>
                  <h2>{subconference.name}</h2>
                  <div>{subconference.schools.map(renderButton)}</div>
                </div>
              ))
              : undefined}
            {conference.schools !== undefined
              ? conference.schools.map(renderButton)
              : undefined}
          </div>
        ))}
      </TabbedContent>
    </>
  );
};
