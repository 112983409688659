import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useProfile } from 'common/hooks/useProfile';
import {
  Registration as RegistrationPayload,
} from 'common/hooks/useProfile/types';
import { fromBackendResponse, MappedFieldErrors } from 'common/utils/errors';

import { Button } from 'consumer/components/Button';
import { RegistrationForm } from 'consumer/components/RegistrationForm';
import { Stage } from 'consumer/components/RegistrationForm/types';

import * as s from './Registration.m.less';

export const Registration: React.FC = function Registration() {
  const {
    subscription, saveSubscription: subscribe, register,
  } = useProfile();

  const [errors, setErrors] = React.useState<MappedFieldErrors>(null);

  const registration: RegistrationPayload = React.useMemo(() => ({
    first_name: subscription.firstName || '',
    last_name: subscription.lastName || '',
    email: subscription.email || '',
    password1: '',
    password2: '',
    username: '',
  }), [subscription.email, subscription.firstName, subscription.lastName]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [
    register[1].isLoading,
    subscribe[1].isLoading,
  ]);

  React.useEffect(() => {
    const json = register[1].error?.json;

    if (json) {
      const err = fromBackendResponse(json.errors || []);
      setErrors(err);
    } else {
      setErrors(null);
    }
  }, [
    register[1].error?.json,
  ]);

  const handleSubmit = React.useCallback((stage: Stage, payload: RegistrationPayload) => {
    if (stage === Stage.general) {
      subscribe[0]({
        ...subscription,
        firstName: payload.first_name,
        lastName: payload.last_name,
        email: payload.email,
      });
    } else
    /* istanbul ignore else */
    if (stage === Stage.access) {
      register[0](payload);
    }
  }, [
    subscription,
    subscribe[0],
    register[0],
  ]);

  return (
    <div className="container" data-testid="container">
      <Helmet>
        <title>WePlayed: Sign Up</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          {register[1].isSuccess ? (
            <>
              <h1>ACCOUNT CREATED</h1>
              <p>Thank you for registering with us!</p>
              <p>Please proceed to the Sign-In page to continue</p>
              <Button
                as={Link}
                data-testid="button-reg-done"
                id="button-reg-done"
                size="lg"
                to="/"
              >
                Sign In to Continue
              </Button>
            </>
          ) : (
            <>
              <div className={s.header}>
                <h1>SIGN UP</h1>
                <p>
                  Become part of the most exciting community in college
                  sports, with full access to game video, athletes, and
                  teams. All for free.
                </p>
              </div>
              <RegistrationForm
                registration={registration}
                onSubmit={handleSubmit}
                errors={errors || null}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
