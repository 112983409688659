import { ReactComponent as Close } from '@mdi/svg/svg/close.svg';
import { ReactComponent as Tune } from '@mdi/svg/svg/tune.svg';
import * as cx from 'classnames';
import { groupBy } from 'lodash/fp';
import * as React from 'react';
import { Sport } from 'weplayed-typescript-api';

import { TeamSearch } from 'common/types';
import { expandSeason } from 'common/utils/dates';

import SportIcon from 'consumer/components/SportIcon';
import { TeamImage } from 'consumer/components/TeamImage';

import { QueryData } from '../types';
import * as s from './SearchFilters.m.less';
import { Props, State } from './types';

/**
 * Creates a list of sport names joined with comma from list of pk
 */
const sportsPkToNames = (facets?: [ Sport, number ][], sports?: string[]): React.ReactNode => {
  if (facets === undefined || sports === undefined) {
    return;
  }

  return sports.map((sport) => {
    const obj = facets.find(([sp]) => sp.pk === sport);
    return obj && obj[0].name;
  }).filter(Boolean).join(', ');
};

/**
 * Creates a list of team logos/names from their pk
 */
const teamsPkToNames = (
  facets?: [ TeamSearch, number ][],
  teams?: string[],
  vs?: boolean,
): React.ReactNode => {
  if (facets === undefined || teams === undefined) {
    return;
  }

  const join = <span className={s.join}>{vs ? 'vs' : '•'}</span>;

  return teams
    .map((team) => {
      const obj = facets.find(([sp]) => sp.pk === team);
      return obj && (
        <React.Fragment key={obj[0].pk}>
          <TeamImage team={obj[0]} size="xx-small" className={s.logo} />
          <span className={s.acronym}>{obj[0].acronym}</span>
          <SportIcon sport={obj[0].sport} />
        </React.Fragment>
      );
    })
    .filter(Boolean)
    .reduce((prev, current, idx) => (idx === 0 ? [current] : [...prev, join, current]), []);
};

/**
 * Renders search facets pad
 */
export class SearchFilters extends React.Component<Props, State> {
  public static defaultProps: Pick<Props, 'vs'> = {
    vs: true,
  };

  public state: State;

  public constructor(props) {
    super(props);

    this.state = {
      query: { ...props.query },
      visible: false,
    };
  }

  public componentDidUpdate(props): void {
    const { query } = this.props;

    if (props.query !== query) {
      this.resetQuery();
    }
  }

  protected resetQuery = (): void => {
    const { query } = this.props;
    this.setState({ query });
  };

  protected togglePane = (): void => {
    this.setState((state: State): State => ({
      ...state,
      visible: !state.visible,
    }));
  };

  protected handleChange = (): void => {
    const { onChange } = this.props;
    this.setState(
      { visible: false },
      () => onChange(this.state.query),
    );
  };

  protected handleClear = (): void => {
    this.setState(
      {
        query: { q: '' },
      },
      this.handleChange,
    );
  };

  protected handleSeason = (e?: React.MouseEvent | React.KeyboardEvent): void => {
    if ((e as React.KeyboardEvent).key && (e as React.KeyboardEvent).key !== 'Enter') {
      return;
    }

    const season: string | null = e && e.currentTarget.getAttribute('data-id');
    const { query: { season: seasons = [] } } = this.state;
    const idx = seasons.indexOf(season);

    this.mergeQuery(
      {
        // eslint-disable-next-line no-nested-ternary
        season: season
          ? idx === -1
            ? [...seasons, season]
            : [...seasons.slice(0, idx), ...seasons.slice(idx + 1)]
          : undefined,
      },
      this.handleChange,
    );
  };

  protected handleSport = (e?: React.MouseEvent | React.KeyboardEvent): void => {
    if ((e as React.KeyboardEvent).key && (e as React.KeyboardEvent).key !== 'Enter') {
      return;
    }

    const sport: string | null = e && e.currentTarget.getAttribute('data-id');
    const { query: { sport: sports = [] } } = this.state;
    const idx = sports.indexOf(sport);

    this.mergeQuery(
      {
        sport: !sport || idx !== -1 ? undefined : [sport],
      },
      this.handleChange,
    );
  };

  protected handleTeam = (e?: React.MouseEvent | React.KeyboardEvent): void => {
    if ((e as React.KeyboardEvent).key && (e as React.KeyboardEvent).key !== 'Enter') {
      return;
    }

    const team: string | null = e && e.currentTarget.getAttribute('data-id');
    let { query: { team: teams = [] } } = this.state;
    const { query: { vs } } = this.state;

    if (!team) {
      return this.mergeQuery(
        {
          vs: undefined,
          team: [],
        },
        this.handleChange,
      );
    }

    const idx = teams.indexOf(team);
    teams = idx === -1 ? [...teams, team] : [...teams.slice(0, idx), ...teams.slice(idx + 1)];

    this.mergeQuery(
      {
        team: teams,
      },
      !vs || (vs && teams.length === 2) ? this.handleChange : undefined,
    );
  };

  protected mergeQuery = (query: Partial<QueryData>, cb?: () => void): void => {
    this.setState(
      (state: State): State => ({
        ...state,
        query: {
          ...state.query,
          ...query,
        },
      }),
      cb,
    );
  };

  protected toggleVs = (): void => {
    const { query: { team, vs } } = this.state;

    const newVs = vs ? undefined : true;
    const newTeam = newVs && team && team.length >= 2 ? team.slice(0, 2) : team;
    const submit = !newVs || (newVs && team && team.length) >= 2;

    this.mergeQuery({ vs: newVs, team: newTeam }, submit ? this.handleChange : undefined);
  };

  public render(): JSX.Element {
    const { facets, user, loading, className, children } = this.props;
    const {
      query: {
        q,
        season: seasons = [],
        sport: sports = [],
        team: teams = [],
        vs,
      },
      visible,
    } = this.state;

    const cmsTeams = user?.org?.teams.map(({ team_pk }) => team_pk) || false;

    // Group teams by conference
    const teamByConference: [string, [TeamSearch, number][]][] = Object.entries(
      groupBy(([team]) => team.conference, cmsTeams
        ? facets.team_full.filter(([team]) => !cmsTeams.includes(team.pk))
        : facets.team_full),
    );

    return (
      <div className={className}>
        <div className={cx(s.root, loading && s.loading)}>
          <div className={s.filters}>
            <button
              type="button"
              className={cx('btn btn-default', visible && 'btn-primary')}
              onClick={this.togglePane}
            >
              <Tune />
              FILTER
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleClear}
            >
              &quot;
              {q}
              &quot;
              <Close />
            </button>
            {seasons.length !== 0 && (
              <button
                className="btn btn-primary"
                onClick={this.handleSeason}
                type="button"
              >
                {seasons.map(expandSeason).join(', ')}
                <Close />
              </button>
            )}
            {sports.length !== 0 && (
              <button
                className="btn btn-primary"
                onClick={this.handleSport}
                type="button"
              >
                {sportsPkToNames(facets && facets.sport_full, sports)}
                <Close />
              </button>
            )}
            {teams.length !== 0 && (
              <button
                className="btn btn-primary"
                onClick={this.handleTeam}
                type="button"
              >
                {teamsPkToNames(facets && facets.team_full, teams, vs)}
                <Close />
              </button>
            )}
          </div>
          {children}
        </div>
        <div className={cx(s.pad, visible && s.visible)}>
          <dl>
            <dt>Season</dt>
            <dd>
              <ul role="menu">
                <li
                  className={seasons.length === 0 ? s.selected : undefined}
                  onClick={this.handleSeason}
                  onKeyPress={this.handleSeason}
                  role="menuitem"
                  tabIndex={visible ? 0 : undefined}
                >
                  ALL
                </li>
                {
                  facets
                  && facets.season
                  && facets.season.map(([season]) => (
                    <li
                      className={seasons.indexOf(String(season)) !== -1 ? s.selected : undefined}
                      data-id={String(season)}
                      key={`season-${season}`}
                      onClick={this.handleSeason}
                      onKeyPress={this.handleSeason}
                      role="menuitem"
                      tabIndex={visible ? 0 : undefined}
                    >
                      {expandSeason(season)}
                    </li>
                  ))
                }
              </ul>
            </dd>
            <dt>Sport</dt>
            <dd>
              <ul role="menu">
                <li
                  className={sports.length === 0 ? s.selected : undefined}
                  onClick={this.handleSport}
                  onKeyPress={this.handleSport}
                  role="menuitem"
                  tabIndex={visible ? 0 : undefined}
                >
                  <span>ALL</span>
                </li>
                {
                  facets
                  && facets.sport_full
                  && facets.sport_full.map(([sport]) => (
                    <li
                      className={sports.indexOf(String(sport.pk)) !== -1 ? s.selected : undefined}
                      data-id={sport.pk}
                      key={`sport-${sport.pk}`}
                      onClick={this.handleSport}
                      onKeyPress={this.handleSport}
                      role="menuitem"
                      tabIndex={visible ? 0 : undefined}
                    >
                      {sport.name}
                    </li>
                  ))
                }
              </ul>
            </dd>
            <dt>{cmsTeams ? 'Opponent' : 'Team'}</dt>
            <dd>
              <ul role="menu">
                <li
                  className={teams.length === 0 ? s.selected : undefined}
                  onClick={this.handleTeam}
                  onKeyPress={this.handleTeam}
                  role="menuitem"
                  tabIndex={visible ? 0 : undefined}
                >
                  ALL
                </li>
                {this.props.vs && !cmsTeams ? (
                  <li
                    className={vs ? s.selected : undefined}
                    onClick={this.toggleVs}
                    onKeyPress={this.toggleVs}
                    role="menuitem"
                    tabIndex={visible ? 0 : undefined}
                  >
                    VS ONLY
                  </li>
                ) : undefined}
                <li className={s.nohover}>
                  {teamByConference.map(([name, teamsInConference]) => (
                    <React.Fragment key={`acc-${name}`}>
                      <strong>{name}</strong>
                      <ul role="menu">
                        {teamsInConference.map(([team]) => (
                          <li
                            className={teams.indexOf(team.pk) !== -1 ? s.selected : undefined}
                            data-id={team.pk}
                            key={`team-${team.pk}`}
                            onClick={this.handleTeam}
                            onKeyPress={this.handleTeam}
                            role="menuitem"
                            tabIndex={visible ? 0 : undefined}
                          >
                            <span>
                              {team.acronym}
                              <SportIcon sport={team.sport} />
                            </span>
                            <TeamImage team={team} size="xx-small" />
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))}
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    );
  }
}
