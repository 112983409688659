import * as cx from 'classnames';
import { ReactComponent as Loading } from 'images/spinner.svg';
import * as React from 'react';

import { LikeButton } from 'consumer/components/LikeButton';
import {
  MomentActionBarContext,
} from 'consumer/components/MomentActionBar/MomentActionBarContext';
import {
  MomentDescriptionReadonly,
} from 'consumer/components/MomentDescription';
import { TooltipButton } from 'consumer/components/TooltipButton';

import * as s from './GameControlsMomentsHub.m.less';
import { GameControlMomentsHubEntryProps } from './types';

const handleOff = (e: React.MouseEvent): void => e.stopPropagation();

export const GameControlMomentsHubEntry: React.FC<
GameControlMomentsHubEntryProps
> = function GameControlMomentsHubEntry({
  moment, active, action, children, disabled, user,
  onClick, onMenu, onCreateMoment, onLike, onLink,
}) {
  const hasFloating = Boolean(
    user?.is_staff && moment && (moment.score?.score || moment.reviewed),
  );

  const { status } = React.useContext(MomentActionBarContext);

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (disabled || ('key' in e && e.key !== 'Enter')) {
        return;
      }

      if (moment) {
        onClick(moment);
      }
    },
    [disabled, moment, onClick],
  );

  const handleCreateMoment = React.useCallback(() => {
    onCreateMoment(action.summary, action.sequence);
  }, [action?.sequence, action?.summary, onCreateMoment]);

  const handleMenu = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onMenu(moment, e.currentTarget as HTMLElement);
  }, [moment, onMenu]);

  const updating = moment && Object.values(status).some((list) => list.includes(moment.pk));
  const summary = moment ? (
    <MomentDescriptionReadonly
      moment={moment}
      inline
      truncate={active ? undefined : 100}
    />
  ) : action.summary;

  const handleLike = React.useCallback(
    (like: boolean): void => {
      onLike(moment, like);
    },
    [moment, onLike],
  );

  const handleLinkAction = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onLink(action);
  }, [onLink, action]);

  return (
    <div
      tabIndex={0}
      className={cx(
        s.entry,
        action?.is_nav && s.nav,
        moment && s.moment,
        active && s.active,
        disabled && !active && s.disabled,
        disabled && s.cursorDefault,
        disabled && onLink && s.withLink,
        moment.liked_by_user && s.withLike,
      )}
      data-action={action?.sequence || null}
      data-moment={(moment?.pk ?? (moment && 'new')) || null}
      role="button"
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      <div className={s.inner}>
        {moment?.pk ? (
          <div className={s.thumbnail}>
            {moment.thumbnail && (
              <img src={moment.thumbnail} alt={moment.name} />
            )}

            {action && <span className={s.clock}>{action.clock}</span>}
          </div>
        ) : (action?.clock && <span className={s.clock}>{action.clock}</span>)}

        {summary && (
          <div className={s.summary}>
            {summary}
          </div>
        )}

        {(hasFloating || updating) && (
          <div className={s.floating}>
            {updating && <Loading />}
            {!updating && moment.reviewed && (
              <span className={cx(s.status, moment.reviewed && s.reviewed)}>
                {moment.reviewed && 'reviewed'}
              </span>
            )}
            {moment.score?.score ? <span className={s.score}>{moment.score.score}</span> : null}
          </div>
        )}
        {!disabled && (
          moment ? (
            <>
              <LikeButton
                className={s.like}
                liked={moment.liked_by_user}
                likes
                onChange={handleLike}
                tooltip
              />
              <button
                className={s.menu}
                onClick={handleMenu}
                type="button"
              >
                menu
              </button>
            </>
          ) : !action.is_nav && (user?.can_create_moments ?? true) && (
            <TooltipButton
              className={s.create}
              type="button"
              onClick={handleCreateMoment}
              tooltip="Add moment"
            >
              Add moment
            </TooltipButton>
          )
      )}
      {onLink && (
        <TooltipButton
          className={s.create}
          type="button"
          onClick={handleLinkAction}
          tooltip="Add Moment"
        >
          Add Moment
        </TooltipButton>
      )}
      </div>
      {children && (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div onClick={handleOff} className={s.children}>{children}</div>
      )}
    </div>
  );
};
