import { ReactComponent as Fullscreen } from '@mdi/svg/svg/fullscreen.svg';
import * as React from 'react';

export const FullscreenControlButton: React.FC<
  React.HTMLAttributes<HTMLButtonElement>
> = function FullscreenControlButton(props) {
  return (
    <button
      aria-label="Toggle Fullscreen"
      type="button"
      {...props}
    >
      <Fullscreen />
    </button>
  );
};
