import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CLIENT_URLS } from 'consumer/routes';

import { SchoolsViewConferences } from './Conferences';
import { SchoolsViewDetails } from './Details';

const SchoolsView: React.FunctionComponent = function SchoolsView() {
  return (
    <Switch>
      <Route
        path={CLIENT_URLS.SCHOOLS.DETAIL_VIEW.route}
        component={SchoolsViewDetails}
      />
      <Route
        path={CLIENT_URLS.SCHOOLS.LIST_VIEW.route}
        component={SchoolsViewConferences}
      />
    </Switch>
  );
};

const SchoolsViewMemo: React.FunctionComponent = React.memo(SchoolsView);

export { SchoolsViewMemo as SchoolsView };
