import * as cx from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import * as React from 'react';
import { EmbedAdsSpec } from 'weplayed-typescript-api';

import { useInactivity } from 'common/hooks/useInactivity';
import { EVENTS } from 'common/utils/events';
import { getImageForSlug } from 'common/utils/sports';

import { AnnotationsCanvas } from '../Annotations';
import { MonetizationAd } from '../MonetizationAd';
import { VideoPlayer } from '../VideoPlayer';
import { AdEvent } from '../VideoPlayer/types';
import { MOMENT_POSITION_INTERVAL } from './constants';
import * as s from './MomentPlayer.m.less';
import { MomentPlayerControls } from './MomentPlayerControls';
import { MomentPlayerProps } from './types';

/**
 * Helper function to get the correct set of CSS class names for placing embed ads
 * @param placement Placement info provided by API
 * @returns CSS class names as string
 */
const placementToClassName = (placement: EmbedAdsSpec): string => placement.position
  .map((p) => {
    switch (p) {
      case 'bottom': return s.bottom;
      case 'left': return s.left;
      case 'right': return s.right;
      case 'top': return s.top;
      default: return '';
    }
  })
  .filter(Boolean)
  .join(' ');

/**
 * Base component for displaying moments
 */
export const MomentPlayer: React.FC<MomentPlayerProps> = React.memo(({
  ads = true,
  bar = true,
  children,
  controls = true,
  fullscreen = true,
  inactivity,
  loader = true,
  loop = true,
  playing = true,
  position,
  moment,
  onEnd,
  onEvent,
  onFullscreen,
  onNext,
  onPosition,
  onPrevious,
  onState,
  overlay,
}) => {
  const {
    ads_spec,
    annotations,
    end,
    pk,
    start,
    thumbnail,
    video: { streaming_url },
    vtt,
  } = moment;

  const loopcount = React.useRef(0);
  const pos = React.useRef(position ?? start);

  const playStart = React.useRef(0);
  const playTime = React.useRef(0);
  const viewStart = React.useRef(new Date().valueOf());
  const positionReported = React.useRef(0);

  React.useEffect(() => {
    if (pk) {
      pos.current = start;
      loopcount.current = 0;
      playStart.current = 0;
      playTime.current = 0;
      positionReported.current = 0;

      if (pk && onEvent) {
        onEvent({
          loopcount: loopcount.current,
          moment_id: pk,
          type: EVENTS.MOMENT_VIEW,
        });

        return (): void => {
          const now = new Date().valueOf();

          onEvent({
            loopcount: loopcount.current,
            manual: true,
            moment_id: pk,
            position: pos,
            play: (playTime.current + (playStart.current ? playStart.current - now : 0)) / 1000,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            total: (now - viewStart.current) / 1000,
            type: EVENTS.MOMENT_CLOSE,
          });
        };
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pk]);

  const inactive = useInactivity(Boolean(inactivity));

  const annotationsRef = React.useRef<AnnotationsCanvas>();

  const [reqState, setReqState] = React.useState(playing);
  React.useEffect(() => {
    setReqState(playing);
  }, [playing]);

  const [state, setState] = React.useState(false);
  const [ann, setAnn] = React.useState(false);

  const handlePosition = React.useCallback((p: number, d: number) => {
    if (onPosition) {
      onPosition(p, d);
    }
    pos.current = p;
    annotationsRef.current?.setTime(p - start);

    const now = new Date().valueOf();

    if (onEvent && now - positionReported.current > MOMENT_POSITION_INTERVAL) {
      positionReported.current = now;

      const moment_length = end - start;
      const moment_progress = Math.max(0, Math.min(p - start, moment_length));

      onEvent({
        loopcount: loopcount.current,
        moment_id: pk,
        moment_length,
        moment_progress,
        play: (playTime.current + (now - playStart.current)) / 1000,
        position: p,
        total: (viewStart.current - now) / 1000,
        type: EVENTS.MOMENT_PROGRESS,
      });
    }
  }, [end, onEvent, onPosition, pk, start]);

  const handleAd = React.useCallback((event: AdEvent, p) => {
    if (onEvent) {
      onEvent({
        moment_id: pk,
        position: p,
        type: (event === 'click' && EVENTS.AD_CLICK)
          || (event === 'complete' && EVENTS.AD_COMPLETE)
          || (event === 'skip' && EVENTS.AD_SKIP)
          || (event === 'start' && EVENTS.AD_START),
      });
    }
  }, [onEvent, pk]);

  const handleStateChange = React.useCallback(($playing) => {
    setState($playing);

    if (!ann) {
      const now = new Date().valueOf();

      if ($playing) {
        playStart.current = now;
      } else {
        playTime.current += now - playStart.current;
        playStart.current = null;
      }

      if (onEvent) {
        onEvent({
          type: $playing ? EVENTS.MOMENT_PLAY : EVENTS.MOMENT_PAUSE,
          moment_id: pk,
        });
      }

      if (onState) {
        onState?.($playing);
      }
    }
  }, [ann, onEvent, onState, pk]);

  const handleEnd = React.useCallback(() => {
    const now = new Date().valueOf();
    if (onEvent) {
      onEvent({
        loopcount: loopcount.current,
        moment_id: pk,
        play: playTime.current + (playStart.current ? playStart.current - now : 0),
        total: (now - viewStart.current) / 1000,
        type: EVENTS.MOMENT_CLOSE,
      });
    }

    loopcount.current += 1;

    if (loop && onEvent) {
      onEvent({
        type: EVENTS.MOMENT_VIEW,
        loopcount: loopcount.current,
        moment_id: pk,
      });
    }
    if (onEnd) {
      onEnd();
    }
  }, [loop, onEnd, onEvent, pk]);

  // React.useEffect(() => handleStateChange(playing ?? true), [playing]);

  React.useEffect(() => {
    if (state && Boolean(inactivity) && inactivity <= inactive) {
      setReqState(false);
    }
  }, [inactivity, inactive, state]);

  const handleAnnotationPlay = React.useCallback((): void => {
    setAnn(false);
    setReqState(true);
  }, []);

  const handleAnnotationPause = React.useCallback((): void => {
    setAnn(true);
    setReqState(false);
  }, []);

  return (
    <div className={s.root}>
      <VideoPlayer
        adTagUrl={ads && moment.ad_tag_video}
        allowFullscreen={fullscreen}
        controls={controls && !ann && (
          <>
            {bar && <MomentPlayerControls />}
            {children}
          </>
        )}
        end={end}
        fade
        loader={loader}
        loop={loop}
        onAd={handleAd}
        onEnd={handleEnd}
        onFullscreen={onFullscreen}
        onNext={onNext}
        onPosition={handlePosition}
        onPrevious={onPrevious}
        onState={handleStateChange}
        playing={reqState}
        position={position}
        poster={thumbnail ?? getImageForSlug(moment.game.sport.slug)}
        start={start}
        url={streaming_url}
        vtt={vtt}
      >
        {!isEmpty(annotations) && (
          <AnnotationsCanvas
            annotations={annotations}
            // it is important to have correct start time on annotations create
            length={end - start}
            onPause={handleAnnotationPause}
            onPlay={handleAnnotationPlay}
            paused={!state}
            ref={annotationsRef}
          />
        )}

        {ads && ads_spec?.length && ads_spec.map((spec) => (
          <MonetizationAd
            config={spec}
            className={cx(s.ads, placementToClassName(spec.placement))}
            key={spec.creative_url}
          />
        ))}

        {overlay}
      </VideoPlayer>
    </div>
  );
});

MomentPlayer.displayName = 'MomentPlayer';
