import { curry, memoize } from 'lodash/fp';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { AnnotationComponentPropTypes } from 'weplayed-typescript-api';

import { AnnotationPrimitives } from '../AnnotationsRepository';
import {
  AnnotationPrimitivesCategory, AnnotationPrimitiveType,
} from '../AnnotationsRepository/types';
import { Base } from '../Components/Base';
import * as s from './AnnotationsPanel.m.less';
import { Props, State } from './types';

const renderIcon = (src: string, name?: string): React.ReactNode => (
  <div className={s.icon}>
    <img src={src} alt={name} />
  </div>
);

export class AnnotationsPanel extends React.PureComponent<Props, State> {
  protected containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  public state: State = {
    selected: [],
    category: 0,
  };

  handleCategory = memoize(curry((category: number | undefined, e: React.MouseEvent): void => {
    e.preventDefault();
    this.setState({ category });
  }));

  protected handlePrimitiveClick = memoize(curry((
    [categoryIdx, itemIdx]: [number, number],
    e: React.MouseEvent,
  ): void => {
    e.preventDefault();
    if (this.props.canvas) {
      const primitive = AnnotationPrimitives[categoryIdx].items[itemIdx];
      this.props.canvas.add(primitive.annotation);
    }
  }));

  protected handlePrimitiveDrag = memoize(curry((
    path: [number, number],
    e: React.DragEvent,
  ): void => {
    e.dataTransfer.setData('annotations/primitive', JSON.stringify(path));
  }));

  public componentDidMount(): void {
    this.containerRef.current.ontouchmove = (e): void => e.preventDefault();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  public select = (selected: Base<AnnotationComponentPropTypes>[]): void => {
    this.setState({ selected });
  };

  handleRemove = (): void => {
    if (this.props.canvas) {
      for (let x = this.state.selected.length - 1; x >= 0; x -= 1) {
        this.props.canvas.remove(this.state.selected[x]);
      }
    }
  };

  renderCategories = (): React.ReactNode => (
    <div className={s.categories}>
      <p>Select category below:</p>
      <ul>
        {AnnotationPrimitives.map(this.renderCategory)}
      </ul>
    </div>
  );

  renderCategory = (category: AnnotationPrimitivesCategory, idx: number): React.ReactNode => (
    <li
      key={idx}
      onClick={this.handleCategory(idx)}
      className={s.iconLink}
    >
      {renderIcon(category.preview, category.name)}
      <span>{category.name}</span>
    </li>
  );

  renderPrimitive = (
    categoryIdx: number,
    primitive: AnnotationPrimitiveType,
    idx: number,
  ): React.ReactNode => (
    <li
      className={s.iconLink}
      draggable
      key={idx}
      onClick={this.handlePrimitiveClick([categoryIdx, idx])}
      onDragStart={this.handlePrimitiveDrag([categoryIdx, idx])}
    >
      {renderIcon(primitive.preview)}
      <span>{primitive.name}</span>
    </li>
  );

  renderCategoryContent = (categoryIdx: number): React.ReactNode => (
    <div className={s.category}>
      <h3>
        {/* <span>←&nbsp;</span> */}
        {AnnotationPrimitives[categoryIdx].name}
      </h3>
      <p>Click on image to add annotation:</p>
      <ul className={s.primitives}>
        {AnnotationPrimitives[categoryIdx].items.map(this.renderPrimitive.bind(null, categoryIdx))}
      </ul>
    </div>
  );

  renderSelected = (): React.ReactNode => {
    const { selected } = this.state;

    if (selected.length === 0) {
      return;
    }

    return (
      <div className={s.selected}>
        <Button variant="danger" onClick={this.handleRemove}>
          Remove selected annotation
          {selected.length > 1 ? 's' : ''}
        </Button>
      </div>
    );
  };

  render(): JSX.Element {
    const { category: categoryIdx } = this.state;

    return (
      <div
        ref={this.containerRef}
        className={s.container}
      >
        {categoryIdx !== undefined
          ? this.renderCategoryContent(categoryIdx)
          : this.renderCategories()}
        {this.renderSelected()}
      </div>
    );
  }
}
