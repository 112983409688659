import {
  ReactComponent as SubtitlesOn,
} from '@mdi/svg/svg/subtitles-outline.svg';
import * as React from 'react';

import { ReactComponent as SubtitlesOff } from './subtitles-outline-off.svg';
import { CaptionsControlButtonProps } from './types';

export const CaptionsControlButton: React.FC<
  CaptionsControlButtonProps
> = function CaptionsControlButton({
  enabled,
  ...props
}) {
  return (
    <button
      type="button"
      title="Toggle subtitles"
      {...props}
    >
      {enabled ? (
        <SubtitlesOn />
      ) : (
        <SubtitlesOff />
      )}
    </button>
  );
};
