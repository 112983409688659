import { ReactComponent as Close } from '@mdi/svg/svg/close.svg';
import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { Props } from './types';
import * as s from './VideoPopup.m.less';

export const VideoPopup: React.FC<Props> = function VideoPopup({ show, url, onClose }) {
  const handleHide = React.useCallback(() => onClose(), [onClose]);

  return (
    <Modal
      dialogClassName={s.root}
      onHide={handleHide}
      show={show}
      centered
    >
      <iframe
        title="video"
        src={url}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        width="100%"
      />
      <Close color="primary" fontSize="large" onClick={handleHide} />
    </Modal>
  );
};
