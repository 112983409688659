import pool from '@ricokahler/pool';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { collections as api } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';

import {
  doCollectionMomentsUpdate, getKey as getCollectionKey,
} from 'consumer/hooks/useCollection/utils';

import { UseCollectionsReturnType } from './types';
import { createRequest, getKey } from './utils';

export function useCollections(): UseCollectionsReturnType {
  const queryCache = useQueryCache();
  const { profile } = useAuth();

  const collections: UseCollectionsReturnType['collections'] = useQuery(
    getKey(),
    async () => {
      let page = 1;
      let result = [];

      while (page) {
        // eslint-disable-next-line no-await-in-loop
        const cc = await api.my(undefined, page);
        result = [...result, ...cc.items];
        page = cc.next;
      }

      return result;
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(profile),
      onSuccess: (data) => {
        // prefill cache with collections
        data.forEach((collection) => {
          queryCache.setQueryData(getCollectionKey(collection.pk), collection);
        });
        return data;
      },
    },
  );

  const create: UseCollectionsReturnType['create'] = useMutation(
    createRequest,
    {
      onSuccess: (): void => {
        queryCache.invalidateQueries(getKey());
      },
    },
  );

  const moments: UseCollectionsReturnType['moments'] = useMutation(
    async (updates) => {
      await pool({
        collection: Object.entries(updates),
        maxConcurrency: 3,
        task: async ([
          collectionId,
          momentIds,
        ]) => doCollectionMomentsUpdate(collectionId, momentIds),
      });
    },
    {
      onSuccess: (_data, updates): void => {
        queryCache.invalidateQueries(getKey());
        Object.keys(updates).forEach((collectionId) => {
          queryCache.invalidateQueries(getCollectionKey(collectionId));
        });
      },
    },
  );

  return { collections, create, moments };
}
