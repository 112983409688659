import { useParams } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';

import { UrlProps } from './types';

export const useTeamId = (): string | undefined => {
  const { subscription } = useAuth();

  const { uid } = useParams<UrlProps>();

  return uid || subscription.recent_team?.pk;
};
