import * as React from 'react';

import LabelError from '../LabelError';

interface Props {
  errors: { [key: string]: any };
}

const FieldErrors: React.FC<Props> = function FieldErrors({ errors }) {
  return (
    <>
      {Object.entries(errors).map(([_, error]) => (
        <LabelError key={error.detail} error={error.detail} />
      ))}
    </>
  );
};

export default FieldErrors;
