import { Collection, collections, ID, User } from 'weplayed-typescript-api';

export const getKey = (): string[] => ['collectons-mine'];

export function isCollectionCreatedByUser(
  collection: Collection,
  user: User,
): boolean {
  if (!collection || !user || !collection.created_by) {
    return false;
  }
  return collection.created_by.pk === user.pk;
}

export const createRequest = (attributes): Promise<ID> => collections.create(attributes);

export const isValidCollectionName = (
  name: string,
  otherCollections: string[],
): boolean => {
  if (!name) {
    return false;
  }
  const nameFormatted = name.trim().toLowerCase();
  if (!nameFormatted || nameFormatted.length < 5) {
    return false;
  }
  if (
    otherCollections
    && otherCollections.find(
      (collection) => collection.trim().toLowerCase() === nameFormatted,
    )
  ) {
    return false;
  }
  return true;
};
