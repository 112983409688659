import './TileList.less';

import { get } from 'lodash/fp';
import * as React from 'react';
import { What, WhatType } from 'weplayed-typescript-api';

import { ErrorMessage } from 'consumer/components/ErrorMessage';
import { Loading } from 'consumer/components/Loading';
import { ModeStates } from 'consumer/components/Tile/types';

import { Props } from './types';

export const TileList = function TileList<T extends What>({
  data, error, footer, header, showContentOnLoad = false, limit, loading, pk,
  renderer, rendererOptions, states, modes, onStateChange, appending,
}: Props<T>): JSX.Element {
  const onChange = React.useCallback((item, m, on) => {
    if (onStateChange) {
      // extend later with check by mode if other than boolean appears in type
      onStateChange({
        ...states,
        [m]: on
          ? [...(states?.[m] || []), item[pk]]
          : (states?.[m] || []).filter((i) => i !== item[pk]),
      });
    }
  }, [onStateChange, pk, states]);

  const renderItem = React.useCallback(
    (item: WhatType<T>): React.ReactNode => {
      // extend later with check by mode if other than boolean appears in type
      const itemState = states && Object.fromEntries(Object.keys(states).map(
        (m) => [m, states[m].includes(item.pk)],
      )) as ModeStates;

      return (
        <div key={get(pk, item)} className="tile-list-spacer">
          {renderer(item, {
            ...rendererOptions,
            modes,
            state: itemState,
            // disable anumated previews in select mode
            enablePreview: !modes?.select,
            onStateChange: onChange,
          })}
        </div>
      );
    },
    [states, pk, renderer, rendererOptions, modes, onChange],
  );

  return (
    <div className="tile-list">
      {header}
      <ErrorMessage error={error} />
      {(!loading || showContentOnLoad) && data && (
        <div className="tile-page-container">
          {(limit ? data.slice(0, limit) : data).map(renderItem)}
        </div>
      )}
      <Loading loading={loading || appending} />
      {footer}
    </div>
  );
};
