import './AvatarUpload.less';

import cx from 'classnames';
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Cropper from 'react-cropper';

import * as s from './AvatarUpload.m.less';
import { AvatarUploadProps, ReactCropperElement } from './types';

export const AvatarUpload: React.FC<AvatarUploadProps> = function AvatarUpload({
  className,
  onSuccess,
}) {
  const cropperRef = React.createRef<ReactCropperElement>();
  const imageRef = React.useRef<HTMLInputElement>();
  const [image, setImage] = React.useState<string>(null);

  const handleChange = React.useCallback((
    e: React.DragEvent | React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    let files;
    if ('dataTransfer' in e) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files[0]) {
      const reader = new FileReader();
      reader.onload = (): void => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  }, []);

  const handleClose = React.useCallback(() => {
    setImage(null);
    imageRef.current.value = '';
  }, []);

  const handleUpload = React.useCallback(() => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      onSuccess(cropperRef.current?.cropper.getCroppedCanvas({
        width: 480,
        height: 480,
      }).toDataURL('image/jpeg', 0.9));
      handleClose();
    }
  }, [cropperRef, handleClose, onSuccess]);

  return (
    <div className={cx(s.root, className)}>
      <div className={s.button} />
      <input
        ref={imageRef}
        accept="image/jpeg, image/jpg, image/png"
        className={s.input}
        name="file"
        onChange={handleChange}
        type="file"
      />
      <Modal
        keyboard
        show={Boolean(image)}
        onHide={handleClose}
      >
        <Modal.Body>
          <Cropper
            aspectRatio={1}
            autoCropArea={1}
            background={false}
            checkOrientation={false}
            guides
            initialAspectRatio={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            ref={cropperRef}
            responsive
            src={image}
            style={{ height: 400, width: '100%' }}
            viewMode={1}
            zoomTo={0.5}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            onClick={handleUpload}
            disabled={!image}
          >
            Crop Photo
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
