import * as cx from 'classnames';
import * as React from 'react';

import { hasTouch } from 'common/utils/features';

import * as s from './ItemPreview.m.less';
import { Props } from './types';

let container: HTMLElement;

/* istanbul ignore else */
if (hasTouch) {
  document.body.addEventListener('touchstart', (e) => {
    const target = e.target as HTMLElement;

    if (container) {
      container.classList.remove(s.hover);
      container = null;
    }

    if (target.parentElement.classList.contains(s.root)) {
      container = target.parentElement;
      container.classList.add(s.hover);
    }
  }, { passive: true });
}

export const ItemPreview: React.FC<Props> = function ItemPreview({
  className, src, animated, enable = true, alt, ...props
}) {
  return (
    <div
      className={cx(s.root, className)}
      data-testid="item-preview-root"
      {...props}
    >
      <img
        alt={alt}
        data-testid="item-preview-image"
        src={src}
      />
      {enable && animated && (
        <div
          data-testid="item-preview-animated"
          style={{ backgroundImage: `url(${animated})` }}
        />
      )}
    </div>
  );
};
