/* eslint-disable @typescript-eslint/no-explicit-any */
export const request = (element: HTMLElement): Promise<void> => {
  const elm = element as any;

  if (elm.requestFullscreen) {
    return elm.requestFullscreen();
  }

  if (elm.webkitRequestFullscreen) {
    return elm.webkitRequestFullscreen();
  }

  if (elm.mozRequestFullScreen) {
    return elm.mozRequestFullScreen();
  }

  if (elm.msRequestFullscreen) {
    return elm.msRequestFullscreen();
  }

  return Promise.reject();
};

export const isFullscreen = (): HTMLElement | null => {
  const doc = window.document as any;
  return doc.fullscreenElement
      || doc.webkitFullscreenElement
      || doc.mozFullScreenElement
      || doc.msFullscreenElement;
};

export const exit = (): void => {
  const doc = window.document as any;

  if (isFullscreen()) {
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  }
};

export const enabled = (): boolean => {
  const doc = window.document as any;

  return (
    doc.fullscreenEnabled
    || doc.webkitFullscreenEnabled
    || doc.mozFullScreenEnabled
    || doc.msFullscreenEnabled
  );
};

export const bind = (handler: (this: Document, ev: Event) => any): void => {
  window.document.addEventListener('fullscreenchange', handler);
  window.document.addEventListener('webkitfullscreenchange', handler);
  window.document.addEventListener('mozfullscreenchange', handler);
  window.document.addEventListener('MSFullscreenChange', handler);
};

export const unbind = (handler: (this: Document, ev: Event) => any): void => {
  window.document.removeEventListener('fullscreenchange', handler);
  window.document.removeEventListener('webkitfullscreenchange', handler);
  window.document.removeEventListener('mozfullscreenchange', handler);
  window.document.removeEventListener('MSFullscreenChange', handler);
};
