import * as cx from 'classnames';
import {
  curry, flatten, flow, groupBy, last, map, memoize, sortBy, toPairs,
} from 'lodash/fp';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SchoolTeam, TSportSeason } from 'weplayed-typescript-api';

import { ucFirst } from 'common/utils/helpers';

import { ConfirmationModal } from 'consumer/components/ConfirmationModal';
import SportIcon from 'consumer/components/SportIcon';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './TeamsList.m.less';
import { Props } from './types';

const seasonsOrder: TSportSeason[] = ['fall', 'winter', 'spring'];

const renderTeams = (teams, handleClick): React.ReactElement => (
  <ul className={s.teams}>
    {teams.map((team) => (
      <li key={team.sport_slug} className={cx(!team.available && s.unavailable)}>
        <Link
          to={(team.available && CLIENT_URLS.HOME.DISCOVER.buildPath({ uid: team.team_pk })) || '#no-team'}
          onClick={handleClick(team)}
        >
          <SportIcon sport={team.sport_slug} />
          <span>{team.sport_name}</span>
        </Link>
      </li>
    ))}
  </ul>
);

export const TeamsList: React.FC<Props> = function TeamsList(
  { className, school, showGetNotified = false },
): React.ReactElement {
  const [team, setTeam] = React.useState(null);

  const teams = React.useMemo(
    () => flow(
      sortBy((t: SchoolTeam) => t.sport_name),
      groupBy((t) => (t.available ? 0 : 1)),
      toPairs,
      sortBy(([sort]) => sort),
      map(last),
      flatten,
    )(school?.teams || []),
    [school],
  );

  const seasons = React.useMemo(
    () => flow(
        groupBy((t: SchoolTeam) => t.sport_season),
        toPairs,
        sortBy(([season]) => seasonsOrder.indexOf(season as TSportSeason)),
        map(([season, tt]) => [`${ucFirst(season)} Sports`, tt]),
      )(teams),
    [teams],
  );

  const handleClick = React.useCallback(memoize(curry((t, e) => {
    if (!t.available) {
      e.preventDefault();
      setTeam(t);
    }
  })), [setTeam]);

  const handleDismiss = React.useCallback(() => {
    setTeam(null);
  }, [setTeam]);

  const handleFollow = React.useCallback(() => {
    window.open('https://about.weplayed.com/contact/');
    handleDismiss();
  }, [handleDismiss]);

  return (school && (
    <div className={cx(s.root, className)}>
      <div className={s.all}>
        <h2>All Sports</h2>
        {renderTeams(teams, handleClick)}
      </div>
      <ul className={s.seasons}>
        {seasons.map(([seasonName, tt]) => (
          <li key={seasonName || 'no-name'} className={s.season}>
            <h2>{seasonName}</h2>
            {renderTeams(tt, handleClick)}
          </li>
        ))}
      </ul>
      <ConfirmationModal
        title={`${school?.name} ${team?.sport_name} Coming Soon`}
        confirmText="Get Notified"
        cancelText="Close"
        onConfirm={showGetNotified && handleFollow}
        onCancel={handleDismiss}
        show={Boolean(team)}
      >
        <p>
          WePlayed has an extensive catalog of
          {` ${school?.name} ${team?.sport_name}`}
          , including games, conferences, teams, and
          players.
        </p>
        <p>And we’ll be making it all available this fall.</p>
        <p>
          {showGetNotified
            ? 'Please click the button below and we’ll notify you as soon as it’s ready.'
            : 'We will notify you as soon as it’s ready.'}
        </p>
      </ConfirmationModal>
    </div>
  )) || null;
};
