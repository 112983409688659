/* eslint-disable max-classes-per-file */
import * as invariant from 'invariant';
import * as pathToRegexp from 'path-to-regexp';
import * as queryString from 'query-string';

import { logger } from 'common/utils/logger';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

function validateParams(params): void {
  Object.keys(params).forEach((key) => {
    if (key.endsWith('Id')) {
      if (!String(params[key]).match(UUID_REGEX)) {
        throw Error(`${key} param is not uuid (${params[key]})`);
      }
    }
  });
}

interface BuildPathProps {
  queryParams?: Record<string, unknown>;
}

class BaseUrl<T extends BuildPathProps> {
  public readonly route: string;

  protected reverse: pathToRegexp.PathFunction;

  constructor(route: string) {
    this.route = route;
    this.reverse = pathToRegexp.compile(route);
  }

  buildPath(props?: T, options = undefined): string {
    const { queryParams = {}, ...params } = props || {};
    const search = typeof queryParams === 'string'
      ? queryParams
      : queryString.stringify(queryParams, options);

    try {
      return search
        ? `${this.reverse(params)}?${search}`
        : this.reverse(params);
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.error('buildPath error', { message: e.message });
      return '#';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  valueOf(): void {
    invariant(false, 'Use "route" or "toPath"');
  }

  // eslint-disable-next-line class-methods-use-this
  toString(): void {
    invariant(false, 'Use "route" or "toPath"');
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
class DataUrl<T extends BuildPathProps = {}> extends BaseUrl<T> {
  props: T;

  constructor(route: string, props?: T) {
    super(route);

    this.props = props;
  }

  toPath(props?: T): DataUrl<T> {
    return new DataUrl(this.route, props);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  combineParams(extraParams: {}): {} {
    return {
      ...this.props?.queryParams,
      ...extraParams,
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  buildPath(extraGetParams?: {}, options = undefined): string {
    return super.buildPath(
      {
        ...this.props,
        queryParams: this.combineParams(extraGetParams),
      },
      options,
    );
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class ClientUrl<T extends BuildPathProps = {}> extends BaseUrl<T> {
  toPath(props?: T, options = undefined): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { queryParams, ...params } = props || {};
    try {
      validateParams(params);
    } catch (err) {
      logger.error(err.messsage, { extra: { route: this.route, props } });
    }

    return super.buildPath(props, options);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function dataUrl<T = {}>(route): DataUrl<T> {
  return new DataUrl(route);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function clientUrl<T = {}>(route): ClientUrl<T> {
  return new ClientUrl(route);
}
