import * as React from 'react';

import { AnnotationsPanel } from 'common/components/Annotations';

import { Button } from 'consumer/components/Button';

import { GameProviderContext } from '../GameProvider/constants';

export const GameControlsAnnotationsPanel: React.FC<
React.HTMLProps<HTMLDivElement>
> = function GameControlsAnnotationsPanel(props) {
  const {
    annotationsCanvas, annotationsPanel, annotations, onMomentAnnotateEnd,
  } = React.useContext(GameProviderContext);

  const handleDone = React.useCallback(() => {
    onMomentAnnotateEnd(true);
  }, [onMomentAnnotateEnd]);

  const handleClear = React.useCallback(() => {
    onMomentAnnotateEnd();
  }, [onMomentAnnotateEnd]);

  const handleCancel = React.useCallback(() => {
    onMomentAnnotateEnd(false);
  }, [onMomentAnnotateEnd]);

  return (
    <div {...props}>
      <AnnotationsPanel
        ref={annotationsPanel}
        canvas={annotationsCanvas.current}
      />
      <div className="moment-actions-bar-container">
        <Button
          variant="primary"
          disabled={!annotations}
          onClick={handleDone}
        >
          Set
        </Button>
        <Button
          variant="warning"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          variant="default"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
