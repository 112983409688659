import * as cx from 'classnames';
import * as React from 'react';

import FieldErrors from '../FieldErrors';
import FormLabel from '../FormLabel';

interface Props {
  name: string;
  children?: React.ReactNode;
  label?: string;
  className?: string;
  required?: boolean;
  errors?: { [key: string]: any };
}

const FormField: React.FC<Props> = function FormField(props) {
  const fieldErrors = (props.errors?.errors || [])
    .filter(
      (error) => error.source?.pointer === `/data/attributes/${props.name}`,
    );
  return (
    <div className={cx(props.className, fieldErrors.length && 'has-error')}>
      <FormLabel
        name={props.name}
        label={props.label || ''}
        required={props.required || false}
      />
      {props.children}
      <FieldErrors errors={fieldErrors} />
      <br />
    </div>
  );
};

export default FormField;
