import { useMutation } from 'react-query';
import { teams } from 'weplayed-typescript-api';

import { UseTeamReturnType, UseTeamType } from './types';

export const useTeam: UseTeamType = function useTeam(uid?: string) {
  const pin: UseTeamReturnType['pin'] = useMutation(
    (pinned) => teams.pin({ uid, pinned }),
  );

  return { pin };
};
