import { ReactComponent as CloseIcon } from '@mdi/svg/svg/close.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { Button } from 'react-bootstrap';

import * as s from './TagInput.m.less';
import { Props, Tag } from './types';

export const TagInput: React.FC<Props> = function TagInput({
  tags, suggestions, onChange, tagUI, className, onValidate, maxLength, startLength = 2,
}) {
  const inputRef = React.useRef<HTMLSpanElement>();
  const suggestionsRef = React.useRef<HTMLUListElement>();
  const prev = React.useRef<string>();
  const [variants, setVariants] = React.useState<Tag[]>([]);
  const [active, setActive] = React.useState<number>();

  const handleRemove = React.useCallback(({ currentTarget: { value } }): void => {
    const idx = parseInt(value, 10);
    onChange([...tags.slice(0, idx), ...tags.slice(idx + 1)]);
  }, [onChange, tags]);

  const handleFocus = React.useCallback(() => {
    inputRef.current.focus();
  }, []);

  React.useEffect(() => {
    if (typeof active === 'number') {
      const container = suggestionsRef.current;
      const item = container.childNodes[active] as HTMLLIElement;

      const containerHeight = container.getBoundingClientRect().height;
      const containerY = container.scrollTop;

      const itemHeight = item.getBoundingClientRect().height;
      const itemY = item.offsetTop;

      if (itemY + itemHeight > containerY + containerHeight) {
        container.scrollTo({ top: itemY + itemHeight - containerHeight });
      } else if (containerY > itemY) {
        container.scrollTo({ top: itemY });
      }
    }
  }, [active]);

  const loadVariants = React.useCallback(async () => {
    const value = inputRef.current.innerText.toLocaleLowerCase()
      .replace(/^\s+/, '').replace(/\s$/, '').replace(/\s+/g, '');

    let v: Tag[];

    if (value.length >= startLength) {
      if (Array.isArray(suggestions)) {
        v = suggestions
          .map((tag) => [tag, tag.value.toLocaleLowerCase().indexOf(value)])
          .filter(([tag, idx]) => idx !== -1 && (!onValidate || onValidate(tag as Tag)))
          .sort(([, a], [, b]) => (a > b ? 1 : -1))
          .map((pair) => pair.shift()) as Tag[];
      } else if (typeof suggestions === 'function') {
        v = await suggestions(value);
      } else if (variants.length !== 0) {
        v = [];
      }
    } else if (variants.length) {
      v = [];
    }

    if (v) {
      setVariants(v);
    }
  }, [onValidate, startLength, suggestions, variants.length]);

  const addTag = React.useCallback((tag: Tag) => {
    onChange([...tags, tag]);
    inputRef.current.textContent = '';
  }, [onChange, tags]);

  React.useEffect(() => {
    loadVariants();
    setActive(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleInput = React.useCallback(
    async (e: React.ChangeEvent<HTMLSpanElement>) => {
      const { target } = e;
      const { innerText } = target;
      let value = innerText.replace(/[\r\n]/g, ' ');

      const selection = window.getSelection();
      let cursor = selection.getRangeAt(0).endOffset;

      if (maxLength && value.length > maxLength) {
        value = value.substring(0, maxLength);
        cursor = Math.min(maxLength, cursor);
      }

      if (value !== innerText) {
        target.innerText = value;
        const range = document.createRange();
        range.setStart(target.childNodes[0], cursor);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      loadVariants();
    },
    [loadVariants, maxLength],
  );

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLSpanElement>) => {
    const { key, currentTarget: { innerText: value } } = e;

    if (key === 'Enter') {
      e.preventDefault();

      let tag;

      if (typeof active === 'number') {
        tag = variants[active];
      } else if (value !== '') {
        tag = onValidate ? onValidate({ value }) : { value };
      }

      if (tag) {
        addTag(tag);
      }
    } else if (key === 'Tab' && variants.length) {
      e.preventDefault();
      addTag(variants[0]);
    } else if (
      (key === 'Delete' || key === 'Backspace')
        && prev.current === key
        && value === ''
        && tags.length !== 0
    ) {
      prev.current = '';
      onChange(tags.slice(0, tags.length - 1));
    } else if (key === 'ArrowDown' && variants.length) {
      e.preventDefault();
      setActive(Math.min((active ?? -1) + 1, variants.length - 1));
    } else if (key === 'ArrowUp' && variants.length) {
      e.preventDefault();
      setActive(Math.max((active ?? variants.length) - 1, 0));
    } else if (key === 'Escape' && typeof active === 'number') {
      setActive(null);
    } else {
      prev.current = key;
    }
  }, [active, addTag, onChange, onValidate, tags, variants]);

  const handleAdd = React.useCallback((e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();

    const { currentTarget } = e;
    const idx = currentTarget.getAttribute('data-idx');
    if (idx && variants[idx]) {
      addTag(variants[idx]);
    }
  }, [addTag, variants]);

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events,
                      jsx-a11y/no-static-element-interactions */
    <div
      className={cx(s.root, variants.length && s.withVariants, className)}
      onClick={handleFocus}
    >
      {tags.map((tag, idx) => {
        const ui = tagUI ? tagUI(tag) : null;
        const css = cx(s.tag, ui?.className);

        return (
          <Button
            className={css}
            key={tag.value}
            onClick={handleRemove}
            size="sm"
            style={ui?.style}
            type="button"
            value={idx}
          >
            {tag.value}
            <CloseIcon />
          </Button>
        );
      })}

      <span
        className={s.input}
        contentEditable
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        spellCheck="false"
      />

      <ul className={s.suggestions} ref={suggestionsRef}>
        {variants?.map((tag, idx) => {
          const ui = tagUI ? tagUI(tag) : null;
          const css = cx(s.tag, ui?.className);

          return (
            <li
              className={cx(active === idx && s.active)}
              // for sure
              key={JSON.stringify(tag)}
              role="menuitem"
              data-idx={idx}
              tabIndex={0}
              onClick={handleAdd}
            >
              <Button
                className={css}
                key={tag.value}
                size="sm"
                style={ui?.style}
                type="button"
              >
                {tag.value}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
