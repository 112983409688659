import { JSONError } from 'weplayed-typescript-api';

export const ERROR_NAME = 'Please enter both your first and last name';
export const ERROR_EMAIL = 'Please enter valid email';
export const ERROR_USERNAME = 'Please follow user name guidelines';
export const ERROR_PASSWORD = 'Password must have at least 8 characters';
export const ERROR_PASSWORD_MATCH = 'Passwords must match';

export interface MappedFieldErrors {
  [key: string]: string;
}

export const fromBackendResponse: (errors: JSONError[]) => MappedFieldErrors = (
  errors: JSONError[] = [],
) => Object.fromEntries(errors.map((error): [string, string] => {
  let key = error.source?.pointer.replace('/data/attributes/', '') || 'non_field_errors';
  let value = error.detail;

  // compose name error
  if (key === 'last_name') {
    key = 'full_name';
    value = ERROR_NAME;
  } else if (key === 'first_name') {
    key = 'full_name';
  }

  return [key, value];
}));
