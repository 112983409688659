import { collections, ID } from 'weplayed-typescript-api';

export const getKey = (
  uid?: string,
  ...args: unknown[]
): unknown[] => [
  'collection',
  uid,
  ...args,
];

export const doCollectionMomentsUpdate = async (
  uid: ID,
  moments: ID[],
): Promise<number> => collections.moments.update({ uid, moments });
