import { mergeAll } from 'lodash/fp';
import * as React from 'react';
import { toast } from 'react-toastify';
import { subscribe, Subscription, User } from 'weplayed-typescript-api';

import { AuthAwareAction } from 'common/components/ApplicationProvider/types';
import { DEFAULT_SETTINGS } from 'common/constants';
import { Settings } from 'common/types';

import { BROADCAST_TIMEOUT, MAINTENANCE_CLASSNAME } from './constants';
import {
  ApplicationContextType, BroadcastMethod, UseCreateAppContext,
} from './types';

const broadcast: BroadcastMethod = (type, message, autoClose) => {
  toast(
    typeof message === 'string' ? message : message.jsx || message.message,
    {
      type,
      autoClose: autoClose ?? (['info', 'success'].includes(type) ? BROADCAST_TIMEOUT : false),
    },
  );
};

export const useCreateAppContext: UseCreateAppContext = function useCreateAppContext({
  strict, popups, loader,
} = { strict: false, popups: true }) {
  const loaders = React.useRef(0);
  const [authAwareAction, setAuthAwareAction] = React.useState<AuthAwareAction>();
  const [playing, setPlaying] = React.useState(false);
  const [appCues, setAppCues] = React.useState(false);
  const [_navType, setNavType] = React.useState({});
  const [subscription, setSubscription] = React.useState<Subscription>(null);
  const [profile, setProfile] = React.useState<User>(null);
  const [error, setError] = React.useState<Error>();
  const [orgId, setOrgId] = React.useState<string>();
  const [settings, setSettings] = React.useReducer(
    (state: Settings, update: DeepPartial<Settings>) => mergeAll([{}, state, update]),
    DEFAULT_SETTINGS,
  );

  const setLoading = React.useCallback((on) => {
    loaders.current = Math.max(0, loaders.current + (on ? 1 : -1));
    if (loader) {
      loader(loaders.current !== 0);
    }
  }, [loader]);

  React.useEffect(() => subscribe(
    418,
    () => document.body.classList.contains(MAINTENANCE_CLASSNAME)
      || document.body.classList.add(MAINTENANCE_CLASSNAME),
  ), []);

  const navType = React.useCallback((type, state) => {
    if (typeof type === 'object') {
      setNavType(Object.keys(type).reduce((acc, k) => ({ ...acc, [k]: type[k] }), _navType));

      return;
    }

    if (typeof state === 'boolean' && Boolean(_navType[type]) !== state) {
      setNavType({ ..._navType, [type]: state });

      return state;
    }

    return Boolean(_navType[type]);
  }, [_navType]);

  return React.useMemo(() => ({
    appCues,
    authAwareAction,
    broadcast,
    error,
    navType: navType as ApplicationContextType['navType'],
    orgId,
    playing,
    popups,
    profile,
    setAppCues,
    setAuthAwareAction,
    setError,
    setLoading,
    setOrgId,
    setPlaying,
    setProfile,
    setSettings,
    setSubscription,
    settings,
    strict,
    subscription,
  }), [
    appCues,
    authAwareAction,
    error,
    navType,
    orgId,
    playing,
    popups,
    profile,
    setLoading,
    settings,
    strict,
    subscription,
  ]);
};
