import './Welcome.less';

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { VideoPopup } from 'consumer/components/VideoPopup';
import { CLIENT_URLS } from 'consumer/routes';

import { getImages, VIDEO_URL } from './constants';
import { Props, State } from './types';

const BG_INTERVAL = 5000;
const eventName = 'onorientationchange' in window ? 'orientationchange' : 'resize';

export class Welcome extends React.PureComponent<Props, State> {
  protected bgTimer: number;

  state = {
    bg: 0,
    images: getImages(),
    video: false,
  };

  componentDidMount(): void {
    window.addEventListener(eventName, this.handleRotation, false);
    this.bgTimer = window.setInterval(
      () => {
        this.setState((state: State): State => ({
          ...state,
          bg: (state.bg + 1) % state.images.length,
        }));
      },
      BG_INTERVAL,
    );
  }

  componentWillUnmount(): void {
    window.clearInterval(this.bgTimer);
    window.removeEventListener(eventName, this.handleRotation, false);
  }

  handleRotation = (): void => {
    this.setState({ images: getImages() });
  };

  handleVideo = (e?: React.MouseEvent<HTMLButtonElement>): void => {
    if (e) {
      e.preventDefault();
    }
    this.setState((state) => ({ video: !state.video }));
  };

  protected renderImages = (): React.ReactNode => this.state.images.map((image, idx) => (
    <div
      key={image}
      className="back"
      style={{
        backgroundImage: `url(${image})`,
        opacity: this.state.bg === idx ? 0.3 : 0,
      }}
    />
  ));

  render(): JSX.Element {
    return (
      <div className="page-welcome">
        <Helmet>
          <title>WePlayed</title>
        </Helmet>
        <div className="back-holder">
          {this.renderImages()}
        </div>
        <h1>
          When the Game Ends,
          <br />
          the Story Begins
        </h1>
        <h3>Create, comment and share every moment from the games and teams you love.</h3>
        <div className="play-video">
          <button
            type="button"
            onClick={this.handleVideo}
          >
            See how it works
          </button>
        </div>
        <div className="join-team">
          <Link to={CLIENT_URLS.SCHOOLS.LIST_VIEW.buildPath()}>
            <Button
              size="lg"
              id="button-find-your-team"
            >
              Find your team
            </Button>
          </Link>
          {/* <span>or</span>
          <Link to={CLIENT_URLS.SPORTS.HOME.buildPath()}>
            <Button
              size="lg"
              color="primary"
              id="button-select-your-sport"
            >
              Select your sport
            </Button>
          </Link> */}
        </div>
        <VideoPopup url={VIDEO_URL} show={this.state.video} onClose={this.handleVideo} />
      </div>
    );
  }
}
