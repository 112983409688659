import * as React from 'react';

import { CaptionsControlButton } from '../CaptionsControlButton';
import { FullscreenControlButton } from '../FullscreenControlButton';
import { VideoPlayerContext } from '../VideoPlayer/constants';
import { VolumeControlButton } from '../VolumeControlButton';
import * as s from './MomentPlayerControls.m.less';

export const MomentPlayerControls: React.FC = React.memo(() => {
  const controlsTimer = React.useRef<ReturnType<typeof requestAnimationFrame>>();
  const sliderRef = React.useRef<HTMLInputElement>();
  const prevStateChanged = React.useRef<boolean>();
  const pauseTimer = React.useRef<ReturnType<typeof setTimeout>>();

  const {
    captions,
    controls,
    end,
    handleCaptions,
    handleFullscreen,
    handlePlayPause,
    handleSeek,
    handleVolume,
    playing,
    position,
    start,
    volume,
  } = React.useContext(VideoPlayerContext);

  // clear all timeouts
  React.useEffect(() => (): void => {
    clearTimeout(pauseTimer.current);
    cancelAnimationFrame(controlsTimer.current);
  }, []);

  const updateControls = React.useCallback((schedule = true) => {
    sliderRef.current.value = String(position() * 100);

    if (schedule) {
      controlsTimer.current = requestAnimationFrame(updateControls);
    }
  }, [position]);

  const handleStart = React.useCallback(() => {
    prevStateChanged.current = playing;
    if (playing) {
      pauseTimer.current = setTimeout(() => handlePlayPause(false), 300);
    }
  }, [handlePlayPause, playing]);

  const handleEnd = React.useCallback(() => {
    if (prevStateChanged.current) {
      sliderRef.current.blur();
      handlePlayPause(true);
    }
    clearTimeout(pauseTimer.current);
  }, [handlePlayPause]);

  const handleChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      handleSeek(parseInt(value, 10) / 100);
    },
    [handleSeek],
  );

  React.useEffect(() => {
    if (controls) {
      controlsTimer.current = requestAnimationFrame(updateControls);

      return (): void => cancelAnimationFrame(controlsTimer.current);
    }
  }, [controls, updateControls]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => updateControls(false), []);

  return (
    <div className={s.root}>
      <div className={s.progress}>
        <input
          ref={sliderRef}
          id="player-progress-slider"
          type="range"
          min={start * 100}
          max={end * 100}
          onMouseDown={handleStart}
          onMouseUp={handleEnd}
          onChange={handleChange}
          defaultValue={start * 100}
        />
        <label
          className="sr-only"
          htmlFor="player-progress-slider"
        >
          Progress slider
        </label>
      </div>
      {handleCaptions && (
        <CaptionsControlButton
          enabled={captions}
          onClick={handleCaptions}
        />
      )}

      {handleFullscreen && (
        <FullscreenControlButton
          onClick={handleFullscreen}
        />
      )}

      <VolumeControlButton
        onVolume={handleVolume}
        volume={volume}
      />
    </div>
  );
});

MomentPlayerControls.displayName = 'MomentPlayerControls';
