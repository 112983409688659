import { Moment, Player, Tag, Team } from 'weplayed-typescript-api';

import {
  parseAsPlayer, parseAsTag, parseAsTeam, RenderData,
} from 'common/utils/moments';

import { FilterTag, FilterTagType } from './types';

export const filterMomentsForReview = (moments: Moment[]): Moment[] => moments
  .filter(
    (moment: Moment) => !moment.reviewed && !moment.is_nav,
  )
  .sort(
    (a: Moment, b: Moment): number => (
      (b.score ? b.score.score : 0) < (a.score ? a.score.score : 0) ? -1 : 1
    ),
  );

type ExtractMomentNames = 'players' | 'teams' | 'tags';

type ExtractMomentTagsResult = Record<ExtractMomentNames, FilterTag[]>;

type ExtractMomentTagMap = Record<ExtractMomentNames, Record<string, FilterTag>>;

const mapToResult = (map: ExtractMomentTagMap): ExtractMomentTagsResult => (
  Object.fromEntries(Object.entries(map).map(([k, v]) => [k, Object.values(v)]))
) as ExtractMomentTagsResult;

export const extractMomentTags = (
  { description }: Moment,
  update?: ExtractMomentTagMap,
): ExtractMomentTagsResult & { text: string } | undefined => {
  let match: RenderData<Player | Tag | Team>;
  let text = description;

  const result = update ?? { teams: {}, tags: {}, players: {} };

  do {
    match = parseAsPlayer(text);
    if (match) {
      if (!result.players[match.id]) {
        result.players[match.id] = {
          color: match.color,
          pk: match.id,
          type: FilterTagType.PLAYER,
          value: match.display,
        };
      }
      text = match.prefix + match.postfix;
    }
  } while (match);

  do {
    match = parseAsTeam(text);
    if (match) {
      if (!result.teams[match.id]) {
        result.teams[match.id] = {
          color: match.color,
          pk: match.id,
          type: FilterTagType.TEAM,
          value: match.display,
        };
      }
      text = match.prefix + match.postfix;
    }
  } while (match);

  do {
    match = parseAsTag(text);
    if (match) {
      if (!result.tags[match.display]) {
        result.tags[match.display] = {
          type: FilterTagType.TAG,
          value: match.display,
        };
      }
      text = match.prefix + match.postfix;
    }
  } while (match);

  if (!update) {
    return {
      ...mapToResult(result),
      text,
    };
  }
};

export const extractMomentsTags = (
  moments?: Moment[],
): ExtractMomentTagsResult => {
  const update = {
    teams: {},
    tags: {},
    players: {},
  };

  moments?.forEach((moment) => extractMomentTags(moment, update));

  return mapToResult(update);
};
