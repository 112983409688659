import * as React from 'react';
import Slider from 'react-slick';
import { Moment, Team } from 'weplayed-typescript-api';

import { MomentTile } from 'consumer/components/MomentTile';
import { TeamImage } from 'consumer/components/TeamImage';

interface Props {
  moments: Moment[];
  team: Team;
}

export const MomentsSection: React.FC<Props> = function MomentsSection({
  moments, team,
}) {
  if (!moments || moments.length === 0) {
    return null;
  }

  return (
    <>
      <div className="home-section-spacer" />
      <h2 className="home-section-header-title">
        TOP MOMENTS
        <span className="home-page-section-header-spacer">—</span>
        {team.acronym}
        <TeamImage team={team} size="x-small" className="home-page-section-header-logo" />
      </h2>
      <hr />
      <Slider
        variableWidth
        infinite={false}
        draggable
        swipeToSlide
        speed={500}
        className="slider variable-width"
      >
        {moments.map((moment) => (
          <MomentTile key={moment.pk} item={moment} />
        ))}
      </Slider>
    </>
  );
};
