import * as React from 'react';

interface Props {
  error: string;
}

const LabelError: React.FC<Props> = function LabelError({ error }) {
  return <span className="badge badge-danger">{error}</span>;
};

export default LabelError;
