import * as React from 'react';

import { logger } from 'common/utils/logger';

export class ErrorBoundary extends React.Component {
  componentDidCatch(error): void {
    document.body.classList.add('error');
    logger.error(error?.message || 'unknown error', error);
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}
