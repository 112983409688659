import { Sport } from 'weplayed-typescript-api';

export const getSegmentNameShort = (
  segment: number,
  sport: Sport | undefined,
  length: number,
): string => (
  `${
    sport && ['MCVOLL', 'WCVOLL'].includes(sport.slug) ? 'G' : (length === 2 && 'H') || 'Q'
  }${
    segment
  }`
);

const hasGames = (sport: Sport): boolean => sport && ['MCVOLL', 'WCVOLL'].includes(sport.slug);

export const getSegmentName = (
  segment: number,
  sport: Sport | undefined,
  length: number,
): string => (
  `${
    ['FIRST', 'SECOND', 'THIRD', 'FOURTH'][segment - 1] || ''
  } ${
    hasGames(sport) ? 'GAME' : (length === 2 && 'HALF') || 'QUARTER'
  }`
);
