import * as React from 'react';
import { Helmet } from 'react-helmet';

import { useApplication } from 'common/hooks/useApplication';
import { useAuth } from 'common/hooks/useAuth';

import * as s from './Game.m.less';
import { GameControlsAnnotationsPanel } from './GameControlsAnnotationsPanel';
import { GameControlsFilter } from './GameControlsFilter';
import { GameControlsHeadline } from './GameControlsHeadline';
import { GameControlsMomentsHub } from './GameControlsMomentsHub';
import { GameProvider } from './GameProvider';
import { GameProviderContext } from './GameProvider/constants';
import { MODE } from './GameProvider/types';
import { GameVideo } from './GameVideo';
import { MomentWarning } from './MomentWarning';

const GameConsumer: React.FC = function Game() {
  const { popups, navType } = useApplication();
  const { profile } = useAuth();

  const { loaded, game, mode, state, stateTo } = React.useContext(GameProviderContext);

  React.useEffect(() => {
    if (loaded) {
      navType('tiny', true);
      return (): void => { navType('tiny', false); };
    }
  }, [loaded, navType]);

  if (!loaded) {
    return null;
  }

  return (
    <div className={s.root}>
      <Helmet>
        <title>{`WePlayed Game: ${game?.name || 'loading...'}`}</title>
      </Helmet>

      <div className={s.video}>
        <GameVideo />
      </div>

      <div className={s.controls}>
        <GameControlsHeadline className={s.headline} />
        {mode === MODE.ANNOTATIONS && (
          <GameControlsAnnotationsPanel className={s.annotations} />
        )}
        {mode !== MODE.ANNOTATIONS && (
          <>
            <GameControlsFilter className={s.filter} />
            <GameControlsMomentsHub headerClassName={s.hub} entryHeaderClassName={s.entry} />
          </>
        )}
      </div>
      {popups && !profile?.is_staff && (
        <MomentWarning
          gamePk={game.pk}
          onState={stateTo}
          playing={state}
        />
      )}
    </div>
  );
};

export const Game: React.FC = function Game() {
  return (
    <GameProvider><GameConsumer /></GameProvider>
  );
};
