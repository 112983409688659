import { isObject } from 'lodash/fp';

const getCookie = (key: string): string | undefined => (
  document.cookie.split(/;\s*/)
    .map((str) => str.split('=', 2).map(decodeURIComponent))
    .filter(([k]) => k === key)
    .map(([, v]) => v)
    .shift()
);

const setCookie = (
  key: string,
  value: string,
  lifetime = 10 * 365 * 24 * 3600,
): void => {
  document.cookie = [
    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    'Path=/',
    `Max-Age=${lifetime}`,
    'Domain=.weplayed.com',
    'SameSite=None',
    'Secure',
  ].join(';');
};

const removeCookie = (key: string): void => {
  document.cookie = [
    `${encodeURIComponent(key)}=`,
    'Path=/',
    'Expires=Thu, 01 Jan 1970 00:00:00 GMT',
    'Domain=.weplayed.com',
    'SameSite=None',
    'Secure',
  ].join(';');
};

interface KeyValue {
  [K: string]: string | null;
}

const isKeyValue = (kv: unknown): kv is KeyValue => isObject(kv);

let cookies = false;
let ls = false;

try {
  setCookie('cookietest', 'test');
  if (document.cookie) {
    cookies = true;
  }
  removeCookie('cookietest');
} catch (e) {
  //
}

try {
  window.localStorage.setItem('test', 'test');
  ls = window.localStorage.getItem('test') === 'test';
  window.localStorage.removeItem('test');
} catch (e) {
  //
}

class Persistent {
  // eslint-disable-next-line class-methods-use-this
  public get hasCookies(): boolean {
    return cookies;
  }

  // eslint-disable-next-line class-methods-use-this
  public get hasLS(): boolean {
    return ls;
  }

  get = (
    key: string,
    withCookie = false,
    dropCookie = false,
  ): string | undefined => {
    let value;

    if (this.hasLS) {
      value = window.localStorage.getItem(key);
      if (value === null) {
        value = undefined;
      }
    } else if (!withCookie) {
      return;
    }

    if (value === undefined && withCookie && this.hasCookies) {
      value = getCookie(key);

      if (value !== undefined) {
        // copy to LS
        this.set(key, value);
        if (dropCookie) {
          removeCookie(key);
        }
      }
    }

    return value;
  };

  set = (key: string | KeyValue, value?: string | null, withCookie = false): this => {
    if (isKeyValue(key)) {
      Object.keys(key).forEach((k) => this.set(k, key[k]));
    } else if (this.hasLS) {
      if (value === null || value === undefined) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, value);
      }

      if (withCookie) {
        if (value) {
          setCookie(key, value);
        } else {
          removeCookie(key);
        }
      }
    }

    return this;
  };

  startsWith = (prefix: string): KeyValue => {
    if (this.hasLS) {
      return Object.fromEntries(
        Object.keys(window.localStorage)
          .filter((k) => k.startsWith(prefix))
          .map((k) => [k, window.localStorage.getItem(k)]),
      );
    }

    return {};
  };
}

export const persistent = new Persistent();
