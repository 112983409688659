import { ReactComponent as Account } from '@mdi/svg/svg/account.svg';
import { ReactComponent as Login } from '@mdi/svg/svg/login.svg';
import { ReactComponent as Logout } from '@mdi/svg/svg/logout.svg';
import { ReactComponent as Magnify } from '@mdi/svg/svg/magnify.svg';
import { ReactComponent as MenuDown } from '@mdi/svg/svg/menu-down.svg';
import { ReactComponent as Menu } from '@mdi/svg/svg/menu.svg';
import * as cx from 'classnames';
import { ReactComponent as Logo } from 'images/logo.svg';
import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useApplication } from 'common/hooks/useApplication';
import { useProfile } from 'common/hooks/useProfile';

import { Avatar } from 'consumer/components/Avatar';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './Header.m.less';

export const Header: React.FC = function Header() {
  const [menu, setMenu] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const { strict, navType } = useApplication();
  const { profile, logout: [logout] } = useProfile();
  const profileMenu = React.useRef<HTMLButtonElement>();

  const toggleMenu = React.useCallback(() => setMenu(!menu), [menu]);

  React.useEffect(() => {
    const listener = (): void => setMenu(false);
    return history.listen(listener);
  }, [history]);

  // Safari does not set buttons focus on click by default
  // so we set focus explicitly
  React.useEffect(() => {
    const element = profileMenu.current;
    if (element) {
      const listener = (e: MouseEvent): void => {
        (e.currentTarget as HTMLButtonElement).focus();
      };

      element.addEventListener('mouseup', listener);
      return (): void => {
        if (element) {
          element.removeEventListener('mouseup', listener);
        }
      };
    }
  }, [profileMenu.current]);

  const handleLogout = React.useCallback(async (): Promise<void> => {
    await logout();

    history.push(CLIENT_URLS.HOME.DISCOVER_DEFAULT.buildPath());
  }, [history, logout]);

  // it is really hard to iterate over all matches all URLs where ?next= should
  // not be applied so just use /login and /registration as URL prefixes to blacklist
  const nextParams = location.pathname.startsWith('/login') || location.pathname.startsWith('/registration')
    ? undefined
    : { queryParams: { next: `${location.pathname}${location.search}` } };

  const tiny = navType('tiny');
  const tinyTimer = React.useRef<ReturnType<typeof setTimeout>>();
  const [tinyOpen, setTinyOpen] = React.useState(false);

  React.useEffect(() => {
    if (tiny) {
      setTinyOpen(true);
      tinyTimer.current = setTimeout(() => setTinyOpen(false), 1000);

      return (): void => {
        setTinyOpen(false);
        clearTimeout(tinyTimer.current);
      };
    }
  }, [tiny]);

  if (strict) {
    return (
      <header className={s.root}>
        <div className={s.container}>
          <div role="banner" aria-label="WePlayed Logo" className={s.brand}>
            <Logo id="logo-page-main" />
          </div>
        </div>
      </header>
    );
  }

  const isAuthenticated = Boolean(profile);

  return (
    <header
      className={cx(
        s.root,
        tiny && s.tiny,
        tiny && tinyOpen && s.tinyHover,
      )}
      role="navigation"
    >
      <div className={cx(s.container, menu && s.open)}>
        <button
          aria-expanded={menu}
          aria-hidden
          aria-label="Menu"
          className={s.menu}
          onClick={toggleMenu}
          type="button"
        >
          <Menu />
        </button>
        <div role="banner" aria-label="WePlayed Logo" className={s.brand}>
          <Link to={CLIENT_URLS.HOME.DISCOVER_DEFAULT.toPath()}>
            <Logo id="logo-page-main" />
          </Link>
        </div>
        <Link
          aria-label="Search"
          className={s.search}
          id="nav-search-mobile"
          to={CLIENT_URLS.SEARCH.PAGE.toPath()}
        >
          <Magnify />
        </Link>
        <ul role="group" aria-label="Navigation">
          <li>
            <Link to={CLIENT_URLS.HOME.INTRO.toPath()} id="nav-intro">
              Intro
            </Link>
          </li>

          <li>
            <Link
              id="nav-schools"
              to={CLIENT_URLS.SCHOOLS.LIST_VIEW.toPath()}
            >
              Schools
            </Link>
          </li>

          {isAuthenticated && (
            <li>
              <Link to={CLIENT_URLS.USER.MY_CHANNEL.toPath()} id="nav-channel">
                My Channel
              </Link>
            </li>
          )}

          <li className={s.search}>
            <Link
              aria-label="Search"
              id="nav-search-desktop"
              to={CLIENT_URLS.SEARCH.PAGE.toPath()}
            >
              <Magnify />
            </Link>
          </li>

          {!isAuthenticated && (
            <>
              <li>
                <Link to={CLIENT_URLS.AUTH.LOGIN.toPath(nextParams)} id="nav-login">
                  <Login />
                  Sign In
                </Link>
              </li>
              <li>
                <Link to={CLIENT_URLS.AUTH.REGISTRATION.toPath(nextParams)} id="nav-signup">
                  <Account />
                  Sign Up
                </Link>
              </li>
            </>
          )}

          {isAuthenticated && profile && (
            <li className={s.profile}>
              <button type="button" ref={profileMenu}>
                <Avatar user={profile} />
                <MenuDown />
              </button>
              <ul id="navbar-profile" role="group" aria-label="Profile">
                <li>
                  <Link
                    aria-label="Profile"
                    id="nav-profile"
                    to={CLIENT_URLS.USER.MY_PROFILE.toPath()}
                  >
                    {profile.full_name}
                  </Link>
                </li>
                <li>
                  <button
                    id="nav-logout"
                    onClick={handleLogout}
                    type="button"
                  >
                    <Logout />
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </header>
  );
};
