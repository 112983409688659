import * as React from 'react';
import { Helmet } from 'react-helmet';

import * as s from './styles.m.less';

export const Page404: React.FC = function Page404() {
  return (
    <section className={s.root} aria-labelledby="page-404-header">
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <h1 id="page-404-header">
        4
        <span>0</span>
        4
      </h1>
      <p>Sorry, the page you&lsquo;re looking for doesn&lsquo;t exist.</p>
    </section>
  );
};
