import { ReactComponent as Record } from '@mdi/svg/svg/record.svg';
import * as cx from 'classnames';
import * as React from 'react';

import * as s from './LiveButton.m.less';
import { Props } from './types';

export const LiveButton: React.FC<Props> = function LiveButton({
  className, collapse = true, live, onClick, ...rest
}) {
  return (
    <button
      {...rest}
      type="button"
      className={cx(s.root, collapse && s.collapse, className)}
      onClick={onClick}
    >
      <Record className={live ? s.liveOnline : s.liveOffline} />
      <span>Live</span>
    </button>
  );
};
