import * as cx from 'classnames';
import { ReactComponent as Logo } from 'images/logo-text-white.svg';
import * as React from 'react';

import { Button } from 'consumer/components/Button';

import * as s from './ConnectWithUs.m.less';
import { Props } from './types';

export const ConnectWithUs: React.FC<Props> = function ConnectWithUs({
  className, logo = false, second, toggle, ...props
}) {
  return (
    <div className={cx(s.root, second && s[second], toggle && s.toggle, className)} {...props}>
      <span>Give fans the content they crave</span>
      {logo && <Logo />}
      <span>
        <Button as="a" href="http://calendly.com/spencer-weplayed">
          Connect with us
        </Button>
      </span>
    </div>
  );
};
