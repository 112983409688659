import { ReactComponent as Copy } from '@mdi/svg/svg/content-copy.svg';
import { ReactComponent as Delete } from '@mdi/svg/svg/delete.svg';
import { ReactComponent as Download } from '@mdi/svg/svg/download.svg';
import { ReactComponent as LeadPencil } from '@mdi/svg/svg/lead-pencil.svg';
import { ReactComponent as Playlist } from '@mdi/svg/svg/playlist-play.svg';
import { ReactComponent as Sort } from '@mdi/svg/svg/sort.svg';
import * as cx from 'classnames';
import { memoize } from 'lodash/fp';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { usePrevious } from 'common/hooks/usePrevious';
import { ShareTarget } from 'common/types';
import { event } from 'common/utils/analytics';
import { EventContext } from 'common/utils/events';
import { getCollectionShareMeta } from 'common/utils/share';

import { ConfirmationModal } from 'consumer/components/ConfirmationModal';
import { GameInfoVs } from 'consumer/components/GameInfoVs';
import { LikeButton } from 'consumer/components/LikeButton';
import { NameDialog } from 'consumer/components/NameDialog';
import { Share } from 'consumer/components/Share';
import { ShareMeta } from 'consumer/components/Share/types';
import { TooltipButton } from 'consumer/components/TooltipButton';
import { UserHighlight } from 'consumer/components/UserHighlight';
import { isValidCollectionName } from 'consumer/hooks/useCollections';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './CollectionHeader.m.less';
import { CollectionContext } from './constants';
import { ModalMode } from './types';

export const CollectionHeader: React.FC = function CollectionHeader({ children }) {
  const history = useHistory();
  const [modal, setModal] = React.useState<ModalMode>();

  const {
    action, collection, scrolled, strict, user,
    isCloning, isRemoving, isDownloading,
    onClone, onDelete, onDownload, onLike, onRename,
  } = React.useContext(CollectionContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleModal = React.useCallback(memoize((m?: ModalMode) => (): void => {
    setModal(m);
  }), []);

  const share: ShareMeta = React.useMemo(() => collection && {
    pk: collection.pk,
    type: What.COLLECTIONS,
    meta: getCollectionShareMeta(collection),
  }, [collection]);

  const prevCloning = usePrevious(isCloning);
  React.useEffect(() => {
    if (prevCloning && !isCloning) {
      setModal(null);
    }
  }, [isCloning, prevCloning]);

  const handleSendAnalytics = React.useCallback(
    (target: ShareTarget): void => {
      const to = (target === 'facebook' && 'FB')
              || (target === 'twitter' && 'TW')
              || (target === 'native' && 'Mobile')
              || 'Other';

      event({
        category: 'Sharing',
        action: `Collection Shared on ${to}`,
        label: collection.pk,
      });
    },
    [collection.pk],
  );

  const handleRename = React.useCallback((name: string) => {
    setModal(null);
    onRename(name);
  }, [onRename]);

  const handleDelete = React.useCallback(() => {
    setModal(null);
    onDelete();
  }, [onDelete]);

  const handleSort = React.useCallback(() => {
    history.push(CLIENT_URLS.COLLECTIONS.EDIT_COLLECTION.buildPath({ uid: collection.pk }));
  }, [collection.pk, history]);

  const handleNameValidate = React.useCallback(
    (name) => isValidCollectionName(name, [collection.name]),
    [collection.name],
  );

  const isOwner = collection.created_by.pk === user?.pk;
  const team = strict && collection.dedicated_game && collection.dedicated_team;
  const { org } = collection;

  return (
    <div className={cx(s.header, scrolled && strict && s.scrolled, team && s.dedicated)}>
      <div className={s.actions}>
        <div className={s.name}>
          {!strict && collection.can_manage ? (
            <TooltipButton
              onClick={handleModal(ModalMode.rename)}
              tooltip="Rename this Playlist"
            >
              <div className="mono-color-link">
                {collection.name}
              </div>
            </TooltipButton>
          ) : (
            collection.name
          )}
        </div>
        {team && (
          <GameInfoVs
            className={s.game}
            game={collection.dedicated_game}
            team={team}
          />
        )}
        <div className={s.counter}>
          <Playlist />
          <span className={!org && s.bold}>{collection.moment_count}</span>
          {`${strict && org ? '#moments' : 'Moments'}${
            !strict && collection.can_see_duration && collection.duration
              ? ` (${collection.duration.toFixed(1)}s)`
              : ''}`}
        </div>
        {!strict && !isOwner && (
          <div className={s.action}>
            <LikeButton
              likes
              liked={collection.liked_by_user}
              onChange={onLike}
              tooltip
            />
          </div>
        )}

        {!strict && (
          <div className={s.action}>
            <Share
              context={EventContext.COLLECTION}
              meta={share}
              onShare={handleSendAnalytics}
              placement="bottom"
              tooltip="Share"
            />
          </div>
        )}

        {!strict && collection.can_manage && (
          <>
            <div className={s.action}>
              <TooltipButton
                tooltip="Delete this Playlist"
                onClick={handleModal(ModalMode.delete)}
                processing={isRemoving}
              >
                <Delete />
              </TooltipButton>
            </div>
            <div className={s.action}>
              <TooltipButton
                tooltip="Rename this Playlist"
                onClick={handleModal(ModalMode.rename)}
              >
                <LeadPencil />
              </TooltipButton>
            </div>
            <div className={s.action}>
              <TooltipButton
                tooltip="Sort Moments"
                onClick={handleSort}
                disabled={collection.moment_count < 2 || action === 'edit'}
              >
                <Sort />
              </TooltipButton>
            </div>
          </>
        )}
        {!strict && collection.can_download && (
          <div className={s.action}>
            <TooltipButton
              tooltip="Download Playlist"
              onClick={onDownload}
              processing={isDownloading}
            >
              <Download />
            </TooltipButton>
          </div>
        )}
        {!strict && collection.can_clone && (
          <div className={s.action}>
            <TooltipButton
              tooltip="Clone Playlist"
              onClick={handleModal(ModalMode.clone)}
              processing={isCloning}
            >
              <Copy />
            </TooltipButton>
          </div>
        )}
        {!strict && !isOwner && (
          <div className={s.creator}>
            <UserHighlight
              user={collection.created_by}
              label="CREATED BY"
              disableLink={strict}
            />
          </div>
        )}
      </div>
      {children}
      {modal === ModalMode.delete && (
        <ConfirmationModal
          onConfirm={handleDelete}
          onCancel={handleModal()}
          title="Delete Playlist"
          message={'Are you sure you want to completely delete this playlist? '
            + 'Moments included in this playlist will not be affected.'}
        />
      )}

      {modal === ModalMode.rename && (
        <NameDialog
          name={collection.name}
          title="Rename Playlist"
          onCancel={handleModal()}
          onSave={handleRename}
          onValidate={handleNameValidate}
        />
      )}

      {modal === ModalMode.clone && (
        <NameDialog
          loading={isCloning}
          name={`Copy of ${collection.name}`}
          title="Clone Playlist"
          onCancel={handleModal()}
          onSave={onClone}
          onValidate={handleNameValidate}
        />
      )}
    </div>
  );
};
