import { castArray, isUndefined, omitBy } from 'lodash/fp';
import { parse, ParseOptions } from 'query-string';
import * as React from 'react';

import { ClientUrl } from 'common/utils/routes';

import { SearchParams, Section } from 'consumer/hooks/useSearch/types';

import { QueryData, QueryParams } from './types';

const qsOptions: ParseOptions = {
  arrayFormat: 'comma',
};

export const parseSearchParams = (qs: string, defaults?: QueryData): [ QueryData, Section ] => {
  const parsed: { [key in keyof QueryParams ]?: string } = parse(qs, qsOptions);
  const q = parsed.q || defaults?.q || '';
  const params = ['season', 'sport', 'team', 'org'].reduce((p, k) => ({
    ...p,
    [k]: k in parsed
      ? castArray(parsed[k]).filter(Boolean)
      : (defaults?.[k] || []),
  }), {});
  const vs = parsed.vs || defaults?.vs || 'false';

  return [
    omitBy(isUndefined, {
      ...params,
      q,
      vs: vs === 'true' ? true : undefined,
    }),
    'tab' in parsed ? parsed.tab as Section : Section.MOMENTS,
  ];
};

export const toSearchParams = (
  query: QueryData,
  sort?: string,
): SearchParams => omitBy(isUndefined, {
  text: query.q || '',
  org_ids: query.org,
  seasons: query.season,
  sport_id: query.sport,
  team_ids: query.team,
  team_both: query.team && query.team.length === 2 && query.vs ? true : undefined,
  sort,
});

export const toTabLabel = (num: number, name: Section): React.ReactElement => (
  <>
    <b>{num}</b>
    {' '}
    {num === 1 ? name.replace(/s$/i, '') : name}
  </>
);

export const toSearchUrl = (
  url: ClientUrl,
  params: {[key: string]: string},
  query?: QueryData,
  tab?: Section,
): string => url.toPath({
  ...params,
  queryParams: query ? { ...query, tab } : undefined,
}, qsOptions);
