import { parse } from 'query-string';
import { User } from 'weplayed-typescript-api';

import { CMS_CONTEXT_KEY } from 'common/constants';
import { CMS_CONTEXT, CMS_DOMAIN, IS_APP, IS_CMS } from 'common/utils/cms';
import { persistent } from 'common/utils/persistent';

export const getRedirectUrl = (
  location: { search?: string },
  defaultRedirect: string,
  ignoreNext?: boolean,
): string => {
  if (!ignoreNext) {
    const params = parse(location.search);

    if (params.next) {
      return params.next as string;
    }
  }

  return defaultRedirect;
};

/**
 * Return redirect to CMS/APP site depending on user and current location
 *
 * @param user Current user
 * @param path Pathname instead of current
 * @returns Redirect URL or undefined
 *
 * Test cases
 * # No user, app -> undefined
 * # No user, cms, not stored -> undefined
 * # No user, cms, stored -> label
 * # No user, label -> undefined
 *
 * # app user, APP -> undefined
 * # app user, cms -> app
 * # app user, label -> app
 *
 * # cms user, APP -> user label
 * # cms user, CMS -> user label
 * # cms user, label = user label -> undefined
 * # cms user, label != user label -> user label
 */

export const getCmsRedirect = (
  user: Pick<User, 'cms_org_namespace'> | undefined,
  path: string,
): string | undefined => {
  if (process.env.REDIRECT_CMS_HOST !== 'true') {
    return;
  }

  const isapp = IS_APP();
  const iscms = IS_CMS();
  const cmscontext = CMS_CONTEXT();

  // store context first on cms site (no label) ONLY
  if ((isapp || (iscms && !cmscontext)) && user?.cms_org_namespace) {
    persistent.set(CMS_CONTEXT_KEY, user.cms_org_namespace);
  }

  const context = iscms && !cmscontext && persistent.get(CMS_CONTEXT_KEY);

  // no user
  if (!user && !context) {
    return;
  }

  // app user
  if (user && !user.cms_org_namespace && (
    isapp
  )) {
    return;
  }

  // cms user
  if (user && user.cms_org_namespace && (
    user.cms_org_namespace === cmscontext
  )) {
    return;
  }

  const slug = user?.cms_org_namespace || context;
  const hostname = slug
    ? `${slug}.${CMS_DOMAIN()}`
    : process.env.APP_SITE_NAME;

  return `${document.location.protocol}//${hostname}${path}`;
};
