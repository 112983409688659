import { MutationResultPair, QueryResult } from 'react-query';
import { Game, HttpError, Moment, Video } from 'weplayed-typescript-api';

interface GameActionScore {
  team: string;
  type: string;
  points: number;
}

interface GameActionBestMoment {
  moment_id: string;
  video_id: string;
  name?: string;
  // explicit reference to moment in game.moments array
  // to make filtering easier by comparing reference
  moment?: Moment;
}

export interface GameAction {
  clock: string;
  play_type: string;
  summary: string;
  sequence: number;
  side: string;
  score?: GameActionScore;
  best_moment?: GameActionBestMoment;
  is_nav?: boolean;
  nav_type?: string;
}

export interface GameSegment {
  segment: number;
  actions: GameAction[];
}

export interface GamePlayByPlay {
  game_id: string;
  cms_namespace?: string;
  segments: GameSegment[];
  segment_names?: string[];
  segment_names_full?: string[];
}

export enum GameQAStatus {
  EXCLUDED,
  READY,
  STARTED,
  DONE,
}

export interface GameFull extends Game {
  cms_namespace?: string;
  cms_namespaces?: string[];
  moments: Moment[];
  review_qa_status: GameQAStatus;
  video: Video;
  videos: Video[];
}

export interface UseGameReturnType {
  create: MutationResultPair<Moment, HttpError, Omit<Moment, 'pk'>, Game>;
  createPivotalPlaylist: MutationResultPair<void, HttpError, void, void>;
  endStream: MutationResultPair<void, HttpError, void, void>;
  game: QueryResult<GameFull, HttpError>;
  pbp: QueryResult<GamePlayByPlay, HttpError>;
  qa: MutationResultPair<void, HttpError, GameQAStatus, void>;
  update: MutationResultPair<Moment, HttpError, Moment, Game>;
}

interface UseGameArgs {
  cms?: string;
  momentId?: string;
  uid: string;
  videoId?: string;
}

export type UseGameType = (args: UseGameArgs) => UseGameReturnType;
