import { hasTouch } from 'common/utils/features';

const handleSize = hasTouch ? 10 : 5;

export const Handle = (paper: RaphaelPaper, x: number, y: number): RaphaelElement => paper
  .circle(x, y, handleSize)
  .attr({
    stroke: 'black',
    fill: 'white',
    'stroke-width': 1,
  });
