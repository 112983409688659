import * as React from 'react';
import Slider from 'react-slick';
import { Player, Team } from 'weplayed-typescript-api';

import { PlayerTile } from 'consumer/components/PlayerTile';
import { TeamImage } from 'consumer/components/TeamImage';

interface Props {
  players: Player[];
  team: Team;
}

export const PlayersSection: React.FC<Props> = function PlayersSection({ players, team }) {
  if (!players || players.length === 0) {
    return null;
  }

  return (
    <>
      <div className="home-section-spacer" />
      <h2 className="home-section-header-title">
        ATHLETES
        <span className="home-page-section-header-spacer">—</span>
        {team.acronym}
        <TeamImage team={team} size="x-small" className="home-page-section-header-logo" />
      </h2>
      <hr />
      <Slider
        variableWidth
        infinite={false}
        draggable
        swipeToSlide
        speed={500}
        className="slider variable-width"
      >
        {players.map((player) => (
          <PlayerTile
            key={player.pk}
            item={player}
          />
        ))}
      </Slider>
    </>
  );
};
