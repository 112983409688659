import * as React from 'react';

import { EmailNagModalContext } from './constants';
import { UseNagModalType } from './types';

export const useEmailNagModal: UseNagModalType = function useEmailNagModal() {
  const { toggle } = React.useContext(EmailNagModalContext);

  const show = React.useCallback(
    (message?: string, action?: () => void): void => toggle({ message, action }),
    [toggle],
  );

  const close = React.useCallback(
    () => toggle(),
    [toggle],
  );

  return { show, close };
};
