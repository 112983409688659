import { ID } from 'weplayed-typescript-api';

import { DEFAULT_SETTINGS, SESSION_SETTINGS } from 'common/constants';
import { Settings } from 'common/types';

import { persistent } from './persistent';

export const getUserSettingsKey = (pk: ID): string => `user_settings/${pk}`;

export const loadSettings = (pk?: ID, sessionStart = false): Settings => {
  let settings = DEFAULT_SETTINGS;

  if (pk && persistent.hasLS) {
    try {
      const stored = JSON.parse(persistent.get(getUserSettingsKey(pk)));
      if (sessionStart) {
        SESSION_SETTINGS.forEach((key) => { delete stored[key]; });
      }

      settings = { ...settings, ...stored };
    } catch (e) {
      //
    }
  }

  return settings;
};

export const saveSettings = (pk: ID, s: Settings): void => {
  if (!pk || !persistent.hasLS) {
    return;
  }

  persistent.set(getUserSettingsKey(pk), JSON.stringify(s));
};
