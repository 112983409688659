import { uniq, without } from 'lodash/fp';
import * as React from 'react';
import { queryCache, QueryResult, useMutation, useQuery } from 'react-query';
import { profile as apiProfile, Team } from 'weplayed-typescript-api';

import {
  ApplicationContext,
} from 'common/components/ApplicationProvider/ApplicationContext';
import { useAuth } from 'common/hooks/useAuth';
import { event } from 'common/utils/analytics';
import { persistent } from 'common/utils/persistent';

import { Registration, UseProfileReturnType, VisitVariables } from './types';
import { pickFollowKey, visitedKey } from './utils';

export function useProfile(): UseProfileReturnType {
  const auth = useAuth();
  const {
    profile, setProfile, settings, setSettings,
    subscription,
  } = React.useContext(ApplicationContext);

  const login: UseProfileReturnType['login'] = useMutation(auth.login);
  const loginSocial: UseProfileReturnType['loginSocial'] = useMutation(auth.loginSocial);
  const logout: UseProfileReturnType['logout'] = useMutation(auth.logout);
  const saveSubscription: UseProfileReturnType['saveSubscription'] = useMutation(
    auth.saveSubscription,
  );
  const saveProfile: UseProfileReturnType['saveProfile'] = useMutation(auth.saveProfile);
  const change_password: UseProfileReturnType['change_password'] = useMutation(
    apiProfile.password.change,
  );
  const reset_password: UseProfileReturnType['reset_password'] = useMutation(
    apiProfile.password.reset,
  );
  const reset_password_confirm: UseProfileReturnType['reset_password_confirm'] = useMutation(
    apiProfile.password.confirm,
  );
  const reset_password_confirm_test: UseProfileReturnType['reset_password_confirm_test'] = useMutation(
    apiProfile.password.test,
  );

  const registration_confirm: UseProfileReturnType['registration_confirm'] = useMutation(
    async (attributes) => apiProfile.confirm(attributes),
  );

  const follow: UseProfileReturnType['follow'] = useMutation(
    async ({ type, follow: following, pk }): Promise<void> => {
      if (profile) {
        let method;

        switch (type) {
          case 'team': {
            method = apiProfile.follow.team;
            break;
          }
          case 'channel': {
            method = apiProfile.follow.channel;
            break;
          }
          case 'player': {
            method = apiProfile.follow.player;
            break;
          }

          default: return;
        }

        const key = pickFollowKey(type);
        const entries = subscription[key];
        const user = {
          ...profile,
          [key]: following ? uniq([...entries, pk]) : without([pk], entries),
        };

        // optimistic update
        setProfile(user);

        await method({ uid: pk, follow: following });
        await auth.loadProfile();
      } else if (subscription.email) {
        const key = pickFollowKey(type);
        const entries = subscription[key];
        const subs = {
          ...subscription,
          [key]: following ? uniq([...entries, pk]) : without([pk], entries),
        };

        await auth.saveSubscription(subs);
      }
    },
  );

  const register: UseProfileReturnType['register'] = useMutation(
    async (variables: Registration): Promise<void> => {
      await apiProfile.register({
        ...variables,
        ...persistent.startsWith('utm_'),
      });

      event({
        category: 'Account',
        action: 'Registered',
      });
    },
  );

  const getVisited: UseProfileReturnType['getVisited'] = (
    { type, pk }: VisitVariables,
  ): QueryResult<boolean, void> => (
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<boolean, void>(
      ['visited', type, pk],
      () => (pk ? persistent.get(visitedKey(type, pk)) === 'y' : false),
    )
  );

  const setVisited: UseProfileReturnType['setVisited'] = useMutation(
    async ({ type, pk }): Promise<boolean> => {
      persistent.set(visitedKey(type, pk), 'y');
      return true;
    },
    {
      onSuccess: async (_, { type, pk }) => {
        queryCache.setQueryData(['visited', type, pk], true);
      },
    },
  );

  const setRecentTeam: UseProfileReturnType['setRecentTeam'] = useMutation(
    async (team?: Team): Promise<void> => {
      if (subscription.recent_team?.pk !== team?.pk) {
        await auth.saveSubscription({
          ...subscription,
          recent_team: team,
        });
      }
    },
  );

  const linkSocial: UseProfileReturnType['linkSocial'] = useMutation(
    async () => {
      const response = await apiProfile.directshare.link({ uid: profile.cms_org_id });
      return response.url;
    },
  );

  return {
    change_password,
    follow,
    getVisited,
    linkSocial,
    login,
    loginSocial,
    logout,
    profile,
    register,
    registration_confirm,
    reset_password_confirm_test,
    reset_password_confirm,
    reset_password,
    settings,
    saveProfile,
    saveSettings: setSettings,
    saveSubscription,
    setRecentTeam,
    setVisited,
    subscription,
  };
}
