import * as React from 'react';

import { ApplicationContextType } from 'common/hooks/useCreateAppContext/types';

export const ApplicationContext: React.Context<ApplicationContextType> = (
  React.createContext({
    appCues: false,
    popups: true,
    strict: false,
    playing: false,
  } as ApplicationContextType)
);
