import { useQuery } from 'react-query';
import { videos } from 'weplayed-typescript-api';

import { UseClipperReturnType } from './types';

export function useClipper(videoId: string): UseClipperReturnType {
  const data: UseClipperReturnType['data'] = useQuery(
    ['clipper', videoId],
    () => videos.game({ uid: videoId }),
  );

  return { data };
}
