import { useInfiniteQuery, useQuery } from 'react-query';
import {
  Channel, channels, HttpError, PaginatedResponse,
} from 'weplayed-typescript-api';

import { ChannelSources, UseChannelReturnType, UseChannelType } from './types';
import { getKey } from './utils';

const getFetchMore = <T = unknown>(
  result: PaginatedResponse<T>,
): number | boolean => result.next || false;

const getSectionKey = (
  userId: string,
  search: string | undefined,
  type: ChannelSources,
): string[] => [
  ...getKey(userId), type, search,
];

export const useChannel: UseChannelType = function useChannel({
  uid: userId, search = '', enabled,
}) {
  const summary = useQuery<Channel, HttpError>(
    getKey(userId),
    () => channels.summary({ userId }),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
    },
  );

  const active = Object.values(ChannelSources)
    .filter(
      (k) => userId
        && (!enabled || (Array.isArray(enabled) && enabled.includes(k)) || k === enabled),
    );

  const params = { userId, search };

  const momentsCurated: UseChannelReturnType['moments']['curated'] = useInfiniteQuery(
    getSectionKey(userId, search, ChannelSources.MOMENTS_CURATED),
    (_1, _2, _3, _4, _5, next: number) => channels.moments.curated(params, undefined, next),
    {
      getFetchMore,
      enabled: userId && active.includes(ChannelSources.MOMENTS_CURATED),
    },
  );

  const momentsLiked: UseChannelReturnType['moments']['liked'] = useInfiniteQuery(
    getSectionKey(userId, search, ChannelSources.MOMENTS_LIKED),
    (_1, _2, _3, _4, _5, next: number) => channels.moments.liked(params, undefined, next),
    {
      getFetchMore,
      enabled: userId && active.includes(ChannelSources.MOMENTS_LIKED),
    },
  );

  const playlistsCreated: UseChannelReturnType['playlists']['created'] = useInfiniteQuery(
    getSectionKey(userId, search, ChannelSources.PLAYLISTS_CREATED),
    (_1, _2, _3, _4, _5, next: number) => channels.collections.created(params, undefined, next),
    {
      getFetchMore,
      enabled: userId && active.includes(ChannelSources.PLAYLISTS_CREATED),
    },
  );

  const playlistsLiked: UseChannelReturnType['playlists']['liked'] = useInfiniteQuery(
    getSectionKey(userId, search, ChannelSources.PLAYLISTS_LIKED),
    (_1, _2, _3, _4, _5, next: number) => channels.collections.liked(params, undefined, next),
    {
      getFetchMore,
      enabled: userId && active.includes(ChannelSources.PLAYLISTS_LIKED),
    },
  );

  return {
    summary,
    moments: { curated: momentsCurated, liked: momentsLiked },
    playlists: { created: playlistsCreated, liked: playlistsLiked },
  };
};
