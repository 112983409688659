import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { authentication, configure } from 'weplayed-typescript-api';

import { ACCESS_TOKEN, REFRESH_TOKEN, SOCIAL_TOKEN } from 'common/constants';
import { useAuth } from 'common/hooks/useAuth';
import { persistent } from 'common/utils/persistent';
import { getCmsRedirect } from 'common/utils/redirects';
import {
  getUserSettingsKey, loadSettings, saveSettings,
} from 'common/utils/settings';

import { ApplicationContext } from './ApplicationContext';
import { Props } from './types';
import { loader } from './utils';

const tokenGetter = (): authentication.Token => {
  const token = persistent.get(SOCIAL_TOKEN);

  return token ? {
    token,
    type: 'social',
  } : {
    refresh: persistent.get(REFRESH_TOKEN),
    access: persistent.get(ACCESS_TOKEN),
    type: 'jwt',
  };
};

export const ApplicationInitializer: React.FC<Props> = function ApplicationInitializer({
  authenticate, children, loginUrl,
}) {
  const {
    profile,
    setError,
    setSettings,
    settings,
    subscription,
  } = React.useContext(ApplicationContext);
  const [initialized, setInitialized] = React.useState(false);
  const loginMatch = useRouteMatch<{ org_slug?: string }>(loginUrl?.route);

  const { logout, refresh, loadProfile, loadSubscription } = useAuth();

  // first we load subscription, once it appeared in context do
  // the rest like profile etc, since subscription is a base
  // for profile
  React.useEffect(() => {
    configure({
      prefix: `${process.env.API_SERVER}/api`,
      prefixCached: `${process.env.API_SERVER_CACHED}/api`,
      tokenGetter: authenticate ? tokenGetter : null,
    });

    loadSubscription();
  }, [authenticate, loadSubscription]);

  const hasSubscription = Boolean(subscription);
  const hasProfile = Boolean(profile);

  // then load the rest
  React.useEffect(() => {
    if (hasSubscription) {
      (async (): Promise<void> => {
        if (authenticate) {
          try {
            if (await refresh()) {
              await loadProfile();
            } else if (loginUrl) {
              // a bit of magic - this allows us to specify
              // login URL directly in browser and override
              // stored value
              const org_slug = loginMatch?.params?.org_slug;
              const redirectTo = getCmsRedirect(
                org_slug ? { cms_org_namespace: org_slug } : undefined,
                loginUrl.buildPath(),
              );

              if (redirectTo) {
                window.location.replace(redirectTo);
              }
            }
          } catch (e) {
            setError(e);
          }
        }

        // hide basic loader, it is pretty safe on this
        // stage to do since no other requests
        // have been made at this moment yet
        loader(false);
        setInitialized(true);
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubscription]);

  React.useEffect(() => {
    if (hasProfile && loginUrl) {
      const redirectTo = getCmsRedirect(
        profile,
        `${loginUrl.buildPath()}?redirected`,
      );

      if (redirectTo) {
        (async (): Promise<void> => {
          await logout();
          window.location.replace(redirectTo);
        })();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasProfile]);

  React.useEffect(() => {
    if (initialized && profile?.pk) {
      setSettings(loadSettings(profile?.pk));

      // JFF, watching to settings changes from another tabs/windows
      if (profile?.pk) {
        const listener = ({ key, newValue }: StorageEvent): void => {
          if (key === getUserSettingsKey(profile.pk)) {
            setSettings(JSON.parse(newValue));
          }
        };

        window.addEventListener('storage', listener);
        return (): void => window.removeEventListener('storage', listener);
      }
    }
  // we do not take prevProfilePk into account since change should
  // not trigger settings reload
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, profile?.pk, setSettings]);

  const timer = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    if (initialized && profile?.pk) {
      // lets gather all the changes and store in the next loop
      timer.current = setTimeout(() => saveSettings(profile.pk, settings), 0);
      return (): void => clearTimeout(timer.current);
    }
  }, [initialized, profile?.pk, settings]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return initialized ? <>{children}</> : null;
};
