import { ReactComponent as MenuDown } from '@mdi/svg/svg/menu-down.svg';
import { ReactComponent as MenuRight } from '@mdi/svg/svg/menu-right.svg';
import * as cx from 'classnames';
import { intersection } from 'lodash/fp';
import * as MarkdownIt from 'markdown-it';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';
import { PaginatedResponse, What } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { EventContext } from 'common/utils/events';
import { getPlayerShareMeta } from 'common/utils/share';

import { Button } from 'consumer/components/Button';
import { ChannelTile } from 'consumer/components/ChannelTile';
import { collectionRenderer } from 'consumer/components/CollectionTile';
import { FollowButton } from 'consumer/components/FollowButton';
import { gameRenderer } from 'consumer/components/GameTile';
import { momentRenderer, MomentTile } from 'consumer/components/MomentTile';
import { PlayerPosition } from 'consumer/components/PlayerPosition';
import { Share } from 'consumer/components/Share';
import { ShareMeta } from 'consumer/components/Share/types';
import { TabbedContent } from 'consumer/components/TabbedContent';
import { TeamLogo } from 'consumer/components/TeamLogo';
import { TileList } from 'consumer/components/TileList';
import { useChannel } from 'consumer/hooks/useChannel';
import { Section } from 'consumer/hooks/usePlayer/types';
import { usePlayer } from 'consumer/hooks/usePlayer/usePlayer';

import * as s from './Player.m.less';
import { Tab, UrlProps } from './types';

const TAB_NAMES: Record<Tab, string> = {
  [Section.MOMENTS]: 'MOMENTS',
  [Section.GAMES]: 'GAMES',
  [Section.PLAYLISTS]: 'PLAYLISTS',
  channel: 'CHANNEL',
};

const gameRendererOptions = { showBottom: false };

function reduceGroups<T>(m: T[], g: PaginatedResponse<T>): T[] {
  return [...m, ...g.items];
}

export const Player: React.FC = function Player() {
  const { uid } = useParams<UrlProps>();
  const { profile } = useAuth();

  const bioRef = React.useRef<HTMLDivElement>();

  const [tabs, setTabs] = React.useState<Tab[]>();
  const [tab, setTab] = React.useState<Tab>(Section.MOMENTS);
  const [showBio, setShowBio] = React.useState(false);

  const { player, moments, games, playlists } = usePlayer({ uid, enabled: tab as Section });
  const { summary: channel } = useChannel({ uid: player.data?.user_id, enabled: [] });

  useHandleQuery(player, { isLoading: moments.isLoading });

  React.useEffect(() => {
    if (player.isSuccess && moments.isSuccess) {
      const ntabs = [
        moments.data.length && Section.MOMENTS,
        player.data.game_count && Section.GAMES,
        player.data.collection_count && Section.PLAYLISTS,
        player.data.user_id && 'channel',
      ].filter(Boolean) as Tab[];
      setTabs(ntabs);
      setTab(ntabs[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player.isSuccess, moments.isSuccess]);

  const showExtended = intersection(
    profile?.org?.teams.map((t) => t.team_pk),
    player.data?.players.map((p) => p.team?.pk).filter(Boolean),
  ).length !== 0;

  const bio = React.useMemo(
    () => (player.data?.bio ? new MarkdownIt().render(player.data.bio) : null),
    [player.data?.bio],
  );

  const share: ShareMeta = React.useMemo(() => player.data?.players?.[0] && ({
    pk: player.data?.pk,
    type: What.ATHLETES,
    meta: getPlayerShareMeta(player.data.players[0]),
  }), [player.data?.pk, player.data?.players]);

  const data = {
    moments: React.useMemo(() => moments.data?.reduce(reduceGroups, []) || [], [moments.data]),
    games: React.useMemo(() => games.data?.reduce(reduceGroups, []) || [], [games.data]),
    playlists: React.useMemo(
      () => playlists.data?.reduce(reduceGroups, []) || [],
      [playlists.data],
    ),
  };

  if (!Array.isArray(tabs)) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`WePlayed: ${player.data.name}`}</title>
      </Helmet>
      <div className={s.summary}>
        <div className={s.photo}>
          {player.data.photo_url ? (
            <img
              alt={player.data.name}
              src={player.data.photo_url}
            />
          ) : (
            <>
              <TeamLogo
                team={player.data.players[0].team}
                size="x-large"
              />
              {player.data.sport && (
                <div className={s.sport}>
                  {player.data.sport.name}
                </div>
              )}
            </>
          )}
        </div>
        <div className={s.info}>
          <h1>{player.data.name}</h1>
          {player.data.players.map((p) => (
            <PlayerPosition
              key={p.pk}
              link
              logo={!!player.data.photo_url || player.data.players.length > 1}
              player={p}
            />
          ))}
          <div className={s.actions}>
            <div>
              {player.data.bio && (
                <Button
                  variant="default"
                  className={s.bio}
                  onClick={(): void => setShowBio(!showBio)}
                >
                  Bio
                  {showBio ? <MenuDown /> : <MenuRight />}
                </Button>
              )}
            </div>
            {showExtended && (
              <>
                <Share
                  context={EventContext.PLAYER}
                  meta={share}
                  tooltip="Share"
                />
                <div className={s.follow}>
                  <FollowButton
                    pk={player.data.players[0].pk}
                    type="player"
                    id="button-follow-player"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={s.bioContent}
          style={{ maxHeight: showBio ? bioRef.current.offsetHeight : 0 }}
        >
          <div
            ref={bioRef}
            dangerouslySetInnerHTML={{ __html: bio }}
          />
        </div>
      </div>
      <TabbedContent
        className={s.content}
        tab={tabs.indexOf(tab)}
        tabClassName={s.tab}
        tabs={tabs.map((t) => ({ label: TAB_NAMES[t] }))}
        tabsClassName={s.tabs}
        onChange={(t): void => setTab(tabs[t])}
      >
        {tabs.map((t) => {
          if (t === Section.MOMENTS) {
            return (
              <InfiniteScroll
                hasMore={!moments.isFetchingMore && moments.canFetchMore}
                initialLoad={t === tab}
                key="moments"
                loadMore={(): void => { moments.fetchMore(); }}
                pageStart={0}
              >
                {channel.data?.top_moment && (
                  <div className={cx(s.topMomentHolder)}>
                    <h3>
                      Top Moment:
                    </h3>
                    <MomentTile item={channel.data.top_moment} />
                    {!moments.isLoading && data.moments.length !== 0 && (
                      <h3>
                        Other Moments:
                      </h3>
                    )}
                  </div>
                )}

                {!moments.isLoading && data.moments.length === 0 && (
                  <h1>No moments found</h1>
                )}

                <TileList<What.MOMENTS>
                  appending={Boolean(moments.isFetchingMore)}
                  data={data.moments}
                  error={moments.error}
                  loading={moments.isLoading}
                  pk="pk"
                  renderer={momentRenderer}
                  showContentOnLoad
                />
              </InfiniteScroll>
            );
          }

          if (t === Section.GAMES) {
            return (
              <InfiniteScroll
                hasMore={!games.isFetchingMore && games.canFetchMore}
                initialLoad={tab === Section.GAMES}
                key="games"
                loadMore={(): void => { games.fetchMore(); }}
                pageStart={0}
              >
                {!games.isLoading && data.games.length === 0 && (
                  <h1>No games found</h1>
                )}

                <TileList<What.GAMES>
                  appending={Boolean(games.isFetchingMore)}
                  data={data.games}
                  error={games.error}
                  loading={games.isLoading}
                  pk="pk"
                  renderer={gameRenderer}
                  rendererOptions={gameRendererOptions}
                  showContentOnLoad
                />
              </InfiniteScroll>
            );
          }

          if (t === Section.PLAYLISTS) {
            return (
              <InfiniteScroll
                hasMore={!playlists.isFetchingMore && playlists.canFetchMore}
                initialLoad={tab === Section.PLAYLISTS}
                key="collections"
                loadMore={(): void => { playlists.fetchMore(); }}
                pageStart={0}
              >
                {!playlists.isLoading && data.playlists.length === 0 && (
                  <h1>No playlists found</h1>
                )}
                <TileList<What.COLLECTIONS>
                  appending={Boolean(playlists.isFetchingMore)}
                  data={data.playlists}
                  error={playlists.error}
                  loading={playlists.isLoading}
                  pk="pk"
                  renderer={collectionRenderer}
                  showContentOnLoad
                />
              </InfiniteScroll>
            );
          }

          if (t === 'channel') {
            return (
              <div key="channel" className={s.channel}>
                {channel.data && (
                  <ChannelTile
                    item={channel.data}
                    withLink
                  />
                )}
              </div>
            );
          }

          return null;
        })}
      </TabbedContent>
    </>
  );
};
