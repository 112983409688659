import * as cx from 'classnames';
import * as React from 'react';

import { formatDate } from 'common/utils/dates';

import { TeamLogo } from 'consumer/components/TeamLogo';

import * as s from './GameInfo.m.less';
import { Props } from './types';

export const GameInfo: React.FC<Props> = function GameInfo({
  className, game, disableLinks, newWindow, withDate,
}) {
  return (
    <div className={cx(s.root, className)}>
      <TeamLogo team={game.team1} linkNewWindow={newWindow} disableLink={disableLinks} />
      <div className={cx(s.info, withDate && !game.live_now && s.withDate)}>
        <div className={s.main}>
          {!game.live_now ? <div className={s.score}>{game.team1_score}</div> : null}
          <div className={s.at}>at</div>
          {!game.live_now ? <div className={s.score}>{game.team2_score}</div> : null}
        </div>
        <div className={s.date}>{formatDate(game.date_played)}</div>
      </div>
      <TeamLogo team={game.team2} linkNewWindow={newWindow} disableLink={disableLinks} />
    </div>
  );
};
