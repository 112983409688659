import * as cx from 'classnames';
import { ReactComponent as Spinner } from 'images/spinner.svg';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import * as s from './TooltipButton.m.less';
import { Props } from './types';

const TooltipButtonRenderer: React.ForwardRefRenderFunction<
HTMLButtonElement, Props & React.HTMLProps<HTMLButtonElement>
> = function TooltipButtonRenderer({
  children, disabled, id, tooltip, className, active, processing,
  onClick,
  ...props
}, ref) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="0">{tooltip}</Tooltip>}
      delay={{ show: 750, hide: 0 }}
    >
      <button
        onClick={onClick}
        className={cx(
          s.root,
          active !== undefined && s.withActive,
          active && s.active,
          className,
        )}
        disabled={disabled || processing}
        id={id}
        ref={ref}
        {...props}
        type="button"
      >
        {processing ? <Spinner /> : children}
      </button>
    </OverlayTrigger>
  );
};

export const TooltipButton:
React.FunctionComponent<
Props & React.RefAttributes<HTMLButtonElement> & React.HTMLProps<HTMLButtonElement>
> = React.forwardRef(TooltipButtonRenderer);
