import * as cx from 'classnames';
import * as React from 'react';

import * as s from './TapButton.m.less';
import { Props } from './types';

const TapButton: React.FunctionComponent<Props> = function TapButton({
  className, children, flash, timeout = 200,
  onSingleTap, onDoubleTap,
  ...rest
}) {
  const touchTimer = React.useRef<number>(null);
  const [visible, setVisible] = React.useState(!flash);

  React.useEffect(() => (): void => {
    window.clearTimeout(touchTimer.current);
  }, []);

  const makeVisible = React.useCallback(() => {
    setVisible(true);
  }, []);

  // toggle off right after on, the rest is on css animations
  React.useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [visible]);

  const processTimer = React.useCallback((cleanup?: boolean): void => {
    if (!cleanup && touchTimer.current && onSingleTap && onSingleTap() !== false) {
      if (flash === 'single' || flash === 'both') {
        makeVisible();
      }
    }
    window.clearTimeout(touchTimer.current);
    touchTimer.current = null;
  }, [flash, makeVisible, onSingleTap]);

  const handleTouchEnd = React.useCallback(
    (e: React.TouchEvent<HTMLButtonElement>): void => {
      e.preventDefault();

      if (touchTimer.current && onDoubleTap() !== false) {
        if (flash === 'double' || flash === 'both') {
          makeVisible();
        }
        processTimer(true);
      } else {
        touchTimer.current = window.setTimeout(processTimer, timeout);
      }
    },
    [flash, makeVisible, onDoubleTap, processTimer, timeout],
  );

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...rest}
      onTouchEnd={handleTouchEnd}
      className={cx(className, flash && s.root, flash && visible && s.visible)}
    >
      {children}
    </button>
  );
};

const TapButtonMemo: React.FunctionComponent<Props> = React.memo(TapButton);

export { TapButtonMemo as TapButton };
