import { AnnotationImageProps } from 'weplayed-typescript-api';

import { ImageComponent } from '../../AnnotationsRepository/types';
import { Block as Base } from '../Block';

export class Image extends Base<AnnotationImageProps> {
  /**
   * Reference to repository component
   */
  protected $component: ImageComponent;

  public draw(): this {
    const width = this.$props.size * this.$paper.width;
    const height = (width * this.$component.height) / this.$component.width;
    const image = this.$paper.image(this.$component.src, -width / 2, -height / 2, width, height);
    this.$element = this.$paper.set([image]);

    return super.draw();
  }
}
