import { parse } from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';

// import { FacebookLogin } from 'consumer/components/FacebookLogin';
import { LoginForm } from 'consumer/components/LoginForm';

export const Login: React.FC = function Login() {
  const { redirected } = parse(document.location.search);

  return (
    <div className="container">
      <Helmet>
        <title>
          WePlayed: Sign In
        </title>
      </Helmet>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {redirected !== undefined && (
            <p className="alert alert-warning">
              The product you are trying to sign into has moved to this URL.
              Please Bookmark this page and SIGN IN again. Thank you.
            </p>
          )}
            <h1>SIGN IN</h1>
            {/* <FacebookLogin />
            <p className={s.orMessage}>
              Or, if you registered using your email address, please use the form below:
            </p> */}
            <LoginForm />
        </div>
      </div>
    </div>
  );
};
