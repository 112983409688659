import * as Markdown from 'markdown-it';
import { parse } from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { hasTouch } from 'common/utils/features';

import { ChannelTile } from 'consumer/components/ChannelTile';
import { collectionRenderer } from 'consumer/components/CollectionTile';
import { momentRenderer } from 'consumer/components/MomentTile';
import { SearchBox } from 'consumer/components/SearchBox';
import { TabbedContent } from 'consumer/components/TabbedContent';
import { TileList } from 'consumer/components/TileList';
import { useChannel } from 'consumer/hooks/useChannel';
import { ChannelSources } from 'consumer/hooks/useChannel/types';

import * as s from './Channel.m.less';
import { UrlParams } from './types';

const { MOMENTS_CURATED, MOMENTS_LIKED, PLAYLISTS_CREATED, PLAYLISTS_LIKED } = ChannelSources;

const tabs = [
  {
    id: MOMENTS_CURATED,
    label: 'Moments Curated',
  },
  {
    id: MOMENTS_LIKED,
    label: 'Moments Liked',
  },
  {
    id: PLAYLISTS_CREATED,
    label: 'Playlists Created',
  },
  {
    id: PLAYLISTS_LIKED,
    label: 'Playlists Liked',
  },
];

function reduceGroups<T>(m: T[], g: { items: T[] }): T[] {
  return [...m, ...g.items];
}

export const Channel: React.FC = function Channel() {
  const location = useLocation();
  const history = useHistory();

  const { profile } = useAuth();
  const { uid } = useParams<UrlParams>();
  const { search = '' } = parse(location.search);

  const [tab, setTab] = React.useState<number>(0);
  const [query, setQuery] = React.useState<string>('');
  const searchBox = React.useRef<HTMLInputElement>();

  const userId = uid || profile?.pk;

  const {
    summary,
    moments: { curated: mc, liked: ml },
    playlists: { created: pc, liked: pl },
  } = useChannel({
    uid: userId,
    search: search as string,
    enabled: tabs[tab].id,
  });

  useHandleQuery(summary);

  React.useEffect(() => {
    setQuery(search as string || '');
  }, [search]);

  const handleSubmit = (): void => {
    searchBox.current.blur();
    const url = location.pathname + query ? `?search=${query}` : '';
    history.push(url);
  };

  const description = React.useMemo(
    () => (summary.data?.description ? new Markdown().render(summary.data.description) : null),
    [summary.data?.description],
  );

  const data = {
    [MOMENTS_CURATED]: React.useMemo(() => mc.data?.reduce(reduceGroups, []) || [], [mc.data]),
    [MOMENTS_LIKED]: React.useMemo(() => ml.data?.reduce(reduceGroups, []) || [], [ml.data]),
    [PLAYLISTS_CREATED]: React.useMemo(() => pc.data?.reduce(reduceGroups, []) || [], [pc.data]),
    [PLAYLISTS_LIKED]: React.useMemo(() => pl.data?.reduce(reduceGroups, []) || [], [pl.data]),
  };

  return (
    <div className="container">
      <Helmet>
        <title>
          {summary.data?.user
            ? `WePlayed: ${summary.data.user.full_name}'s Channel`
            : 'WePlayed'}
        </title>
      </Helmet>
      {summary.error && `ERROR: ${summary.error.message}`}
      {summary.data && (
        <div className={s.headerPositioner}>
          <ChannelTile
            className={s.headerContainer}
            edit={profile && profile.pk === summary.data.user.pk}
            item={summary.data}
            showAthleteLink
          />
          {description && (
            <div
              className={s.headerInfo}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      )}

      <TabbedContent
        className={s.tabsContainer}
        divider={(
          <div className={s.searchContainer}>
            <SearchBox
              autofocus={!hasTouch}
              onChange={setQuery}
              onSubmit={handleSubmit}
              placeholder="search filter"
              ref={searchBox}
              value={query}
            />
          </div>
        )}
        onChange={setTab}
        tab={tab}
        tabClassName={s.tab}
        tabs={tabs}
        tabsClassName={s.tabs}
      >
        <InfiniteScroll
          hasMore={!mc.isFetchingMore && mc.canFetchMore}
          initialLoad={tabs[tab].id === MOMENTS_CURATED}
          key={MOMENTS_CURATED}
          // do NOT set directly to function because `loadMore` passes page number
          // which overrides internal value
          loadMore={(): void => { mc.fetchMore(); }}
          pageStart={0}
        >
          {!mc.isLoading && data[MOMENTS_CURATED].length === 0 && (
            <h1>No moments found</h1>
          )}
          <TileList<What.MOMENTS>
            appending={!!mc.isFetchingMore}
            data={data[MOMENTS_CURATED]}
            error={mc.error}
            loading={mc.isLoading}
            pk="pk"
            renderer={momentRenderer}
            showContentOnLoad
          />
        </InfiniteScroll>
        <InfiniteScroll
          hasMore={!ml.isFetchingMore && ml.canFetchMore}
          initialLoad={tabs[tab].id === MOMENTS_LIKED}
          key={MOMENTS_LIKED}
          loadMore={(): void => { ml.fetchMore(); }}
          pageStart={0}
        >
          {!ml.isLoading && data[MOMENTS_LIKED].length === 0 && (
            <h1>No moments found</h1>
          )}
          <TileList<What.MOMENTS>
            appending={!!ml.isFetchingMore}
            data={data[MOMENTS_LIKED]}
            error={ml.error}
            loading={ml.isLoading}
            pk="pk"
            renderer={momentRenderer}
            showContentOnLoad
          />
        </InfiniteScroll>
        <InfiniteScroll
          hasMore={!pc.isFetchingMore && pc.canFetchMore}
          initialLoad={tabs[tab].id === PLAYLISTS_CREATED}
          key={PLAYLISTS_CREATED}
          loadMore={(): void => { pc.fetchMore(); }}
          pageStart={0}
        >
          {!pc.isLoading && data[PLAYLISTS_CREATED].length === 0 && (
            <h1>No playlists found</h1>
          )}
          <TileList<What.COLLECTIONS>
            appending={!!pc.isFetchingMore}
            data={data[PLAYLISTS_CREATED]}
            error={pc.error}
            loading={pc.isLoading}
            pk="pk"
            renderer={collectionRenderer}
            showContentOnLoad
          />
        </InfiniteScroll>
        <InfiniteScroll
          hasMore={!pl.isFetchingMore && pl.canFetchMore}
          initialLoad={tabs[tab].id === PLAYLISTS_LIKED}
          key={PLAYLISTS_LIKED}
          loadMore={(): void => { pl.fetchMore(); }}
          pageStart={0}
        >
          {!pl.isLoading && data[PLAYLISTS_LIKED].length === 0 && (
            <h1>No playlists found</h1>
          )}
          <TileList<What.COLLECTIONS>
            appending={!!pl.isFetchingMore}
            data={data[PLAYLISTS_LIKED]}
            error={pl.error}
            loading={pl.isLoading}
            pk="pk"
            renderer={collectionRenderer}
            showContentOnLoad
          />
        </InfiniteScroll>
      </TabbedContent>
    </div>
  );
};
