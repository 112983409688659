// extracted by mini-css-extract-plugin
var _1 = "bgkkqHWH2jTWmhVA7WjF";
var _2 = "HhEBskw9ltapynFaa8Cg";
var _3 = "i1zcsnIPHBtoRUkv8OuW";
var _4 = "mvNeSHRvBzeqWHTWLyDi";
var _5 = "QLAg5NVsa_1JxsU_VBJQ";
var _6 = "a7zdAWlT1y4xSLuS5Wrg";
var _7 = "CNBosVjZjALYrEtJoBLn";
var _8 = "g27WaiAG3qKwtwez9jvP";
var _9 = "mxJxftsvvbPDVXTYlAUp";
var _a = "rFw9YaFaqD2JT_yUt6NA";
var _b = "qSJ7gjqiFa3rQWSZQwYN";
var _c = "gOrcK_QCfWC7Y3ck5_pz";
var _d = "OGyKV7kPeWe14a0MU5pC";
var _e = "TbYlnUv9JetHM2033nAB";
var _f = "fbFDxaLihaEWTmGq4MzK";
var _10 = "GQ0vPqauJgG9FPNeUh_A";
export { _1 as "at", _2 as "body", _3 as "cover", _4 as "extra", _5 as "header", _6 as "live", _7 as "liveButton", _8 as "root", _9 as "score", _a as "shim", _b as "team", _c as "teamImage", _d as "teamName", _e as "teamScore", _f as "top", _10 as "withCover" }
