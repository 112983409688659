import { AnnotationObjectsType } from '../types';
import animated from './images/animated';
import statics from './images/statics';

export const AnnotationObjects: AnnotationObjectsType = {
  images: {
    animated,
    // js doesn't allow `static` as keyword
    static: statics,
  },
};
