import * as React from 'react';
import { queryCache, useMutation, useQuery, useQueryCache } from 'react-query';
import {
  Collection, collections, exprts, ShareAdsSpec, What,
} from 'weplayed-typescript-api';

import { event } from 'common/utils/analytics';
import { logger } from 'common/utils/logger';

import { createRequest } from 'consumer/hooks/useCollections';
import {
  getKey as getMyCollectionsKey,
} from 'consumer/hooks/useCollections/utils';
import { updateLike, updateMoment } from 'consumer/hooks/useMoment';
import {
  getLikeQueryData, getPublicationQueryData, getRemoveQueryData,
  getUpdateQueryData, isLikeQuery, isPublicationQuery, isRemoveQuery,
  isUpdateQuery,
} from 'consumer/hooks/useMoments';

import { perPage } from './constants';
import { UseCollectionArgs, UseCollectionReturnType } from './types';
import { doCollectionMomentsUpdate, getKey } from './utils';

export const useCollection = function useCollection<T extends ShareAdsSpec = ShareAdsSpec>({
  uid, infinite = false, where, wlOrgId,
}: UseCollectionArgs): UseCollectionReturnType<T> {
  const cache = useQueryCache();
  const [page, setPage] = React.useState(infinite ? 0 : -1);

  const getCollectionKey = React.useMemo(
    () => (p?: number): unknown[] => getKey(
      uid,
      wlOrgId,
      infinite,
      infinite ? (Math.max(0, p ?? page)) : -1,
    ),
    [uid, wlOrgId, infinite, page],
  );

  // update collection moments cache on like
  React.useEffect(() => cache.subscribe((qc, query) => {
    const collectionKey = getCollectionKey();
    if (isLikeQuery(query)) {
      const { uid: collectionId, like } = getLikeQueryData(query);
      const data = qc.getQueryData<Collection>(collectionKey);
      const update = updateLike(collectionId, like);

      if (data && data.moments.some(
        ({ pk, liked_by_user }) => pk === collectionId && liked_by_user !== like,
      )) {
        qc.setQueryData(collectionKey, { ...data, moments: data.moments.map(update) });
      }
    } else if (isPublicationQuery(query)) {
      const { uid: collectionId, publication } = getPublicationQueryData(query);
      const update = updateMoment(collectionId, publication);
      const data = qc.getQueryData<Collection>(collectionKey);

      if (data && data.moments.some(
        ({ pk }) => pk === collectionId,
      )) {
        qc.setQueryData(collectionKey, { ...data, moments: data.moments.map(update) });
      }
    } else if (isRemoveQuery(query)) {
      const { uid: momentId } = getRemoveQueryData(query);
      const collection = qc.getQueryData<Collection>(collectionKey);

      if (collection.moments.some(({ pk }) => pk === momentId)) {
        qc.setQueryData(collectionKey, {
          ...collection,
          moment_count: collection.moment_count - 1,
          moments: collection.moments.filter(({ pk }) => pk !== momentId),
        });
      }
    } else if (isUpdateQuery(query)) {
      const collection = qc.getQueryData<Collection>(collectionKey);
      const { moment } = getUpdateQueryData(query);

      const idx = collection.moments.findIndex(({ pk }) => moment.pk === pk);

      if (idx !== -1) {
        queryCache.setQueryData(collectionKey, {
          ...collection,
          moments: [
            ...collection.moments.slice(0, idx),
            moment,
            ...collection.moments.slice(idx + 1),
          ],
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [cache, getCollectionKey]);

  const collection: UseCollectionReturnType<T>['collection'] = useQuery(
    getCollectionKey(),
    async ($_, $uid: string, $wlOrgId: string, $infinite: boolean, p: number) => {
      const params = {
        uid: $uid,
        where,
        wl_org_id: $wlOrgId,
      };

      if (p === -1) {
        return collections.view(params);
      }

      const updateCount = queryCache.getQuery(
        [$_, $uid, $wlOrgId, $infinite, p],
      )?.state?.updateCount;

      const size = (p === 0 || updateCount === 0) ? perPage : perPage * (p + 1);
      const number = (p === 0 || updateCount === 0) ? p + 1 : 1;

      const promises: [
        Promise<collections.moments.get.Response>,
        Promise<collections.summary.Response<T>>?,
      ] = [
        collections.moments.get({ uid: $uid }, size, number),
      ];

      if (p === 0) {
        promises.push(collections.summary<T>(params));
      }

      const [moments, summary] = await Promise.all(promises);
      let $collection;

      if (p === 0) {
        $collection = summary;
      } else {
        $collection = { ...cache.getQueryData(getCollectionKey(p - 1)) };
      }

      $collection.moments = (p === 0 || updateCount === 0)
        ? [...$collection.moments, ...moments.items]
        : moments;

      return $collection;
    },
    {
      enabled: Boolean(uid),
      refetchOnWindowFocus: false,
      keepPreviousData: infinite,
    },
  );

  const loadMore = React.useCallback(() => {
    setPage(page + 1);
  }, [page]);

  React.useEffect(() => {
    if (infinite && page === -1) {
      setPage(0);
    } else if (!infinite && page !== -1) {
      setPage(-1);
    }
  }, [infinite, page]);

  const hasLoadMore = infinite && collection.data
    && !collection.isFetching
    && collection.data.moment_count > collection.data.moments.length;

  const update: UseCollectionReturnType['update'] = useMutation(
    async (payload) => { await collections.update({ ...payload, uid }); },
    {
      onMutate: (payload) => {
        const collectionKey = getCollectionKey();
        const prev = cache.getQueryData<Collection>(collectionKey);
        if (prev) {
          if (payload.name) {
            cache.setQueryData(collectionKey, { ...prev, name: payload.name });
          }
          return prev.name;
        }
      },
      onError: (error, _v, name) => {
        if (name) {
          const collectionKey = getCollectionKey();
          const prev = cache.getQueryData<Collection>(collectionKey);
          cache.setQueryData(collectionKey, { ...prev, name });
        }
        logger.error('Collection update failed', { message: error.message });
      },
      onSuccess: () => {
        queryCache.invalidateQueries(getCollectionKey());
      },
    },
  );

  const like: UseCollectionReturnType['like'] = useMutation(
    (liked) => collections.like({ uid, like: liked }),
    {
      onMutate: (liked) => {
        const collectionKey = getCollectionKey();
        const prev = cache.getQueryData<Collection>(collectionKey);
        cache.setQueryData(collectionKey, { ...prev, liked_by_user: liked });

        return prev;
      },
      onSuccess: (_r, liking) => {
        event({
          category: 'Engagement',
          action: liking ? 'Collection Liked' : 'Collection Unliked',
          label: uid,
        });
      },
      onError: (error, _v, snapshot) => {
        cache.setQueryData(getCollectionKey(), snapshot);
        logger.error('Collection like failed', { message: error.message });
      },
    },
  );

  const download: UseCollectionReturnType['download'] = useMutation(
    async () => {
      await exprts.create({
        uid: [uid],
        type: What.COLLECTIONS,
        notification: [exprts.NotificationType.EMAIL],
      });
    },
    {
      onError: (error) => {
        logger.error('Collection download failed', { message: error.message });
      },
    },
  );

  const clone: UseCollectionReturnType['clone'] = useMutation(
    async (name) => {
      const { moments } = await collections.view({ uid });

      const pk = await createRequest({ name });
      if (moments.length !== 0) {
        await doCollectionMomentsUpdate(pk, moments.map((moment) => moment.pk));
      }
      return pk;
    },
    {
      onSuccess: () => cache.invalidateQueries(getMyCollectionsKey()),
      onError: (error) => {
        logger.error('Collection clone failed', { message: error.message });
      },
    },
  );

  const remove: UseCollectionReturnType['remove'] = useMutation(
    () => collections.remove({ uid }),
    {
      onSuccess: () => {
        cache.invalidateQueries(getMyCollectionsKey());
      },
    },
  );

  const moments: UseCollectionReturnType['moments'] = useMutation(
    async (momentIds) => {
      await doCollectionMomentsUpdate(uid, momentIds);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(getCollectionKey());
      },
      onError: (error) => {
        logger.error('Collection moments update failed', { message: error.message });
      },
    },
  );

  return {
    clone,
    collection,
    download,
    like,
    loadMore: hasLoadMore ? loadMore : undefined,
    moments,
    remove,
    update,
  };
};
