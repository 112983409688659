import { parse } from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useCreateAppContext } from 'common/hooks/useCreateAppContext';
import { persistent } from 'common/utils/persistent';

import { ApplicationContext } from './ApplicationContext';
import { ApplicationInitializer } from './ApplicationInitializer';
import { Props } from './types';
import { loader as toggleLoader } from './utils';

export const ApplicationProvider: React.FC<Props> = function ApplicationProvider({
  authenticate = true,
  children,
  loader = toggleLoader,
  loginUrl,
}) {
  const location = useLocation();

  const { strict, popups } = React.useMemo(() => {
    // obfuscate in source
    const a = 'mmel';
    const b = 'awe';
    const c = 'hct';
    const LEMMEWATCH_KEYWORD = [a, b, c].reverse().join('').split('').reverse()
      .join('');
    const qs = parse(location.search);
    let showPopups = true;

    try {
      showPopups = !(
        qs[LEMMEWATCH_KEYWORD] || JSON.parse(persistent.get(LEMMEWATCH_KEYWORD))
      );
    } catch (e) {
      // ignore
    }

    return {
      strict: Boolean(qs.namespace),
      popups: showPopups,
    };
  // Intentionally set these parameters for the whole session
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useCreateAppContext({ strict, popups, loader });

  // reset context error on location change
  const { setError } = context;

  React.useEffect(() => setError(null), [setError, location.pathname]);

  return (
    <ApplicationContext.Provider value={context}>
      <ApplicationInitializer
        authenticate={authenticate}
        loginUrl={loginUrl}
      >
        {children}
      </ApplicationInitializer>
    </ApplicationContext.Provider>
  );
};
