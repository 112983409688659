/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { search } from 'weplayed-typescript-api';

import {
  Section, UseSearchReturnType, UseSearchSectionArgs,
  UseSearchSectionReturnType, UseSearchType,
} from './types';

const PAGE_SIZE = 30;

const defaultOptions = {
  staleTime: 5 * 60 * 1000,
  refetchOnWindowFocus: false,
};

const useSearchSection = function useSearchSection<T>({
  enabled, data, facets, query, type,
}: UseSearchSectionArgs<T>): UseSearchSectionReturnType<T> {
  const dataQ: UseSearchSectionReturnType<T>['data'] = useInfiniteQuery(
    ['search-data', type, query],
    (_, __, ___, next: number) => data(query, PAGE_SIZE, next),
    {
      ...defaultOptions,
      enabled,
      getFetchMore: ({ next }) => next,
    },
  );

  const facetsQ: UseSearchSectionReturnType<T>['facets'] = useQuery(
    ['search-facets', type, query],
    async () => {
      const fields = await facets(query);

      if (Array.isArray(fields.season)) {
        // sort season facets in descending order
        fields.season.sort(([a], [b]) => (a < b ? 1 : -1));
      }

      return fields;
    },
    {
      ...defaultOptions,
      enabled: facets && enabled,
    },
  );

  return { data: dataQ, facets: facetsQ };
};

export const useSearch: UseSearchType = function useSearch({ query, enabled }) {
  const summary: UseSearchReturnType['summary'] = useQuery(
    ['search', query],
    async () => {
      const { collections: playlists, ...$summary } = await search.summary(query);
      return { ...$summary, playlists };
    },
    { enabled: Boolean(query?.text) },
  );

  const active = Object.values(Section)
    .filter((k) => query?.text && !summary.isLoading && (!enabled || enabled.includes(k)));

  const queryp = React.useMemo(() => ({ sort: 'relevance', ...query }), [query]);

  const moments: UseSearchReturnType['moments'] = useSearchSection({
    query,
    type: Section.MOMENTS,
    data: search.moments.data,
    facets: search.moments.facets,
    enabled: active.includes(Section.MOMENTS),
  });

  const playlists: UseSearchReturnType['playlists'] = useSearchSection({
    query,
    type: Section.PLAYLISTS,
    data: search.collections.data,
    facets: search.collections.facets,
    enabled: active.includes(Section.PLAYLISTS),
  });

  const games: UseSearchReturnType['games'] = useSearchSection({
    query,
    type: Section.GAMES,
    data: search.games.data,
    facets: search.games.facets,
    enabled: active.includes(Section.GAMES),
  });

  const players: UseSearchReturnType['players'] = useSearchSection({
    query: queryp,
    type: Section.PLAYERS,
    data: search.players.data,
    facets: search.players.facets,
    enabled: active.includes(Section.PLAYERS),
  });

  const channels: UseSearchReturnType['channels'] = useSearchSection({
    query,
    type: Section.CHANNELS,
    data: search.channels.data,
    enabled: active.includes(Section.CHANNELS),
  });

  return { summary, moments, playlists, games, channels, players };
};
