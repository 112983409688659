import './Avatar.less';

import * as cx from 'classnames';
import * as React from 'react';

import { Props } from './types';

export const Avatar: React.FunctionComponent<Props> = function Avatar({
  className, hideVerified = false, imageId, size = 'small', user, avatarUrl,
}) {
  const url = avatarUrl ?? (['small', 'xx-small', 'x-small'].includes(size)
    ? (user.avatar_64x64_url || user.avatar_url || user.avatar_480_480_url)
    : (user.avatar_480_480_url || user.avatar_url || user.avatar_64x64_url));

  return (
    <div
      className={cx('avatar-container', className, size)}
      data-testid="avatar-container"
      aria-label={user.full_name}
    >
      <div aria-hidden>
        {url ? (
          <img
            alt={user.full_name}
            id={imageId}
            src={url}
            data-testid="avatar-image"
            title={user.full_name}
          />
        ) : (
          <svg viewBox="0 0 36 36" data-testid="avatar-image">
            <text x="18" y="26" textAnchor="middle" fontSize="20">
              {(user.full_name || '').split(/\s+/).slice(0, 2)
                .map((s) => s.substring(0, 1).toUpperCase()).join(' ')}
            </text>
          </svg>
        )}
      </div>
      {user.is_verified && !hideVerified ? <b data-testid="verified-badge" /> : undefined}
    </div>
  );
};
