import { cloneDeep, get } from 'lodash/fp';
import * as Raphael from 'raphael';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {
  AnnotationComponentPropTypes, AnnotationImageProps, AnnotationPathImageProps,
  AnnotationPathProps, AnnotationPauseProps,
} from 'weplayed-typescript-api';

import { roundTimeToHalfSecond } from 'common/utils/timeCalcs';

import { AnnotationPrimitives } from '../AnnotationsRepository';
import { Base } from '../Components/Base';
import { Image } from '../Components/Image';
import { Path } from '../Components/Path';
import { PathImage } from '../Components/PathImage';
import { Pause } from '../Components/Pause';
import * as s from './AnnotationsCanvas.m.less';
import { Props } from './types';

/**
 * Annotation canvas
 */
export class AnnotationsCanvas extends React.PureComponent<Props> {
  /**
   * DIV container
   */
  protected $container: React.RefObject<HTMLDivElement> = React.createRef();

  /**
   * SVG paper (Raphael)
   */
  protected $paper: RaphaelPaper;

  /**
   * List of all components in annotation
   */
  protected $elements: Base<AnnotationComponentPropTypes>[] = [];

  /**
   * Script to execute (list of component properties)
   */
  protected $script: AnnotationComponentPropTypes[] = [];

  /**
   * Pause in effect
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  protected $pause: Pause;

  /**
   * Previous position when onPosition is used
   */
  protected $position: number;

  /**
   * Init values
   */
  public componentDidMount(): void {
    this.$paper = Raphael(
      this.$container.current,
      this.$container.current.clientWidth,
      this.$container.current.clientHeight,
    );
    // IOS safari
    this.$container.current.ontouchmove = (e): void => e.preventDefault();

    // deselect elements
    this.$container.current.addEventListener('mousedown', (e: MouseEvent) => {
      if (!e.defaultPrevented) {
        this.blur();
      }
    });

    this.$container.current.addEventListener('dragover', this.handleDragOver);
    this.$container.current.addEventListener('dragenter', this.handleDragEnter);
    this.$container.current.addEventListener('drop', this.handleDrop);

    this.load(this.props.annotations);
  }

  /**
   * Listen to react properties update and redraw canvas
   */
  public componentDidUpdate(prevProps): void {
    if (this.props.annotations !== prevProps.annotations
        || !this.props.editable !== !prevProps.editable) {
      this.load(this.props.annotations);
      return;
    }

    if (this.props.length !== prevProps.length) {
      for (let idx = this.$elements.length - 1; idx >= 0; idx -= 1) {
        // for the cases when pause already removed too
        if (this.$elements[idx] && this.$elements[idx].props.start > this.props.length) {
          this.remove(this.$elements[idx]);
        }
      }
    }

    // The code below intended to "force play" mode (pause skip) and does not work
    // because of HLSource autoPlay enabled which is needed to show play button if
    // denied
    //
    // if (!this.props.paused
    //     && this.$pause
    //     && !this.props.paused !== !prevProps.paused
    //     && this.props.position) {
    //   this.$pause.clear();
    //   this.$pause = undefined;
    // }
  }

  public componentWillUnmount(): void {
    this.clear();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  public setTime = (position: number): void => {
    const rounded = roundTimeToHalfSecond(position);
    if (rounded !== this.$position) {
      this.$position = rounded;
      this.timeEvent(rounded);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  get elements(): ReadonlyArray<Base<AnnotationComponentPropTypes>> {
    return Object.freeze(this.$elements);
  }

  public add = (props: AnnotationComponentPropTypes): Base<AnnotationComponentPropTypes> | void => {
    const position = roundTimeToHalfSecond(this.$position);
    const element = this.attach({
      ...cloneDeep(props),
      start: position,
    });

    if (element) {
      this.adjustPauses();
      this.timeEvent(position);
      // Notify parent about change
      this.focus(element);
      this.onChange();
    }

    return element;
  };

  public blur = (): void => {
    this.$elements.forEach((element) => element.blur());
    if (this.props.onSelect) {
      this.props.onSelect([]);
    }
  };

  public focus = (
    item: Base<AnnotationComponentPropTypes> | number,
    append?: boolean,
  ): void => {
    const element: Base<AnnotationComponentPropTypes> = typeof item === 'number'
      ? this.$elements[item]
      : item;

    if (this.props.onPosition) {
      this.props.onPosition(element.props.start);
    }

    if (!append) {
      this.blur();
      element.focus();
    } else if (element.selected) {
      element.blur();
    } else {
      element.focus();
    }

    if (this.props.onSelect) {
      this.props.onSelect(this.$elements.filter((el) => el.selected));
    }
  };

  public remove = (element: Base<AnnotationComponentPropTypes>): void => {
    const idx = this.$elements.indexOf(element);

    if (idx !== -1) {
      if (element.selected) {
        // toggle focus, fire onSelect event
        this.focus(element, true);
      }
      element.clear();
      this.$elements.splice(idx, 1);
      this.adjustPauses();
      this.onChange();
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  public time = (
    idx: number,
    position: number,
    duration?: number,
  ): void => {
    this.$elements[idx].time(position, duration);
    this.adjustPauses();
    this.timeEvent(this.$position);
  };

  protected adjustPauses = (): void => {
    for (let x = this.$elements.length - 1; x >= 0; x -= 1) {
      if (this.$elements[x].props.type === 'pause') {
        this.$elements[x].clear();
        this.$elements.splice(x, 1);
      }
    }

    this.$elements
      .reduce(
        (
          times: number[],
          element: Base<AnnotationComponentPropTypes>,
        ): number[] => (times.indexOf(element.props.start) === -1
          ? [...times, element.props.start]
          : times),
        [],
      )
      .forEach((start: number): void => {
        this.attach({
          type: 'pause',
          start,
          duration: 2,
        });
      });
  };

  protected handleDrop = (e: DragEvent): void => {
    e.preventDefault();

    try {
      const path = JSON.parse(e.dataTransfer.getData('annotations/primitive')) as (string | number)[];
      path.splice(1, 0, 'items');

      const primitive = get(path, AnnotationPrimitives);
      const canvasBBox = this.$container.current.getBoundingClientRect();
      const positionX = (e.clientX - canvasBBox.left) / canvasBBox.width;
      const positionY = (e.clientY - canvasBBox.top) / canvasBBox.height;
      const element = this.add(primitive.annotation);
      if (element) {
        element.centerTo(positionX, positionY);
      }
    } catch (_e) {
      // noop
    }
  };

  // eslint-disable-next-line class-methods-use-this
  protected handleDragOver = (e: DragEvent): void => {
    e.preventDefault();
  };

  // eslint-disable-next-line class-methods-use-this
  protected handleDragEnter = (e: DragEvent): void => {
    e.preventDefault();
  };

  /**
   * Load script
   */
  protected load = (script: AnnotationComponentPropTypes[]): void => {
    this.clear();

    if (!Array.isArray(script)) {
      // format is not recognized
      return;
    }

    let pauseStart;
    // cleanup doubled pauses so far
    // needs to be cleaned on backend later
    this.$script = cloneDeep(script.filter((comp: AnnotationComponentPropTypes): boolean => {
      if (comp.type === 'pause') {
        if (pauseStart === undefined) {
          pauseStart = comp.start;
        } else if (pauseStart === comp.start) {
          return false;
        }
      }

      return true;
    }));

    this.$script.forEach((props) => this.attach(props));
    this.onChange();
  };

  /**
   * Notify all elements on time position change
   */
  protected timeEvent = (position: number): void => {
    this.$elements.forEach(
      (element) => element.timeEvent(position, this.props.paused, this.props.editable),
    );
  };

  /**
   * Factory to detect annotation type and attach it to canvas
   */
  protected attach = (
    props: AnnotationComponentPropTypes,
  ): Base<AnnotationComponentPropTypes> | void => {
    let element;

    const discreteProps = {
      ...props,
      start: props.start !== undefined ? roundTimeToHalfSecond(props.start) : undefined,
      duration: props.duration !== undefined ? roundTimeToHalfSecond(props.duration) : undefined,
    };

    switch ((discreteProps as AnnotationComponentPropTypes).type) {
      case 'image': {
        element = new Image(this.$paper, discreteProps as AnnotationImageProps);
        break;
      }

      case 'path': {
        element = new Path(this.$paper, discreteProps as AnnotationPathProps);
        break;
      }

      case 'path-image': {
        element = new PathImage(this.$paper, discreteProps as AnnotationPathImageProps);
        break;
      }

      case 'pause': {
        element = new Pause(this.$paper, discreteProps as AnnotationPauseProps);
        if (!this.props.editable) {
          element.onPlay(this.onPlay).onPause(this.onPause);
        }
        break;
      }

      default: {
        // unknown type, silently skip
        return;
      }
    }

    this.$elements.push(element);
    element.blur();

    if (this.props.editable) {
      element.onSelect((append: boolean): void => {
        this.focus(element, append);
      });

      element.onChange(this.onChange);

      if (element.onMove) {
        element.onMove((dx: number, dy: number): void => {
          this.$elements.forEach((el) => el.selected && el.translate(dx, dy));
        });
      }
    }

    return element;
  };

  protected onPlay = (): void => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.$pause = undefined;
    // hide all zero-duration elements
    this.timeEvent(roundTimeToHalfSecond(this.$position));
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  protected onPause = (pause: Pause, position: number): void => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.$pause = pause;
    if (this.props.onPause) {
      this.props.onPause(position);
    }
  };

  /**
   * Clear canvas from all elements
   */
  protected clear = (): void => {
    this.$elements.forEach((element) => element.clear());
    this.$elements = [];
    this.$script = [];
    this.$paper.clear();
    this.onChange();
  };

  /**
   * Resizes SVG canvas to fit parent container
   */
  protected handleResize = (width: number, height: number): void => {
    if (this.$paper) {
      this.$paper.setSize(width, height);
    }
  };

  /**
   * Notify parent component about changes in annotations
   * in edit mode
   */
  protected onChange = (): void => {
    if (this.props.onChange) {
      const props = this.$elements.map((element) => element.props);
      this.props.onChange(props);
    }
  };

  /**
   * Render react component
   */
  public render(): JSX.Element {
    return (
      <div
        aria-hidden
        aria-label="Annotations"
        className={s.root}
        style={{
          pointerEvents: this.props.editable ? 'auto' : 'none',
          touchAction: this.props.editable ? 'none' : 'auto',
        }}
        ref={this.$container}
      >
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.handleResize}
        />
      </div>
    );
  }
}
