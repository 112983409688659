import { intersection } from 'lodash/fp';
import { Game, GameStatus, SchoolTeam, Team } from 'weplayed-typescript-api';

export const getOwnTeam = (game: Game, teams: SchoolTeam[] = []): Team | undefined => {
  const teamPks = [game.team1.pk, game.team2.pk];
  const orgTeamPks = teams.map(({ team_pk }) => team_pk);
  const teamPk = intersection(teamPks, orgTeamPks).shift();
  if (teamPk) {
    return game.team1.pk === teamPk ? game.team1 : game.team2;
  }
};

/**
 * Transforms game status into human readable string
 * @param status Game status
 * @returns Human readable status text
 */
export const status2text = (status: GameStatus): string => ({
  [GameStatus.CAPTURE_CANCELED]: 'Canceled',
  [GameStatus.CAPTURE_DONE]: 'Completed',
  [GameStatus.CAPTURE_FAILED]: 'Live capture failed',
  [GameStatus.CAPTURE_STARTED]: 'Live capture is in progress',
  [GameStatus.CAPTURE_STARTING]: 'Live capture starting...',
  [GameStatus.CAPTURE_STOPPING]: 'Live capture stopping...',
  [GameStatus.COMPLETED]: 'Completed',
  [GameStatus.LIVE]: 'Live',
  [GameStatus.MOMENT_DONE]: 'Completed',
  [GameStatus.MOMENT_FAILED]: 'Moments detection failed',
  [GameStatus.MOMENT_NOT_SUPPORTED]: 'Moments detection not supported',
  [GameStatus.MOMENT_STARTED]: 'Moments detection is in progress',
  [GameStatus.MOMENT_STARTING]: 'Moments detection starting...',
  [GameStatus.NONE]: 'Unknown',
  [GameStatus.TRANSCODE_DONE]: 'Completed',
  [GameStatus.TRANSCODE_FAILED]: 'Video processing failed',
  [GameStatus.TRANSCODE_STARTED]: 'Video processing is in progress',
  [GameStatus.TRANSCODE_STARTING]: 'Video processing starting...',
  [GameStatus.TRANSCODE_STOPPING]: 'Video processing stopping...',
  [GameStatus.UPCOMING]: 'Upcoming',
}[status]);

const {
  CAPTURE_STARTING, CAPTURE_STOPPING, CAPTURE_STARTED,
  TRANSCODE_STARTED, TRANSCODE_STOPPING, TRANSCODE_STARTING,
  MOMENT_STARTED, MOMENT_STARTING,
} = GameStatus;

export const isGameLive = (
  game: Pick<Game, 'status' | 'live_now'>,
): boolean => game.live_now
  || [CAPTURE_STARTING, CAPTURE_STOPPING, CAPTURE_STARTED].includes(game.status);

export const isGameProcessing = (
  game: Pick<Game, 'status' | 'live_now'>,
): boolean => !game.live_now && [
  TRANSCODE_STARTED, TRANSCODE_STOPPING, TRANSCODE_STARTING,
  MOMENT_STARTED, MOMENT_STARTING,
].includes(game.status);
