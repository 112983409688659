import * as cx from 'classnames';
import { noop } from 'lodash/fp';
import * as MarkdownIt from 'markdown-it';
import * as React from 'react';
import * as InfiniteScroll from 'react-infinite-scroller';
import { AdsSpec, ShareAdsSpec } from 'weplayed-typescript-api';

import { MonetizationAd } from 'common/components/MonetizationAd';
import { EventContext } from 'common/utils/events';

import { ConnectWithUs } from 'consumer/components/ConnectWithUs';
import { Loading } from 'consumer/components/Loading';
import { MomentPresenter } from 'consumer/components/MomentPresenter';
import {
  MomentPresenterInstance,
} from 'consumer/components/MomentPresenter/types';

import * as s from './Collection.m.less';
import { CollectionHeader } from './CollectionHeader';
import { CollectionContext } from './constants';

const placementToClassName = (placement: ShareAdsSpec): string => placement.position
  .map((p) => {
    switch (p) {
      case 'left': return s.left;
      case 'right': return s.right;
      default: return '';
    }
  })
  .filter(Boolean)
  .join(' ');

const renderAds = (
  specs: Array<AdsSpec<ShareAdsSpec>> | undefined,
  type: 'top' | 'bottom',
): React.ReactNode => specs?.map((spec, idx) => (spec.placement.position.includes(type) ? (
  <div
    className={cx(s.ads, placementToClassName(spec.placement))}
    key={spec.id || idx}
  >
    <MonetizationAd config={spec} className={s.ad} />
  </div>
) : null));

export const CollectionView: React.FC = function CollectionView() {
  const presenters = React.useRef<MomentPresenterInstance[]>([]);

  const {
    adjustPk, collection, cwu, isMoment, loadMore,
    onMoment, onCancelMomentEdit, paused,
    register, scrolled, strict, user,
  } = React.useContext(CollectionContext);
  const description = React.useMemo(() => {
    const builder = new MarkdownIt();

    return builder.render(collection.description || '');
  }, [collection.description]);

  const { moments, org, ads_spec } = collection;

  return (
    <>
      <CollectionHeader />
      <div className={s.content}>

        {description && (
          <div
            className={s.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        {collection.moment_count === 0 && <h2>This playlist has no moments yet</h2>}

        {renderAds(ads_spec, 'top')}
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore || noop}
          hasMore={!!loadMore}
          loader={<Loading loading key="loader" />}
        >
          {moments.length > 0
            && moments.map((moment) => (
              <MomentPresenter
                autoPlay={false}
                className={s.moment}
                collection={collection}
                context={EventContext.COLLECTION}
                hideActionBar={strict}
                hideCuratorHighlight={strict}
                hideGameBar={collection.dedicated_game && strict}
                instances={presenters.current}
                key={moment.pk}
                mode={adjustPk === moment.pk ? 'adjust' : undefined}
                moment={moment}
                onCancel={onCancelMomentEdit}
                onSave={onMoment}
                paused={paused}
                ref={register(moment.pk)}
                saving={adjustPk === moment.pk && isMoment}
                share={org && strict}
                user={user}
                withDuration={collection.can_see_duration}
                withLinks={!strict}
                wlOrgId={org?.pk}
              />
            ))}
        </InfiniteScroll>

        {renderAds(ads_spec, 'bottom')}

        {strict && org && (
          <a
            className="wl-button"
            style={{
              backgroundColor: `#${org.primary_color}`,
            }}
            href={`http://${org.athletics_website}`}
          >
            visit &nbsp;
            {org.athletics_website}
          </a>
        )}
      </div>
      {cwu && <ConnectWithUs second="gray" toggle={scrolled} logo={scrolled} />}
    </>
  );
};
