import * as cx from 'classnames';
import { debounce } from 'lodash/fp';
import * as React from 'react';
import Draggable, {
  DraggableEvent, DraggableEventHandler,
} from 'react-draggable';
import { Moment } from 'weplayed-typescript-api';

import { hasTouch } from 'common/utils/features';

import {
  FOCUS_TIMEOUT, MARKER_SIZE, MARKER_SPACING, MAX_ZOOM, TIMELINE_COLLAPSED,
  ZOOM_STEP,
} from './constants';
import * as s from './GameTimeline.m.less';
import { Moments } from './Moments';
import { Segments } from './Segments';
// import {dumpStuff} from '../../utils/state';
import { Props, State } from './types';
import { combineEvents, getXOffset } from './utils';

const fixBoundaries = (
  shiftX: number,
  controlLength: number,
  scrollerWidth: number,
): number => Math.round(
  Math.max(
    0,
    Math.min(
      controlLength - scrollerWidth,
      shiftX,
    ),
  ),
);

/**
* Calculates event position relative to control
*/
const getVideoEventPosition = (e: MouseEvent): number => {
  let { offsetX } = e;
  let node = e.target as HTMLElement;
  while (node && node.id !== 'clickableDiv') {
    offsetX += node.offsetLeft;
    node = node.parentNode as HTMLElement;
  }

  return offsetX;
};

export class GameTimeline extends React.Component<Props, State> {
  private scrollerRef: React.MutableRefObject<HTMLDivElement> = React.createRef();

  private groupFocusTimeout: number;

  protected touchDistance: number = null;

  private resizeObserver: ResizeObserver;

  protected position = 0;

  constructor(props) {
    super(props);

    this.state = {
      controlLength: 0,
      events: [],
      eventsX: 0,
      scrollerWidth: 0,
      progressLength: null,
      zoom: 1,
      shiftX: 0,
    };
  }

  componentDidMount(): void {
    this.resizeObserver = new ResizeObserver(this.updateControlLengthState);
    this.resizeObserver.observe(this.scrollerRef.current);
    this.updateControlLengthState();

    if (hasTouch) {
      this.scrollerRef.current.addEventListener('touchstart', this.handleTouchStart);
      this.scrollerRef.current.addEventListener('touchmove', this.handleTouchMove);
      this.scrollerRef.current.addEventListener('touchend', this.handleTouchEnd);
    } else {
      this.scrollerRef.current.addEventListener('wheel', this.handleWheel, true);
    }

    if (!this.props.groups) {
      document.documentElement.style.setProperty('--game-timeline-height', TIMELINE_COLLAPSED);
    }
  }

  componentDidUpdate(prevProps, prevState): void {
    const { groups, onResize } = this.props;
    const { size } = this.state;

    if (prevProps.groups && !groups) {
      document.documentElement.style.setProperty('--game-timeline-height', TIMELINE_COLLAPSED);
    } else if (!prevProps.groups && groups) {
      document.documentElement.style.removeProperty('--game-timeline-height');
    }

    if (prevState.size !== size && onResize) {
      onResize(size);
    }
  }

  componentWillUnmount(): void {
    this.resizeObserver.disconnect();

    window.clearTimeout(this.groupFocusTimeout);
    document.documentElement.style.removeProperty('--game-timeline-height');
    if (hasTouch) {
      this.scrollerRef.current.removeEventListener('touchstart', this.handleTouchStart);
      this.scrollerRef.current.removeEventListener('touchmove', this.handleTouchMove);
      this.scrollerRef.current.removeEventListener('touchend', this.handleTouchEnd);
    } else {
      this.scrollerRef.current.removeEventListener('wheel', this.handleWheel, true);
    }
  }

  protected handleGroupMouseLeave = (): void => {
    this.handleFocus();
    this.setState(({ events }) => (events.length > 0 ? { events: [] } : null));
  };

  protected handleGroupMouseEnter = ({ currentTarget }: React.MouseEvent<HTMLDivElement>): void => {
    const idx = parseInt(currentTarget.getAttribute('data-group'), 10);
    const moments = this.props.groups[idx];

    this.handleFocus(moments);

    const events = combineEvents(moments);
    if (events.length !== 0) {
      this.setState({
        events,
        eventsX: currentTarget.offsetLeft,
      });
    }
  };

  protected handleFocus: (moments?: Moment[]) => void = (moments?: Moment[]) => {
    window.clearTimeout(this.groupFocusTimeout);
    if (this.props.onGroupFocus) {
      if (moments) {
        this.groupFocusTimeout = window.setTimeout(
          () => this.props.onGroupFocus(moments),
          FOCUS_TIMEOUT,
        );
      } else {
        // cleanup immediately
        this.props.onGroupFocus();
      }
    }
  };

  // eslint-disable-next-line react/sort-comp
  updateControlLengthState = debounce(200, (): void => {
    this.setState((state) => {
      const { length } = this.props;
      const { zoom } = state;
      const scrollerWidth = this.scrollerRef.current.clientWidth;
      const controlLength = scrollerWidth * zoom;

      return {
        size: (length * (MARKER_SIZE + MARKER_SPACING)) / controlLength,
        scrollerWidth,
        controlLength,
        progressLength: getXOffset(this.position, length, controlLength),
      };
    });
  });

  protected handleMomentClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();

    if (e.button !== 0) {
      return;
    }

    const { currentTarget } = e;
    let data = currentTarget.getAttribute('data-moment');

    if (data) {
      const [gidx, midx] = data.split('.').map(Number);
      this.props.onMomentSelected(this.props.groups[gidx][midx]);
      return;
    }

    data = currentTarget.getAttribute('data-group');
    if (data) {
      const idx = parseInt(data, 10);
      this.props.onMomentSelected(this.props.groups[idx][0]);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  public setTime = (position: number): void => {
    this.position = position;
    const { state, props } = this;

    if (state.stickyX === undefined) {
      const { controlLength, scrollerWidth } = state;
      const progressLength = getXOffset(
        position,
        props.length,
        state.controlLength,
      );

      let { shiftX } = state;

      if (progressLength !== state.progressLength) {
        if (progressLength < scrollerWidth + shiftX) {
          shiftX = fixBoundaries(progressLength - scrollerWidth / 2, controlLength, scrollerWidth);
        }

        this.setState({ progressLength, shiftX });
      }
    }
  };

  zoom = (amount: number): void => {
    const zoom = Math.max(1, Math.min(MAX_ZOOM, amount));

    this.setState((state) => {
      // New controlLength
      const { controlLength: oldControlLength, scrollerWidth } = state;
      const controlLength = scrollerWidth * zoom;
      const progressLength = getXOffset(
        this.position,
        this.props.length,
        controlLength,
      );

      // To zoom in mouse pointer change lines
      // const eventX = this.getVideoEventPosition(e);
      const eventX = state.progressLength;

      // End zoom change
      const offsetX = eventX - state.shiftX;

      const shiftX = fixBoundaries(
        (eventX / oldControlLength) * controlLength - offsetX,
        controlLength,
        state.scrollerWidth,
      );
      return {
        controlLength, progressLength, zoom, shiftX,
      };
    }, this.updateControlLengthState);
  };

  handleSlider = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    this.zoom(+value);
  };

  timelineClickEvent = (e: React.MouseEvent<HTMLElement>): void => {
    if (e.button === 0 && this.props.onTimeSelected) {
      const eventX = getVideoEventPosition(e.nativeEvent);
      const position = eventX / this.state.controlLength;
      const newTime = position * this.props.length;
      this.props.onTimeSelected(newTime);
    }
  };

  onScrubDotDrag: DraggableEventHandler = (_e, { x }): void => {
    const { stickRight } = this.props;
    const { controlLength } = this.state;

    if (stickRight) {
      this.setState({
        stickyX: x > controlLength - MARKER_SIZE ? controlLength - x : 0,
      });
    }
  };

  onScrubDotDragFinished: DraggableEventHandler = (_e, { x }): void => {
    const { stickRight, length, onTimeSelected } = this.props;
    const { controlLength } = this.state;

    if (stickRight && x >= controlLength - MARKER_SIZE) {
      onTimeSelected(this.props.length);
    } else {
      const ratio = x / controlLength;
      onTimeSelected(ratio * length);
    }

    this.setState({
      stickyX: undefined,
    });
  };

  onScrubDotDragStarted = (e: DraggableEvent): void => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ stickyX: 0 });
  };

  handleWheel = (e: WheelEvent): void => {
    e.preventDefault();

    const { zoom } = this.state;
    if (Math.abs(e.deltaY) > 2) {
      this.zoom(zoom + (e.deltaY > 0 ? -1 : 1));
    }
  };

  handleTouchStart = (e: TouchEvent): void => {
    if (e.touches.length !== 2) {
      this.touchDistance = null;
    } else {
      this.touchDistance = Math.abs(e.touches[1].clientX - e.touches[0].clientX);
    }
  };

  handleTouchMove = (e: TouchEvent): void => {
    if (this.touchDistance !== null && e.touches.length === 2) {
      const { zoom } = this.state;
      const newDistance = Math.abs(e.touches[1].clientX - e.touches[0].clientX);
      const distance = newDistance - this.touchDistance;
      if (Math.abs(distance) > 10) {
        this.touchDistance = newDistance;
        this.zoom(zoom + Math.floor(distance / 10));
      }
    }
  };

  handleTouchEnd = (): void => {
    this.touchDistance = null;
  };

  render(): JSX.Element {
    const {
      controlLength, shiftX, progressLength,
      scrollerWidth, events, eventsX, zoom = 1,
    } = this.state;

    const { length, moment, segments, groups } = this.props;

    return (
      <div className={cx(s.root, groups?.length > 0 && s.withGroups)}>
        <div className={s.holder}>
          <div
            className={s.scroller}
            onMouseDown={this.timelineClickEvent}
            ref={this.scrollerRef}
            role="button"
            tabIndex={0}
          >
            {controlLength !== null && this.scrollerRef.current ? (
              <div
                id="clickableDiv"
                style={{
                  left: -shiftX,
                  width: controlLength,
                }}
              >
                <div
                  className={s.progress}
                  style={{ borderLeftWidth: progressLength || 0 }}
                />
                <Draggable
                  axis="x"
                  position={{ x: progressLength, y: 0 }}
                  bounds={{
                    left: shiftX,
                    right: shiftX + scrollerWidth,
                    top: 0,
                    bottom: 0,
                  }}
                  onDrag={this.onScrubDotDrag}
                  onStop={this.onScrubDotDragFinished}
                  onStart={this.onScrubDotDragStarted}
                >
                  <div
                    className={s.scrubber}
                    style={{ left: this.state.stickyX || 0 }}
                  >
                    <div className={s.dot} />
                    <div className={s.bar} />
                  </div>
                </Draggable>
                {groups?.length ? <Segments segments={segments} length={length} /> : null}
                <Moments
                  groups={groups}
                  length={length}
                  moment={moment}
                  onClick={this.handleMomentClick}
                  onEnter={this.handleGroupMouseEnter}
                  onLeave={this.handleGroupMouseLeave}
                  width={controlLength}
                />
              </div>
            ) : undefined}
          </div>
          {events.length !== 0 && (
            <div
              className={s.popupHolder}
              style={{ left: eventsX - shiftX }}
            >
              <div className={s.popup}>
                {/* eslint-disable-next-line react/no-array-index-key */}
                {events.map((label: string, n: number) => <span key={`${label}${n}`}>{label}</span>)}
              </div>
            </div>
          )}
        </div>
        <div className={s.zoom}>
          <input
            type="range"
            min={1}
            max={MAX_ZOOM}
            onChange={this.handleSlider}
            step={ZOOM_STEP}
            value={zoom}
          />
        </div>
      </div>
    );
  }
}
