import { Moment } from 'weplayed-typescript-api';

export const getKey = (
  uid?: string,
  ...args: unknown[]
): unknown[] => ['moment', uid, ...args].filter(Boolean);

export const updateMoment = (
  pk: string,
  update: Partial<Moment> | ((moment: Moment) => Partial<Moment> | undefined),
) => (
  moment: Moment,
): Moment => {
  if (moment.pk !== pk) {
    return moment;
  }

  const up = typeof update === 'function' ? update(moment) : update;
  if (!up) {
    return moment;
  }

  return {
    ...moment,
    ...up,
  };
};

export const updateLike = (
  pk: string,
  like: boolean,
) => (
  moment: Moment,
): Moment => {
  const update = (m: Moment): Partial<Moment> | undefined => (
    m.liked_by_user !== like
      ? { liked_by_user: like }
      : undefined
  );

  return updateMoment(pk, update)(moment);
};
