import * as React from 'react';
import { Redirect, Route as RRDoute, useLocation } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';
import { getRedirectUrl } from 'common/utils/redirects';

import { CLIENT_URLS } from 'consumer/routes';

import { Props, RouteGroup } from './types';

export const Route: React.FC<Props> = function Route({ groups = [], ...props }) {
  const location = useLocation();
  const { profile } = useAuth();

  if (profile && groups.includes(RouteGroup.UNAUTHENTICATED)) {
    const next = getRedirectUrl(
      location,
      CLIENT_URLS.HOME.DISCOVER_DEFAULT.buildPath(),
      profile.is_cms_user,
    );

    if (!profile.is_cms_user && !profile.email) {
      return (
        <Redirect
          to={{
            pathname: CLIENT_URLS.USER.UPDATE_EMAIL.toPath(),
            search: next && `?next=${next}`,
          }}
        />
      );
    }

    return <Redirect to={next} />;
  }

  if (!profile && groups.includes(RouteGroup.AUTHENTICATED)) {
    const to = {
      pathname: CLIENT_URLS.AUTH.REGISTRATION.buildPath(),
      search: `?next=${location.pathname}`,
    };
    return <Redirect to={to} />;
  }

  return <RRDoute {...props} />;
};
