import * as cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';
import { formatDate } from 'common/utils/dates';

import { ItemPreview } from 'consumer/components/ItemPreview';
import {
  MomentDescriptionReadonly,
} from 'consumer/components/MomentDescription';
import { TeamLogo } from 'consumer/components/TeamLogo';
import { Tile } from 'consumer/components/Tile';
import { Renderer } from 'consumer/components/Tile/types';
import { UserHighlight } from 'consumer/components/UserHighlight';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './MomentTile.m.less';
import { Props } from './types';

export const MomentTile: React.FC<Props> = function MomentTile({
  item, modes, state, onStateChange, enablePreview,
}) {
  const { profile } = useAuth();

  return (
    <Tile
      item={item}
      className={s.root}
      modes={modes}
      state={state}
      onStateChange={onStateChange}
    >
      <Link
        className={cx(s.link, item.game?.sport?.slug)}
        to={CLIENT_URLS.MOMENT.VIEW_MOMENT.toPath({ uid: item.pk })}
      >
        {item.thumbnail && (
          <ItemPreview
            alt={item.name}
            animated={item.preview_webp}
            className={s.preview}
            enable={enablePreview}
            id={`image-moment-${item.pk}`}
            src={item.thumbnail}
          />
        )}

        <div className={cx(s.stats, item.thumbnail && s.fade)}>
          <div className={s.game}>
            <TeamLogo team={item.game.team1} disableLink size="xx-small" />
            <span>{formatDate(item.game.date_played)}</span>
            <TeamLogo team={item.game.team2} disableLink size="xx-small" />
          </div>
        </div>
      </Link>
      <div className={s.description}>
        {(!profile?.is_cms_user || !item.curator?.is_staff) && (
          <UserHighlight user={item.curator} semicolon />
        )}
        <MomentDescriptionReadonly
          moment={item}
          truncate={150}
          inline
        />
      </div>
    </Tile>
  );
};

export const momentRenderer: Renderer<What.MOMENTS> = (
  item,
  options,
) => (
  <MomentTile
    {...options}
    item={item}
  />
);
