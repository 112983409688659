import { AnnotationObjectsType } from '../../types';
import Circle from './files/static-circle.svg';
import TLine from './files/t-line.png';

const statics: AnnotationObjectsType = {
  circle: {
    scale: true,
    rotate: false,
    src: Circle,
    width: 200,
    height: 200,
    defaultSize: 0.1,
    preview: Circle,
  },
  't-line': {
    scale: true,
    rotate: true,
    path: [
      [{ x: 0, y: 0 }, { x: 1, y: 0 }],
      [{ x: 0.5, y: 0 }, { x: 0.5, y: 1 }],
    ],
    width: 200,
    height: 200,
    defaultSize: 0.05,
    preview: TLine,
  },
};

export default statics;
