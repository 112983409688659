import { parse, stringify } from 'query-string';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  ApplicationContext,
} from 'common/components/ApplicationProvider/ApplicationContext';
import {
  AuthAwareAction, AuthAwareCallback,
} from 'common/components/ApplicationProvider/types';
import { Debug } from 'common/utils/debug';

import { UseApplicationReturnType, UseApplicationType } from './types';

const debug = Debug.extend('useApplication');

export const useApplication: UseApplicationType = function useApplication() {
  const location = useLocation();
  const history = useHistory();

  const callback = React.useRef<AuthAwareCallback<AuthAwareAction>>();
  const processed = React.useRef(false);

  const {
    appCues, setLoading, broadcast,
    error, orgId, setOrgId, setAppCues, strict,
    popups, playing, setPlaying, profile,
    navType, setAuthAwareAction, authAwareAction,
  } = React.useContext(ApplicationContext);

  const isAuthenticated = Boolean(profile);

  const invokeAuthAware: AuthAwareCallback<AuthAwareAction> = React.useCallback(
    (act: AuthAwareAction): void => {
      if (isAuthenticated) {
        /* istanbul ignore else */
        if (callback.current) {
          callback.current(act);
        }
      } else {
        setAuthAwareAction(act);
      }
    },
    [isAuthenticated, setAuthAwareAction],
  );

  const resetAuthAware = React.useCallback(
    () => setAuthAwareAction(null),
    [setAuthAwareAction],
  );

  const registerAuthAware: UseApplicationReturnType['registerAuthAware'] = React.useCallback(
    (cbb, process = true) => {
      /* istanbul ignore else */
      if (callback.current !== cbb) {
        callback.current = cbb;
      }

      if (callback.current) {
        if (process && !processed.current) {
          processed.current = true;

          const { action, ...rest } = parse(location.search);

          if (action) {
            try {
              const act: AuthAwareAction = JSON.parse(action as string);

              // execute next cycle
              setTimeout(() => {
                debug('processAuthAware: action is %o', act);
                invokeAuthAware(act);

                // strip `action` from search params
                history.replace({ ...location, search: stringify(rest) });
              }, 0);
            } catch (e) {
              /* istanbul ignore next */
              debug('processAuthAware: cannot parse action: %s', action);
            }
          }
        }

        return invokeAuthAware;
      }
    },
    [history, invokeAuthAware, location],
  );

  const wlOrgId: UseApplicationReturnType['wlOrgId'] = React.useMemo(
    (): string => parse(location.search).wl_org_id as string,
    [location.search],
  );

  const resetOrgId: UseApplicationReturnType['resetOrgId'] = React.useCallback(
    (): void => setOrgId(undefined),
    [setOrgId],
  );

  return {
    authAwareAction,
    resetAuthAware,
    appCues,
    broadcast,
    error,
    navType,
    orgId,
    playing,
    popups,
    registerAuthAware,
    resetOrgId,
    setAppCues,
    setLoading,
    setOrgId,
    setPlaying,
    strict,
    wlOrgId,
  };
};
