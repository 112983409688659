import * as cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { PlayerPosition } from 'consumer/components/PlayerPosition';
import { Renderer } from 'consumer/components/Tile/types';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './PlayerTile.m.less';
import { Props } from './types';

export const PlayerTile: React.FC<Props> = function PlayerTile({ item, size }) {
  const link = CLIENT_URLS.PLAYER.VIEW.toPath({ uid: item.pk });

  return (
    <div className={cx(s.root, size)}>
      {item.photo_url && (
        <div className={s.image}>
          <img
            alt={item.name}
            id={`image-player-${item.pk}`}
            src={item.photo_url}
          />
        </div>
      )}
      <Link className={s.content} to={link}>
        <h3>{item.name}</h3>
        <PlayerPosition
          player={item}
          size={item.photo_url
            ? ((size === 'small' && 'x-small') || 'small')
            : ((size === 'small' && 'small') || 'large')}
          logo
        />
        {!item.photo_url && item.sport && (
          <div className={s.sport}>
            {item.sport.name}
          </div>
        )}
        {false && item.moment_count && (
          <div className={s.moments}>
            {`${item.moment_count} Moments`}
          </div>
        )}
      </Link>
    </div>
  );
};

export const playerRenderer: Renderer<What.PLAYERS> = (
  item,
  options,
): React.ReactNode => (
  <PlayerTile
    {...options}
    item={item}
  />
);
