import { Collection, Moment, User } from 'weplayed-typescript-api';

import { AuthAwareAction } from 'common/components/ApplicationProvider/types';

import { MomentPresenter } from 'consumer/components/MomentPresenter';

export interface RouteParams {
  uid: string;
  action?: 'edit';
}

export interface QueryParams {
  cwu?: '1';
}

export interface RouteProps extends RouteParams {
  cwu: boolean;
}

export interface CollectionPostLoginAction extends AuthAwareAction {
  action: 'collection_like';
  like: boolean;
}

export enum MomentsOrder {
  addedAsc = 'addedAsc',
  addedDesc = 'addedDesc',
  playedAsc = 'playedAsc',
  playedDesc = 'playedDesc',
}

export enum ModalMode {
  delete,
  rename,
  clone,
}

export interface SortableMomentContainerProps {
  collection: Collection;
  items: Moment[];
  onMenu(index: number): () =>void;
}

export interface SortableMomentProps {
  momentIndex: number;
  onMenu(index: number);
  value: Moment;
  withDuration: boolean;
}

export interface CollectionContextType {
  action?: 'edit';
  adjustPk: string;
  collection: Collection;
  cwu: boolean;
  isCloning: boolean;
  isRemoving: boolean;
  isDownloading: boolean;
  isMoment: boolean;
  isSorting: boolean;
  isUpdating: boolean;
  loadMore?(): void;
  onCancelMomentEdit(): void;
  onClone(name: string): void;
  onDelete(): void;
  onDownload(): void;
  onLike(like: boolean): void;
  onRename(name: string): void;
  onMoment(moment: Moment): void;
  onMomentRemove(moment: Moment): void;
  onSort(momentPks: string[]): void;
  paused: boolean;
  register(momentPk: string): (presenter: MomentPresenter) => void;
  strict: boolean;
  scrolled?: boolean;
  user: User | undefined;
}

export enum SortIntent {
  toTop,
  toBottom,
  remove,
}

export interface MomentMenuProps {
  className?: string;
  style?: React.CSSProperties;
  onClick(intent: SortIntent): (e: React.MouseEvent) => void;
}
