import { ReactComponent as CloseCircle } from '@mdi/svg/svg/close-circle.svg';
import { ReactComponent as CodeBrackets } from '@mdi/svg/svg/code-brackets.svg';
import { ReactComponent as CodeTags } from '@mdi/svg/svg/code-tags.svg';
import { ReactComponent as Delete } from '@mdi/svg/svg/delete.svg';
import { ReactComponent as Download } from '@mdi/svg/svg/download.svg';
import { ReactComponent as Flag } from '@mdi/svg/svg/flag.svg';
import { ReactComponent as LeadPencil } from '@mdi/svg/svg/lead-pencil.svg';
import { ReactComponent as PlaylistPlus } from '@mdi/svg/svg/playlist-plus.svg';
import { ReactComponent as ShapePlus } from '@mdi/svg/svg/shape-plus.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { What } from 'weplayed-typescript-api';

import { ShareTarget } from 'common/types';
import { getMomentShareMeta } from 'common/utils/share';

import { LikeButton } from 'consumer/components/LikeButton';
import { Share } from 'consumer/components/Share';
import { ShareMeta } from 'consumer/components/Share/types';
import { TooltipButton } from 'consumer/components/TooltipButton';

import { MomentActionBarContext } from './MomentActionBarContext';
import { Action, ActionType, BarProps } from './types';

const convertShare = (target: ShareTarget): Partial<Action> => ({ target });
const convertLike = (like: boolean): Partial<Action> => ({ like });

export const MomentActionBar: React.FunctionComponent<BarProps> = function MomentActionBar({
  className, moment, collection, shareOptions, context,
  showCollectionAction, showDeleteAction, showDownloadAction,
  showEditAction, showEmbed, showFlagAction, showGameAction, showLikingAction,
  showRemoveAction, showAdjustTime,
}) {
  const { invoke, user, status } = React.useContext(MomentActionBarContext);

  const share: ShareMeta = React.useMemo(() => moment && {
    pk: moment.pk,
    type: What.MOMENTS,
    meta: getMomentShareMeta(moment),
  }, [moment]);

  const handleAction = React.useCallback(
    (
      action: ActionType,
      args?: Partial<Action>,
      converter?: (...props: unknown[]) => Partial<Action>,
      notify?: boolean,
    ) => (...props: unknown[]): void => {
      invoke({
        ...args,
        ...(converter ? converter(...props) : null),
        action,
        momentPk: moment.pk,
        gamePk: moment.game_id || moment.game?.pk,
      }, notify);
    },
    [invoke, moment],
  );

  const handleClick = React.useCallback(
    (action: ActionType, args?: Partial<Action>) => (
      _e: React.MouseEvent<HTMLButtonElement>,
    ): void => {
      invoke({
        ...args,
        action,
        momentPk: moment.pk,
        gamePk: moment.game_id || moment.game?.pk,
      });
    },
    [invoke, moment],
  );

  return (
    <div className={cx('moment-actions-bar-container', className)}>
      {showEditAction && (
        <TooltipButton
          // WPM-1589
          className="no-mobile"
          onClick={handleClick('edit')}
          tooltip="Edit Moment"
        >
          <LeadPencil />
        </TooltipButton>
      )}

      {showLikingAction && (
        <LikeButton
          id={`moment-actions-like-${moment.pk}`}
          likes={!user?.is_cms_user}
          liked={moment.liked_by_user}
          onChange={handleAction('like', null, convertLike)}
          tooltip
        />
      )}

      {showCollectionAction && (
        <TooltipButton
          id={`moment-actions-add-playlist-${moment.pk}`}
          onClick={handleClick('collection', { collectionPk: collection?.pk })}
          tooltip="Add to Playlist"
        >
          <PlaylistPlus />
        </TooltipButton>
      )}

      <Share
        {...(shareOptions || {})}
        context={context}
        meta={share}
        onShare={handleAction('share', null, convertShare)}
        tooltip="Share"
      />

      {showGameAction && (
        <TooltipButton
          id={`moment-actions-game-${moment.pk}`}
          onClick={handleClick('game')}
          tooltip="View in the Game"
        >
          <ShapePlus />
        </TooltipButton>
      )}

      {showAdjustTime && (
        <TooltipButton
          // WPM-1589
          className="no-mobile"
          id={`moment-actions-adjust-time-${moment.pk}`}
          onClick={handleClick('adjust')}
          tooltip="Update Timing"
        >
          <CodeBrackets />
        </TooltipButton>

      )}

      {showDeleteAction && (
        <TooltipButton
          id={`moment-actions-delete-${moment.pk}`}
          onClick={handleClick('delete')}
          tooltip="Delete"
          processing={status.delete.includes(moment.pk)}
        >
          <Delete />
        </TooltipButton>
      )}

      {showRemoveAction && collection?.can_manage && (
        <TooltipButton
          id={`moment-actions-remove-${moment.pk}`}
          onClick={handleClick('remove', { collectionPk: collection.pk })}
          tooltip="Remove from Playlist"
          processing={status.remove.includes(moment.pk)}
        >
          <CloseCircle />
        </TooltipButton>
      )}

      {showFlagAction && (
        <TooltipButton
          id={`moment-actions-flag-${moment.pk}`}
          onClick={handleClick('flag')}
          tooltip="Flag Content"
          processing={status.flag.includes(moment.pk)}
        >
          <Flag />
        </TooltipButton>
      )}

      {showEmbed && (
        <TooltipButton
          id={`moment-actions-embed-${moment.pk}`}
          onClick={handleClick('embed')}
          tooltip="Embed Code"
        >
          <CodeTags />
        </TooltipButton>
      )}

      {showDownloadAction && (
        <TooltipButton
          id={`moment-actions-download-${moment.pk}`}
          onClick={handleClick('download')}
          tooltip="Download Moment"
          processing={status.download.includes(moment.pk)}
        >
          <Download />
        </TooltipButton>
      )}
    </div>
  );
};
