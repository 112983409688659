import { get } from 'lodash/fp';
import * as Raphael from 'raphael';
import {
  AnnotationBlockProps, AnnotationBlockType,
} from 'weplayed-typescript-api';

import { AnnotationObjects } from '../../AnnotationsRepository';
import { BlockComponent } from '../../AnnotationsRepository/types';
import { Handle } from '../../Handle';
import { Base } from '../Base';
import { DEFAULT_COMPONENT_SIZE } from '../contants';

export abstract class Block<T extends AnnotationBlockProps<AnnotationBlockType>> extends Base<T> {
  /**
   * Reference to repository component
   */
  protected $component: BlockComponent;

  /**
   * Image handle used to rotate and scale image
   */
  protected $handle?: RaphaelSet;

  /**
   * Absolute screen values used in calculation of translation or rotation
   */
  protected $ex: number;

  protected $ey: number;

  static preview(data: AnnotationBlockProps<AnnotationBlockType>): string {
    return get(data.path, AnnotationObjects).preview;
  }

  public constructor(paper: RaphaelPaper, props: T) {
    super(paper, props);
    this.$component = get(this.$props.path, AnnotationObjects) as BlockComponent;

    if (!this.$component) {
      throw new Error(`No component ${this.props.path} found`);
    }

    this.$props.size = this.$props.size || this.$component.defaultSize || DEFAULT_COMPONENT_SIZE;
    this.$props.angle = this.$props.angle || 0;
  }

  /**
   * Abstract method to draw Raphael component on canvas
   */
  public draw(): this {
    if (!this.$element) {
      throw new Error('No element present');
    }

    this.$element
      .transform(`...R${this.$props.angle},0,0`)
      .transform(`...T${this.$props.x * this.$paper.width},${this.$props.y * this.$paper.height}`)
      .drag(
        this.handleDrag.bind(this),
        this.handleDragStart.bind(this),
        this.handleDragEnd.bind(this),
      );

    return this;
  }

  /**
   * Move component that center appeared in given point
   */
  public centerTo(x: number, y: number): this {
    this.translate(x - this.$props.x, y - this.$props.y);
    if (this.$handleChange) {
      this.$handleChange();
    }
    return this;
  }

  /**
   * Translate component
   */
  public translate(dx: number, dy: number): this {
    this.$element.transform(`...T${dx * this.$paper.width},${dy * this.$paper.height}`);
    this.$props.x += dx;
    this.$props.y += dy;

    return super.translate(dx, dy);
  }

  /**
   * Scale component
   */
  public scale(scale: number): this {
    const cx = this.$props.x * this.$paper.width;
    const cy = this.$props.y * this.$paper.height;
    this.$element.transform(`...S${scale},${scale},${cx},${cy}`);
    if (this.$handle) {
      this.$handle[1].transform(`...S${1 / scale},${1 / scale}`);
    }
    this.$props.size *= scale;
    return this;
  }

  /**
   * Rotate component around center
   */
  public rotate(angle: number): this {
    const cx = this.$props.x * this.$paper.width;
    const cy = this.$props.y * this.$paper.height;
    this.$element.transform(`...R${angle},${cx},${cy}`);
    this.$props.angle += angle;
    return this;
  }

  public focus(): this {
    if (!this.$selected && (this.$component.rotate || this.$component.scale)) {
      if (!this.$element) {
        this.draw();
      }
      this.$handle = this.$paper.set();
      const x1 = this.$props.x * this.$paper.width;
      const x2 = (this.$props.x + this.$props.size / 2) * this.$paper.width;
      const y = this.$props.y * this.$paper.height;
      const line = this.$paper.path(`M${x1} ${y}L${x2} ${y}`).attr({ stroke: 'white', 'stroke-width': 1 });
      const handle = Handle(this.$paper, x2, y)
        .drag(
          this.handleScaleRotate.bind(this),
          this.handleScaleRotateStart.bind(this),
          this.handleScaleRotateEnd.bind(this),
        );
      this.$handle.push(line, handle);
      this.$handle.transform(`R${this.$props.angle},${x1},${y}`);
      this.$element.push(this.$handle);
      return super.focus();
    }
    return this;
  }

  public blur(): this {
    if (this.$selected) {
      this.$element.exclude(this.$handle);
      this.$handle.remove();
      this.$handle = undefined;
      return super.blur();
    }
    return this;
  }

  protected handleScaleRotateStart(x: number, y: number): void {
    this.$ex = x;
    this.$ey = y;
  }

  protected handleScaleRotate(_dx: number, _dy: number, x: number, y: number): void {
    const rect = this.$paper.canvas.getBoundingClientRect();
    const cx = (this.$props.x * this.$paper.width) + window.pageXOffset + rect.left;
    const cy = (this.$props.y * this.$paper.height) + window.pageYOffset + rect.top;

    if (this.$component.scale) {
      const before = Math.sqrt((cx - this.$ex) ** 2 + (cy - this.$ey) ** 2);
      const now = Math.sqrt((cx - x) ** 2 + (cy - y) ** 2);
      const scale = now / before;

      this.scale(scale);
    }

    if (this.$component.rotate) {
      const angle = Raphael.angle(x, y, this.$ex, this.$ey, cx, cy);
      this.rotate(angle);
    }

    this.$ex = x;
    this.$ey = y;
  }

  protected handleScaleRotateEnd(): void {
    if (this.$handleChange) {
      this.$handleChange();
    }
  }

  protected handleDragStart(x: number, y: number, e: DragEvent): void {
    this.$handleSelect(e.ctrlKey);
    this.$ex = x;
    this.$ey = y;
  }

  protected handleDrag(_dx: number, _dy: number, x: number, y: number): void {
    this.$handleMove((x - this.$ex) / this.$paper.width, (y - this.$ey) / this.$paper.height);
    this.$ex = x;
    this.$ey = y;
  }

  protected handleDragEnd(): void {
    if (this.$handleChange) {
      this.$handleChange();
    }
  }
}
