import * as React from 'react';
import { Game, User } from 'weplayed-typescript-api';

import { GameDetailedSlide } from 'consumer/components/GameDetailedSlide';

interface Props {
  games: Game[];
  user?: User;
}

export const GamesDetailedSection: React.FC<Props> = function GamesDetailedSection({
  games, user,
}) {
  return (
    <GameDetailedSlide
      game={games?.[0]}
      title={user?.is_cms_user ? 'LATEST GAME' : 'FEATURED GAME'}
    />
  );
};
