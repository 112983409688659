import { cloneDeep, omit } from 'lodash/fp';
import { AnnotationBaseProps, AnnotationType } from 'weplayed-typescript-api';

import CrossImg from './cross.svg';

/**
 * Base class for all canvas components
 */
export abstract class Base<T extends AnnotationBaseProps<AnnotationType>> {
  /**
   * Component properties enriched with defaults
   */
  protected $props?: T;

  /**
   * Visible SVG component element
   */
  protected $element: RaphaelSet;

  /**
   * SVG paper
   */
  protected $paper: RaphaelPaper;

  /**
   * Is component selected
   */
  protected $selected = false;

  protected $handleSelect: (append: boolean) => void = this.focus.bind(this);

  protected $handleChange?: () => void;

  protected $handleMove: (dx: number, dy: number) => void = (
    (dx: number, dy: number) => this.translate(dx, dy)
  );

  static preview(_data: AnnotationBaseProps<AnnotationType>): string {
    return CrossImg as unknown as string;
  }

  public constructor(paper: RaphaelPaper, props: T) {
    this.$paper = paper;
    this.$props = props;
  }

  public get props(): T {
    return omit(['id'], cloneDeep(this.$props)) as T;
  }

  public get selected(): boolean {
    return this.$selected;
  }

  public abstract draw(): this;

  public abstract centerTo(x: number, y: number): this;

  public timeEvent(position: number, _editable: boolean, paused: boolean): void {
    if (typeof this.$props.start === 'number'
        && typeof this.$props.duration === 'number') {
      if (!this.$element
          && position >= this.$props.start
          // <= for elements with duration === 0
          && position <= this.$props.start + this.$props.duration) {
        this.draw();
      } else if (this.$element
        && (
          position < this.$props.start
          || (
            paused && this.$props.duration === 0
              ? position > this.$props.start
              : position >= this.$props.start + this.$props.duration
          )
        )
      ) {
        this.clear();
      }
    }
  }

  public blur(): this {
    this.$selected = false;

    return this;
  }

  public focus(): this {
    this.$selected = true;
    this.$element.toFront();

    return this;
  }

  public time(start: number, duration?: number): this {
    this.$props.start = start;
    if (duration !== undefined) {
      this.$props.duration = duration;
    }

    if (this.$handleChange) {
      this.$handleChange();
    }
    return this;
  }

  public translate(_dx: number, _dy: number): this {
    return this;
  }

  public onSelect(callback?: (append: boolean) => void): this {
    this.$handleSelect = callback || this.focus.bind(this);

    return this;
  }

  public onMove(callback?: (dx: number, dy: number) => void): this {
    this.$handleMove = callback || this.translate.bind(this);

    return this;
  }

  public onChange(callback?: () => void): this {
    this.$handleChange = callback;

    return this;
  }

  public clear(): void {
    // must remove all controls
    this.blur();

    if (this.$element) {
      this.$element.remove();
      this.$element = undefined;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  protected handleDragStart(_x: number, _y: number, _e: DragEvent): void {
    // empty
  }

  // eslint-disable-next-line class-methods-use-this
  protected handleDrag(_dx: number, _dy: number, _x: number, _y: number): void {
    // empty
  }

  // eslint-disable-next-line class-methods-use-this
  protected handleDragEnd(): void {
    // empty
  }
}
