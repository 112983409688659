import * as React from 'react';

import * as s from './GameTimeline.m.less';
import { SegmentsProps } from './types';

const SegmentsRenderer: React.FC<SegmentsProps> = function SegmentsRenderer({ segments, length }) {
  if (!segments || segments.length === 0) {
    return null;
  }

  return (
    <div className={s.segments}>
      {segments.map(({ time, name }) => (
        <div
          className={s.segment}
          key={time}
          style={{ left: `${((100 * time) / length).toFixed(2)}%` }}
        >
          <div />
          {name}
        </div>
      ))}
    </div>
  );
};

export const Segments = React.memo(SegmentsRenderer);
