import * as React from 'react';

import { RECONNECT_TIMEOUT, wsDebug } from './constants';
import {
  GameEvents, SystemEvents, UseWSReturnType, WSAllAction, WSAllSubscribeArgs,
  WSAllUnsubscribeArgs, WSGameAction, WSGameSubscribeArgs,
  WSGameUnsubscribeArgs, WSListeners, WSSystemAction, WSSystemSubscribeArgs,
  WSSystemUnsubscribeArgs,
} from './types';

let ws: WebSocket;
let wsReconnectTimer: ReturnType<typeof setTimeout>;
let globalListeners = false;

const wsSend = (action: WSAllAction): void => {
  if (ws && ws.readyState === ws.OPEN) {
    ws.send(JSON.stringify(action));
    wsDebug('send: %o', action);
  } else {
    wsDebug('send: ws is not active');
  }
};

const wsListeners: WSListeners = {
  game: {},
  system: [],
};

const gameJoin = (game_id: string): void => wsSend({
  action: GameEvents.JOIN,
  game_id,
});

const gameLeave = (game_id: string): void => wsSend({
  action: GameEvents.LEAVE,
  game_id,
});

const wsNotify = (action: WSAllAction): void => {
  wsDebug('notify: %o', action);

  const [namespace] = action.action.split('::');
  if (action.action === 'system::connection') {
    wsListeners.system.forEach(
      (listener) => listener(action as WSSystemAction),
    );
  } else /* istanbul ignore else */ if (namespace === 'game') {
    const { game_id } = action as WSGameAction;

    if (wsListeners.game[game_id]) {
      wsListeners.game[game_id].forEach(
        (listener) => listener(action as WSGameAction),
      );
    }
  }
};

const wsNeedsConnection = (): boolean => (
  Object.keys(wsListeners.game).length + wsListeners.system.length !== 0
);

const wsOnOpen = (): void => {
  wsDebug('ws.open');
  clearTimeout(wsReconnectTimer);
  wsNotify({ action: SystemEvents.CONNECTION, state: true });

  // restore game subscriptions
  Object.keys(wsListeners.game).forEach((gameId) => gameJoin(gameId));
};

const wsOnClose = (): void => {
  wsDebug('ws.close');
  wsNotify({ action: SystemEvents.CONNECTION, state: false });
};

const wsOnMessage = ({ data }: MessageEvent): void => {
  try {
    wsNotify(JSON.parse(data));
  } catch (error) {
    /* istanbul ignore next */
    wsDebug('ws.message: parse parse error %o', error);
  }
};

const wsConnect = (): void => {
  if (wsNeedsConnection() && (!ws
      || ws.readyState === ws.CLOSED
      || ws.readyState === ws.CLOSING)
  ) {
    if (!globalListeners) {
      globalListeners = true;
      window.addEventListener('online', wsConnect);
      // eslint-disable-next-line no-use-before-define
      window.addEventListener('offline', wsDisconnect);
    }

    clearTimeout(wsReconnectTimer);

    ws = new WebSocket(process.env.WS_SERVER);
    // eslint-disable-next-line no-use-before-define
    ws.addEventListener('error', wsOnError);
    ws.addEventListener('close', wsOnClose);
    ws.addEventListener('open', wsOnOpen);
    ws.addEventListener('message', wsOnMessage);
  }
};

const wsOnError = (e: Event): void => {
  wsDebug('ws.error', e);
  clearTimeout(wsReconnectTimer);
  // eslint-disable-next-line no-use-before-define
  wsDisconnect();

  wsReconnectTimer = setTimeout(wsConnect, RECONNECT_TIMEOUT);
};

const wsDisconnect = (): void => {
  /* istanbul ignore else */
  if (ws) {
    if (!wsNeedsConnection()) {
      window.removeEventListener('online', wsConnect);
      window.removeEventListener('offline', wsDisconnect);
    }

    ws.removeEventListener('open', wsOnOpen);
    ws.removeEventListener('close', wsOnClose);
    ws.removeEventListener('error', wsOnError);
    ws.removeEventListener('message', wsOnMessage);

    // manually notify
    wsOnClose();

    if (ws.readyState !== ws.CLOSED) {
      ws.close();
    }
    ws = undefined;
  }
};

const wsSubscribe = (...args: WSAllSubscribeArgs): void => {
  if (args[0] === 'system') {
    const [, listener] = args as WSSystemSubscribeArgs;
    wsListeners.system.push(listener);
  } else /* istanbul ignore else */ if (args[0] === 'game') {
    const [, gameId, listener] = args as WSGameSubscribeArgs;

    if (!wsListeners.game[gameId]) {
      wsListeners.game[gameId] = [];

      // for the cases when we already have active connection
      // if not, this will be covered by wsOnOpen callback
      gameJoin(gameId);
    }
    wsListeners.game[gameId].push(listener);
  }

  wsConnect();
};

const wsUnsubscribe = (...args: WSAllUnsubscribeArgs): void => {
  if (args[0] === 'system') {
    const [, listener] = args as WSSystemUnsubscribeArgs;
    const idx = wsListeners.system.indexOf(listener);
    /* istanbul ignore else */
    if (idx !== -1) {
      wsListeners.system.splice(idx, 1);
    }
  } else /* istanbul ignore else */ if (args[0] === 'game') {
    const [, gameId, listener] = args as WSGameUnsubscribeArgs;
    const idx = wsListeners.game[gameId]?.indexOf(listener) ?? -1;

    /* istanbul ignore else */
    if (idx !== -1) {
      wsListeners.game[gameId].splice(idx, 1);

      if (wsListeners.game[gameId].length === 0) {
        gameLeave(gameId);
        delete wsListeners.game[gameId];
      }
    }
  }

  if (!wsNeedsConnection()) {
    wsDisconnect();
  }
};

export const useWS = function useWS(): UseWSReturnType {
  const listeners = React.useRef<WSListeners>({
    game: {},
    system: [],
  });

  const subscribe = React.useCallback((...args: WSAllSubscribeArgs): void => {
    if (args[0] === 'system') {
      const [, listener] = args as WSSystemSubscribeArgs;
      listeners.current.system.push(listener);
    } else /* istanbul ignore else */ if (args[0] === 'game') {
      const [, gameId, listener] = args as WSGameSubscribeArgs;

      /* istanbul ignore else */
      if (!listeners.current.game[gameId]) {
        listeners.current.game[gameId] = [];
      }
      listeners.current.game[gameId].push(listener);
    }

    wsSubscribe(...args);
  }, []);

  const unsubscribe = React.useCallback((...args: WSAllUnsubscribeArgs): void => {
    wsUnsubscribe(...args);

    if (args[0] === 'system') {
      const [, listener] = args as WSSystemUnsubscribeArgs;
      const idx = listeners.current.system.indexOf(listener);
      /* istanbul ignore else */
      if (idx !== -1) {
        listeners.current.system.splice(idx);
      }
    } else /* istanbul ignore else */ if (args[0] === 'game') {
      const [, gameId, listener] = args as WSGameUnsubscribeArgs;
      const idx = listeners.current.game[gameId]?.indexOf(listener) ?? -1;

      if (idx !== -1) {
        listeners.current.game[gameId].splice(idx, 1);
      }
    }
  }, []);

  React.useEffect(() => (): void => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listeners.current.system.forEach((listener) => wsUnsubscribe('system', listener));
    Object.keys(listeners.current.game).forEach(
      (game) => listeners.current.game[game]
        .forEach((listener) => wsUnsubscribe('game', game, listener)),
    );
  }, []);

  return {
    subscribe,
    unsubscribe,
  };
};
