import * as cx from 'classnames';
import { ReactComponent as Spinner } from 'images/spinner.svg';
import * as React from 'react';
import { Button as BsButton, ButtonProps } from 'react-bootstrap';

import * as s from './Button.m.less';
import { ButtonType } from './types';

export const Button: ButtonType = function Button({
  children, loading, className, over, ...rest
}) {
  return (
    <BsButton<React.FC<ButtonProps>>
      {...rest}
      className={cx(s.root, over && s.over, className)}
    >
      {children}
      {loading && <Spinner />}
    </BsButton>
  );
};
