import * as React from 'react';

import { logger } from 'common/utils/logger';
import { logoPath } from 'common/utils/logos';

import { Props } from './types';

const names = [];

export const TeamImage: React.FC<Props> = function TeamImage({
  team: { logo, name, primary_color },
  size,
  ...props
}) {
  let l = logo;

  if (!l) {
    const n = (name || '').replace(/\s+/, '').substring(0, 1).toUpperCase();
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><circle cx="18" cy="18" r="18" fill="#${primary_color || 'A0A6B3'}" /><text font-family="sans-serif" fill="#fff" x="18" y="24" text-anchor="middle" font-size="18">${n}</text></svg>`;
    l = `data:image/svg+xml;utf8,${svg.replace(/"/g, "'").replace(/#/g, encodeURIComponent)}`;

    if (name && !names.includes(name)) {
      logger.warn(`team ${name} logo is not provided`);
      names.push(name);
    }
  } else {
    l = logoPath(l, size);
  }

  return <img {...props} src={l} alt={name} />;
};
