import * as cx from 'classnames';
import * as React from 'react';

import { getVisitorId } from 'common/utils/visitorId';

import * as s from './MonetizationAd.m.less';
import { MonetizationAdProps } from './types';

const logUrl = '/api/logging/v0/event/';

export const MonetizationAd: React.FC<MonetizationAdProps> = function MonetizationAd({
  className,
  config,
}) {
  const { click_out_url, creative_url } = config;
  const io = React.useRef<IntersectionObserver>();
  const ref = React.useRef<HTMLAnchorElement>();
  const [visible, setVisible] = React.useState(false);

  const [pixels, appendPixels] = React.useReducer((urls, p) => {
    const visitorId = getVisitorId();

    return [
      ...urls,
      ...p.map((src) => (
        src.includes(logUrl)
          ? `${src}parent=${encodeURIComponent(document.location.toString())}&visitor_id=${visitorId}`
          : src
      )),
    ];
  }, []);

  const handleIntersection = React.useCallback(
    ([e]: IntersectionObserverEntry[]): void => {
      if (e.isIntersecting) {
        setVisible(true);
        appendPixels(config.view_tracking_pixels);
      }
    },
    [config],
  );

  const handleClick = React.useCallback((): void => {
    appendPixels(config.click_tracking_pixels);
  }, [config]);

  React.useEffect(() => {
    if (!io.current) {
      io.current = new IntersectionObserver(handleIntersection);
    }
    const element = ref.current;
    io.current.observe(element);

    return (): void => {
      io.current.unobserve(element);
    };
  }, [handleIntersection]);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={cx(s.root, className, visible && s.visible)}
      href={click_out_url}
      onClick={handleClick}
      ref={ref}
      rel="noopener"
      target="_blank"
    >
      <img src={creative_url} alt="" />
      {pixels.map((url, idx) => (
        // it is safe to use IDX here since list may only append new items
        // eslint-disable-next-line react/no-array-index-key
        <img src={url} className={s.pixel} key={`${url}--${idx}`} alt="" />
      ))}
    </a>
  );
};
