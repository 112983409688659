import { Images } from './types';

export const VIDEO_URL = 'https://player.vimeo.com/video/356902022?autoplay=1&controls=0';

const vertical: Images = {
  MCFOOT: [
    'https://media.weplayed.com/home_covers/mfootball1_vert.jpg',
    'https://media.weplayed.com/home_covers/mfootball2_vert.jpg',
    'https://media.weplayed.com/home_covers/mfootball3_vert.jpg',
    'https://media.weplayed.com/home_covers/mfootball4_vert.jpg',
  ],
  WCBASK: [
    'https://media.weplayed.com/home_covers/wbasketball_vert.jpg',
  ],
  MCBASK: [
    'https://media.weplayed.com/home_covers/mbasketball_vert.jpg',
  ],
  WCLAX: [
    'https://media.weplayed.com/home_covers/wlacrosse_vert.jpg',
  ],
  MCLAX: [
    'https://media.weplayed.com/home_covers/mlacrosse_vert.jpg',
  ],
  WCSOCC: [
    'https://media.weplayed.com/home_covers/wsoccer_vert.jpg',
  ],
  MCSOCC: [
    'https://media.weplayed.com/home_covers/msoccer_vert.jpg',
  ],
  WCSOFT: [
    'https://media.weplayed.com/home_covers/wsoftball_vert.jpg',
  ],
  MCBASE: [
    'https://media.weplayed.com/home_covers/mbaseball_vert.jpg',
  ],
};

const horizontal: Images = {
  MCFOOT: [
    'https://media.weplayed.com/home_covers/mfootball1_horiz.jpg',
    'https://media.weplayed.com/home_covers/mfootball2_horiz.jpg',
    'https://media.weplayed.com/home_covers/mfootball3_horiz.jpg',
    'https://media.weplayed.com/home_covers/mfootball4_horiz.jpg',
  ],
  WCBASK: [
    'https://media.weplayed.com/home_covers/wbasketball_horiz.jpg',
  ],
  MCBASK: [
    'https://media.weplayed.com/home_covers/mbasketball_horiz.jpg',
  ],
  WCLAX: [
    'https://media.weplayed.com/home_covers/wlacrosse_horiz.jpg',
  ],
  MCLAX: [
    'https://media.weplayed.com/home_covers/mlacrosse_horiz.jpg',
  ],
  WCSOCC: [
    'https://media.weplayed.com/home_covers/wsoccer_horiz.jpg',
  ],
  MCSOCC: [
    'https://media.weplayed.com/home_covers/msoccer_horiz.jpg',
  ],
  WCSOFT: [
    'https://media.weplayed.com/home_covers/wsoftball_horiz.jpg',
  ],
  MCBASE: [
    'https://media.weplayed.com/home_covers/mbaseball_horiz.jpg',
  ],
};

/**
 * An order in which images will be returned for sliding on welcome page
 * Syntax is [KEY, item]
 */
const imagesOrder = [
  ['MCFOOT', 1],
  ['WCBASK', 0],
  ['MCBASK', 0],
  ['MCFOOT', 0],
  ['WCLAX', 0],
  ['MCLAX', 0],
  ['MCFOOT', 2],
  ['WCSOCC', 0],
  ['MCSOCC', 0],
  ['MCFOOT', 3],
  ['WCSOFT', 0],
  ['MCBASE', 0],
];

export const getImages = (): string[] => {
  const portrait = window.orientation === undefined
    ? window.innerWidth > window.innerHeight
    : window.orientation as number % 90 === 0;
  const images = portrait ? horizontal : vertical;
  return imagesOrder.map(([key, idx]) => images[key][idx]);
};
