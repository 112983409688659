/**
 * Helper to convert slice of color hex representation into dec
 * @param color Color hex representation without leading dash
 * @param len Slice length
 * @returns Method to feed with slice index
 */
const pick = (
  color: string,
  len: number,
) => (
  pos: number,
): number => (color.length < (pos + 1) * len
  ? 255
  : parseInt(
      color.substring(pos * len, (pos + 1) * len),
      16,
    ) * (17 ** Math.abs(len - 2))
  );

type RGBA = [number, number, number, number];

/**
 * Convert color hex representation into RGBA array
 * @param color HEX color
 * @returns RGBA array
 */
export const hex2rgba = (color: string): RGBA => {
  // consider non-existing color as a transparent one
  if (!color) {
    return [0, 0, 0, 0];
  }

  let str = color;

  if (str[0] === '#') {
    str = str.substring(1);
  }

  const picker = pick(str, str.length <= 4 ? 1 : 2);

  return [0, 1, 2, 3].map(picker) as RGBA;
};

/**
 * Calculates color luminance
 * @param color HEX color
 * @returns Luminance value 0..1
 */
export const hex2luminance = (color: string): number => {
  const rgb = hex2rgba(color)
    .slice(0, 3)
    .map((v) => v / 255)
    .map((v) => (v <= 0.04045 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4));

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
};

/**
 * Detects does given color could be considered black or not
 * @param color HEX color
 * @returns Color is dark or not
 */
export const isColorDark = (color: string): boolean => hex2luminance(color) <= 0.179;
