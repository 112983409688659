import * as React from 'react';

import {
  ApplicationContext,
} from 'common/components/ApplicationProvider/ApplicationContext';

import { UseHandleQueryType } from './types';

export const useHandleQuery: UseHandleQueryType = function useHandleQuery(...data): boolean {
  const { setLoading, setError } = React.useContext(ApplicationContext);

  const isLoading = data.some((d) => d.isLoading);

  const err: Error | undefined = data.reduce(
    (p, { error }) => (error && error?.status !== 400 ? [...p, error] : p),
    [],
  ).shift();

  React.useEffect(() => {
    if (isLoading) {
      setLoading(true);
      return (): void => setLoading(false);
    }
  }, [setLoading, isLoading]);

  React.useEffect(() => {
    setError(err);
  }, [err, setError]);

  return isLoading;
};
