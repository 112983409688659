import { ReactComponent as Record } from '@mdi/svg/svg/record.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { GameQAStatus } from 'weplayed-typescript-api';

import { isGameLive, isGameProcessing } from 'common/utils/games';

import * as s from './GameStatusLabel.m.less';
import { GameStatusLabelProps } from './types';

export const GameStatusLabel: React.FC<GameStatusLabelProps> = function GameStatusLabel({
  className,
  collapse = true,
  game,
  edge = true,
  onClick,
  text = true,
}) {
  if (game.review_qa_status === GameQAStatus.DONE) {
    return null;
  }

  const $live = isGameLive(game);
  const $processing = isGameProcessing(game);

  const title = ($live && 'Live') || ($processing && 'Processing');

  if (!title) {
    return null;
  }

  const cn = cx(s.root, collapse && s.collapse, text && s.text, className);

  const children = [
    <Record
      key="icon"
      className={($live && (edge ? s.liveOnline : s.liveOffline)) || ($processing && s.processing)}
    />,
    <span key="text">{title}</span>,
  ];

  return onClick
    ? <button title={title} type="button" className={cn} onClick={onClick}>{children}</button>
    : <div title={title} className={cn}>{children}</div>;
};
