import * as cx from 'classnames';
import * as React from 'react';

import * as s from './CheckboxList.m.less';
import { Props, Values } from './types';

export const CheckboxList: React.FunctionComponent<Props> = function CheckboxList({
  filter, items, onChange, onItemChange, values = {}, className, ...props
}) {
  const handleChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
      const newValues = items.reduce((val, item): Values => {
        if (value !== item.id) {
          // eslint-disable-next-line no-param-reassign
          val[item.id] = values[item.id];
        } else {
          // eslint-disable-next-line no-param-reassign
          val[item.id] = onItemChange ? onItemChange(item.id, values[item.id]) : !values[item.id];
        }
        return val;
      }, {});
      onChange(newValues);
    },
    [items, values],
  );

  return (
    <div {...props} className={cx(s.root, className)}>
      {items
        .filter(
          (item) => !filter || item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
        )
        .map((item) => (
          <label
            key={item.id}
            className={s.entry}
          >
            <span className={cx({
              'form-control': true,
              [s.multiple]: values[item.id] === null,
              [s.checked]: values[item.id],
            })}
            >
              <input type="checkbox" value={item.id} onChange={handleChange} />
            </span>
            {item.name}
          </label>
        ))}
    </div>
  );
};
