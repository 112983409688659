import * as React from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useProfile } from 'common/hooks/useProfile';

import { CLIENT_URLS } from 'consumer/routes';

import { UrlParams } from './types';

export const RegistrationConfirm: React.FunctionComponent = function RegistrationConfirm() {
  const { key } = useParams<UrlParams>();

  const { registration_confirm: [update, data] } = useProfile();

  // ignore any error
  useHandleQuery({ isLoading: data.isLoading });

  React.useEffect(() => {
    update({ key });
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>WePlayed: Sign Up</title>
        <meta name="description" content="Sign Up" />
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          {data.isLoading && (
            <h1>Confirm Email</h1>
          )}
          {data.error && data.error.status === 400 && (
            <>
              <h1>Email Confirm Error</h1>
              <p>Sorry we cannot confirm your registration. Is link expired?</p>
            </>
          )}
          {!data.isLoading && !data.isError && (
            <>
              <h1>Email Confirmed</h1>
              <p>Thank you. We have confirmed your email address. </p>
              <Button
                size="lg"
                as={Link}
                to={CLIENT_URLS.AUTH.LOGIN.toPath()}
              >
                Sign In
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
