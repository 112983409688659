import { AnnotationObjectsType } from '../../types';
import GearImg from './files/annotation_focus_gear_red.svg';

const animated: AnnotationObjectsType = {
  circle: {
    scale: true,
    rotate: false,
    src: GearImg,
    width: 200,
    height: 200,
    defaultSize: 0.1,
    preview: GearImg,
  },
};

export default animated;
