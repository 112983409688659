import * as s from './GameTimeline.m.less';

export const MAX_ZOOM = 20;
export const ZOOM_STEP = 0.1;
export const MAX_LABELS_PER_POPUP = 5;
export const FOCUS_TIMEOUT = 300;

export const MARKER_SIZE = parseInt(s.markerGroupWidth, 10);
export const MARKER_SPACING = parseInt(s.markerSpacing, 10);
export const TIMELINE_COLLAPSED = s.timelineCollapsedHeight;
