import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Registration as Reg } from './Registration';
import { UrlProps } from './types';

export const Registration: React.FC = function Registration() {
  const { invitation_pk, org_slug } = useParams<UrlProps>();

  if (invitation_pk && org_slug) {
    window.location.replace(`https://${org_slug}.weplayed.com/registration/${invitation_pk}`);
    return null;
  }

  return <Reg />;
};
