import { Moment } from 'weplayed-typescript-api';

import { MAX_LABELS_PER_POPUP } from './constants';

/**
 * Calculates position of marker inside timeline
 * Returns null if position is not in between current timeline bounds.
 * @param position Current video position in seconds
 * @param controlLength Width of timeline element in pixels
 */
export const getXOffset = (
  position: number,
  length: number,
  controlLength: number,
): number | null => {
  if (position <= length && position >= 0 && controlLength > 0) {
    return Math.floor((position / length) * controlLength);
  }

  return null;
};

/**
 * Fetch first MAX_LABELS_PER_POPUP labels grouping by label type in sequence
 */
export const combineEvents = (
  group: Moment[],
  max: number = MAX_LABELS_PER_POPUP,
): string[] => group.reduce(
  (labels: string[], moment: Moment, _i: number, moments: Moment[]): string[] => {
    let ret: string[] = labels;

    if (Boolean(moment.primary_label) && ret[ret.length - 1] !== moment.primary_label) {
      ret = [...ret, ret.length === max ? '…' : moment.primary_label];
      if (ret.length > max) {
        moments.splice(max - 1);
      }
    }

    return ret;
  },
  [],
);
