import { InfiniteQueryResult, QueryResult } from 'react-query';
import {
  Channel, Collection, HttpError, Moment, PaginatedResponse,
} from 'weplayed-typescript-api';

export interface UseChannelReturnType {
  moments: {
    curated: InfiniteQueryResult<PaginatedResponse<Moment>, HttpError>;
    liked: InfiniteQueryResult<PaginatedResponse<Moment>, HttpError>;
  };
  playlists: {
    created: InfiniteQueryResult<PaginatedResponse<Collection>, HttpError>;
    liked: InfiniteQueryResult<PaginatedResponse<Collection>, HttpError>;
  };
  summary: QueryResult<Channel, HttpError>;
}

export enum ChannelSources {
  MOMENTS_CURATED = 'moments.curated',
  MOMENTS_LIKED = 'moments.liked',
  PLAYLISTS_CREATED = 'playlists.created',
  PLAYLISTS_LIKED = 'playlists.liked',
}

interface UseChannelArgs {
  uid: string;
  search?: string;
  enabled?: ChannelSources | ChannelSources[];
}

export type UseChannelType = (args: UseChannelArgs) => UseChannelReturnType;
