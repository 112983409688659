import * as cx from 'classnames';
import * as React from 'react';

import * as s from './PlayPauseButton.m.less';
import { PlayPauseButtonProps } from './types';

const PlayPauseButton: React.FunctionComponent<PlayPauseButtonProps> = function PlayPauseButton({
  className,
  playing = false,
  ...rest
}) {
  return (
    <button
      className={cx(s.root, playing && s.playing, className)}
      data-test-id="play-pause-button"
      type="button"
      {...rest}
    >
      {playing ? 'Pause' : 'Play'}
    </button>
  );
};

const PlayPauseButtonMemo: React.FC<PlayPauseButtonProps> = React.memo(PlayPauseButton);

export { PlayPauseButtonMemo as PlayPauseButton };
