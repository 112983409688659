import { withRouter } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';
import { compose, withProps } from 'common/utils/recompose';

import { ContextProps } from './types';
import { Welcome } from './Welcome';

const withProfile = withProps<ContextProps>(() => {
  const { profile } = useAuth();
  return { profile };
});

const component = compose(
  withRouter,
  withProfile,
)(Welcome);

export { component as Welcome };
