import { defaultsDeepAll } from 'lodash/fp';
import { AnnotationPauseProps } from 'weplayed-typescript-api';

import { Base } from '../Base';

export class Pause extends Base<AnnotationPauseProps> {
  protected $timer: number;

  // eslint-disable-next-line no-use-before-define
  protected $onPause: (pause: Pause, position: number) => void;

  protected $onPlay: () => void;

  constructor(paper: RaphaelPaper, props: AnnotationPauseProps) {
    super(paper, defaultsDeepAll([props, { duration: 2 }]));
  }

  public onPause(callback?: (pause: Pause, position: number) => void): this {
    this.$onPause = callback;
    return this;
  }

  public onPlay(callback?: () => void): this {
    this.$onPlay = callback;
    return this;
  }

  public centerTo(): this {
    return this;
  }

  public timeEvent(position: number, editable: boolean, paused: boolean): void {
    if (paused || editable) {
      this.clear();
      return;
    }

    if (typeof this.$props.start === 'number' && typeof this.$props.duration === 'number') {
      if (position === this.$props.start && !this.$timer) {
        this.draw();
      } else if (this.$timer) {
        this.clear();
      }
    }
  }

  public clear(): this {
    if (this.$timer) {
      window.clearTimeout(this.$timer);
      this.$timer = undefined;
      if (this.$onPlay) {
        this.$onPlay();
      }
    }

    return this;
  }

  public draw(): this {
    if (!this.$timer) {
      this.$onPause(this, this.props.start);
      this.$timer = window.setTimeout(
        () => {
          this.$timer = undefined;
          if (this.$onPlay) {
            this.$onPlay();
          }
        },
        this.props.duration * 1000,
      );
    }

    return this;
  }
}
