import { RouteProps } from 'react-router-dom';

export enum RouteGroup {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
}

export interface Props extends RouteProps {
  groups?: RouteGroup[];
}
