import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  title: string;
  options: Array<string | number> | Record<string, string>;
  current?: string;
  onSelect?: (option: string | number) => void;
  onCancel?: () => void;
}

export const SingleSelectionModal: React.FC<Props> = function SingleSelectionModal({
  current,
  onCancel,
  onSelect,
  options,
  title,
}) {
  const opts = React.useMemo(
    () => (Array.isArray(options)
      ? Object.fromEntries(options.map((o) => [o, o]))
      : options),
    [options],
  );

  return (
    <Modal
      show
      keyboard
      onHide={onCancel}
      onEscapeKeyDown={onCancel}
      backdropClassName="modal-backdrop-blackout"
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(opts).map(([key, value]) => (
          <React.Fragment key={key}>
            <Button
              variant="primary"
              className="single-selection-modal-row"
              id={`single-selection-option-${encodeURIComponent(key)}`}
              disabled={current.toString().toLowerCase().trim()
                        === key.toString().toLowerCase().trim()}
              onClick={(): void => {
                onSelect(key);
              }}
            >
              {value}
            </Button>
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
