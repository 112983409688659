import { useAuth } from 'common/hooks/useAuth';
import { withProps } from 'common/utils/recompose';

import { OuterProps, StateProps } from './types';

export const withUserId = withProps<StateProps, OuterProps>(() => {
  const { profile } = useAuth();

  return { userId: profile?.pk };
});
