import { Debug } from './debug';
import { persistent } from './persistent';

const debug = Debug.extend('common/utils/analytics');

const w = window;
const LS_ENABLE_FULLSTORY = 'use-fullstory';
const FULLSTORY_THROTTLE = 1; // 1.0 is 100% of users

export interface UserAnalyticsProfile {
  visitor_id: string;
  db_id?: string;
  email?: string;
  username?: string;
  full_name?: string;
  is_staff?: boolean;
  is_cms_user?: boolean;
  is_curator?: boolean;
}

export const init = (): void => {
  debug('Initialize analytics');

  if (w.ga) {
    if (!persistent.hasCookies) {
      // we're in jail, go with local storage
      const name = 'GAclientId';
      const clientId = persistent.get(name);
      if (clientId) {
        w.ga('create', process.env.GOOGLE_ANALYTICS, { storage: 'none', clientId });
      } else {
        w.ga('create', process.env.GOOGLE_ANALYTICS, { storage: 'none' });
        w.ga((tracker): void => {
          persistent.set(name, tracker.get('clientId'));
        });
      }
    } else {
      w.ga('create', process.env.GOOGLE_ANALYTICS, 'auto');
    }

    debug('GA initialized');
  }

  if (w.analytics && w.analytics.load) {
    w.analytics.load(process.env.SEGMENT);
    debug('SEGMENT initialized');
  }

  if (w.fbq) {
    w.fbq('init', process.env.FACEBOOK_PIXEL);
    debug('FB initialized');
  }
};

let fullstoryEnabled = false;

export const initFullStory = (force = false): void => {
  if (fullstoryEnabled || !process.env.FULLSTORY_ORG_ID) {
    return;
  }

  debug('Initialize FullStory');

  let enabled = false;

  try {
    enabled = JSON.parse(persistent.get(LS_ENABLE_FULLSTORY));
  } catch (e) {
    //
  }

  if (typeof enabled !== 'boolean' || force) {
    fullstoryEnabled = force || Math.random() <= FULLSTORY_THROTTLE;
    persistent.set(LS_ENABLE_FULLSTORY, JSON.stringify(fullstoryEnabled));
  } else {
    fullstoryEnabled = enabled;
  }

  if (!fullstoryEnabled) {
    return;
  }

  /* eslint-disable */
  w['_fs_debug'] = false;
  w['_fs_host'] = 'fullstory.com';
  w['_fs_script'] = 'edge.fullstory.com/s/fs.js';
  w['_fs_org'] = process.env.FULLSTORY_ORG_ID;
  w['_fs_namespace'] = 'FS';
  if (process.env.FULLSTORY_ORG_ID && !w[w['_fs_namespace']]) {
    (function(m: any,n,e,t,l,o,g,y){
      if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+w['_fs_script'];
      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      g.log = function(a,b) { g("log", [a,b]) };
      g.consent=function(a){g("consent",!arguments.length||a)};
      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      g.clearUserCookie=function(){};
    })(w,document,w['_fs_namespace'],'script','user');
  }
  debug('FullStory initialized');
  /* eslint-enable */
};

export const identify = (
  visitorId: string,
  profile: UserAnalyticsProfile,
): void => {
  debug('identify', visitorId, profile);

  if (w.ga) {
    w.ga('set', profile);
  }

  if (w.analytics) {
    w.analytics.identify(visitorId, profile);
  }

  /* eslint-disable dot-notation */
  if (w[w['_fs_namespace']] && profile?.email) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (w as any)[w['_fs_namespace']].identify(
      profile.db_id || profile.email,
      {
        email: profile.email,
        username: profile.username,
      },
    );
  }
  /* eslint-enable dot-notation */
};

export const pageView = (): void => {
  const location = window.location.pathname + window.location.search;
  debug('pageView', location);

  if (w.ga) {
    w.ga('send', 'pageview', location);
  }

  if (w.analytics) {
    w.analytics.page();
  }

  if (w.fbq) {
    w.fbq('track', 'PageView');
  }
};

export interface TrackingEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

export const event = ($event: TrackingEvent): void => {
  debug('event', $event);

  if (w.ga) {
    w.ga('send', 'event', $event.category, $event.action, $event.label, $event.value);
  }

  if (w.analytics) {
    w.analytics.track(`${$event.category}/${$event.action}`, {
      label: $event.label,
      value: $event.value,
    });
  }

  if (w.fbq) {
    if ($event.category === 'Account' && $event.action === 'Registered') {
      w.fbq('track', 'CompleteRegistration', {});
    }

    if ($event.category === 'Curation' && $event.action === 'Moment Created') {
      w.fbq('track', 'CustomizeProduct', {});
    }

    if ($event.category === 'Account' && $event.action === 'Email Provided') {
      w.fbq('track', 'Lead', {});
    }
  }
};
