import './UserHighlight.less';

import * as React from 'react';
import { Link } from 'react-router-dom';
import { User } from 'weplayed-typescript-api';

import { SYSTEM_USERS } from 'common/constants';

import { Avatar } from 'consumer/components/Avatar';
import { CLIENT_URLS } from 'consumer/routes';

import { Props } from './types';

const renderContent = (user: User, label: string, semicolon: boolean): JSX.Element => (
  <>
    <Avatar user={user} />
    <div className="user-highlight-label-and-name">
      {label && <div className="user-highlight-label">{label}</div>}
      <div className="user-highlight-name">
        {user.full_name}
        {semicolon && ':'}
      </div>
    </div>
  </>
);

export const UserHighlight: React.FC<Props> = function UserHighlight({
  disableLink = false, label, semicolon, user, linkNewWindow,
}) {
  if (!user || !user.pk || SYSTEM_USERS.includes(user.pk)) {
    return null;
  }

  const className = 'user-highlight';

  if (disableLink || SYSTEM_USERS.includes(user?.pk)) {
    return (
      <div className={className}>
        {renderContent(user, label, semicolon)}
      </div>
    );
  }

  return (
    <Link
      className={className}
      target={linkNewWindow ? '_blank' : undefined}
      to={CLIENT_URLS.USER.CHANNEL.toPath({ uid: user.pk })}
    >
      {renderContent(user, label, semicolon)}
    </Link>
  );
};
