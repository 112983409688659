/**
 * Is app running on APP domain?
 */
export const IS_APP = (): boolean => window.location.host === process.env.APP_SITE_NAME;

/**
 * CMS base domain
 */
export const CMS_DOMAIN = (): string => process.env.CMS_SITE_NAME.split('.').slice(1).join('.');

const getDomain = (): string => window.location.host.split('.').slice(1).join('.');

const getHostName = (): string => window.location.host.split('.').shift();

/**
 * Is app running on CMS domain?
 */
export const IS_CMS = (): boolean => !IS_APP() && (
  window.location.host === process.env.CMS_SITE_NAME
  || (CMS_DOMAIN() === getDomain() && getHostName() !== 'www')
);

/**
 * CMS slug name taken from URL
 */
export const CMS_CONTEXT = (): string | undefined => (
  IS_CMS() && window.location.host !== process.env.CMS_SITE_NAME ? getHostName() : undefined
);
