import {
  BaseEntityWithPk, EntityWithFollow, EntityWithLike, HttpError, ID,
} from 'weplayed-typescript-api';

export const queryRetry = <E>(fails: number, error: E): boolean => {
  if (error instanceof HttpError && error.status === 404) {
    return false;
  }

  return fails < 2;
};

export const updateItem = <T extends BaseEntityWithPk>(
  uids: ID[],
  update: Partial<T> | ((item: T) => Partial<T> | undefined),
) => (
  item: T,
): T => {
  if (!uids.includes(item.pk)) {
    return item;
  }

  const up = typeof update === 'function' ? update(item) : update;
  if (!up) {
    return item;
  }

  return {
    ...item,
    ...up,
  };
};

export const updateRemove = <T extends BaseEntityWithPk>(
  uids: ID[],
// eslint-disable-next-line react/function-component-definition
) => (
  item: T,
): T | null => (uids.includes(item.pk) ? null : item);

export const updateLike = <T extends EntityWithLike>(
  uids: ID[],
  like: boolean,
) => (
  item: T,
): T => {
  const update = (m: T): T | undefined => (
    m.liked_by_user !== like
      ? { liked_by_user: like } as T
      : undefined
  );

  return updateItem<T>(uids, update)(item);
};

export const updateFollow = <T extends EntityWithFollow>(
  uids: ID[],
  follow: boolean,
) => (
  item: T,
): T => {
  const update = (m: T): Partial<T> | undefined => (
    m.followed_by_user !== follow
      ? {
        followed_by_user: follow,
      } as Partial<T>
      : undefined
  );

  return updateItem<T>(uids, update)(item);
};
