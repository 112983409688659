import { Size } from 'common/types';

/**
 * Changes URL to logo image depending on desired size
 * @param path URL to logo image
 * @param size Desired logo size
 * @returns URL to resized logo image
 */
export const logoPath = (path: string, size: Size): string => {
  const imagesize = size.includes('small') ? 50 : 200;
  return path.replace(/([^/]+)$/, `${imagesize}/$1`);
};
