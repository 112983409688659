import { useAuth } from 'common/hooks/useAuth';
import { withProps } from 'common/utils/recompose';

import { Search } from './Search';

const withUser = withProps(() => {
  const { profile: user } = useAuth();
  return { user };
});

const SearchRouter = withUser(Search);

export { SearchRouter as Search };
