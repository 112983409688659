import { ReactComponent as Magnify } from '@mdi/svg/svg/magnify.svg';
import * as cx from 'classnames';
import { ReactComponent as Spinner } from 'images/spinner.svg';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { hasTouch } from 'common/utils/features';

import { toSearchUrl } from 'consumer/components/Search/utils';

import * as s from './SearchBox.m.less';
import { Props } from './types';

export const SearchBox = React.forwardRef<HTMLInputElement, Props>((
  {
    autofocus, button = true, header, value,
    min = 2, path, params = {},
    className, loading, placeholder, onChange, onSubmit,
  },
  forwardedRef: React.RefObject<HTMLInputElement>,
): React.ReactElement => {
  const ref = forwardedRef || React.createRef();
  const history = useHistory();
  const urlParams = useParams();

  const isTermValid = React.useCallback((text) => (text?.trim().length || 0) >= min, [min]);

  const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { value: text } = ref?.current || {};

    if (!isTermValid(text)) {
      toast.warn(`Please enter at least ${min} characters`);
      return;
    }

    if (onSubmit) {
      onSubmit(text);
    } else if (path) {
      history.push(toSearchUrl(path, urlParams, { ...params, q: text }));
    }

    if (ref?.current && hasTouch) {
      ref.current.blur();
    }
  }, [ref, ref.current, onSubmit, min, path, history, params]);

  const handleChange = React.useCallback(
    ({ target: { value: changed } }: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(changed);
      }
    },
    [onChange, value],
  );

  return (
    <form
      method="get"
      action={path?.toPath(urlParams)}
      className={cx(s.searchBox, !button && s.noButton, className)}
      onSubmit={handleSubmit}
    >
      {header && (
        <h1>{header}</h1>
      )}

      <input
        autoFocus={autofocus}
        autoComplete="off"
        autoCorrect="off"
        id="input-search-term"
        maxLength={100}
        onChange={handleChange}
        placeholder={placeholder}
        ref={ref}
        required
        type="search"
        value={value}
      />
      {button && (
        <Button
          type="submit"
          variant="primary"
          id="buttons-search-submit"
        >
          {loading ? <Spinner /> : <Magnify />}
        </Button>
      )}
    </form>
  );
});

SearchBox.displayName = 'SearchBox';
