import * as React from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { AdsSpecConfig, Moment, moments as api } from 'weplayed-typescript-api';

import {
  getLikeQueryData, getPublicationQueryData, getRemoveQueryData, isLikeQuery,
  isPublicationQuery, isRemoveQuery,
} from 'consumer/hooks/useMoments';

import { UseMomentArgs, UseMomentReturnType } from './types';
import { getKey, updateLike, updateMoment } from './utils';

export const useMoment = function useMoment<T extends AdsSpecConfig = AdsSpecConfig>({
  uid, enabled = true, ...options
}: UseMomentArgs): UseMomentReturnType<T> {
  const queryCache = useQueryCache();

  React.useEffect(() => queryCache.subscribe((qc, query) => {
    // update moments on like
    if (isLikeQuery(query)) {
      const { uid: momentId, like } = getLikeQueryData(query);
      const update = updateLike(momentId, like);

      qc.getQueries<Moment>(getKey()).forEach(({ queryKey: qk, state: { data: moment } }) => {
        if ((moment?.pk === momentId && moment.liked_by_user !== like)) {
          qc.setQueryData(qk, update(moment));
        }
      });
    // update moments on publication
    } else if (isPublicationQuery(query)) {
      const { uid: momentId, publication } = getPublicationQueryData(query);
      const update = updateMoment(momentId, publication);

      qc.getQueries<Moment>(getKey()).forEach(({ queryKey: qk, state: { data: moment } }) => {
        if (moment?.pk === momentId) {
          qc.setQueryData(qk, update(moment));
        }
      });
    } else if (isRemoveQuery(query)) {
      const { uid: momentId } = getRemoveQueryData(query);

      qc.getQueries<Moment>(getKey()).forEach(({ queryKey: qk, state: { data: moment } }) => {
        if (moment?.pk === momentId) {
          qc.setQueryData(qk, undefined);
        }
      });
    }
  }), [queryCache]);

  const moment: UseMomentReturnType<T>['moment'] = useQuery(
    getKey(uid, options),
    () => api.get({ uid, ...options, cached: window.weplayed_skip_auth || false }),
    {
      enabled: uid && enabled,
      refetchOnWindowFocus: false,
    },
  );

  return { moment };
};
