import { ReactComponent as PlaylistPlus } from '@mdi/svg/svg/playlist-plus.svg';
import { curry, isEqual, memoize } from 'lodash/fp';
import * as React from 'react';
import * as InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PaginatedResponse, What } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';
import { event } from 'common/utils/analytics';

import { channelRenderer } from 'consumer/components/ChannelTile';
import { collectionRenderer } from 'consumer/components/CollectionTile';
import { ErrorMessage } from 'consumer/components/ErrorMessage';
import { gameRenderer } from 'consumer/components/GameTile';
import { momentRenderer } from 'consumer/components/MomentTile';
import { playerRenderer } from 'consumer/components/PlayerTile';
import { TabbedContent } from 'consumer/components/TabbedContent';
import { Mode, ModeStates } from 'consumer/components/Tile/types';
import { ModeState } from 'consumer/components/TileList/types';
import { CollectionModal } from 'consumer/containers/CollectionModal';
import { useSearch } from 'consumer/hooks/useSearch';
import { Section } from 'consumer/hooks/useSearch/types';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './Search.m.less';
import { SearchResults } from './SearchResults';
import { Props, QueryData } from './types';
import {
  parseSearchParams, toSearchParams, toSearchUrl, toTabLabel,
} from './utils';

function getData<T>(data: PaginatedResponse<T>[] = []) {
  return (): { items: T[]; total: number } => ({
    items: data?.reduce((vv, { items: v }) => [...vv, ...v], []),
    total: data?.[0]?.total,
  });
}

const tabNames = Object.values(Section);

export const Search: React.FC<Props> = function Search({
  query: text,
  url = CLIENT_URLS.SEARCH.PAGE,
  onChange,
  onLoading,
  children,
  params,
}) {
  const location = useLocation();
  const history = useHistory();
  const urlParams = useParams();
  const { profile } = useAuth();
  const authenticated = Boolean(profile);

  // query derived from location.search
  const [query, setQuery] = React.useState<QueryData>({
    ...params,
    q: text || '',
  });

  // current tab
  const [tab, setTab] = React.useState<Section>(Section.MOMENTS);

  // tab modes
  const [modes, setModes] = React.useState<{ [K in Section]?: ModeStates }>({});

  // tab mode states
  const [states, setStates] = React.useState<{ [W in Section]?: ModeState }>({
    moments: { select: [] },
  });

  // collections modal on moments tab
  const [showCollectionsModal, setShowCollectionsModal] = React.useState(false);

  // stored queries between tab switches
  const [queries, setQueries] = React.useState<{ [K in Section]?: QueryData }>({});

  // Listen to change location search
  React.useEffect(() => {
    const [parsedQuery, parsedTab] = parseSearchParams(location.search, params);

    if (!isEqual(parsedQuery, query)) {
      if (parsedQuery.q) {
        setQueries({ ...queries, [parsedTab]: parsedQuery });
        setQuery(parsedQuery);
      } else {
        setQueries({});
        setQuery({ q: '' });
      }
    }

    setTab(parsedTab);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, params]);

  // listen to change text and params properties and
  // update location
  React.useEffect(() => {
    if (text && text !== query.q) {
      history.push(toSearchUrl(url, urlParams, { q: text }));
    } else if (!text && query.q) {
      history.push(toSearchUrl(url, urlParams));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  // trigger onChange and cleanup modes
  React.useEffect(() => {
    if (onChange) {
      onChange(query, tab);
    }
    setModes({
      ...modes,
      [tab]: {},
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, tab]);

  // send search event
  React.useEffect(() => {
    if (query.q && tab) {
      event({
        category: 'Search',
        action: 'Searched For',
        label: `${tab}: ${query.q}`,
      });
    }
  }, [query.q, tab]);

  const {
    summary,
    moments,
    playlists,
    games,
    channels,
    players,
  } = useSearch({
    query: toSearchParams(query),
    enabled: tab,
  });

  // hide collections modal on tab change
  React.useEffect(
    () => {
      if (tab !== Section.MOMENTS && showCollectionsModal) {
        setShowCollectionsModal(false);
      }
    },
    [tab, showCollectionsModal],
  );

  // Change tab to one available with results
  React.useEffect(() => {
    if (query.q && summary.data) {
      const available = tabNames.filter((w) => summary.data[w] !== 0);
      const whatTab = available.includes(tab) ? tab : available[0];

      // switch to non-empty tab
      if (whatTab && tab !== whatTab) {
        history.replace(toSearchUrl(url, urlParams, query, whatTab));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, tab, summary.data]);

  // trigger onLoading on stats change
  React.useEffect(() => {
    if (onLoading) {
      onLoading(summary.isLoading);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary.isLoading]);

  // cleanup cache when query text changed
  React.useEffect(() => setQueries({}), [summary.data]);

  const handleTabChange = React.useCallback((newTabIdx) => {
    const newTab = tabNames[newTabIdx];

    if (newTab !== tab) {
      const { q } = query;
      history.push(toSearchUrl(url, urlParams, queries[newTab] || { q }, newTab));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, history, query, queries, url]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeQuery = React.useCallback(
    curry((t, q) => {
      setQueries({ ...queries, [t]: q.q ? q : undefined });
      const to = q.q
        ? toSearchUrl(url, urlParams, q, t)
        : toSearchUrl(url, urlParams);
      history.push(to);
    }),
    [url, queries],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleMode = React.useCallback(
    curry((what: Section, mode: Mode, _e): void => {
      setModes({
        ...modes,
        [what]: {
          ...(modes?.[what]),
          [mode]: !modes?.[what]?.[mode],
        },
      });
      if (!modes?.[what]?.[mode]) {
        setStates({
          ...states,
          [what]: {
            ...states[what],
            [mode]: [],
          },
        });
      }
    }),
    [modes, setModes],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateStates = React.useCallback(
    curry((what: Section, state: ModeStates): void => {
      setStates({
        ...states,
        [what]: state,
      });
    }),
    [states, setStates],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSetCollectionsModal = React.useCallback(
    memoize((state: boolean) => (success: boolean): void => {
      setShowCollectionsModal(state);
      if (!state && success) {
        // clear selections and disable select mode
        setModes({ ...modes, moments: { ...modes.moments, select: false } });
        setStates({ ...states, moments: { ...states.moments, select: [] } });
      }
    }),
    [modes, states, setModes, setStates],
  );

  const hasResults = Object.values(summary.data || {}).some(Boolean);

  /* eslint-disable react-hooks/exhaustive-deps */
  const data = {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Section.MOMENTS]: React.useMemo(getData(moments.data.data), [moments.data.data]),
    [Section.PLAYLISTS]: React.useMemo(getData(playlists.data.data), [playlists.data.data]),
    [Section.GAMES]: React.useMemo(getData(games.data.data), [games.data.data]),
    [Section.PLAYERS]: React.useMemo(getData(players.data.data), [players.data.data]),
    [Section.CHANNELS]: React.useMemo(getData(channels.data.data), [channels.data.data]),
  };
  /* eslint-enable react-hooks/exhaustive-deps */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleSelectAll = React.useCallback(
    curry((type: Section, _e) => {
      setStates({
        ...states,
        [type]: {
          ...states?.[type],
          select: (states?.[type]?.select?.length || 0) === 0
            ? [...data.moments.items.map((d) => d.pk)]
            : [],
        },
      });
    }),
    [states, setStates, data.moments.items],
  );

  const tabs = React.useMemo(
    () => tabNames.map((section) => ({
      disabled: !summary.data?.[section],
      id: `tab-search-result-${section}`,
      label: toTabLabel(data[section].total ?? summary.data?.[section], section),
    })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      summary.data,
      data.moments.total,
      data.players.total,
      data.games.total,
      data.players.total,
      data.channels.total,
    ],
  );

  return (
    <>
      <ErrorMessage error={summary.error} />
      {showCollectionsModal && (
        <CollectionModal
          moments={states.moments.select}
          onClose={handleSetCollectionsModal(false)}
        />
      )}

      {(hasResults && !summary.error && query?.q && tab && (
        <TabbedContent
          tabClassName={s.tab}
          tabsClassName={s.tabs}
          onChange={handleTabChange}
          tabs={tabs}
          tab={tabNames.indexOf(tab)}
        >
          <InfiniteScroll
            initialLoad={tab === Section.MOMENTS}
            loadMore={(): void => { moments.data.fetchMore(); }}
            pageStart={0}
            hasMore={
              tab === Section.MOMENTS
              && !moments.data.isFetchingMore
              && moments.data.canFetchMore
            }
          >
            <SearchResults<What.MOMENTS>
              appending={Boolean(moments.data.isFetchingMore)}
              count={data.moments.total}
              data={data.moments.items}
              error={moments.data.error}
              extraFilters={authenticated && (
                <div className={s.select}>
                  {modes.moments?.select && (
                    <>
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled={states.moments.select.length === 0}
                        onClick={handleSetCollectionsModal(true)}
                      >
                        <PlaylistPlus />
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={toggleSelectAll('moments')}
                      >
                        {states.moments.select.length === 0 ? 'SELECT ALL' : 'DESELECT ALL'}
                      </button>
                    </>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={toggleMode('moments', 'select')}
                    type="button"
                  >
                    {modes?.moments?.select ? 'CANCEL' : 'SELECT'}
                  </button>
                </div>
              )}
              facets={moments.facets.data}
              loading={moments.data.isLoading}
              modes={modes.moments}
              name="moment"
              onChange={handleChangeQuery(Section.MOMENTS)}
              onStateChange={updateStates('moments')}
              pk="pk"
              query={query}
              renderer={momentRenderer}
              states={states.moments}
            />
          </InfiniteScroll>
          <InfiniteScroll
            initialLoad={tab === Section.PLAYLISTS}
            loadMore={(): void => { playlists.data.fetchMore(); }}
            pageStart={0}
            hasMore={
              tab === Section.PLAYLISTS
              && !playlists.data.isFetchingMore
              && playlists.data.canFetchMore
            }
          >
            <SearchResults
              count={data.playlists.total}
              data={data.playlists.items}
              error={playlists.data.error}
              facets={playlists.facets.data}
              loading={playlists.data.isLoading}
              name="playlist"
              onChange={handleChangeQuery(Section.PLAYLISTS)}
              pk="pk"
              query={query}
              renderer={collectionRenderer}
            />
          </InfiniteScroll>
          <InfiniteScroll
            initialLoad={tab === Section.GAMES}
            loadMore={(): void => { games.data.fetchMore(); }}
            pageStart={0}
            hasMore={
              tab === Section.GAMES
              && !games.data.isFetchingMore
              && games.data.canFetchMore
            }
          >
            <SearchResults
              count={data.games.total}
              data={data.games.items}
              error={games.data.error}
              facets={games.facets.data}
              loading={games.data.isLoading}
              name="game"
              onChange={handleChangeQuery(Section.GAMES)}
              pk="pk"
              query={query}
              renderer={gameRenderer}
            />
          </InfiniteScroll>
          <InfiniteScroll
            initialLoad={tab === Section.PLAYERS}
            loadMore={(): void => { players.data.fetchMore(); }}
            pageStart={0}
            hasMore={
              tab === Section.PLAYERS
              && !players.data.isFetchingMore
              && players.data.canFetchMore
            }
          >
            <SearchResults
              count={data.players.total}
              data={data.players.items}
              error={players.data.error}
              facets={players.facets.data}
              loading={players.data.isLoading}
              name="player"
              onChange={handleChangeQuery(Section.PLAYERS)}
              pk="pk"
              query={query}
              renderer={playerRenderer}
            />
          </InfiniteScroll>
          <InfiniteScroll
            initialLoad={tab === Section.CHANNELS}
            loadMore={(): void => { channels.data.fetchMore(); }}
            pageStart={0}
            hasMore={
              tab === Section.CHANNELS
              && !channels.data.isFetchingMore
              && channels.data.canFetchMore
            }
          >
            <SearchResults
              count={data.channels.total}
              data={data.channels.items}
              error={channels.data.error}
              facets={channels.facets.data}
              loading={channels.data.isLoading}
              name="channel"
              onChange={handleChangeQuery(Section.CHANNELS)}
              pk="user.pk"
              query={query}
              renderer={channelRenderer}
            />
          </InfiniteScroll>
        </TabbedContent>
      ))
      || (!summary.isLoading && !summary.error && !summary.data && children)
      || (summary.data && !hasResults && (
        <h2 className={s.empty}>Sorry, nothing found</h2>
      ))}
    </>
  );
};
