import * as React from 'react';

import { VideoPlayerProps, VideoPlayerRefType } from './types';
import { VideoPlayer } from './VideoPlayer';
import { withPlayerGuard } from './withPlayerGuard';

const GuardedVideoPlayer = React.forwardRef<
  VideoPlayerRefType, VideoPlayerProps
>(withPlayerGuard(VideoPlayer));

export { GuardedVideoPlayer as VideoPlayer };
