import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'consumer/components/Button';

import * as s from './NameDialog.m.less';
import { Props } from './types';

export const NameDialog: React.FC<Props> = function NameDialog({
  loading, name: oldName, title, onCancel, onSave, onValidate,
}) {
  const [name, setName] = React.useState(oldName);
  const handleSave = React.useCallback(() => {
    if (!loading && (!onValidate || onValidate(name))) {
      onSave(name);
    }
  }, [name, oldName, onSave]);

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent): void => {
    e.stopPropagation();
    e.nativeEvent.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (e.key === 'Enter') {
      handleSave();
    }

    if (e.key === 'Escape') {
      onCancel();
    }
  }, [onCancel, handleSave]);

  return (
    <Modal
      show
      keyboard
      onHide={onCancel}
      onEscapeKeyDown={onCancel}
      backdropClassName="modal-backdrop-blackout"
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          autoComplete="off"
          autoCorrect="off"
          disabled={loading}
          type="text"
          name="name"
          value={name}
          maxLength={255}
          onKeyDown={handleKeyDown}
          onChange={
            ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => setName(value)
          }
          onFocus={({ target }: React.FocusEvent<HTMLInputElement>): void => target.select()}
          className={s.input}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          loading={loading}
          disabled={loading || (onValidate && !onValidate(name))}
          onClick={handleSave}
        >
          Ok
        </Button>
        <Button
          disabled={loading}
          variant="default"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
