import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useHandleQuery } from 'common/hooks/useHandleQuery';

import { useClipper } from 'consumer/hooks/useClipper';

import { ClipperView as ClipperViewComponent } from './ClipperView';
import { UrlParams } from './types';

export const ClipperView: React.FunctionComponent = function ClipperView() {
  const { uid } = useParams<UrlParams>();
  const location = useLocation();
  const { data } = useClipper(uid);

  useHandleQuery(data);

  return <ClipperViewComponent data={data.data} location={location} />;
};
