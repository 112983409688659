import { format as formatFns } from 'date-fns';
import { isNumber } from 'lodash/fp';

export const dateFormat = 'MMM d, yyyy';
export const timeFormat = 'h:mm aa zzz';
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const formatDate = (
  date: Date | number | string,
  format = dateFormat,
  text = '—',
): string => {
  if (!date) {
    return text;
  }

  let parsedDate;
  if (typeof date === 'string') {
    parsedDate = new Date(date);
    parsedDate = new Date(parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000);
  } else {
    parsedDate = date;
  }
  return formatFns(parsedDate, format);
};

export const expandSeason = (season: string | number): string => `${season}-${String(+season + 1).substring(2)}`;

interface DateValues {
  da?: number;
  ho?: number;
  mi?: number;
  mo?: number;
  ms?: number;
  se?: number;
  ye?: number;
  time?: 'b' | 'e';
}

export const setDateTime = (
  date: Date,
  { ye, mo, da, ho, mi, se, ms, time }: DateValues,
): Date => {
  const newDate = new Date(date);

  if (isNumber(ye)) { newDate.setFullYear(ye); }
  if (isNumber(mo)) { newDate.setMonth(mo); }
  if (isNumber(da)) { newDate.setDate(da); }
  if (time === 'b') {
    newDate.setHours(0, 0, 0, 0);
  }
  if (time === 'e') {
    newDate.setHours(23, 59, 59, 999);
  }
  if (isNumber(ho)) { newDate.setHours(ho); }
  if (isNumber(mi)) { newDate.setMinutes(mi); }
  if (isNumber(se)) { newDate.setSeconds(se); }
  if (isNumber(ms)) { newDate.setMilliseconds(ms); }

  return newDate;
};
