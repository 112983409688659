import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';
import { useProfile } from 'common/hooks/useProfile';
import { getRedirectUrl } from 'common/utils/redirects';

import { Button } from 'consumer/components/Button';
import FormField from 'consumer/components/Field/FormField';
import NonFieldErrors from 'consumer/components/Field/NonFieldErrors';
import { CLIENT_URLS } from 'consumer/routes';

import { Form } from './types';

export const UpdateEmail: React.FunctionComponent = function UpdateEmail() {
  const location = useLocation();
  const { profile: user } = useAuth();
  const { saveProfile: [update, { isLoading: isUpdating, error }] } = useProfile();

  const handleSubmit = React.useCallback((e: React.FormEvent<Form>) => {
    e.preventDefault();

    update({ pk: user.pk, email: e.currentTarget.elements.email.value });
  }, [update, user?.pk]);

  const errors = error?.json;

  return (
    <div className="row">
      <Helmet>
        <title>WePlayed: Update Email</title>
      </Helmet>
      <div className="col-lg-6 offset-lg-3">
        <h1>THANK YOU</h1>

        {!isUpdating && user?.email && (
          <>
            <p>Your email preferences updated successfuly.</p>
            <Link to={getRedirectUrl(location, CLIENT_URLS.HOME.DISCOVER_DEFAULT.buildPath())}>
              <Button
                type="button"
                size="lg"
                variant="primary"
              >
                Continue
              </Button>
            </Link>
          </>
        )}

        {!user.email && (
          <>
            <p>We could not acquire your email address from FB, please enter it below:</p>

            <form onSubmit={handleSubmit}>
              <NonFieldErrors errors={errors} />
              <FormField
                label="Username"
                name="username"
              >
                <input
                  type="text"
                  value={user.username}
                  className="form-control"
                  readOnly
                />
              </FormField>
              <FormField
                label="Full Name"
                name="full_name"
              >
                <input
                  type="text"
                  name="full_name"
                  value={user.full_name}
                  className="form-control"
                  readOnly
                />
              </FormField>
              <FormField
                label="Email"
                name="email"
                errors={errors}
                required
              >
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required
                />
              </FormField>

              <div className="profile-form-save-button-container">
                <Button type="submit" size="lg" loading={isUpdating} disabled={isUpdating}>
                  Save
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
