import * as React from 'react';

import { TeamLogo } from 'consumer/components/TeamLogo';

import * as s from './PlayerPosition.m.less';
import { Props } from './types';

export const PlayerPosition: React.FC<Props> = function PlayerPosition({
  link,
  logo,
  player,
  size,
}) {
  return (
    <div
      className={s.position}
      key={player.pk}
    >
        {logo && player.team && (
          <TeamLogo
            className={s.logo}
            disableLink={!link}
            team={player.team}
            size={size}
          />
        )}
        <h2>{player.number ? `#${player.number} ${player.position}` : `(${player.position})`}</h2>
    </div>
  );
};
