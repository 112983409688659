import * as React from 'react';
import { HttpError } from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';

import { Page403 } from './403';
import { Page404 } from './404';
import { Page500 } from './500';
import { Props } from './types';

const getErrorComponent = (error?: Error): React.FC | null => (error instanceof HttpError && (
  (error.status === 403 && Page403)
  || (error.status === 404 && Page404)
  || (error.status >= 500 && Page500)
)) || null;

export const Errors: React.FC<Props> = function Errors({ children, error, render }) {
  const { broadcast } = useApplication();

  const Component = getErrorComponent(error);

  React.useEffect(() => {
    if (error && !Component) {
      if (error instanceof HttpError && error.status === 0) {
        broadcast('error', 'Network error');
      } else {
        broadcast('error', error.message);
      }
    }
  }, [broadcast, error, Component]);

  return Component
    ? <Component />
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <>{render ? render() : children}</>;
};
