import * as React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AvatarUpload } from 'common/components/AvatarUpload';
import { useProfile } from 'common/hooks/useProfile';
import { UpdateUser } from 'common/types';

import { Avatar } from 'consumer/components/Avatar';
import { Button } from 'consumer/components/Button';
import FormField from 'consumer/components/Field/FormField';
import NonFieldErrors from 'consumer/components/Field/NonFieldErrors';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './ProfileUpdate.m.less';
import { Form } from './types';

export const ProfileUpdate: React.FunctionComponent = function ProfileUpdate() {
  const history = useHistory();
  const {
    profile,
    saveProfile: [update, { isLoading: isUpdating, error }],
  } = useProfile();
  const [avatarUrl, setAvatarUrl] = React.useState<string>();

  const handleSubmit = React.useCallback((e: React.FormEvent<Form>) => {
    e.preventDefault();

    const updateUser = ['username', 'first_name', 'last_name', 'email'].reduce(
      (acc, key) => ({ ...acc, [key]: e.currentTarget.elements[key].value }),
      { new_avatar_url: avatarUrl } as UpdateUser,
    );

    update(updateUser, {
      onSuccess: async () => {
        toast.success('Profile successfully updated!');
        history.push(CLIENT_URLS.USER.MY_PROFILE.toPath());
      },
    });
  }, [avatarUrl, history, update]);

  const handleCancel = React.useCallback(() => history.goBack(), [history]);

  const errors = error?.json;

  return (
    <div className="container">
      <Helmet>
        <title>WePlayed: My Profile</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>USER PROFILE</h1>
          <form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <FormField
              label="Username"
              name="username"
              errors={errors}
              required
            >
              <input
                type="username"
                name="username"
                defaultValue={profile.username}
                className="form-control"
                required
              />
            </FormField>
            <FormField
              label="First name"
              name="first_name"
              errors={errors}
              required
            >
              <input
                type="text"
                name="first_name"
                defaultValue={profile.first_name}
                className="form-control"
                required
              />
            </FormField>
            <FormField
              label="Last name"
              name="last_name"
              errors={errors}
              required
            >
              <input
                type="text"
                name="last_name"
                defaultValue={profile.last_name}
                className="form-control"
                required
              />
            </FormField>
            <FormField label="Email" name="email" errors={errors} required>
              <input
                // type="email"
                name="email"
                defaultValue={profile.email}
                className="form-control"
                required
              />
            </FormField>

            <h4>Your Photo:</h4>

            <div className={s.avatarContainer}>
              <Avatar
                size="x-large"
                user={profile}
                avatarUrl={avatarUrl}
                hideVerified
              />
              <AvatarUpload onSuccess={setAvatarUrl} />
            </div>
            <div className={s.button}>
              <Button
                disabled={isUpdating}
                loading={isUpdating}
                size="lg"
                type="submit"
              >
                Save
              </Button>
              <Button
                disabled={isUpdating}
                onClick={handleCancel}
                size="lg"
                type="button"
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
