import * as cx from 'classnames';
import { curry, memoize, pick } from 'lodash/fp';
import { parse } from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AdsSpec, ShareAdsSpec } from 'weplayed-typescript-api';

import { MonetizationAd } from 'common/components/MonetizationAd';
import { useApplication } from 'common/hooks/useApplication';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useProfile } from 'common/hooks/useProfile';
import { EventContext } from 'common/utils/events';
import { getOwnTeam } from 'common/utils/games';
import { cleanPageTitle } from 'common/utils/seo';

import { MomentActionBarProvider } from 'consumer/components/MomentActionBar';
import {
  Action, MomentActionBarProviderRefType,
} from 'consumer/components/MomentActionBar/types';
import { MomentPresenter } from 'consumer/components/MomentPresenter';
import {
  MomentPresenterInstance,
} from 'consumer/components/MomentPresenter/types';
import { useMoment } from 'consumer/hooks/useMoment';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './Moment.m.less';
import { QueryParams, UrlParams } from './types';

const placementToClassName = (placement: ShareAdsSpec): string => placement.position
  .map((p) => {
    switch (p) {
      case 'left': return s.left;
      case 'right': return s.right;
      default: return '';
    }
  })
  .filter(Boolean)
  .join(' ');

const renderAds = (
  specs: AdsSpec<ShareAdsSpec>[] | undefined,
  type: 'top' | 'bottom',
): React.ReactNode => specs?.map((spec, idx) => (spec.placement.position.includes(type) ? (
  <div
    className={cx(s.ads, placementToClassName(spec.placement))}
    key={spec.id || idx}
  >
    <MonetizationAd config={spec} className={s.ad} />
  </div>
) : null));

export const Moment: React.FC = function Moment() {
  const location = useLocation();
  const history = useHistory();
  const { uid } = useParams<UrlParams>();
  const { teamNumber } = React.useMemo<QueryParams>(
    () => pick(['teamNumber'], parse(location.search)),
    [location.search],
  );

  const momentDispatcherRef = React.useRef<MomentActionBarProviderRefType>();
  const refs = React.useRef<Record<string, MomentPresenterInstance>>({});
  const instances = React.useRef<MomentPresenterInstance[]>([]);
  const scrollTimer = React.useRef(0);

  const {
    profile, subscription: { recent_team },
    setRecentTeam: [handleSetRecentTeam],
  } = useProfile();

  const { appCues: paused, strict, wlOrgId, registerAuthAware } = useApplication();

  const { moment } = useMoment<ShareAdsSpec>({
    uid,
    wl_org_id: wlOrgId,
    teamNumber,
    where: wlOrgId ? 'inSharePage' : undefined,
  });

  useHandleQuery(moment);

  const m = moment.data;

  const doAuthAware = Boolean(moment.isFetchedAfterMount && momentDispatcherRef);

  const authAwareCallback = React.useCallback((action: Action): void => {
    if (action?.momentPk) {
      momentDispatcherRef.current(action);

      // let scroll reach its target
      scrollTimer.current = window.setTimeout(() => {
        const ref = refs.current[action.momentPk];
        if (ref) {
          ref.ref.current.scrollIntoView();
        }
      }, 300);
    }
  }, []);

  const invokeAuthAware = registerAuthAware(authAwareCallback, doAuthAware);

  React.useEffect(() => clearTimeout(scrollTimer.current), []);

  React.useEffect(() => {
    if (!recent_team && profile?.org?.teams && m?.game) {
      const team = getOwnTeam(m.game, profile.org.teams);
      if (team) {
        handleSetRecentTeam(team);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, m?.game?.pk]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRef = React.useCallback(memoize(curry((momentPk, ref) => {
    refs.current[momentPk] = ref;
  })), [refs]);

  const handleAccept = React.useCallback(({ action, momentPk }: Action) => {
    if (action === 'delete' && momentPk === uid) {
      history.push(CLIENT_URLS.USER.MY_CHANNEL.toPath());
    }
  }, [history, uid]);

  if (!m || !moment.isFetchedAfterMount) {
    return null;
  }

  const { ads_spec } = m;

  return (
    <MomentActionBarProvider
      ref={momentDispatcherRef}
      onUnauthorized={invokeAuthAware}
      user={profile}
      onAccept={handleAccept}
    >
      <div className="moment-viewing-page">
        <Helmet>
          <title>{`WePlayed Moment: ${cleanPageTitle(m.name)}`}</title>
        </Helmet>
        <div className="moment-viewing-content">
          {renderAds(ads_spec, 'top')}
          <MomentPresenter
            autoPlay
            context={EventContext.MOMENT}
            hideActionBar={strict}
            hideAdjust
            hideCuratorHighlight={strict}
            instances={instances.current}
            key={m.pk}
            moment={m}
            paused={paused}
            ref={setRef(m.pk)}
            share={!!m.org}
            user={profile}
            withLinks={!strict}
            wlOrgId={wlOrgId}
          />

          {strict && m.org && (
            <a
              className="wl-button"
              style={{
                backgroundColor: `#${m.org.primary_color}`,
              }}
              href={`http://${m.org.athletics_website}`}
            >
              visit &nbsp;
              {m.org.athletics_website}
            </a>
          )}
          {renderAds(ads_spec, 'bottom')}
        </div>
      </div>
    </MomentActionBarProvider>

  );
};
