import { Registration } from 'common/hooks/useProfile/types';
import { MappedFieldErrors } from 'common/utils/errors';

export enum Stage {
  source,
  general,
  access,
}

type ReadOnlyFields = 'email' | 'username';

export interface Props {
  avatar?: boolean;
  errors?: MappedFieldErrors;
  loading?: boolean;
  onSubmit(stage: Stage, registration: Registration): void;
  org_slug?: string;
  readonly?: ReadOnlyFields[];
  registration?: Registration;
}
