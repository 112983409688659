import * as React from 'react';
import { Helmet } from 'react-helmet';

import * as s from './styles.m.less';

export const Page403: React.FC = function Page403() {
  return (
    <section className={s.root} aria-labelledby="page-403-header">
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <h1 id="page-403-header">
        Hands up!
      </h1>
      <p>You&apos;re not allowed to see this page!</p>
    </section>
  );
};
