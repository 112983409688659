import { Moment } from 'weplayed-typescript-api';

import { GameFull, GamePlayByPlay, GameSegment } from './types';

export const getKey = (uid: string, videoId?: string, cms?: string): string[] => [
  'gamefull', uid, cms, videoId,
].filter(Boolean);

export const getPbpKey = (uid: string, cms?: string): string[] => [
  'gamepbp', uid, cms,
].filter(Boolean);

export const injectMomentGame = (game: GameFull, moment: Moment): GameFull => {
  let { moments } = game;

  const momentIdx = moment.pk
    ? moments.findIndex(({ pk }) => pk === moment.pk)
    : -1;

  const nmoment = momentIdx !== -1
    ? { ...moments[momentIdx], ...moment }
    : { ...moment, likes: 0 };

  // moment is already in game
  if (momentIdx !== -1) {
    moments = [
      ...moments.slice(0, momentIdx),
      nmoment,
      ...moments.slice(momentIdx + 1),
    ];
  } else {
    // at this point we assume that all moments are already sorted by start descending
    const momentBefore = moments.find(({ start }) => start > nmoment.start);

    const momentBeforeIdx = momentBefore
      ? moments.indexOf(momentBefore)
      // last moment case covered
      : moments.length;
    moments = [
      ...moments.slice(0, momentBeforeIdx),
      nmoment,
      ...moments.slice(momentBeforeIdx),
    ];
  }

  return { ...game, moments };
};

export const injectMomentPBP = (
  { video: { pk: videoId } }: GameFull,
  pbp: GamePlayByPlay,
  moment: Moment,
): GamePlayByPlay => {
  const segments = pbp.segments.map((segment): GameSegment => {
    const idx = segment.actions.findIndex(
      (action) => (
        action.sequence === moment.copied_pbp_id
        || action.best_moment?.moment_id === moment.pk
      ),
    );

    // do not update action if there is best_moment with different pk
    if (idx === -1 || (
      segment.actions[idx].best_moment?.moment_id
      && segment.actions[idx].best_moment.moment_id !== moment.pk
    )) {
      return segment;
    }

    return {
      ...segment,
      actions: [
        ...segment.actions.slice(0, idx),
        {
          ...segment.actions[idx],
          best_moment: {
            moment_id: moment.pk,
            video_id: videoId,
            name: moment.name,
            moment,
          },
        },
        ...segment.actions.slice(idx + 1),
      ],
    };
  });

  return { ...pbp, segments };
};
