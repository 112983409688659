import { ReactComponent as PinOff } from '@mdi/svg/svg/pin-off.svg';
import { ReactComponent as PinOn } from '@mdi/svg/svg/pin.svg';
import { ReactComponent as Loading } from 'images/spinner.svg';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useApplication } from 'common/hooks/useApplication';
import { useAuth } from 'common/hooks/useAuth';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useProfile } from 'common/hooks/useProfile';
import { useTeam } from 'common/hooks/useTeam';
import { cleanPageTitle } from 'common/utils/seo';

import { ConfirmationModal } from 'consumer/components/ConfirmationModal';
import { FollowButton } from 'consumer/components/FollowButton';
import {
  CollectionsSection,
} from 'consumer/components/Home/CollectionsSection';
import {
  GamesDetailedSection,
} from 'consumer/components/Home/GamesDetailedSection';
import { MomentsSection } from 'consumer/components/Home/MomentsSection';
import { PlayersSection } from 'consumer/components/Home/PlayersSection';
import {
  SeasonGamesSection,
} from 'consumer/components/Home/SeasonGamesSection';
import SportIcon from 'consumer/components/SportIcon';
import { TeamImage } from 'consumer/components/TeamImage';
import { useEmailNagModal } from 'consumer/containers/EmailNagModal';
import { useHome } from 'consumer/hooks/useHome';
import { SeasonSummary } from 'consumer/hooks/useHome/types';
import { CLIENT_URLS } from 'consumer/routes';

import { useTeamId } from '../useTeamId';
import * as s from './HomeViewing.m.less';

const FOLLOW_TIMEOUT = 30 * 1000;

export const HomeViewing: React.FC = function HomeViewing() {
  // toggle follow popup
  const [showFollow, setShowFollow] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);

  // season year
  const [year, setYear] = React.useState<number>();

  // season data, used for caching between season requests
  const [season, setSeason] = React.useState<SeasonSummary>();

  const { subscription, profile } = useAuth();

  const {
    setRecentTeam: [setRecentTeam],
    getVisited,
    setVisited: [setVisited],
    follow: [follow],
  } = useProfile();

  const { show: subscriptionModal } = useEmailNagModal();

  const {
    popups: isPopupsEnabled,
    setOrgId,
  } = useApplication();

  const teamId = useTeamId();
  const { pin } = useTeam(teamId);

  const followTimeout = React.useRef<number>();

  const { data: visited = false } = getVisited({ type: 'team', pk: teamId });
  const following = (profile || subscription).following_teams?.includes(teamId) || false;

  const { team: homeData, loadSeason } = useHome(teamId);

  const seasonData = loadSeason(year);

  // track loading state from both home and seasons but raise 404 from home only
  useHandleQuery({
    isLoading: homeData.isLoading || seasonData.isLoading,
    error: homeData.error,
  });

  React.useEffect(() => {
    if (seasonData.data) {
      setSeason(seasonData.data);
    }
  }, [seasonData.data]);

  // should we schedule follow popup?
  const scheduleFollow = Boolean(
    homeData.data
    && isPopupsEnabled
    && !visited
    && !following
    && !subscriptionModal
    && !profile?.is_staff,
  );

  React.useEffect(() => {
    if (scheduleFollow) {
      followTimeout.current = window.setTimeout(() => setShowFollow(true), FOLLOW_TIMEOUT);
      return (): void => window.clearTimeout(followTimeout.current);
    }
    // schedule show if teamId changed too
  }, [teamId, scheduleFollow]);

  React.useEffect(() => {
    if (homeData.data) {
      setOrgId(homeData.data.org_id);
      setRecentTeam(homeData.data.team);
    }

    setShowFollow(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData.data]);

  const handleConfirmFollow = React.useCallback(() => {
    setVisited({ type: 'team', pk: teamId });

    if (!profile && !subscription?.email) {
      subscriptionModal(null, () => {
        follow({ type: 'team', pk: teamId, follow: true });
      });
    } else {
      follow({ type: 'team', pk: teamId, follow: true });
    }

    setShowFollow(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const handleDeclineFollow = React.useCallback(() => {
    setVisited({ type: 'team', pk: teamId });
    setShowFollow(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const handleShowPin = (): void => setShowPin(true);

  const handleDeclinePin = (): void => setShowPin(false);

  const handleConfirmPin = async (): Promise<void> => {
    handleDeclinePin();
    await pin[0](!homeData.data.team.pinned_team);
    homeData.refetch();
  };

  if (!homeData.data || !season) {
    return null;
  }

  const {
    team,
    org_id,
    top_games,
    top_moments,
    top_collections,
    top_players,
  } = homeData.data;

  return (
    <div className="container-fluid">
      <Helmet>
        <title>
          {team.name
            ? `WePlayed: ${cleanPageTitle(team.name)}`
            : 'WePlayed'}
        </title>
      </Helmet>
      <div className={s.header}>
        <h1>
          <TeamImage team={team} size="small" className={s.logo} />
          {team.name}
        </h1>
        <div className={s.sport}>
          <h2>
            <SportIcon
              className={s.logo}
              sport={team.sport?.slug}
              alt={team.sport?.name}
            />
            {team.sport?.name}
          </h2>
          <FollowButton
            type="team"
            className={s.follow}
            pk={teamId}
            id="button-follow-team"
            onFollow={handleConfirmFollow}
          />
          {team?.can_pin && (
            <Button
              variant="link"
              onClick={homeData.data.team.pinned_team ? handleConfirmPin : handleShowPin}
            >
              {((pin[1].isLoading || homeData.isFetching) && (
                <Loading width="24" height="24" fill="white" />
              )) || (
                team.pinned_team ? (
                  <PinOn />
                ) : (
                  <PinOff className={s.pinOff} />
                )
              )}
            </Button>
          )}
        </div>
        <Button
          as={Link}
          disabled={!org_id}
          className={s.teams}
          to={CLIENT_URLS.SCHOOLS.DETAIL_VIEW.toPath({ uid: org_id || '' })}
        >
          Select Team
        </Button>
      </div>
      <div className={s.content}>
        <GamesDetailedSection
          games={top_games}
          user={profile}
        />
        <MomentsSection
          team={team}
          moments={top_moments}
        />
        <SeasonGamesSection
          team={team}
          seasonSummary={season}
          onLoadSeasonSummary={setYear}
        />
        <CollectionsSection
          team={team}
          collections={top_collections}
        />
        <PlayersSection
          team={team}
          players={top_players}
        />
      </div>
      {showFollow && (
        <ConfirmationModal
          cancelText="No"
          confirmText="Yes"
          id="modal-team-follow"
          message={`Would you like to Follow ${team.name} ${team.sport.name}?`}
          onCancel={handleDeclineFollow}
          onConfirm={handleConfirmFollow}
          title="Follow team"
        />
      )}
      {showPin && (
        <ConfirmationModal
          cancelText="No"
          confirmText="Yes"
          id="modal-team-pin"
          message="Are you sure you want this team to be pinned to the school-level widget?"
          onCancel={handleDeclinePin}
          onConfirm={handleConfirmPin}
          title="Pin team"
        />
      )}
    </div>
  );
};
