import { stringify } from 'query-string';
import { Collection, Moment, Player } from 'weplayed-typescript-api';

const sharePrefix = `${process.env.LANDING_SHARE_URL_BASE || ''}/s/`;

export const getMomentShareURL = (
  momentId: string,
  qs?: Record<string, string>,
): string => `${sharePrefix}m/${momentId}${qs ? `?${stringify(qs)}` : ''}`;

export const getCollectionShareURL = (
  collectionId: string,
  qs?: Record<string, string>,
): string => `${sharePrefix}c/${collectionId}${qs ? `?${stringify(qs)}` : ''}`;

const getPlayerShareURL = (playerId: string): string => `${sharePrefix}p/${playerId}`;

export const getMomentShareMeta = (
  moment: Moment,
  qs?: Record<string, string>,
): ShareData => ({
  url: getMomentShareURL(moment.pk, qs),
  title: 'WePlayed moment',
  text: `Check out this moment:\n\n${moment.name}\n`,
});

export const getCollectionShareMeta = (
  collection: Collection,
  qs?: Record<string, string>,
): ShareData => ({
  url: getCollectionShareURL(collection.pk, qs),
  title: 'WePlayed playlist',
  text: `Check out this playlist:\n\n${collection.name}\n`,
});

export const getPlayerShareMeta = (player: Player): ShareData => ({
  url: getPlayerShareURL(player.pk),
  title: 'WePlayed athlete',
  text: `Check out this athlete:\n\n${player.name}\n`,
});
