import { defaultsDeepAll, meanBy } from 'lodash/fp';
import { AnnotationPathProps, AnnotationPoint } from 'weplayed-typescript-api';

import { Handle } from '../../Handle';
import { Base } from '../Base';
import { DEFAULT_COLOR, DEFAULT_THICKNESS } from '../contants';
import { makePath } from '../utils';

export class Path extends Base<AnnotationPathProps> {
  /**
   * Line path
   */
  protected $path: RaphaelElement;

  /**
   * Handles to operate with each path point
   */
  protected $handles: RaphaelSet;

  /**
   * Point index used for move
   */
  protected $idx: number;

  /**
   * Absolute screen values of active element used in translation
   */
  protected $ex: number;

  protected $ey: number;

  static preview(props: AnnotationPathProps): string {
    const width = 160;
    const height = 90;
    const thickness = 4;
    const xx = props.points.map((p) => p.x);
    const yy = props.points.map((p) => p.y);
    const maxx = Math.max(...xx);
    const minx = Math.min(...xx);
    const medx = (minx + maxx) / 2;
    const maxy = Math.max(...yy);
    const miny = Math.min(...yy);
    const medy = (miny + maxy) / 2;
    // make len bigger to get resulting path smaller
    const len = Math.sqrt((maxx - minx) ** 2 + (maxy - miny) ** 2) * 1.2;
    const points = props.points.map(({ x, y }) => ({
      x: (0.5 - (medx - x) / len),
      y: (0.5 - (medy - y) / len),
    }));

    // 16:9 with video proportions
    const path = makePath(points, width, height);

    const arrowDef = `
      <defs>
        <path stroke-linecap="round" d="M5,0 0,2.5 5,5 3.5,3 3.5,2z" id="marker"/>
        <marker id="marker-end" markerHeight="${thickness}" markerWidth="${thickness}" orient="auto" refX="1.5" refY="1.5">
          <use xlink:href="#marker" transform="rotate(180 1.5 1.5) scale(0.6,0.6)" stroke-width="1.6667" fill="${props.color}" stroke="none"></use>
        </marker>
      </defs>`;

    const image = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 ${width} ${height}" version="1.1">
        ${props.arrow ? arrowDef : ''}
        <path stroke="${props.color}" d="${path}" stroke-width="${thickness * 2}" stroke-linecap="round" ${props.arrow ? 'marker-end="url(#marker-end)"' : ''} />
      </svg>`.trim().replace(/>[^<]+</g, '><');

    return `data:image/svg+xml;base64,${btoa(image)}`;
  }

  constructor(paper: RaphaelPaper, props: AnnotationPathProps) {
    super(paper, defaultsDeepAll([props, {
      color: DEFAULT_COLOR,
      thickness: DEFAULT_THICKNESS,
      arrow: false,
    }]));
  }

  public draw(): this {
    this.$path = this.$paper
      .path(this.makePath())
      .attr({
        stroke: this.$props.color,
        'stroke-width': this.$paper.width * this.$props.thickness,
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'arrow-end': this.$props.arrow ? 'classic' : 'none',
      });

    this.$element = this.$paper.set([this.$path]);

    this.$element.drag(
      this.handleDrag.bind(this),
      this.handleDragStart.bind(this),
      this.handleDragEnd.bind(this),
    );
    return this;
  }

  public centerTo(x: number, y: number): this {
    const posX = meanBy((point: AnnotationPoint): number => point.x, this.$props.points);
    const posY = meanBy((point: AnnotationPoint): number => point.y, this.$props.points);
    this.translate(x - posX, y - posY);

    return this;
  }

  public translate(dx: number, dy: number): this {
    this.$props.points.forEach((point) => {
      /* eslint no-param-reassign:0 */
      point.x += dx;
      point.y += dy;
    });
    this.$path.attr('path', this.makePath());
    if (this.$handles) {
      this.$handles.forEach((point) => point.transform(`...T${dx * this.$paper.width},${dy * this.$paper.height}`));
    }
    return super.translate(dx, dy);
  }

  public focus(): this {
    if (!this.$selected) {
      if (!this.$element) {
        this.draw();
      }

      this.$handles = this.$paper.set(
        this.$props.points.map(
          (point, idx) => Handle(
            this.$paper,
            point.x * this.$paper.width,
            point.y * this.$paper.height,
          )
            .drag(
              this.pointDrag.bind(this),
              this.pointDragStart.bind(this, idx),
              this.pointDragEnd.bind(this),
            ),
        ),
      );

      this.$element.push(this.$handles);
    }
    return super.focus();
  }

  public blur(): this {
    if (this.$selected) {
      this.$element.exclude(this.$handles);
      this.$handles.remove();
      this.$handles = undefined;
    }
    return super.blur();
  }

  protected pointDragStart(idx: number, x: number, y: number): void {
    this.$idx = idx;
    this.$ex = x;
    this.$ey = y;
  }

  protected pointDrag(_dxx: number, _dyy: number, x: number, y: number): void {
    const dx = x - this.$ex;
    const dy = y - this.$ey;
    this.$props.points[this.$idx].x += dx / this.$paper.width;
    this.$props.points[this.$idx].y += dy / this.$paper.height;
    this.$handles[this.$idx].transform(`...T${dx},${dy}`);
    this.$path.attr('path', this.makePath());
    this.$ex = x;
    this.$ey = y;
  }

  protected pointDragEnd(): void {
    if (this.$handleChange) {
      this.$handleChange();
    }
  }

  protected makePath(): string {
    return makePath(this.$props.points, this.$paper.width, this.$paper.height);
  }

  protected handleDragStart = (x: number, y: number, e: DragEvent): void => {
    this.$handleSelect(e.ctrlKey);
    this.$ex = x;
    this.$ey = y;
  };

  protected handleDrag = (_dx: number, _dy: number, x: number, y: number): void => {
    this.$handleMove((x - this.$ex) / this.$paper.width, (y - this.$ey) / this.$paper.height);
    this.$ex = x;
    this.$ey = y;
  };

  protected handleDragEnd(): void {
    this.$path.transform('').attr('path', this.makePath());
    if (this.$handleChange) {
      this.$handleChange();
    }
  }
}
