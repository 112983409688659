import * as React from 'react';
import { useQuery } from 'react-query';
import { HttpError, teams } from 'weplayed-typescript-api';

import { queryRetry } from 'common/hooks/utils';

import { HomeSummary, SeasonSummary, UseHomeReturnType } from './types';

export const useHome = (teamId: string): UseHomeReturnType => {
  const loadSeason: UseHomeReturnType['loadSeason'] = React.useCallback((
    year?: number,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  ) => useQuery<SeasonSummary, HttpError>(
    `home-team-season-${teamId}-${year || new Date().getFullYear()}`,
    () => teams.home.season({ uid: teamId, year }),
    { retry: queryRetry, staleTime: Infinity },
  ), [teamId]);

  const team: UseHomeReturnType['team'] = useQuery<HomeSummary, HttpError>(
    `home-team-${teamId}`,
    () => teams.home.summary({ uid: teamId }),
    { retry: queryRetry, staleTime: Infinity },
  );

  return { loadSeason, team };
};
