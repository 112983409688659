import * as cx from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Search as SearchComponent } from 'consumer/components/Search';
import { SearchBox } from 'consumer/components/SearchBox';

import * as s from './Search.m.less';

export const Search: React.FC = function Search() {
  const termRef = React.createRef<HTMLInputElement>();
  const [term, setTerm] = React.useState('');
  const [query, setQuery] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSearchChange = React.useCallback(({ q = '' }) => {
    setTerm(q);
    setQuery(q);
  }, [setTerm, setQuery]);

  return (
    <div className={cx('container', s.searchPage)}>
      <Helmet>
        <title>
          WePlayed Search
          {query ? `: ${query}` : ''}
        </title>
      </Helmet>
      <h1>SEARCH</h1>

      <SearchBox
        autofocus
        loading={loading}
        onChange={setTerm}
        onSubmit={setQuery}
        placeholder="moments, playlists, athletes .."
        ref={termRef}
        value={term}
      />

      <SearchComponent
        query={query}
        onLoading={setLoading}
        onChange={handleSearchChange}
      />
    </div>
  );
};
