import { What } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';

import { TileList } from 'consumer/components/TileList';

import { SearchFilters } from '../SearchFilters';
import { Props } from './types';

export const SearchResults = function SearchResults<T extends What>({
  data, facets, loading, name, onChange, query, error, extraFilters, ...rest
}: Props<T>): JSX.Element {
  const text = !loading && !error && data && data.length === 0
    && `sorry, did not find any matching ${name}s`;

  const { profile } = useAuth();

  return (
    <TileList
      {...rest}
      data={data}
      error={error}
      header={(
        <>
          {facets && onChange && query && (
            <SearchFilters
              facets={facets}
              user={profile}
              query={query}
              onChange={onChange}
              loading={loading}
            >
              {extraFilters}
            </SearchFilters>
          )}
          {text && <h3>{text}</h3>}
        </>
      )}
      loading={loading}
    />
  );
};
