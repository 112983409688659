import { ReactComponent as Thumbnail } from '@mdi/svg/svg/image-area-close.svg';
import { ReactComponent as Edit } from '@mdi/svg/svg/lead-pencil.svg';
import { ReactComponent as Plus } from '@mdi/svg/svg/plus-circle.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { Annotations, Moment } from 'weplayed-typescript-api';

import { AnnotationsCanvas } from 'common/components/Annotations';
import { GamePlayer } from 'common/components/GamePlayer';
import { GameTimeline } from 'common/components/GameTimeline';
import { MomentTrimmer } from 'common/components/MomentTrimmer';
import { RefType } from 'common/components/MomentTrimmer/types';
import { VideoPlayerRefType } from 'common/components/VideoPlayer/types';
import { useApplication } from 'common/hooks/useApplication';
import { createGroups } from 'common/utils/moments';

import { MIN_LIVE_DURATION, MOMENT_EDIT_STEP } from '../constants';
import { GameProviderContext } from '../GameProvider/constants';
import { MODE } from '../GameProvider/types';
import { ReactComponent as Annotate } from './Annotate.svg';
import * as s from './GameVideo.m.less';

export const GameVideo: React.FC = function GameVideo() {
  const {
    user, moment, moments, saving, onPosition, mode, game,
    loopFrom, loopTo, onState, segments, onMoment, state, stateTo,
    onMomentSelect, duration, atLiveEdge, requestedPosition, positionTo,
    onEditMoment, onCreateMoment, onMomentAnnotateStart, annotations, onAnnotations, requestedState,
    annotationsPanel, onJumpTo, onFocusMoments, annotationsCanvas,
  } = React.useContext(GameProviderContext);

  const { broadcast } = useApplication();

  const position = React.useRef(0);
  const trimmerRef = React.useRef<RefType>();
  const videoRef = React.useRef<VideoPlayerRefType>();
  const timelineRef = React.useRef<GameTimeline>();

  const [keepState, setKeepState] = React.useState(null);
  const [size, handleResize] = React.useState(0);
  const [loop, setLoop] = React.useState(true);
  const [next, setNext] = React.useState<Moment>();
  const [prev, setPrev] = React.useState<Moment>();

  const groups = React.useMemo(
    () => (moments && size ? createGroups(moments, size, user?.pk) : []),
    [moments, size, user?.pk],
  );

  const handlePosition = React.useCallback(
    ($position: number, $duration: number | undefined, $live_edge?: boolean) => {
      onPosition($position, $duration, $live_edge);
      timelineRef.current?.setTime(requestedPosition ?? (atLiveEdge ? duration : $position));
      trimmerRef.current?.setTime($position);
      if (typeof moment?.start === 'number' && annotationsCanvas.current) {
        annotationsCanvas.current.setTime($position - moment.start);
      }
      position.current = requestedPosition ?? $position;

      let $prev: Moment;
      let $next: Moment;

      groups?.forEach((mm) => {
        const [min, max] = mm.reduce(
          ([n, x], m) => [Math.min(n, m.start), Math.max(x, m.end)],
          [Number.POSITIVE_INFINITY, 0],
        );

        if (max < $position) {
          [$prev] = mm;
        }

        if (min > $position && !$next) {
          [$next] = mm;
        }
      });

      setNext($next);
      setPrev($prev);
    },
    [onPosition, requestedPosition, atLiveEdge, duration, annotationsCanvas, moment?.start, groups],
  );

  const handleSetLoop = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      target.blur();
      setLoop(target.checked);
    },
    [],
  );

  type Pair = Pick<Moment, 'start' | 'end'>;

  const ranges: Pair[] = React.useMemo(() => {
    if (moments?.length) {
      let idx = 0;
      let range: Pair;
      const $ranges: Pair[] = [];

      while (moments[idx]) {
        const m = moments[idx];

        // assuming all moments sorted by start asc
        if (range && m.start >= range.start && m.start <= range.end) {
          range.end = Math.max(range.end, m.end);
        } else {
          range = { start: m.start, end: m.end };
          $ranges.push(range);
        }

        idx += 1;
      }

      return $ranges;
    }

    return null;
  }, [moments]);

  const fade: boolean | ((pos: number, fadeTime: number) => number) = React.useMemo(() => {
    if (mode === MODE.NONE && ranges) {
      return (pos: number, fadeTime: number): number => {
        const range = ranges.find(({ start, end }) => start <= pos && end >= pos);

        if (!range) {
          return 1;
        }

        let volume = 1;

        const { start, end } = range;

        if (pos - start < fadeTime / 1000) {
          volume = Math.min(1, Math.max(0, ((pos - start) * 1000) / fadeTime));
        } else if (end - pos < fadeTime / 1000) {
          volume = Math.min(1, Math.max(0, ((end - pos) * 1000) / fadeTime));
        }

        return volume;
      };
    }

    return true;
  }, [mode, ranges]);

  const handleMomentLoop = React.useCallback(() => {
    if (mode !== MODE.NONE && !loop) {
      return false;
    }
  }, [mode, loop]);

  const handleThumbnailPosition = React.useCallback(() => {
    onMoment({
      ...moment,
      thumbnail_position: position.current,
      thumbnail: videoRef.current?.image() || moment.thumbnail,
    });
    broadcast('success', 'Thumbnail for this moment was set at this position');
  }, [broadcast, moment, onMoment, position]);

  const handleEdit = React.useCallback(() => {
    if (moment?.can_manage) {
      onEditMoment(moment.pk);
    } else {
      onCreateMoment(position.current);
    }
  }, [moment, onCreateMoment, onEditMoment, position]);

  React.useEffect(() => {
    if (mode === MODE.EDIT && moment && !moment.thumbnail) {
      onMoment({ ...moment, thumbnail: videoRef.current.image() });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const create = !moment?.can_manage;

  const handlePlayPause = React.useCallback((playing: boolean) => {
    onState(playing);
  }, [onState]);

  const handleTrimmerChange = React.useCallback((m: Moment, a: Annotations) => {
    stateTo(keepState);
    setKeepState(null);
    onMoment(m);
    onAnnotations(mode === MODE.ANNOTATIONS ? a : annotations);
  }, [stateTo, keepState, onMoment, onAnnotations, mode, annotations]);

  const handleAnnotationsPosition = React.useCallback((pos: number): void => {
    positionTo(moment.start + pos);
  }, [positionTo, moment?.start]);

  const handleTrimmerPosition = React.useCallback((pos: number, pause: boolean) => {
    if (keepState === null && pause) {
      setKeepState(state);
      stateTo(false);
    }
    positionTo(pos);
  }, [keepState, positionTo, state, stateTo]);

  const handleNext = React.useCallback(() => onJumpTo(next), [next, onJumpTo]);
  const handlePrev = React.useCallback(() => onJumpTo(prev), [onJumpTo, prev]);

  const live = game?.live_now;
  const videoUrl = game?.video.streaming_url;

  return (
    <div className={cx(s.root, saving && s.disabled)}>
      <div className={s.player}>
        <GamePlayer
          controls={mode !== MODE.ANNOTATIONS}
          keyboard={mode !== MODE.ANNOTATIONS}
          fade={fade}
          loop={mode !== MODE.NONE && loop}
          start={mode !== MODE.NONE && moment ? moment.start : loopFrom}
          end={mode !== MODE.NONE && moment ? moment.end : loopTo}
          onEnd={handleMomentLoop}
          onMomentCreate={handleEdit}
          onNext={mode === MODE.NONE && next && handleNext}
          onState={handlePlayPause}
          onPosition={handlePosition}
          onPrevious={mode === MODE.NONE && prev && handlePrev}
          playing={requestedState}
          position={requestedPosition}
          ref={videoRef}
          url={videoUrl}
        >
          {moment && (
            <AnnotationsCanvas
              annotations={moment.annotations}
              editable={mode === MODE.ANNOTATIONS}
              length={moment.end - moment.start}
              onChange={onAnnotations}
              onPosition={handleAnnotationsPosition}
              onSelect={annotationsPanel.current?.select}
              ref={annotationsCanvas}
            />
          )}
        </GamePlayer>

        {mode !== MODE.ANNOTATIONS && (
          <div className={s.actions}>
            {mode === MODE.NONE && (((user?.can_create_moments ?? true) && create) || !create) && (
              <button
                // WPM-1589
                className={cx(create && s.create, 'no-mobile')}
                id="moment_action_button"
                onClick={handleEdit}
                type="button"
              >
                {create
                  ? <Plus aria-label="Add Moment" />
                  : <Edit aria-label="Edit Moment" />}
                {create ? 'Add Moment' : 'Edit Moment'}
              </button>
            )}
            {mode === MODE.EDIT && (
              <>
                <button
                  onClick={handleThumbnailPosition}
                  type="button"
                >
                  <Thumbnail />
                  Set Thumbnail
                </button>
                <button
                  onClick={onMomentAnnotateStart}
                  type="button"
                >
                  <Annotate />
                  Annotate
                </button>
              </>
            )}
          </div>
        )}
      </div>

      {mode === MODE.NONE && typeof duration === 'number' && (
        <GameTimeline
          groups={(!live || duration > MIN_LIVE_DURATION) && groups}
          length={duration}
          moment={moment}
          onGroupFocus={onFocusMoments}
          onMomentSelected={onMomentSelect}
          onResize={handleResize}
          onTimeSelected={positionTo}
          ref={timelineRef}
          segments={segments}
          stickRight={live}
          user={user}
        />
      )}

      {mode !== MODE.NONE && moment && (
        <div className={cx(s.trimmer, saving && s.disabled)}>
          <MomentTrimmer
            annotations={mode === MODE.ANNOTATIONS && annotations}
            annotationsCanvas={annotationsCanvas.current}
            length={duration}
            moment={moment}
            onChange={handleTrimmerChange}
            onPosition={handleTrimmerPosition}
            ref={trimmerRef}
            step={MOMENT_EDIT_STEP}
            videoUrl={videoUrl}
          />
          <label className={s.loop}>
            <input type="checkbox" checked={loop} onChange={handleSetLoop} />
            loop
          </label>
        </div>
      )}
    </div>
  );
};
