import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { persistent } from 'common/utils/persistent';

import { CLIENT_URLS } from 'consumer/routes';

import {
  INTERVAL, WARNING_EXPIRE, WARNING_LEVELS, WARNING_TEXTS,
} from './constants';
import * as s from './MomentWarning.m.less';
import { Props, WatchState } from './types';

const getKey = (gamePk: string): string => `watch-state-${gamePk}`;

const getLevel = (time: number): number => (
  WARNING_LEVELS.map((t, i) => [t, i]).filter(([t]) => t > time).shift()?.pop()
    ?? WARNING_LEVELS.length - 1
);

export const MomentWarning: React.FunctionComponent<Props> = function MomentWarning({
  gamePk, playing, onState,
}) {
  const history = useHistory();

  const [show, setShow] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [level, setLevel] = React.useState(0);

  const isLastLevel = level >= WARNING_LEVELS.length - 1;

  // do the cleanup!
  React.useEffect(() => {
    const states = persistent.startsWith(getKey(''));
    const now = new Date().valueOf();

    Object.keys(states).forEach((key) => {
      try {
        const expiresAt = (JSON.parse(states[key]) as WatchState)?.expiresAt;
        if (expiresAt <= now) {
          persistent.set(key);
        }
      } catch (e) {
        // unknown content, remove too
        persistent.set(key);
      }
    });
  }, []);

  React.useEffect(() => {
    const date = new Date().valueOf();

    try {
      const data: WatchState = JSON.parse(persistent.get(getKey(gamePk)));
      if (data.expiresAt > date) {
        setTime(data.time);
        setLevel(data.level);
      }
    } catch (e) {
      setLevel(0);
    }
  }, [gamePk]);

  React.useEffect(() => {
    if (playing) {
      const timer = window.setTimeout(() => {
        setTime(time + INTERVAL);
      }, INTERVAL * 1000);
      return (): void => clearTimeout(timer);
    }
  }, [playing, time]);

  React.useEffect(() => {
    const newLevel = getLevel(time);

    if (level !== newLevel || (isLastLevel && time >= WARNING_LEVELS[WARNING_LEVELS.length - 1])) {
      onState(false);
      setShow(true);
    }

    persistent.set(getKey(gamePk), JSON.stringify({
      time,
      level: newLevel,
      expiresAt: new Date().valueOf() + WARNING_EXPIRE * 1000,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, time, isLastLevel]);

  // reset time on mouse move
  React.useEffect(() => {
    const handleMouseMove = (): void => {
      if (playing) {
        // set time to a minimal value for current level level
        setTime(WARNING_LEVELS.slice(0, level).pop() || 0);
      }
    };
    document.addEventListener('mousemove', handleMouseMove);

    return (): void => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [level, playing]);

  const handleDismiss = (): void => {
    setLevel(level + 1);
    onState(true);
    setShow(false);
  };

  const handleAway = (): void => {
    setShow(false);
    history.push(CLIENT_URLS.HOME.DISCOVER_DEFAULT.buildPath());
  };

  return (
    <Modal
      backdrop={isLastLevel ? 'static' : true}
      show={show}
      keyboard={!isLastLevel}
      onHide={handleDismiss}
      backdropClassName="modal-backdrop-blackout"
      dialogClassName={s.root}
    >
      <Modal.Body>
        {WARNING_TEXTS[level]}
      </Modal.Body>

      <Modal.Footer>
        {!isLastLevel ? (
          <Button
            className="btn btn-primary btn-normal"
            onClick={handleDismiss}
          >
            Yes, Let me Create
          </Button>
        ) : undefined}
        <Button
          className="btn btn-primary btn-normal"
          onClick={handleAway}
        >
          {isLastLevel ? 'Ok, go Home' : 'No, go Home'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
