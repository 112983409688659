import { ReactComponent as Heart } from '@mdi/svg/svg/heart.svg';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Collection, What } from 'weplayed-typescript-api';

import { useAuth } from 'common/hooks/useAuth';
import { MINIMUM_LIKES_TO_SHOW } from 'common/utils/config';

import { Renderer } from 'consumer/components/Tile/types';
import { UserHighlight } from 'consumer/components/UserHighlight';
import { CLIENT_URLS } from 'consumer/routes';

import DefaultImg from './images/default.png';
import { Props } from './types';

const collectionToProps = (item: Collection): Omit<Collection, 'thumbnail'> => {
  const result = {
    item,
    pk: item.pk,
    name: item.name,
    thumbnail_url: item.thumbnail,
    moment_count: item.moment_count,
    liked_by_user: item.liked_by_user,
    created_by: item.created_by,
    duration: item.duration,
  };
  return result;
};

export const CollectionTile: React.FC<Props> = function CollectionTile(props) {
  const { profile } = useAuth();

  let params: Props;

  if (props.item) {
    params = {
      ...collectionToProps(props.item),
      ...props,
    };
  } else {
    params = props;
  }

  return (
    <div className="collection-tile mono-color-link" key={params.pk}>
      <Link to={CLIENT_URLS.COLLECTIONS.VIEW_COLLECTION.toPath({ uid: params.pk })}>
        <div className="collection-tile-content">
          {params.thumbnail_url
            ? (
              <>
                <img
                  className="collection-tile-image"
                  id={`image-collection-${params.pk}`}
                  src={params.thumbnail_url}
                  alt={params.name}
                />
                <div className="collection-tile-photo-shim" />
              </>
            )
            : (
              <img
                className="collection-tile-image"
                src={DefaultImg}
                alt={params.name}
              />
            )}
          <h3 className="collection-tile-name">{params.name}</h3>
          {!profile?.is_cms_user && params.likes >= MINIMUM_LIKES_TO_SHOW
            ? (
              <div className="collection-tile-likes-count">
                <Heart
                  fontSize="small"
                  className="like-count-icon"
                />
                {`${params.likes} Likes`}
              </div>
            )
            : ''}
        </div>
      </Link>
      <div className="tile-legend">
        <div>
          {!props.hide_created_by && (
            <UserHighlight user={params.created_by} />
          )}
        </div>
        <div className="tile-legend-moment-count">
          {params.moment_count > 0
            ? (
              <Link to={CLIENT_URLS.COLLECTIONS.VIEW_COLLECTION.toPath({ uid: params.pk })}>
                <button
                  className="btn btn-primary nowrap-button"
                  type="button"
                >
                  {`${params.moment_count} Moments`}
                </button>
              </Link>
            )
            : ''}
        </div>
      </div>
    </div>
  );
};

export const collectionRenderer: Renderer<What.COLLECTIONS> = (
  collection,
  options,
) => (
  <CollectionTile
    {...options}
    item={collection}
  />
);
