import { useInfiniteQuery, useQuery } from 'react-query';
import { athletes, ID } from 'weplayed-typescript-api';

import { cachePrefix } from './constants';
import { Section, UsePlayerReturnType, UsePlayerType } from './types';

const PER_PAGE = 20;

const defaultOptions = {
  staleTime: 5 * 60 * 1000,
  refetchOnWindowFocus: false,
};

export const usePlayer: UsePlayerType = function usePlayer({
  uid: playerPk,
  enabled,
}) {
  const player: UsePlayerReturnType['player'] = useQuery(
    [cachePrefix, playerPk],
    (_, uid: ID) => athletes.summary.byPlayer({ uid }),
    {
      ...defaultOptions,
      enabled: !!playerPk,
    },
  );

  const active = Object.values(Section)
    .filter(
      (k) => playerPk
            && (!enabled || (Array.isArray(enabled) && enabled.includes(k)) || k === enabled),
    );

  const moments: UsePlayerReturnType['moments'] = useInfiniteQuery(
    ['player', playerPk, 'moments'],
    (_1, uid: ID, _2, next: number) => athletes.moments({ uid }, PER_PAGE, next || 0),
    {
      ...defaultOptions,
      getFetchMore: (last) => last?.next,
      enabled: active.includes(Section.MOMENTS),
    },
  );

  const games: UsePlayerReturnType['games'] = useInfiniteQuery(
    ['player', playerPk, 'games'],
    async (_1, uid: ID) => {
      const items = await athletes.games({ uid });
      return { items, next: undefined, total: items.length };
    },
    {
      ...defaultOptions,
      getFetchMore: () => false,
      enabled: active.includes(Section.GAMES),
    },
  );

  const playlists: UsePlayerReturnType['playlists'] = useInfiniteQuery(
    ['player', playerPk, 'playlists'],
    async (_1, uid: ID) => {
      const items = await athletes.collections({ uid });
      return { items, next: undefined, total: items.length };
    },
    {
      ...defaultOptions,
      getFetchMore: () => false,
      enabled: active.includes(Section.PLAYLISTS),
    },
  );

  return { games, moments, player, playlists };
};
