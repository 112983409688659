import * as React from 'react';
import { queryCache, useMutation, useQuery, useQueryCache } from 'react-query';
import { games, HttpError, Moment, moments } from 'weplayed-typescript-api';

import { event } from 'common/utils/analytics';

import { updateLike, updateMoment } from 'consumer/hooks/useMoment';
import {
  getLikeQueryData, getPublicationQueryData, getRemoveQueryData, isLikeQuery,
  isPublicationQuery, isRemoveQuery,
} from 'consumer/hooks/useMoments';

import {
  GameFull, GamePlayByPlay, UseGameReturnType, UseGameType,
} from './types';
import { getKey, getPbpKey, injectMomentGame, injectMomentPBP } from './utils';

export const useGame: UseGameType = function useGame({ uid, momentId, videoId, cms }) {
  const [pbpKey, setPbpKey] = React.useState<string[] | null>(null);

  const [stoppingLive, setStoppingLive] = React.useState(false);
  const [creatingPlaylists, setCreatingPlaylists] = React.useState(false);

  const cache = useQueryCache();

  const gameKey = getKey(uid, videoId, cms);

  React.useEffect(() => cache.subscribe((qc, query) => {
    if (isLikeQuery(query)) {
      const { uid: mId, like } = getLikeQueryData(query);
      const update = updateLike(mId, like);

      qc.getQueries<GameFull>(gameKey).forEach(({ queryKey: qk, state: { data: game } }) => {
        if (game?.moments.some(
          ({ pk, liked_by_user }) => pk === mId && liked_by_user !== like,
        )) {
          qc.setQueryData(qk, {
            ...game,
            moments: game.moments.map(update),
          });
        }
      });
    } else if (isPublicationQuery(query)) {
      const { uid: mId, publication } = getPublicationQueryData(query);
      const update = updateMoment(mId, publication);

      qc.getQueries<GameFull>(gameKey).forEach(({ queryKey: qk, state: { data: game } }) => {
        if (game?.moments.some(({ pk }) => pk === mId)) {
          qc.setQueryData(qk, {
            ...game,
            moments: game.moments.map(update),
          });
        }
      });
    } else if (isRemoveQuery(query)) {
      const { uid: mId } = getRemoveQueryData(query);

      qc.getQueries<GameFull>(gameKey).forEach(({ queryKey: qk, state: { data: game } }) => {
        if (game?.moments.some(({ pk }) => pk === mId)) {
          qc.setQueryData(qk, {
            ...game,
            moments: game.moments.filter(({ pk }) => pk !== mId),
          });
        }
      });
    }
  }), [cache, gameKey]);

  const invalidateCache = (): void => {
    // disabled so far
    cache.invalidateQueries(gameKey);
  };

  const endStream: UseGameReturnType['endStream'] = useMutation(
    async () => {
      setStoppingLive(true);
      await games.endStream({ uid });
    },
    {
      onSuccess: invalidateCache,
    },
  );

  const createPivotalPlaylist: UseGameReturnType['createPivotalPlaylist'] = useMutation(
    async () => {
      setCreatingPlaylists(true);
      await games.createPlaylists({ uid });
    },
    {
      onSuccess: invalidateCache,
    },
  );

  const qa: UseGameReturnType['qa'] = useMutation(
    (status) => games.qa({ uid, status }),
    {
      onSuccess: invalidateCache,
    },
  );

  const onMomentSuccess = (moment: Moment): void => {
    let game = queryCache.getQueryData<GameFull>(gameKey);
    if (game) {
      game = injectMomentGame(game, moment);
      queryCache.setQueryData(gameKey, game);
    }

    let pbp = queryCache.getQueryData<GamePlayByPlay>(pbpKey);

    if (game && pbp && pbp.game_id === game.pk) {
      pbp = injectMomentPBP(game, pbp, moment);
      queryCache.setQueryData(pbpKey, pbp);
    }

    invalidateCache();
    // no point in reloading pbp here
  };

  const create: UseGameReturnType['create'] = useMutation(
    async (moment) => {
      const $moment = await moments.create({ moment });

      event({
        category: 'Curation',
        action: 'Moment Created',
      });

      onMomentSuccess($moment);
      return $moment;
    },
  );

  const update: UseGameReturnType['update'] = useMutation(
    async (moment) => {
      const $moment = await moments.update({ uid: moment.pk, moment });

      $moment.reviewed = true;
      $moment.copied_pbp_id = moment.copied_pbp_id;

      onMomentSuccess(moment);
      return moment;
    },
  );

  const game: UseGameReturnType['game'] = useQuery<GameFull, HttpError>(
    gameKey,
    async () => {
      const data = await games.get({ uid, videoId, cms, momentId });

      // data.video.streaming_url = 'https://content.weplayed.nomad-cms.com/live/stas-test-channel-7/stas-test-channel-7.m3u8';
      // data.live_now = true;
      // data.can_create_playlist = true;
      // data.has_play_by_play = false;
      // data.moments = [];
      // data.moment_count = 0;

      setPbpKey(data.has_play_by_play ? getPbpKey(uid, cms) : null);

      if (stoppingLive && !data.live_now) {
        setStoppingLive(false);
      }

      if (creatingPlaylists && !data.can_create_playlist) {
        setCreatingPlaylists(false);
      }

      return data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: (creatingPlaylists || stoppingLive) ? 2000 : undefined,
    },
  );

  const pbp: UseGameReturnType['pbp'] = useQuery(
    pbpKey,
    () => games.pbp({ uid, cms }),
    {
      keepPreviousData: true,
      enabled: Boolean(pbpKey),
      refetchOnWindowFocus: false,
    },
  );

  return {
    create,
    createPivotalPlaylist,
    endStream,
    game,
    pbp,
    qa,
    update,
  };
};
