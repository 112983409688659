import { ReactComponent as VolumeHigh } from '@mdi/svg/svg/volume-high.svg';
import { ReactComponent as VolumeLow } from '@mdi/svg/svg/volume-low.svg';
import { ReactComponent as VolumeMedium } from '@mdi/svg/svg/volume-medium.svg';
import { ReactComponent as VolumeOff } from '@mdi/svg/svg/volume-off.svg';
import * as cx from 'classnames';
import * as React from 'react';

import { VolumeControlButtonProps } from './types';
import * as s from './VolumeControlButton.m.less';

export const VolumeControlButton: React.FC<
  VolumeControlButtonProps
> = function VolumeControlButton({
  className,
  onVolume,
  volume,
}) {
  const [visible, setVisible] = React.useState(false);

  const handleToggle = React.useCallback((e) => {
    e.stopPropagation();
    setVisible(!visible);
  }, [visible]);

  React.useEffect(() => {
    if (visible) {
      window.addEventListener('click', handleToggle);

      return (): void => window.removeEventListener('click', handleToggle);
    }
  }, [handleToggle, visible]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      onVolume?.(parseFloat(e.target.value));
    },
    [onVolume],
  );

  return (
    <div className={s.root}>
      <button
        aria-label="Volume control"
        className={cx(s.button, className)}
        onClick={handleToggle}
        type="button"
      >
        {(volume === 0 && (
          <VolumeOff />
        )) || (volume < 0.33 && (
          <VolumeLow />
        )) || (volume < 0.66 && (
          <VolumeMedium />
        )) || (
          <VolumeHigh />
        )}
      </button>
      {visible && (
        <span>
          <input
            defaultValue={volume}
            max={1}
            min={0}
            onChange={handleChange}
            step="0.01"
            type="range"
          />
        </span>
      )}
    </div>
  );
};
