import { curry, memoize } from 'lodash/fp';
import * as React from 'react';
import { AnnotationComponentPropTypes } from 'weplayed-typescript-api';

import { preventDefault } from 'common/utils/helpers';
import { roundTimeToHalfSecond } from 'common/utils/timeCalcs';

import { Image } from '../Components/Image';
import { Path } from '../Components/Path';
import { PathImage } from '../Components/PathImage';
import * as s from './AnnotationsTimeline.m.less';
import { Props, State } from './types';

const getKey = (component, idx): string => `${component.type}-${idx}`;

const getImage = (component: AnnotationComponentPropTypes): string => {
  switch (component.type) {
    case 'path':
      return Path.preview(component);
    case 'image':
      return Image.preview(component);
    case 'path-image':
      return PathImage.preview(component);

    default:
      throw new Error('No image supported');
  }
};

export class AnnotationsTimeline extends React.PureComponent<Props, State> {
  public state: State = {};

  protected rootBBox: ClientRect;

  protected handleSelect = memoize(curry((
    idx: number,
    e: React.MouseEvent<HTMLDivElement>,
  ): void => {
    if (this.props.onSelect) {
      // our IDX is different to
      this.props.onSelect(idx);

      this.rootBBox = e.currentTarget.getBoundingClientRect();
      this.setState((state: State): State => ({
        ...state,
        idx,
        position: this.props.annotations[idx].start,
      }));
    }
  }));

  public static defaultProps: Partial<Props> = {
    annotations: [],
  };

  public componentDidMount(): void {
    window.document.addEventListener('mousemove', this.handleMove);
    window.document.addEventListener('mouseup', this.handleRelease);
    window.document.addEventListener('mouseleave', this.handleRelease);
    window.document.addEventListener('touchmove', this.handleMove);
    window.document.addEventListener('touchend', this.handleRelease);
  }

  public componentWillUnmount(): void {
    window.document.removeEventListener('mousemove', this.handleMove);
    window.document.removeEventListener('mouseup', this.handleRelease);
    window.document.removeEventListener('mouseleave', this.handleRelease);
    window.document.removeEventListener('touchmove', this.handleMove);
    window.document.removeEventListener('touchend', this.handleRelease);
  }

  protected handleRelease = (): void => {
    if (this.state.idx !== undefined) {
      this.setState((state: State): State => ({
        ...state,
        position: undefined,
        idx: undefined,
      }));
    }
  };

  protected handleMove = (e: MouseEvent | TouchEvent): void => {
    if (this.state.idx !== undefined) {
      const pageX = (e as TouchEvent).touches
                    && (e as TouchEvent).touches[0]
        ? (e as TouchEvent).touches[0].pageX
        : (e as MouseEvent).pageX;
      const position = Math.max(0, Math.min(roundTimeToHalfSecond(
        ((pageX - this.rootBBox.left) * this.props.length) / this.rootBBox.width,
      )));

      if (this.props.onMove) {
        this.props.onMove(this.state.idx, position);
      }

      this.setState({ position });
    }
  };

  protected renderKnob = (component: AnnotationComponentPropTypes, idx: number): JSX.Element => (
    <div
      className={s.knob}
      style={{
        left: `${((this.state.idx === idx
          ? this.state.position
          : component.start) * 100) / this.props.length}%`,
        backgroundImage: `url(${getImage(component)})`,
      }}
    />
  );

  public render(): JSX.Element {
    return (
      <div className={s.root}>
        {this.props.annotations && this.props.annotations.length
          ? this.props.annotations.map(
            (component, idx): JSX.Element => component.type !== 'pause' && (
              <div
                tabIndex={0}
                className={s.track}
                role="slider"
                aria-valuenow={this.state.position}
                key={getKey(component, idx)}
                onMouseDown={this.handleSelect(idx)}
                onTouchStart={this.handleSelect(idx)}
                onContextMenu={preventDefault}
              >
                {this.renderKnob(component, idx)}
              </div>
            ),
          ) : ''}
      </div>
    );
  }
}
