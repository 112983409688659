import * as React from 'react';
import { Switch } from 'react-router';

import { Route } from 'consumer/components/Route';
import { CLIENT_URLS } from 'consumer/routes';

import { HomeViewing } from './HomeViewing';
import { useTeamId } from './useTeamId';
import { Welcome } from './Welcome';

export const Home: React.FC = function Home() {
  const teamId = useTeamId();

  const Component = teamId ? HomeViewing : Welcome;

  return (
    <Switch>
      <Route
        path={CLIENT_URLS.HOME.INTRO.route}
        component={Welcome}
        exact
      />
      <Route
        path={CLIENT_URLS.HOME.DISCOVER.route}
        component={Component}
        exact
      />
      <Route
        path={CLIENT_URLS.HOME.DISCOVER_DEFAULT.route}
        component={Component}
        exact
      />
    </Switch>
  );
};
