import * as React from 'react';
import { Helmet } from 'react-helmet';

import * as s from './styles.m.less';

export const Page500: React.FC = function Page500() {
  return (
    <section className={s.root} aria-labelledby="page-500-header">
      <Helmet>
        <title>Server Error</title>
      </Helmet>
      <h1 id="page-500-header">
        Woops!
      </h1>
      <p>
        Sorry, internal error occurred, please try again.
        <br />
        Our system admins have been notified.
      </p>
    </section>
  );
};
