import {
  add, constant, max, min, over, partialRight, pipe, subtract,
} from 'lodash/fp';

export type TimeSpan = [number, number];

export const START_VIDEO_OFFSET = 5;
export const FINISH_VIDEO_OFFSET = 5;

const getTimeSpanFabric = (offsetLeft: number, offsetRight: number) => (
  stopPosition: number,
  minValue = 0,
  maxValue = Infinity,
): TimeSpan => over([
  pipe(
    over([partialRight(subtract, [offsetLeft]), constant(minValue)]),
    max,
  ),
  pipe(over([add(offsetRight), constant(maxValue)]), min),
])(stopPosition) as TimeSpan;

export const getVideoSpan = getTimeSpanFabric(
  START_VIDEO_OFFSET,
  FINISH_VIDEO_OFFSET,
);
