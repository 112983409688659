import { InfiniteQueryResult, QueryResult } from 'react-query';
import {
  Athlete, Collection, Game, HttpError, Moment, PaginatedResponse,
} from 'weplayed-typescript-api';

export enum Section {
  MOMENTS = 'moments',
  PLAYLISTS = 'playlists',
  GAMES = 'games',
}

export interface UsePlayerReturnType {
  games: InfiniteQueryResult<PaginatedResponse<Game>>;
  moments: InfiniteQueryResult<PaginatedResponse<Moment>>;
  player: QueryResult<Athlete, HttpError>;
  playlists: InfiniteQueryResult<PaginatedResponse<Collection>>;
}

interface UsePlayerArgs {
  enabled?: Section | Section[];
  uid: string;
}

export type UsePlayerType = (args: UsePlayerArgs) => UsePlayerReturnType;
