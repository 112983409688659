import { noop } from 'lodash/fp';
import * as React from 'react';

import { defaultState } from './constants';
import { Context } from './types';

export const MomentActionBarContext = React.createContext<Context>({
  invoke: noop,
  user: null,
  status: defaultState,
});
