import { ReactComponent as FilterIcon } from '@mdi/svg/svg/filter.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';

import { TagInput } from 'consumer/components/TagInput';
import { Tag, TagUI } from 'consumer/components/TagInput/types';

import { GameProviderContext } from '../GameProvider/constants';
import {
  FilterControlTags, FilterTag, FilterTagType, MODE,
} from '../GameProvider/types';
import * as s from './GameControlsFilter.m.less';

const tagUI = (tag: FilterTag): TagUI => {
  if (tag.type === FilterTagType.CONTROL) {
    return { className: s.filterControl };
  }

  if (tag.type === FilterTagType.TEXT) {
    return { className: s.filterText };
  }

  if (tag.type === FilterTagType.TAG) {
    return { className: s.filterTag };
  }

  if (tag.type === FilterTagType.PLAYER) {
    return { className: s.filterPlayer, style: { backgroundColor: tag.color } };
  }

  if (tag.type === FilterTagType.TEAM) {
    return { className: s.filterTeam, style: { backgroundColor: tag.color } };
  }
};

export const GameControlsFilter: React.FC<
React.HTMLProps<HTMLDivElement>
> = function GameControlsFilter({ className, ...props }) {
  const [expanded, setExpanded] = React.useState(false);
  const { filters, onFilters, suggestions, mode, user } = React.useContext(GameProviderContext);
  const ref = React.useRef<HTMLDivElement>();

  const toggleExpand = React.useCallback(() => setExpanded(!expanded), [expanded]);

  React.useEffect(() => {
    // update after tags input looses focus
    setTimeout(() => {
      if (ref.current) {
        document.documentElement.style.setProperty(s.name, `${ref.current.scrollHeight}px`);
      }
    }, 0);
  }, [expanded, filters]);

  React.useEffect(() => (): void => {
    document.documentElement.style.removeProperty(s.name);
  }, []);

  const enabled = React.useMemo(
    () => filters.filter((tag) => tag.type === FilterTagType.CONTROL),
    [filters],
  );

  const isChecked = React.useCallback(
    (control: FilterControlTags) => !!enabled.find(({ value }) => value === control),
    [enabled],
  );

  const handleCheck = React.useCallback(({ target: { value, checked } }) => {
    const filtered = filters.filter(
      ({ type }) => type !== FilterTagType.CONTROL,
    );

    if (checked) {
      filtered.push({ value, type: FilterTagType.CONTROL });
    }

    onFilters(filtered);
  }, [filters, onFilters]);

  const handleValidate = React.useCallback((tag: Tag | FilterTag): FilterTag | false => {
    const t = 'type' in tag ? tag.type : FilterTagType.TEXT;
    const v = tag.value;

    const exists = filters.find(({ type, value }) => type === t && value === v);

    if (!exists) {
      if (t === FilterTagType.TEXT) {
        return { type: FilterTagType.TEXT, value: v };
      }

      return tag as FilterTag;
    }

    return false;
  }, [filters]);

  const handleChange = React.useCallback(
    (type: FilterTagType) => ({ currentTarget }: React.ChangeEvent<HTMLSelectElement>): void => {
      const tag = suggestions.find(({ pk, value, type: t }) => (
        (type === FilterTagType.PLAYER && type === t && pk === currentTarget.value)
        || (type === FilterTagType.TAG && type === t && value === currentTarget.value)
      ));

      if (tag) {
        onFilters([...filters, tag]);
      }

      // eslint-disable-next-line no-param-reassign
      currentTarget.selectedIndex = 0;
    },
    [filters, onFilters, suggestions],
  );

  const players = React.useMemo(
    () => suggestions
      .filter((f) => f.type === FilterTagType.PLAYER
        && !filters.find((ff) => ff.type === f.type && ff.pk === f.pk))
      .sort(({ value: a }, { value: b }) => (a > b ? 1 : -1)),
    [suggestions, filters],
  );

  const tags = React.useMemo(
    () => suggestions
      .filter((f) => f.type === FilterTagType.TAG
        && !filters.find((ff) => ff.type === f.type && ff.value === f.value))
      .sort(({ value: a }, { value: b }) => (a > b ? 1 : -1)),
    [suggestions, filters],
  );

  const controls: FilterControlTags[] = React.useMemo(() => {
    const ret: FilterControlTags[] = [];
    if (user) {
      ret.push(FilterControlTags.MY);
    }

    ret.push(FilterControlTags.PLAY_BY_PLAY);

    if (user?.is_staff) {
      ret.push(
        FilterControlTags.REVIEWED,
        FilterControlTags.NOT_REVIEWED,
      );
    }

    return ret;
  }, [user]);

  return (
    <div
      className={cx(s.root, expanded && s.expanded, className)}
      ref={ref}
      {...props}
    >
      <div className={cx(s.search, mode !== MODE.NONE && s.disabled)}>
        <TagInput
          className={s.input}
          maxLength={20}
          onChange={onFilters}
          onValidate={handleValidate}
          suggestions={suggestions}
          tags={filters}
          tagUI={tagUI}
        />
        <button
          className={s.button}
          onClick={toggleExpand}
          type="button"
        >
          <FilterIcon />
        </button>
      </div>
      <div className={s.controls}>
        <ul className={s.checkboxes}>
          {controls.map((value) => (
            <li key={value}>
              <Form.Check
                id={value}
                checked={isChecked(value)}
                label={value}
                onChange={handleCheck}
                value={value}
              />
            </li>
          ))}
        </ul>
        <ul className={s.tags}>
          <li>
            <select
              defaultValue=""
              disabled={!players.length}
              onChange={handleChange(FilterTagType.PLAYER)}
            >
              <option value="" disabled>Athletes</option>
              {players.map(({ value, pk }) => (
                <option key={pk} value={pk}>{value}</option>
              ))}
            </select>
          </li>
          <li>
            <select
              defaultValue=""
              disabled={!tags.length}
              onChange={handleChange(FilterTagType.TAG)}
            >
              <option value="" disabled>Tags</option>
              {tags.map(({ value }) => (
                <option key={value}>{value}</option>
              ))}
            </select>
          </li>
        </ul>
      </div>
    </div>
  );
};
