import * as cx from 'classnames';
import * as React from 'react';

import * as s from './GameTimeline.m.less';
import { MomentsProps } from './types';

// const groupWidth = parseInt(s.markerGroupWidth, 10);
const markerWidth = parseInt(s.markerWidth, 10);

const MomentsRenderer: React.FC<MomentsProps> = function MomentsRenderer({
  groups, length, moment, width,
  onClick, onEnter, onLeave,
}) {
  if (!groups || groups.length === 0) {
    return null;
  }

  const zoom = width / length;

  const momentGroups = groups.map(
    (group, idx) => {
      const minStart = group.reduce(
        (acc: number, curr) => (curr.start < acc || acc === null ? curr.start : acc),
        null,
      );

      if (minStart === null) {
        return null;
      }

      const left = `${((100 * minStart) / length).toFixed(2)}%`;

      return (
        <div
          className={s.momentGroup}
          data-group={idx}
          key={group[0].pk}
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          role="button"
          style={{ left }}
          tabIndex={0}
        >
          <div className={s.moments}>
            {group.map((m, midx) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <div
                className={cx(moment?.pk === m.pk && s.active)}
                style={{
                  marginLeft: `${((m.start - minStart) * zoom).toFixed(1)}px`,
                  width: `${(markerWidth + (m.end - m.start) * zoom).toFixed(1)}px`,
                }}
                key={m.pk}
                data-moment={`${idx}.${midx}`}
                onMouseDown={onClick}
                role="button"
                tabIndex={0}
              />
            ))}
          </div>
          <div className={s.counter}><b>{group.length}</b></div>
        </div>
      );
    },
  );

  return (<div className={s.momentGroups}>{momentGroups}</div>);
};

export const Moments = React.memo(MomentsRenderer);
