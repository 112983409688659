// Value in seconds, warning expire
export const WARNING_EXPIRE = 60 * 60;

// Value in seconds
export const INTERVAL = 5;

// These values are in seconds, total time
// Any next value must be bigger than previous
export const WARNING_LEVELS = [600, 720, 840, 960];

export const WARNING_TEXTS = [
  (
    <>
      <h1>
        Create Your Moment in&nbsp;
        <span>Game Mode</span>
      </h1>
      <p>
        <span>Game Mode</span>
        &nbsp;is here for you to create your favorite Moments and Playlists on WePlayed.
      </p>
      <p>
        Viewing is limited to 10 minutes in&nbsp;
        <span>Game Mode</span>
        .
      </p>
      <p>Would you like to create a Moment or add a Moment to a Playlist?</p>
    </>
  ),
  (
    <>
      <h1>4-Minute Warning</h1>
      <p>You haven&apos;t taken an action yet.</p>
      <p>Are you still interested in creating a Moment or adding a Moment to a Playlist?</p>
    </>
  ),
  (
    <>
      <h1>2-Minute Warning</h1>
      <p>We still haven&apos;t seen you create a moment.</p>
      <p>Are you still interested in creating a Moment or adding a Moment to a Playlist?</p>
      <p>(This is your chance)</p>
    </>
  ),
  (
    <>
      <h1>3… 2… 1… 0 Game Over</h1>
      <p>
        Sorry, you reached the&nbsp;
        <span>Game Mode</span>
        &nbsp;time limit for this game.
      </p>
      <p>You can come back to this game after 1 hour.</p>
      <p>
        You can still view and search for Moments,
        Playlists and explore the team&apos;s Home screen.
      </p>
    </>
  ),
];
