// import { debounce } from 'lodash/fp';
import * as React from 'react';

import { AnnotationsTimeline } from 'common/components/Annotations';
import { VideoTrimmer } from 'common/components/VideoTrimmer';
import { TimeSpan } from 'common/utils/timeSpan';

import { Props, RefType } from './types';

export const MomentTrimmerRef: React.ForwardRefRenderFunction<
RefType, Props
> = function MomentTrimmerRef({
  annotations: outerAnnotations, annotationsCanvas, moment, length,
  onChange, onPosition, step, videoUrl, disabled,
}, ref) {
  const [span, setSpan] = React.useState<TimeSpan>([moment.start, moment.end]);
  const [annotations, setAnnotations] = React.useState(outerAnnotations);
  const [position, setPos] = React.useState(0);

  React.useImperativeHandle(ref, () => ({
    setTime: setPos,
  }), []);

  React.useEffect(() => setAnnotations(outerAnnotations), [outerAnnotations]);

  const handleAnotationPosition = React.useCallback((
    idx: number,
    pos: number,
    duration?: number,
  ): void => {
    onPosition(pos);
    if (annotationsCanvas) {
      annotationsCanvas.focus(idx);
      annotationsCanvas.time(idx, pos, duration);
    }
  }, [annotationsCanvas, onPosition]);

  const handleSpanChange = React.useCallback((start: number, end: number): void => {
    onPosition(span[0] !== start ? start : end, true);
    setSpan([start, end]);

    if (Array.isArray(outerAnnotations)) {
      const shift = moment.start - start;
      setAnnotations(outerAnnotations.map((annotation) => ({
        ...annotation,
        start: annotation.start + shift,
      })));
    }
  }, [moment.start, onPosition, outerAnnotations, span]);

  const handleSpanChangeEnd = React.useCallback((start: number, end: number) => {
    setSpan([start, end]);

    if (onChange) {
      const shift = moment.start - start;
      const momentlength = end - start;
      const ann = (annotations || moment.annotations?.map((annotation) => ({
        ...annotation,
        start: annotation.start + shift,
      })))?.filter((annotation) => annotation.start >= 0 && annotation.start < momentlength);

      onChange(
        {
          ...moment,
          annotations: annotations ? moment.annotations : ann,
          start,
          end,
        },
        annotations ? ann : undefined,
      );
    }
  }, [moment, annotations, onChange]);

  return (
    <VideoTrimmer
      disabled={disabled}
      end={span[1]}
      length={length}
      onChange={handleSpanChange}
      onChangeEnd={handleSpanChangeEnd}
      onPosition={onPosition}
      position={position}
      start={span[0]}
      step={step}
      videoUrl={videoUrl}
    >
      {annotations && (
        <AnnotationsTimeline
          annotations={annotations}
          length={span[1] - span[0]}
          onMove={handleAnotationPosition}
          onSelect={annotationsCanvas?.focus}
        />
      )}
    </VideoTrimmer>
  );
};

export const MomentTrimmer = React.forwardRef<RefType, Props>(MomentTrimmerRef);
