interface WSAction {
  action: string;
}

export enum SystemEvents {
  CONNECTION = 'system::connection',
}

interface WSSystemConnectionAction extends WSAction {
  action: SystemEvents.CONNECTION;
  state: boolean;
}

export type WSSystemAction = WSSystemConnectionAction;
type WSSystemListener = (action: WSSystemAction) => void;
export type WSSystemSubscribeArgs = ['system', WSSystemListener]
export type WSSystemUnsubscribeArgs = ['system', WSSystemListener];

interface WSGameBaseAction extends WSAction {
  game_id: string;
}

export enum GameEvents {
  JOIN = 'game::join',
  LEAVE = 'game::leave',
  UPDATED = 'game::updated',
}

interface WSGameJoinAction extends WSGameBaseAction {
  action: GameEvents.JOIN;
}

interface WSGameLeaveAction extends WSGameBaseAction {
  action: GameEvents.LEAVE;
}

export interface WSGameUpdateAction extends WSGameBaseAction {
  action: GameEvents.UPDATED;
  moments?: true;
  pbp?: true;
}

export type WSGameAction = WSGameJoinAction | WSGameLeaveAction
| WSGameUpdateAction;
type WSGameListener = (action: WSGameAction) => void;
export type WSGameSubscribeArgs = ['game', string, WSGameListener];
export type WSGameUnsubscribeArgs = ['game', string, WSGameListener];

export type WSAllSubscribeArgs = WSGameSubscribeArgs | WSSystemSubscribeArgs;
export type WSAllUnsubscribeArgs = WSGameUnsubscribeArgs | WSSystemUnsubscribeArgs;

export type WSAllAction = WSSystemAction | WSGameAction

export interface WSListeners {
  game: {
    [K: string]: WSGameListener[];
  };
  system: WSSystemListener[];
}

export interface UseWSReturnType {
  subscribe(...args: WSAllSubscribeArgs): void;
  unsubscribe(...args: WSAllUnsubscribeArgs): void;
}
