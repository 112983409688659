import * as uuidv1 from 'uuid/v1';

import { persistent } from './persistent';

export const VISITOR_ID = 'visitorId';

const generateVisitorId = (): string => uuidv1();

export const setVisitorId = (visitorId?: string | null): void => {
  persistent.set(VISITOR_ID, visitorId, true);
};

export function getVisitorId(): string {
  let visitorId = persistent.get(VISITOR_ID, true);

  if (!visitorId) {
    visitorId = generateVisitorId();
  }
  // refresh cookie and local storage
  setVisitorId(visitorId);
  return visitorId;
}
