// load video player and plugins which will register automatically
import 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';

import * as React from 'react';

import { VideoPlayerProps } from './types';
import { VideoPlayer } from './VideoPlayer';

/**
 * This component reinitialize video component after wake from sleep
 */
export const withPlayerGuard: (
  Component: React.ComponentClass<VideoPlayerProps>
) => React.ForwardRefRenderFunction<
  VideoPlayer,
  VideoPlayerProps
> = (
  Component: React.ComponentClass<VideoPlayerProps>,
) => function VideoPlayerGuard({
  adTagUrl,
  ...props
}, ref): JSX.Element {
  const adTag = React.useRef<string>(adTagUrl);

  const [serial, bump] = React.useReducer((s) => s + 1, 0);

  React.useEffect(() => {
    const pageshowHandler = ({ persisted }: PageTransitionEvent): void => persisted && bump();

    window.addEventListener('pageshow', pageshowHandler);

    return (): void => {
      window.removeEventListener('pageshow', pageshowHandler);
    };
  }, [serial]);

  return <Component key={serial} ref={ref} adTagUrl={adTag.current} {...props} />;
};
