import { debounce, pickBy } from 'lodash';
import { ID, LinkType, What } from 'weplayed-typescript-api';

import { logger } from 'common/utils/logger';

import { Debug } from './debug';
import { persistent } from './persistent';
import { getVisitorId } from './visitorId';

const SEND_EVENT = '/api/logging/v0/event/';
const SEND_EVENTS = '/api/logging/v0/events/';

const paramName = 'weplayed-events';

const TIMEOUT = 2000;
const MAX_TIMEOUT = 5000;
const debug = Debug.extend('common/utils/events');

export enum EVENTS {
  /**
   * Advertisement started
   */
  AD_START = 'AD_START',

  /**
   * User clicked on advertisement
   */
  AD_CLICK = 'AD_CLICK',

  /**
   * Advertisement finished
   */
  AD_COMPLETE = 'AD_COMPLETE',

  /**
   * User clicked to skip advertisement
   */
  AD_SKIP = 'AD_SKIP',

   /**
   * Moment opened in video player
   */
  MOMENT_VIEW = 'MOMENT_VIEW',

  /**
   * Moment starts playing in video player
   */
  MOMENT_PLAY = 'MOMENT_PLAY',

  /**
   * Moment paused in video player
   */
  MOMENT_PAUSE = 'MOMENT_PAUSE',

  /**
   * Next moment from queue is about to play (embedded)
   */
  MOMENT_NEXT = 'MOMENT_NEXT',

  /**
   * Previous moment from queue is about to play (embedded)
   */
  MOMENT_PREV = 'MOMENT_PREV',

  /**
   * Pick moment manually from collection moment previews
   */
  MOMENT_PICK = 'MOMENT_PICK',

  /**
   * Current player position event
   */
  MOMENT_PROGRESS = 'MOMENT_PROGRESS',

  /**
   * Video player with the moment closed
   */
  MOMENT_CLOSE = 'MOMENT_CLOSE',

  /**
   * Moment info frame appeared
   */
  MOMENT_INFOFRAME = 'MOMENT_INFOFRAME',

  /**
   * Embed moment
   */
  MOMENT_EMBED = 'MOMENT_EMBED',

  /**
   * Web page with collection opened in browser window
   */
  COLLECTION_VIEW = 'COLLECTION_VIEW',

  /**
   * Share action invoked, e.g. data copied into clipboard or
   * facebook popup opened
   */
  SHARE = 'SHARE',

  /**
   * Misc widget events passed from widget to embed player
   */
  WIDGET_ERROR = 'WIDGET_ERROR',
  WIDGET_WARN = 'WIDGET_WARN',
}

type EventExtra = {
  [K: string]: unknown;
};

/**
 * Event contexts
 */
export enum EventContext {
  /**
   * Clipper
   */
  CLIPPER = 'clipper',

  /**
   * CMS
   */
  ADMIN = 'admin',

  /**
   * Game screen
   */
  GAME = 'game',

  /**
   * Moment view
   */
  MOMENT = 'moment',

  /**
   * Collection view
   */
  COLLECTION = 'collection',

  /**
   * Embed generated code
   */
  EMBED = 'embed',

  /**
   * Widget
   */
  WIDGET = 'widget',

  /**
   * Standalone player (SideArm player)
   */
  PLAYER = 'player',
}

interface EventType extends EventExtra {
  context?: EventContext,
  type: EVENTS;
  visitor_id?: string;
  /**
   * eventId is used mostly for deduplication purposes
   */
  event_id?: string;
  parent?: string;
}

type AD_EVENTS = EVENTS.AD_CLICK | EVENTS.AD_COMPLETE | EVENTS.AD_START | EVENTS.AD_SKIP;

interface AdEvent extends EventType {
  type: AD_EVENTS;
}

interface MomentEventType extends EventType {
  collection_id?: string;
  moment_id: string;
}

interface MomentViewEvent extends MomentEventType {
  loopcount?: number;
  type: EVENTS.MOMENT_VIEW;
}

interface MomentCloseEvent extends MomentEventType {
  type: EVENTS.MOMENT_CLOSE;
  loopcount?: number;
  play: number;
  total: number;
}

interface MomentPlayEvent extends MomentEventType {
  type: EVENTS.MOMENT_PLAY;
}

interface MomentPauseEvent extends MomentEventType {
  type: EVENTS.MOMENT_PAUSE;
}

interface MomentNextEvent extends MomentEventType {
  type: EVENTS.MOMENT_NEXT;
  manual?: false;
}

interface MomentPrevEvent extends MomentEventType {
  type: EVENTS.MOMENT_PREV;
}

interface MomentPickEvent extends MomentEventType {
  type: EVENTS.MOMENT_PICK;
}

interface MomentProgressEvent extends MomentEventType {
  type: EVENTS.MOMENT_PROGRESS;
  position: number;
  loopcount: number;
  total: number;
  play: number;
}

interface MomentInfoFrameEvent extends MomentEventType {
  type: EVENTS.MOMENT_INFOFRAME;
}

interface MomentEmbedEvent extends MomentEventType {
  type: EVENTS.MOMENT_EMBED;
}

interface CollectionViewEvent extends EventType {
  type: EVENTS.COLLECTION_VIEW;
  collection_id: string;
}

export interface ShareEvent extends EventType {
  service: string;
  entity_type: What;
  entity_id: ID[];
  type: EVENTS.SHARE;
  stats: Record<LinkType, number>;
}

interface WidgetEvent extends EventType {
  type: EVENTS.WIDGET_ERROR | EVENTS.WIDGET_WARN;
}

export type MomentEvents = MomentViewEvent | MomentCloseEvent | MomentPlayEvent | MomentPauseEvent
| MomentNextEvent | MomentPrevEvent | MomentProgressEvent | AdEvent
| MomentInfoFrameEvent | MomentEmbedEvent | MomentPickEvent;

type CollectionEvents = CollectionViewEvent;

export type EventData = MomentEvents | CollectionEvents | ShareEvent | WidgetEvent;

const getUrl = (path: string): string => `${process.env.LOG_SERVER}${path}`;

const notifyLog = (url, json: object): boolean => {
  if (!navigator.sendBeacon) {
    return true;
  }

  try {
    return navigator.sendBeacon(
      url,
      new Blob([JSON.stringify(json)], { type: 'text/plain' }),
    );
  } catch (e) {
    logger.warn('cannot send events', e.message);
  }
  return false;
};

const flushEvents = async (): Promise<void> => {
  try {
    const content = persistent.get(paramName);

    if (!content) {
      return;
    }

    const events: EventData[] = JSON.parse(content);

    if (events.length === 0) {
      return;
    }

    debug('events to send', events);

    // for the events remaining in queue
    notifyLog(getUrl(SEND_EVENTS), { data: {
      type: 'LogEvents',
      attributes: { events: events.map((event) => ({ level: 'track', event })) },
    } });

    persistent.set(paramName);
  } catch (e) {
    logger.warn('cannot process events', { error: e.message });
  }
};

const flushEventsDebounced = debounce(flushEvents, TIMEOUT, { maxWait: MAX_TIMEOUT });

// flush stale events, drop remaining after (in case of failed send attempt)
flushEvents().then(() => persistent.set(paramName));

let extra: EventExtra = {};
export const setExtra = (e: EventExtra | ((e: EventExtra) => EventExtra)): void => {
  if (typeof e === 'function') {
    extra = e(extra);
  } else {
    extra = e;
  }
};

const cleanupEvent = (event: EventData): EventData => pickBy(
  event,
  (v) => v !== undefined && v !== null,
);

export const weplayedEvent = (
  eventData: EventData,
): void => {
  const url = String(document.location);
  const visitor_id = getVisitorId();

  const time = new Date().toISOString();
  const event: EventData = cleanupEvent({ ...extra, ...eventData, time, url, visitor_id });

  if (window.Appcues) {
    window.Appcues.track(event.type, event);
  }

  debug(`event to send: ${event.type}`, event);

  const queued = process.env.TRACK_EVENTS === 'true'
    ? notifyLog(getUrl(SEND_EVENT), { data: {
      type: 'LogEvent',
      attributes: { level: 'track', event },
    } })
    : true;

  if (!queued) {
    let events: EventData[] = [];

    try {
      events = JSON.parse(persistent.get(paramName));
      if (!Array.isArray(events)) {
        events = [];
      }
    } catch (e) {
      events = [];
    }

    events.push({ ...extra, ...event, time, url, visitor_id });
    persistent.set(paramName, JSON.stringify(events));
    flushEventsDebounced();
  }
};
