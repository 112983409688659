import * as cx from 'classnames';
import { curry, memoize } from 'lodash/fp';
import * as React from 'react';

import { getObjectKey } from 'common/utils/object-keys';

import * as s from './TabbedContent.m.less';
import { Props } from './types';

const MIN_SWIPE = 60;

const TabbedContentRef: React.ForwardRefRenderFunction<
HTMLDivElement, Props
> = function TabbedContentRef({
  className, tabsClassName, tabClassName, contentClassName, tabActiveClassName,
  tab: outerTab = 0, tabs, enabled = [],
  children, divider,
  onChange,
}, ref) {
  const [tab, setTab] = React.useState(outerTab);
  const contentRef = React.useRef<HTMLDivElement>();

  React.useEffect(
    () => {
      if (typeof outerTab === 'number') {
        setTab(outerTab);
      }
    },
    [outerTab],
  );

  const handleSwipe = (i: number): void => {
    setTab(i);

    if (onChange) {
      onChange(i, tabs[i]);
    }
  };

  const handleTab = memoize(curry((t: number, _e): void => handleSwipe(t)));

  const [posX, setPosX] = React.useState<[number, number]>([0, 0]);

  const handleTouchStart = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setPosX([e.touches[0].clientX, e.touches[0].clientX]);
  }, [setPosX]);

  const handleTouchMove = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setPosX([posX[0], e.touches[0].clientX]);
  }, [posX, setPosX]);

  const handleTouchEnd = React.useCallback(() => {
    const diff = posX[1] - posX[0];
    if (Math.abs(diff) > MIN_SWIPE) {
      handleSwipe(Math.max(0, Math.min(tabs.length - 1, diff > 0 ? tab - 1 : tab + 1)));
    }
  }, [posX, handleSwipe]);

  React.useEffect(() => {
    Array.prototype.forEach.call(
      contentRef.current?.children || [],
      (element: HTMLElement, idx) => {
        if (
          (idx !== tab || enabled[idx] === false)
          && !element.classList.contains(s.invisible)
        ) {
          element.classList.add(s.invisible);
        } else if (
          idx === tab
          && enabled[idx] !== false
          && element.classList.contains(s.invisible)
        ) {
          element.classList.remove(s.invisible);
        }
      },
    );
  }, [tab]);

  return (
    <div ref={ref} className={cx(s.root, className)}>
      <div className={cx(s.tabs, tabsClassName)}>
        {tabs.map((tb, idx) => {
          if (enabled[idx] === false) {
            return null;
          }

          const props = typeof tb !== 'object' ? { label: tb } : tb;
          const { label, className: cn, ...rest } = props;

          return (
            <button
              type="button"
              key={getObjectKey(props.label, idx)}
              {...rest}
              className={cx(
                s.tab,
                tabClassName,
                cn,
                tab === idx && s.active,
                tab === idx && tabActiveClassName,
              )}
              onClick={handleTab(idx)}
            >
              {label}
            </button>
          );
        })}
      </div>
      {divider}
      <div
        ref={contentRef}
        className={cx(s.content, contentClassName)}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </div>
    </div>
  );
};

export const TabbedContent: React.ForwardRefExoticComponent<
Props & React.RefAttributes<HTMLDivElement>
> = React.forwardRef<HTMLDivElement, Props>(TabbedContentRef);
