import { uniq, without } from 'lodash/fp';
import * as React from 'react';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';

import { useApplication } from 'common/hooks/useApplication';
import { event } from 'common/utils/analytics';

import { ConfirmationModal } from 'consumer/components/ConfirmationModal';
import { CollectionModal } from 'consumer/containers/CollectionModal';
import { EmbedModal } from 'consumer/containers/EmbedModal';
import { useMoments } from 'consumer/hooks/useMoments';
import { CLIENT_URLS } from 'consumer/routes';

import { defaultState } from './constants';
import { MomentActionBarContext } from './MomentActionBarContext';
import {
  Action, ActionType, MomentActionBarMomentsStatus,
  MomentActionBarProviderRefType, ProviderProps,
} from './types';

const MomentActionBarProvider: React.ForwardRefRenderFunction<
MomentActionBarProviderRefType,
ProviderProps
> = function MomentActionBarProvider({
  children, user,
  onStart, onAccept, onDecline, onUnauthorized,
}, ref) {
  // statusRef is a source of states between updates,
  // status/setStatus is used for notifying context only
  const statusRef = React.useRef<MomentActionBarMomentsStatus>(defaultState);
  const [status, setStatus] = React.useState<MomentActionBarMomentsStatus>(statusRef.current);

  const history = useHistory();

  const [action, setAction] = React.useState<Action>(null);
  const [showFlagDone, setShowFlagDone] = React.useState(false);
  const [note, setNote] = React.useState('');

  const {
    download: [download],
    like: [like],
    remove: [deleteMoment],
    flag: [flag],
    pin: [pin],
    uncollect: [uncollect],
  } = useMoments();

  const { broadcast } = useApplication();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateStatus = React.useCallback((
    momentId: string,
  ) => (
    where: keyof MomentActionBarMomentsStatus,
  ): (
    ) => void => {
    statusRef.current = {
      ...statusRef.current,
      [where]: uniq(statusRef.current[where].concat([momentId])),
    };
    setStatus(statusRef.current);

    return (): void => {
      statusRef.current = {
        ...statusRef.current,
        [where]: without([momentId], statusRef.current[where]),
      };
      setStatus(statusRef.current);
    };
  }, [setStatus, statusRef]);

  const run = React.useCallback(

    async (act: Action): Promise<void> => {
      const update = updateStatus(act.momentPk);

      if (act.action === 'download') {
        if (!statusRef.current.download.includes(act.momentPk)) {
          const reset = update('download');
          download({ uid: act.momentPk }).then(() => broadcast(
            'success',
            'Email with download with be sent shortly',
          )).catch((error) => {
            if (error.status === 403) {
              broadcast(
                'error',
                'Sorry, you do not have the necessary content rights to get this download',
              );
            } else {
              broadcast('error', 'Error occurred while requesting the download');
            }
          }).finally(reset);
        }
      } else if (act.action === 'like') {
        like({ uid: act.momentPk, like: act.like }).catch(() => {
          broadcast('error', 'Moment like failed');
        });
      } else if (act.action === 'remove') {
        if (!statusRef.current.remove.includes(act.momentPk)) {
          const reset = update('remove');
          uncollect({ uid: act.momentPk, collections: [act.collectionPk] }).then(() => {
            broadcast('success', 'Moment has been removed from collection');
          }).catch(() => {
            broadcast('success', 'Cannot remove moment from collection');
          }).finally(reset);
        }
      } else if (act.action === 'flag') {
        if (!statusRef.current.flag.includes(act.momentPk)) {
          const reset = update('flag');
          flag({ uid: act.momentPk, note: act.note }).then(() => {
            setShowFlagDone(true);
          }).catch(() => {
            broadcast('error', 'Cannot flag moment');
          }).finally(reset);
        }
      } else if (act.action === 'delete') {
        if (!statusRef.current.delete.includes(act.momentPk)) {
          const reset = update('delete');
          deleteMoment({ uid: act.momentPk }).then(() => {
            broadcast('success', 'Moment deleted');
          }).catch(() => {
            broadcast('error', 'Cannot delete moment');
          }).finally(reset);
        }
      } else if (act.action === 'share') {
        switch (act.target) {
          case 'native': {
            event({
              category: 'Sharing',
              action: 'Moment Mobile-Shared',
              label: act.momentPk,
            });
            break;
          }

          case 'facebook': {
            event({
              category: 'Sharing',
              action: 'Moment Shared on FB',
              label: act.momentPk,
            });
            break;
          }

          case 'twitter': {
            event({
              category: 'Sharing',
              action: 'Moment Shared on TW',
              label: act.momentPk,
            });
            break;
          }

          case 'copy': {
            event({
              category: 'Sharing',
              action: 'Moment URL Copied',
              label: act.momentPk,
            });
            break;
          }

          default:
            //
        }
      } else if (act.action === 'game') {
        history.push(CLIENT_URLS.GAME.VIEW.buildPath({
          uid: act.gamePk,
          muid: act.momentPk,
        }));
      } else if (act.action === 'pin') {
        if (!statusRef.current.pin.includes(act.momentPk)) {
          const reset = update('pin');
          pin({ uid: act.momentPk, pin: act.pin }).then(() => {
            broadcast('success', act.pin
              ? 'This moment was set as your Top Moment'
              : 'This moment is no longer your Top Moment');
          }).catch(() => {
            broadcast('error', 'Cannot pin moment');
          }).finally(reset);
        }
      }

      if (onAccept) {
        await onAccept(act, update);
      }
    },
    [
      download, like, uncollect, onAccept,
      updateStatus, pin, broadcast, flag, history, deleteMoment,
    ],
  );

  const invoke = React.useCallback(
    (act: Action, notify?: boolean) => {
      const requires = ([
        'edit', 'like', 'collection', 'delete', 'remove',
        'flag', 'pin', 'adjust', 'review',
      ] as ActionType[]).includes(act.action);

      if (requires && !user) {
        if (onUnauthorized) {
          onUnauthorized(act);
        }
        return;
      }

      if (onStart && notify !== false) {
        onStart(act);
      }

      if (notify) {
        return;
      }

      if (act.action === 'flag') {
        setNote('');
      }

      // these actions require confirmation so store params
      // and set action to raise modal
      if (['delete', 'remove', 'flag', 'embed', 'collection'].includes(act.action)) {
        setAction(act);
      } else {
        run(act);
      }
    },
    [onUnauthorized, run, user, onStart],
  );

  React.useImperativeHandle(ref, () => invoke, [invoke]);

  const handleConfirm = React.useCallback(
    (): void => {
      if (action.action === 'flag') {
        run({ ...action, note });
      } else {
        run(action);
      }
      setAction(null);
    },
    [run, action, note],
  );

  const handleDecline = React.useCallback(
    (): void => {
      setAction(null);

      if (onDecline) {
        onDecline(action);
      }
    },
    [onDecline, action],
  );

  const handleCollection = React.useCallback(
    async (result: boolean): Promise<void> => {
      if (result && onAccept) {
        await onAccept(action, updateStatus(action.momentPk));
      } else if (!result && onDecline) {
        onDecline(action);
      }

      // a way to avoid problem with collection modal close
      setTimeout(() => {
        setAction(null);
      }, 0);
    },
    [onAccept, onDecline, action, updateStatus],
  );

  const value = React.useMemo(() => ({ invoke, user, status }), [invoke, user, status]);

  const handleNote = React.useCallback(
    ({ currentTarget: { value: v } }: React.ChangeEvent<HTMLTextAreaElement>) => setNote(v),
    [],
  );

  return (
    <>
      <MomentActionBarContext.Provider value={value}>
        {children}
      </MomentActionBarContext.Provider>

      {action?.action === 'delete' && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Delete Moment"
          message="Are you sure you want to completely delete this moment?"
        />
      )}
      {action?.action === 'collection' && (
        <CollectionModal
          moments={[action.momentPk]}
          selectedId={action.collectionPk}
          onClose={handleCollection}
        />
      )}
      {action?.action === 'remove' && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Remove Moment from Playlist"
          message="Are you sure you want to remove this moment from the playlist?"
        />
      )}
      {action?.action === 'flag' && (
        <ConfirmationModal
          cancelText="No, Cancel"
          confirmText="Yes, Flag"
          onConfirm={handleConfirm}
          onCancel={handleDecline}
          title="Flag Content"
          message=""
        >
          <>
            Are you sure you want to flag this content as invalid?
            {user?.is_staff && (
              <Form.Control
                as="textarea"
                className="mt-2"
                onChange={handleNote}
                placeholder="Add note..."
                rows={4}
                value={note}
              />
            )}
          </>
        </ConfirmationModal>
      )}
      {action?.action === 'embed' && (
        <EmbedModal
          momentId={action.momentPk}
          onClose={handleDecline}
        />
      )}

      {showFlagDone && (
        <ConfirmationModal
          onConfirm={(): void => setShowFlagDone(false)}
          title="Flag Content"
          message={'Thank you for flagging this content as invalid. '
                                + 'We will review and take action.'}
        />
      )}

    </>
  );
};

const Component = React.forwardRef<
MomentActionBarProviderRefType,
ProviderProps
>(MomentActionBarProvider);

export { Component as MomentActionBarProvider };
