import { MomentActionBarMomentsStatus } from './types';

export const defaultState: MomentActionBarMomentsStatus = {
  delete: [],
  download: [],
  flag: [],
  pin: [],
  remove: [],
  review: [],
};
