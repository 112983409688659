/* eslint-disable no-console */
import { datadogLogs, LogsEvent } from '@datadog/browser-logs';

import { Debug } from 'common/utils/debug';

const debug = Debug.extend('logger');

const noop = (): void => {};

let hasDD = false;

const BAN_ERRORS = [
  // weird 3rd party errors
  'page-level tag does not work inside iframes',
  'xdm at top',
  'error: s:0 rs:4',
  'safari-extension',
  'cannot redefine property: googletag',
  // 'uncaught {"istrusted":true}',
  'uncaught "wallet not created"',
  'uncaught "empty reason"',
  'uncaught "not implemented on this platform"',
  'uncaught "object not found matching id:1, methodname:update, paramcount:4"',
  'cannot read properties of undefined (reading \'isbroken\')',
  // weird error coming from embed
  'div:has(> iframe[id=',
  // network error often appears when request was cancelled
  '0 networkerror',
];

const APP_ERRORS = [
  // this one originates from videojs error
  'cannot perform construct on a detached arraybuffer',
  // permission-related dom exceptions
  // 'play() can only be initiated by a user gesture',
  'the play() request was interrupted',
  // 'request was interrupted by a call to pause',
  'is not allowed by the user agent',
  // 'the operation was aborted',
  // 'fullscreen request denied',
  // 'because the user denied permission',
  'interact with the document first',

  // third-party script error
  'uncaught "script error."',

  // video player errors
  'videojs: error: (code:',
];

if (process.env.DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.DATADOG_TOKEN,
    service: 'browser',
    version: process.env.UI_VERSION,
    beforeSend: (log: LogsEvent): false | undefined => {
      const { error } = log;
      const stack = (typeof error?.stack === 'string' && error?.stack?.toLowerCase()) || '';
      const message = log.message?.toLowerCase() || '';
      const status_code = log.http?.status_code;

      if (
        // do not track extensions errors
        stack.includes('-extension://')
        // do not log network errors or aborted requests
        || status_code === 0
        // mute additional errors
        || BAN_ERRORS.some((text) => message.includes(text))
      ) {
        debug('%cNot sending log to DD because of precondition', 'color:green', log);
        return false;
      }

      // next errors considered as warnings
      if (
        // mute third party or already handled errors
        (error && (
          !stack || stack.startsWith('no stack, ')
          || error.handling === 'handled'
        ))
        // mute http errors of 4XX
        || [400, 401, 403, 404].some((code) => (
          code === status_code
          || message.startsWith(`httperror: ${code} `)
        ))
        // mute errors from ban list
        || APP_ERRORS.some((text) => message.includes(text))
      ) {
        debug('%cSend log message lowering level', 'color:yellow', log);
        /* eslint-disable no-param-reassign */
        log.status = 'warn';
        log.muted_error = log.error;
        delete log.error;
      } else {
        debug('%cSend log message original level', 'color:red', log);
      }

      // send nothing to DD not in live
      if (process.env.NODE_ENV !== 'production') {
        return false;
      }

      return undefined;
    },
  });

  hasDD = true;
}

export const logger = (hasDD && datadogLogs.logger)
|| (process.env.NODE_ENV === 'test' && {
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
})
|| console;
