import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { useApplication } from 'common/hooks/useApplication';
import { useAuth } from 'common/hooks/useAuth';
import { compose, withProps } from 'common/utils/recompose';

import { withSourceTracking } from 'consumer/components/UTMTracking';

import { App } from './App';
import { AppContextProps } from './types';

const withAppProps = withProps<AppContextProps>(() => {
  const { error, setAppCues, wlOrgId, authAwareAction, resetAuthAware } = useApplication();
  const { subscription, profile } = useAuth();
  const history = useHistory();
  const location = useLocation();

  return {
    authenticate: Boolean(authAwareAction),
    error,
    history,
    location,
    resetAuthenticate: resetAuthAware,
    setAppCues,
    subscription,
    user: profile,
    wlOrgId,
  };
});

const Component: React.ComponentType = compose(
  hot,
  withRouter,
  withSourceTracking,
  withAppProps,
)(App);

export { Component as App };
