import {
  ReactComponent as BookmarkMultiple,
} from '@mdi/svg/svg/bookmark-multiple.svg';
import * as React from 'react';
import Slider from 'react-slick';
import { Collection, Team } from 'weplayed-typescript-api';

import { CollectionTile } from 'consumer/components/CollectionTile';
import { TeamImage } from 'consumer/components/TeamImage';

interface Props {
  collections: Collection[];
  team: Team;
}

export const CollectionsSection: React.FC<Props> = function CollectionsSection({
  collections, team,
}) {
  if (!collections || collections.length === 0) {
    return null;
  }

  return (
    <>
      <div className="home-section-spacer" />
      <h2 className="home-section-header-title">
        <BookmarkMultiple className="section-title-icon-leading" />
        TOP PLAYLISTS
        <span className="home-page-section-header-spacer">—</span>
        {team.acronym}
        <TeamImage team={team} size="x-small" className="home-page-section-header-logo" />
      </h2>
      <hr />
      <Slider
        variableWidth
        infinite={false}
        draggable
        swipeToSlide
        speed={500}
        className="slider variable-width"
      >
        {collections.map((collection) => (
          <CollectionTile
            key={collection.pk}
            item={collection}
            moment_count={0}
            hide_created_by
          />
        ))}
      </Slider>
    </>
  );
};
