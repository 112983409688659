import { curry } from 'lodash/fp';
import * as React from 'react';

export const compose = <TInner, TOuter = unknown>(
  ...functions
): (c: React.ComponentType<TInner>) => React.ComponentType<TOuter> => functions.reduce(
  <Inner, Outer>(acc, com) => (
    (c: React.ComponentType<Inner>): React.ComponentType<Outer> => acc(com(c))
  ),
  (c: TInner): TInner => c,
);

type WithProps = <TInner, TOuter = unknown>(
  props: TInner | ((props: TOuter) => TInner)
) => (NextComponent: React.ComponentType<TInner & TOuter>) => (
  React.ComponentType<TOuter>
)

export const withProps: WithProps = curry((extra, NextComponent, props) => {
  const inner = typeof extra === 'function' ? extra(props) : extra;
  return <NextComponent {...props} {...inner} />;
});

interface LCTA<P> {
  props: P;
}

interface ReactLifeCycleFunctions<P, I> {
  componentDidMount?: (this: LCTA<P> & I) => void;
  componentDidUpdate?: (this: LCTA<P> & I, prevProps: P) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Lifecycle = <P, T = any>(lifecycle: ReactLifeCycleFunctions<P, T> & T) => (
  (component: React.ComponentType<P> & T) => React.ComponentType<P> & T
);

export const lifecycle: Lifecycle = curry((
  funcs,
  Comp,
) => {
  class Component extends React.PureComponent {
    render(): JSX.Element { return <Comp {...this.props} />; }
  }
  Object.assign(Component.prototype, funcs);

  return function WrappedFunc(props): React.ReactElement {
    return <Component {...props} />;
  };
});
