import { debounce } from 'lodash';
import * as React from 'react';

import { INACTIVITY_DEBOUNCE_TIMEOUT, INACTIVITY_INTERVAL } from './constants';
import { UseInactivityType } from './types';

const setters = [];
let timeout = 0;
let timer: ReturnType<typeof setTimeout>;

const scheduleTimer = (): void => {
  setters.forEach((setter) => setter(timeout));
  timer = setTimeout(() => {
    timeout += INACTIVITY_INTERVAL;
    scheduleTimer();
  }, INACTIVITY_INTERVAL);
};

const resetTimer = debounce(
  (): void => {
    clearTimeout(timer);
    timeout = 0;
    scheduleTimer();
  },
  INACTIVITY_DEBOUNCE_TIMEOUT,
  { leading: true, trailing: true, maxWait: INACTIVITY_DEBOUNCE_TIMEOUT },
);

const startTimer = (): void => {
  document.body.addEventListener('mousemove', resetTimer);
  document.body.addEventListener('touchstart', resetTimer);
  document.body.addEventListener('keypress', resetTimer);
  scheduleTimer();
};

const stopTimer = (): void => {
  clearTimeout(timer);
  document.body.removeEventListener('mousemove', resetTimer);
  document.body.removeEventListener('touchstart', resetTimer);
  document.body.removeEventListener('keypress', resetTimer);
};

export const useInactivity: UseInactivityType = function useInactivity(enabled = true) {
  const [inactivity, setInactivity] = React.useState<number>(0);

  React.useEffect(() => {
    if (enabled) {
      setters.push(setInactivity);
      if (setters.length === 1) {
        startTimer();
      }

      return (): void => {
        setters.splice(setters.indexOf(setInactivity), 1);
        if (setters.length === 0) {
          stopTimer();
        }
      };
    }
  }, [enabled]);

  return inactivity;
};
