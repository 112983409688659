import './ChannelTile.less';

import { ReactComponent as PlaylistPlay } from '@mdi/svg/svg/playlist-play.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { SYSTEM_USERS } from 'common/constants';
import { useProfile } from 'common/hooks/useProfile';

import { Avatar } from 'consumer/components/Avatar';
import { FollowButton } from 'consumer/components/FollowButton';
import { Renderer } from 'consumer/components/Tile/types';
import { CLIENT_URLS } from 'consumer/routes';

import { Props } from './types';

export const ChannelTile: React.FC<Props> = function ChannelTile({
  edit, item,
  size = 'large', className, withLink, showAthleteLink,
}) {
  const { profile } = useProfile();

  const tile = (
    <>
      <Avatar
        className="user-avatar"
        imageId={`image-channel-${item.pk}`}
        user={item.user}
        size="x-large"
        hideVerified
      />
      <div className="description">
        <div className="username-and-edit">
          <div className="username">
            <span>
              {`@${item.user.username}`}
            </span>
            {item.user.is_verified && (<span className="verified"><span>verified</span></span>)}
          </div>
          {edit && (
            <Link
              to={CLIENT_URLS.USER.MY_PROFILE.toPath()}
              className="btn btn-default edit-button"
            >
              Edit Profile
            </Link>
          )}
        </div>
        <div className="full-name">
          {item.user.full_name}
        </div>
        <div className="stats-row">
          <div className="stat">
            <div className="stat-count">
              {item.moments_curated_count
                + item.moments_liked_count}
            </div>
            <div className="stat-label">
              MOMENTS
            </div>
          </div>

          <div className="stat">
            <PlaylistPlay className="playlist-icon" />
            <div className="stat-count">
              {item.collection_created_count
                + item.collection_liked_count}
            </div>
            <div className="stat-label">
              PLAYLISTS
            </div>
          </div>
        </div>

        <div className="buttons-row">
          {(!profile || profile.pk !== item.user?.pk) && (
            <FollowButton
              type="channel"
              pk={item.pk}
              id="button-follow-channel"
            />
          )}
          {item.user.linked_players && showAthleteLink && (
            <Link
              to={CLIENT_URLS.PLAYER.VIEW.toPath({ uid: item.user.linked_players[0] })}
              className="btn btn-default"
            >
              Athlete
            </Link>
          )}
        </div>
      </div>
    </>
  );

  return withLink && !SYSTEM_USERS.includes(item?.user?.pk) ? (
    <Link
      className={cx('channel-tile', size, className)}
      to={CLIENT_URLS.USER.CHANNEL.buildPath({ uid: item.user.pk })}
    >
      {tile}
    </Link>
  ) : (
    <div className={cx('channel-tile', size, className)}>
      {tile}
    </div>
  );
};

export const channelRenderer: Renderer<What.CHANNELS> = (
  item,
  options,
) => (
  <ChannelTile
    {...options}
    item={item}
    withLink
  />
);
