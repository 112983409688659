export const isEmailValid = (email: string): boolean => (
  email && /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
);

export const isValidUsername = (username: string): boolean => (
  username
    && username.length >= 3
    && username.length <= 15
    && /^[a-zA-Z0-9_]+$/.test(username)
);

export const isValidPassword = (password: string): boolean => (
  password
    && password.length >= 8
);
