import { ReactComponent as ChevronLeft } from '@mdi/svg/svg/chevron-left.svg';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { useAuth } from 'common/hooks/useAuth';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useLoadOrg } from 'common/hooks/useLoadOrg';

import { TeamLogo } from 'consumer/components/TeamLogo';
import { TeamsList } from 'consumer/components/TeamsList';
import { CLIENT_URLS } from 'consumer/routes';

import { conferenceNameMap } from '../constants';
import * as s from './Details.m.less';
import { UrlProps } from './types';

export const SchoolsViewDetails: React.FunctionComponent = function SchoolsViewDetails() {
  const { uid } = useParams<UrlProps>();
  const { subscription, profile } = useAuth();
  const isAuthenticated = Boolean(profile);

  const data = useLoadOrg(null, uid);

  useHandleQuery(data);

  if (data.isLoading || data.isError) {
    return null;
  }

  const school = data.data;

  const title = `WePlayed Schools: ${[
    conferenceNameMap[school.conference],
    school.subconference,
    school.name,
  ].filter(Boolean).join(' / ')}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Schools and Teams on WePlayed" />
      </Helmet>
      <div className={s.root}>
        <h1>
          <TeamLogo
            disableLink
            team={school}
            size="normal"
          />
          <span>{school.name}</span>
        </h1>

        <Link to={CLIENT_URLS.SCHOOLS.LIST_VIEW.buildPath()}>
          <ChevronLeft />
        </Link>

        <TeamsList
          school={school}
          showGetNotified={!isAuthenticated && !subscription?.email}
        />
      </div>
    </>
  );
};
