let curId = 1;
const keys = new WeakMap();

export const getObjectKey = (object: unknown, append: string | number = ''): string => {
  let key;
  if (typeof object === 'string' || typeof object === 'number') {
    key = String(object);
  } else if (typeof object === 'object') {
    if (keys.has(object)) {
      key = keys.get(object);
    }

    if (!key) {
      key = String(curId += 1);
      keys.set(object, key);
    }
  }

  return `${key}${append}`;
};
