import { curry, memoize } from 'lodash/fp';
import * as React from 'react';
import { Button, FormCheck, Modal } from 'react-bootstrap';

import { persistent } from 'common/utils/persistent';

import * as s from './ConfirmationModal.m.less';
import { Props } from './types';

export class ConfirmationModal extends React.PureComponent<Props> {
  protected checkbox: HTMLInputElement;

  protected handleClick = memoize(curry((choice: boolean, _e: MouseEvent): void => {
    const {
      onCancel, onConfirm,
      rememberName, rememberConfirm, rememberCancel,
    } = this.props;

    if (this.checkbox
        && this.checkbox.checked
        && persistent.hasLS
        && ((choice && rememberConfirm) || (!choice && rememberCancel))) {
      persistent.set(`confirmation-modal-${rememberName}`, JSON.stringify(choice));
    }

    return choice ? onConfirm() : onCancel();
  }));

  static defaultProps: Pick<Props, OptionalKeys<Props>> = {
    rememberConfirm: true,
    rememberCancel: false,
    show: true,
  };

  protected testChoice = (): boolean => {
    const { onConfirm, onCancel, rememberName } = this.props;

    const choice = rememberName && persistent.get(`confirmation-modal-${rememberName}`);

    if (choice === 'true' && onConfirm) {
      onConfirm();

      return true;
    }

    if (choice === 'false' && onCancel) {
      onCancel();

      return true;
    }

    return false;
  };

  protected setRef = (checkbox: HTMLInputElement): void => {
    this.checkbox = checkbox;
  };

  public render(): JSX.Element {
    if (this.testChoice()) {
      return null;
    }

    const {
      children,
      confirmText,
      cancelText,
      id,
      onCancel,
      onConfirm,
      message,
      title,
      rememberName,
    } = this.props;

    return (
      <Modal
        id={id}
        show={this.props.show}
        keyboard
        onHide={onCancel}
        onEscapeKeyDown={onCancel}
        backdropClassName="modal-backdrop-blackout"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message || children}
          {rememberName && persistent.hasLS && (
            <div className={s.remember}>
              <FormCheck ref={this.setRef} value="aaaa">
                {(onConfirm && onCancel && 'don\'t ask again') || 'don\'t show again'}
              </FormCheck>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {onConfirm && (
            <Button
              className="btn btn-primary btn-normal"
              data-testid="confirmation-modal-ok"
              id={id ? `${id}-confirm` : undefined}
              onClick={this.handleClick(true)}
            >
              {confirmText || 'Ok'}
            </Button>
          )}
          {onCancel && (
            <Button
              className="btn btn-default btn-normal"
              data-testid="confirmation-modal-cancel"
              id={id ? `${id}-cancel` : undefined}
              onClick={this.handleClick(false)}
            >
              {cancelText || 'Cancel'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
