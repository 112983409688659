import { ReactComponent as Check } from '@mdi/svg/svg/check-circle-outline.svg';
import { ReactComponent as CodeTags } from '@mdi/svg/svg/code-tags.svg';
import { ReactComponent as Delete } from '@mdi/svg/svg/delete.svg';
import { ReactComponent as Download } from '@mdi/svg/svg/download.svg';
import { ReactComponent as Flag } from '@mdi/svg/svg/flag.svg';
import { ReactComponent as Pencil } from '@mdi/svg/svg/pencil.svg';
import { ReactComponent as PlaylistPlus } from '@mdi/svg/svg/playlist-plus.svg';
import * as cx from 'classnames';
import { ReactComponent as Loading } from 'images/spinner.svg';
import * as React from 'react';
import { Overlay } from 'react-bootstrap';
import { What } from 'weplayed-typescript-api';

import { ShareTarget } from 'common/types';
import { getMomentShareMeta } from 'common/utils/share';

import { LikeButton } from 'consumer/components/LikeButton';
import { Share } from 'consumer/components/Share';

import { MomentActionBarContext } from './MomentActionBarContext';
import * as s from './MomentActionDropdown.m.less';
import { Action, ActionType, DropdownProps } from './types';

const convertShare = (target: ShareTarget): Partial<Action> => ({ target });

export const MomentActionDropdown: React.FunctionComponent<
DropdownProps
> = function MomentActionDropdown({
  moment, collection, shareOptions, onClose, className, context,
  showCollectionAction, showDeleteAction, showDownloadAction,
  showEditAction, showEmbed, showFlagAction, showLikingAction,
  showReview,
  target,
}) {
  const { invoke, status, user } = React.useContext(MomentActionBarContext);

  const convertLike = (): Partial<Action> => ({ like: !moment.liked_by_user });
  const convertReview = (): Partial<Action> => ({ review: !moment.reviewed });

  const share = React.useMemo(() => moment && {
    pk: moment.pk,
    type: What.MOMENTS,
    meta: getMomentShareMeta(moment),
  }, [moment]);

  const handleAction = React.useCallback(
    (
      action: ActionType,
      args?: Partial<Action>,
      converter?: (...props: unknown[]) => Partial<Action>,
    ) => (...props: unknown[]): void => {
      onClose();
      invoke({
        ...args,
        ...(converter ? converter(...props) : null),
        action,
        momentPk: moment.pk,
        gamePk: moment.game_id || moment.game?.pk,
      });
    },
    [invoke, moment?.game?.pk, moment?.game_id, moment?.pk, onClose],
  );

  const handleClick = React.useCallback(
    (action: ActionType, args?: Partial<Action>) => (
      _e: React.MouseEvent<HTMLButtonElement>,
    ): void => {
      onClose();
      invoke({
        ...args,
        action,
        momentPk: moment.pk,
        gamePk: moment.game_id || moment.game?.pk,
      });
    },
    [invoke, moment?.game?.pk, moment?.game_id, moment?.pk, onClose],
  );

  return (
    <Overlay
      onHide={onClose}
      placement="left-start"
      rootClose
      rootCloseEvent="click"
      show={Boolean(target)}
      target={target}
    >
      {({ className: cs, style, ref }): React.ReactNode => (
        <div
          className={cx(s.root, className, cs)}
          ref={ref}
          style={style}
        >
          {moment && (
            <>
              <div>
                {showCollectionAction && (
                  <button
                    type="button"
                    id={`moment-actions-add-playlist-${moment.pk}`}
                    onClick={handleClick('collection', { collectionPk: collection?.pk })}
                  >
                    <PlaylistPlus />
                    Playlist
                  </button>
                )}

                {showDownloadAction && (
                  <button
                    type="button"
                    id={`moment-actions-download-${moment.pk}`}
                    onClick={handleClick('download')}
                  >
                    {status.download.includes(moment.pk) ? <Loading /> : <Download />}
                    Download
                  </button>
                )}
              </div>

              <div>
                <Share
                  context={context}
                  {...(shareOptions || {})}
                  meta={share}
                  onShare={handleAction('share', null, convertShare)}
                  withText
                >
                  Share
                </Share>

                {showLikingAction && (
                  <LikeButton
                    id={`moment-actions-like-${moment.pk}`}
                    liked={moment.liked_by_user}
                    likes={!user?.is_cms_user}
                    withText
                    onChange={handleAction('like', null, convertLike)}
                  />
                )}

                {showEmbed && (
                  <button
                    type="button"
                    id={`moment-actions-embed-${moment.pk}`}
                    onClick={handleClick('embed')}
                  >
                    <CodeTags />
                    Embed
                  </button>
                )}
              </div>

              <div>
                {showEditAction && (
                  <button
                    // WPM-1589
                    className="no-mobile"
                    onClick={handleClick('edit')}
                    type="button"
                  >
                    <Pencil />
                    Edit
                  </button>
                )}

                {showReview && (
                  <button
                    className={cx(moment.reviewed && s.reviewed)}
                    type="button"
                    onClick={handleAction('review', null, convertReview)}
                  >
                    <Check />
                    Review
                  </button>
                )}

                {showFlagAction && (
                  <button
                    id={`moment-actions-flag-${moment.pk}`}
                    onClick={handleClick('flag')}
                    type="button"
                  >
                    {status.flag.includes(moment.pk) ? <Loading /> : <Flag />}
                    Flag
                  </button>
                )}
              </div>

              <div>
                {showDeleteAction && (
                  <button
                    className={s.danger}
                    id={`moment-actions-delete-${moment.pk}`}
                    onClick={handleClick('delete')}
                    type="button"
                  >
                    {status.delete.includes(moment.pk) ? <Loading /> : <Delete />}
                    Delete
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </Overlay>
  );
};
