// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNowStrict } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import locale from 'date-fns/locale/en-US';

interface FormatDurationOptions {
  fraction?: number;
  keepHours?: boolean;
}

export const formatDuration = (
  time: number,
  opts?: FormatDurationOptions,
): string => {
  const { fraction, keepHours } = {
    fraction: 0,
    keepHours: false,
    ...opts,
  };
  const pow = 10 ** fraction;

  const h = Math.floor(time / 3600);
  const m = Math.floor((time - h * 3600) / 60);
  const s = Math.floor(time - h * 3600 - m * 60);
  const f = Math.round((time - h * 3600 - m * 60 - s) * pow);

  let str = '';
  if (h || keepHours) {
    str += `${h}:`;
  }
  str += `${h ? String(m).padStart(2, '0') : m}:`;
  str += String(s).padStart(2, '0');

  if (fraction) {
    str += '.';
    str += String(f).padStart(fraction, '0');
  }

  return str;
};

export const formatDistance = (
  time: number,
  short?: boolean,
): string => {
  if (!short) {
    return `${formatDistanceToNowStrict(time)} ago`;
  }

  return formatDistanceToNowStrict(time, {
    locale: {
      ...locale,
      formatDistance: (token, count) => {
        const result = {
          lessThanXSeconds: '{{count}}s',
          xSeconds: '{{count}}s',
          halfAMinute: '30s',
          lessThanXMinutes: '{{count}}m',
          xMinutes: '{{count}}m',
          aboutXHours: '{{count}}h',
          xHours: '{{count}}h',
          xDays: '{{count}}d',
          aboutXWeeks: '{{count}}w',
          xWeeks: '{{count}}w',
          aboutXMonths: '{{count}}m',
          xMonths: '{{count}}m',
          aboutXYears: '{{count}}y',
          xYears: '{{count}}y',
          overXYears: '{{count}}y',
          almostXYears: '{{count}}y',
        }[token].replace('{{count}}', count);

        return result;
      },
    },
  });
};

export const toTS = (
  date: Date | undefined,
  ignoreTZ = false,
): number | undefined => (date
  ? Math.floor((date.valueOf() - (ignoreTZ ? date.getTimezoneOffset() * 60000 : 0)) / 1000)
  : undefined);

export const fromTS = (
  ts: number | undefined,
  fixTZ = false,
): Date | undefined => (ts
  ? (new Date(ts * 1000 + (fixTZ ? new Date().getTimezoneOffset() * 60000 : 0)))
  : undefined);
